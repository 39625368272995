let consoleStorage = [];
const timers = {};

var checkTheLimit = function() {
    consoleStorage.length > 500 && (consoleStorage = consoleStorage.slice(-500));
}.debounce(250);

const push2Storage = function(type, args) {
    for (var r = [], s = '', l = args.length, i = 0; i < l; i++) {
        s = null;
        switch (typeof args[i]) {
            case 'object':
                s =
                    args[i] instanceof Array
                        ? args[i] + ''
                        : JSON.stringify(args[i], function(k, v) {
                              if (typeof v == 'object') return '[object]';
                          });
                break;
            case 'function':
                s = '[function]';
                break;
            default:
                args[i] && (s = args[i].toString());
                break;
        }
        s && r.push(s);
    }
    consoleStorage.push(type + ': ' + r.join(', '));
    checkTheLimit();
};

const __savedConsoleObject = window.console;

window['restoreWebConsole'] = function() {
    window.console = __savedConsoleObject;
    // window.onerror = null;
    __savedConsoleObject = null;
};

!__DEV__ &&
    (window.console = {
        log: function() {
            push2Storage('LOG', arguments);
        },
        warn: function() {
            push2Storage('WARN', arguments);
        },
        info: function() {
            push2Storage('INFO', arguments);
        },
        assert: function() {
            ![].slice.call(arguments, 0, 1) && push2Storage('ASSERT', arguments);
        },
        error: function() {
            push2Storage('ERROR', arguments);
        },
        debug: function() {
            push2Storage('DEBUG', arguments);
        },
        getMessages: function() {
            return consoleStorage.join('\n');
        },
        getMessagesAsArray: function() {
            return consoleStorage;
        },
        time: function(id) {
            timers[id] = new Date().getTime();
        },
        timeEnd: function(id) {
            push2Storage('TIME', [id + ' ' + (new Date().getTime() - timers[id]) + 'ms']);
            delete timers[id];
        }
    });
