function casualLink() {
    let source;
    let target;
    let ver = 0;
    let linked = false;
    const abs = Math.abs;

    let taskHeight = 0;
    let taskWidth = 0;
    let taskHalfWidth = 0;
    let taskHalfHeight = 0;
    const margin = 30;

    const between = function between(val, sp, lp) {
        return val >= sp && val <= lp;
    };
    const getMin = function getMin(v) {
        return abs(v);
    };

    function link(d, i, justCoordinates) {
        const p0 = source.call(this, d, i),
            p3 = target.call(this, d, i);

        if (p0.h) {
            taskHeight = p0.h;
            taskHalfHeight = p0.h / 2;
        }

        switch (ver) {
            default:
                const p0x = p0.x + taskHalfWidth;
                const p0y = p0.y + taskHeight;
                const p3x = p3.x + taskHalfWidth;
                const p3y = p3.y - 2;
                var cx = (p0x - p3x) / 2;
                var cy = (p0y - p3y) / 2;

                return justCoordinates
                    ? { x: p0x, y: p0y, x1: p3x, y1: p3y }
                    : p0.x == p3.x && p0.y == p3.y
                    ? `M${[p0x, p0y]} l0,1`
                    : `M${[p0x, p0y]} Q${[p0x, p0y + margin]} ${[p0x - cx, p0y - cy]} T${[p3x, p3y]}`;

            case 1:
                const $fo = p0;
                const $to = p3;
                const dx = $fo.x - $to.x;
                const dy = $fo.y - $to.y;
                const angle = (Math.atan2(dy, dx) * 180) / Math.PI;
                const aangle = abs(angle);
                const distance = Math.sqrt(dx * dx + dy * dy);
                const distanceX = abs([dx, $fo.x + taskWidth - $to.x].min(getMin));
                const distanceY = abs([dy, $fo.y + taskHeight - $to.y].min(getMin));
                const p = [];

                let nfox;
                let nfoy;
                let ntox;
                let ntoy;
                let ofsp;
                switch (true) {
                    case between(aangle, 0, 45): // left quoter
                        switch (true) {
                            case between(angle, 10, 45):
                                nfox = $fo.x;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x + taskWidth + 2;
                                ntoy = $to.y + taskHalfHeight;
                                ofsp = [nfox - margin, nfoy];
                                break;
                            case angle < 0 && dx < taskWidth && abs(dy) < taskHeight + margin:
                                nfox = $fo.x;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x + taskHalfWidth;
                                ntoy = $to.y - 2;
                                ofsp = `C${[nfox - margin, nfoy]} ${[ntox, ntoy - margin]} ${[ntox, ntoy]}`;
                                break;
                            case between(aangle, 0, 10):
                                nfox = $fo.x;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x + taskWidth + 2;
                                ntoy = $to.y + taskHalfHeight;
                                break;
                            default:
                                nfox = $fo.x + taskHalfWidth;
                                nfoy = $fo.y + taskHeight;
                                ntox = $to.x + taskHalfWidth;
                                ntoy = $to.y;
                                ofsp = [nfox, nfoy + margin];
                                break;
                        }
                        break;
                    case angle < 0 && between(aangle, 45, 135): // bottom
                        nfox = $fo.x + taskHalfWidth;
                        nfoy = $fo.y + taskHeight;
                        ntox = $to.x + taskHalfWidth;
                        ntoy = $to.y - 2;
                        ofsp = [nfox, nfoy + margin];
                        break;
                    case between(aangle, 170, 180): // right
                        nfox = $fo.x + taskWidth;
                        nfoy = $fo.y + taskHalfHeight;
                        ntox = $to.x - 2;
                        ntoy = $to.y + taskHalfHeight;
                        ofsp = [nfox, nfoy + margin];
                        break;
                    case angle > 0 && between(angle, 45, 170): // top
                        switch (true) {
                            case $fo.x + taskWidth > $to.x:
                                nfox = $fo.x + taskWidth;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x + taskWidth;
                                ntoy = $to.y + taskHalfHeight;
                                ofsp = `C${[nfox + margin, nfoy]} ${[ntox + margin, ntoy]} ${[ntox, ntoy]}`;
                                break;
                            default:
                                nfox = $fo.x + taskWidth;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x - 2;
                                ntoy = $to.y + taskHalfHeight;
                                ofsp = [nfox + margin, nfoy];
                                break;
                        }
                        break;
                    case angle < 0 && between(aangle, 135, 170):
                        switch (true) {
                            case $to.x < $fo.x + taskWidth && distanceY > taskHalfHeight:
                                nfox = $fo.x + taskHalfWidth;
                                nfoy = $fo.y + taskHeight;
                                ntox = $to.x + taskHalfWidth;
                                ntoy = $to.y;
                                ofsp = [nfox, nfoy + margin];
                                break;
                            case $to.x < $fo.x + taskWidth && distanceY < taskHalfHeight:
                                nfox = $fo.x + taskWidth;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x + taskHalfWidth;
                                ntoy = $to.y;
                                ofsp = `C${[nfox + margin, nfoy]} ${[ntox, ntoy - margin]} ${[ntox, ntoy]}`;
                                break;
                            case distanceX > taskHalfWidth && distanceY < taskHalfHeight:
                                nfox = $fo.x + taskWidth;
                                nfoy = $fo.y + taskHalfHeight;
                                ntox = $to.x;
                                ntoy = $to.y + taskHalfHeight;
                                ofsp = [nfox + margin, nfoy];
                                break;
                            default:
                                nfox = $fo.x + taskHalfWidth;
                                nfoy = $fo.y + taskHeight;
                                ntox = $to.x + taskHalfWidth;
                                ntoy = $to.y;
                                ofsp = [nfox, nfoy + margin];
                                break;
                        }
                        break;
                }

                p.push(`M${[nfox, nfoy]}`);
                // stright left, right, top and bottom
                if (
                    distance < 100 ||
                    between(aangle, 0, 10) ||
                    (angle < 0 && between(aangle, 80, 100)) ||
                    between(aangle, 170, 180)
                ) {
                    p.push(` L${[ntox, ntoy]}`);
                } else {
                    var cx = (nfox - ntox) / 2;
                    var cy = (nfoy - ntoy) / 2;

                    if (ofsp instanceof Array) {
                        p.push(` Q${ofsp}`);
                        p.push(` ${[nfox - cx, nfoy - cy]}`);
                        p.push(` T${[ntox, ntoy]}`);
                    } else {
                        p.push(ofsp);
                    }
                }

                // console.warn(distance, angle);
                if (this instanceof SVGPathElement) {
                    $(this).data({
                        s: {
                            x: nfox,
                            y: nfoy
                        },
                        e: {
                            x: ntox,
                            y: ntoy
                        }
                    });
                }

                return justCoordinates ? { x: nfox, y: nfoy, x1: ntox, y1: ntoy } : p.join('');
                break;
        }
    }

    link.source = function(x) {
        if (!arguments.length) return source;
        source = x;
        return link;
    };

    link.target = function(x) {
        if (!arguments.length) return target;
        target = x;
        return link;
    };

    link.useVersion = x => {
        ver = x;
        return link;
    };

    link.useAbsoluteCoord = x => {
        linked = x;
        return link;
    };

    link.useBoxDim = (width, height) => {
        taskWidth = width;
        taskHeight = height;
        taskHalfWidth = taskWidth / 2;
        taskHalfHeight = taskHeight / 2;
        return link;
    };

    return link;
}
