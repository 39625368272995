/**
 * @param {cTaskInfo} task
 * @returns {boolean}
 */
const isDeadlineAlert = task => {
    const { state, deadline, values } = task;
    const now = new Date();
    const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
    const lateFlag=values && (values["willFinishLate"] || values["lateHours"]);

    return state != enmTaskInfoStates.Completed && (lateFlag || (deadline ? deadline < startTime : false));
};
/**
 * @extends {cTaskInfo}
 */
(p => {
    p.isDeadlineAlert = function() {
        // return (
        //     (this.deadline &&
        //         this.state != enmTaskInfoStates.Completed &&
        //         moment(this.deadline)
        //             .add(1, 'd')
        //             .isBefore()) ||
        //     false
        // );
        const now = new Date();
        const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();
        const lateFlag=this.values && (this.values["willFinishLate"] || this.values["lateHours"]);
        return this.state != enmTaskInfoStates.Completed && (lateFlag || (this.deadline ? this.deadline < startTime : false));
    };

    function sortUsers(a, b) {
        switch (true) {
            case a.t > b.t:
                return 1;
            case a.t < b.t:
                return -1;
            case a.t == b.t:
                switch (true) {
                    case a.s > b.s:
                        return 1;
                    case a.s < b.s:
                        return -1;
                    case a.s == b.s:
                        return 0;
                }
        }
    }

    p.execIds = function() {
        if (this._execsHash) {
            return this._execIds;
        } else {
            const isMine = this.execs.indexOf($scope[_bndCurrentWorkspaceUser].id) > -1,
                team = $scope[_bndProjectTeams].$item && this.teamId && $scope[_bndProjectTeams].$item(this.teamId),
                ids = this.execs
                    .map(id => {
                        const u = $scope[_bndWorkspaceUsers].$item(id),
                            r =
                                (u && {
                                    id,
                                    s: u.firstName !== undefined && `${u.firstName} ${u.lastName}`,
                                    t: (u.id == $scope[_bndCurrentWorkspaceUser].id && 1) || 3
                                }) ||
                                undefined;
                        return r;
                    })
                    .compact();

            if (team) {
                ids.push({
                    id: team.id,
                    s: team.symbol || team.name,
                    t: 2
                });
            }

            ids.sort(sortUsers);
            return (this._execIds = ids.map(obj => obj.id).slice(0, 4));
        }
    };

    p.execsHash = function() {
        if (this._execsHash) {
            return this._execsHash;
        } else {
            const ids = this.execIds();
            return (this._execsHash = (ids.length && md5(ids.join())) || 'null');
        }
    };
})($classes[_cTaskInfo].prototype);
