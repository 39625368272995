/**
 * @fileoverview Binds implementation
 */

const /** @const */ _gcBindsDefaultSubset = '--def--';
const /** @const */ _beNoEscape = 1;
const /** @const */ _beHTMLEscape = 2;
const /** @const */ _beValueEscape = 3;

function procValue(value) {
    return value instanceof Function ? value.call(this, Array.prototype.slice.call(arguments, 1)) : value;
}

/**
 * Implement binding element-data
 * @constructor
 * @private
 * @param {HTMLElement} el element to process
 * @param {string} rule binding rule
 */
function Bind2Element(el, rule) {
    const str = rule.match(/^(!)?([\w\-\.\$]+)(?:([@:])(.*?)(?:=(.*))?)?$/);
    if (!str) {
        throw new Error(`data-bind contain wrong structure "${str}"`);
    }
    const field = str[2];
    const sep = str[3];
    let target = str[4];
    const defVals = str[5];

    /** @type {function} */
    let valSet;

    this.id = str[0];
    this.defGet = str[1] != undefined;

    let tpl;
    const $el = $(el);
    if ((tpl = $el.data('bind-repeat'))) {
        !this._binds && (this._binds = new Binds());
        // if ($el.data('bind-use-d3')) {
        /** @type {string} */
        let cnt = $el.data('bind-container').toString();

        if (/\./.test(cnt)) {
            let tmp = cnt.split('.');
            cnt = {
                el: tmp[0],
                cls: tmp.slice(1).join(' '),
                full: cnt,
                layoutProcessor: $el.data('bind-layout-processor'),
                postProcessor: $el.data('bind-postprocessor')
            };
        }

        this._d3data = {
            tpl,
            cnt,
            idfield: $el.data('bind-id-field') || 'id',
            each: $el.data('bind-each'),
            spinner: $el.data('bind-spinner'),
            empty: $el.data('bind-ifempty')
            // paging: {
            //     curPage: 0,
            //     perPage: $el.data('bind-paging'),
            //     enabler: $el.data('bind-use-paging')
            // },
            // pager: $el.data('bind-pager'),
            // updateEvent: $el.data('bind-update-event')
        };

        $(window).bind(
            'resize',
            (() => {
                const d3d = this._d3data || [],
                    els = d3.select(this.el).selectAll(d3d.cnt.full || d3d.cnt);

                if (d3d.cnt.layoutProcessor) {
                    core_exp[d3d.cnt.layoutProcessor].call(null, this.el, els);
                }
            }).throttle(100)
        );

        this.set = function(data) {
            let lf = false;
            const d3d = this._d3data || [];
            // var curPage = d3d.paging.curPage || 0;
            // var perPage = d3d.paging.perPage;
            // var paged = false;
            // var total = 0;
            // var updateEvent = d3d.updateEvent;

            if (data && data.length == 1 && data[0] == null) {
                lf = true;
                data = [];
                // d3d.paging.curPage = 0;
            }
            //  else if (
            //     data &&
            //     d3d.paging.perPage < data.length &&
            //     (d3d.paging.enabler && core_exp[d3d.paging.enabler].call(null, this.el))
            // ) {
            //     total = Math.ceil(data.length / perPage);
            //     var c = curPage * perPage;
            //     data = data.slice(c, c + perPage);
            //     paged = true;
            // }

            const els = d3
                .select(this.el)
                .selectAll(d3d.cnt.full || d3d.cnt)
                .data(data || [], (d3d.idfield && (d => d && core_utils.ns(d, d3d.idfield))) || undefined);

            if (!lf) {
                if (d3d.$spinner) {
                    d3d.$spinner.remove();
                    d3d.$spinner = null;
                }

                els.enter()
                    .append((d3d.cnt && d3d.cnt.el) || d3d.cnt || div)
                    .classed((d3d.cnt && d3d.cnt.cls) || d3d.cnt, d3d.cnt && !!d3d.cnt.cls);

                els.exit().remove();

                if (!data || (data && data.length == 0 && d3d.empty)) {
                    if (core_exp[d3d.empty] instanceof Function) {
                        const r = core_exp[d3d.empty].call(this.el);
                        r &&
                            $(`<${(d3d.cnt && d3d.cnt.el) || d3d.cnt || div} />`)
                                .addClass((d3d.cnt && d3d.cnt.cls) || d3d.cnt)
                                .appendTo(this.el)
                                .append(r);
                    }
                } else {
                    //     var pagers = $('.' + _bndPager, this.el);
                    //     if (paged) {
                    //         if (!pagers.length) {
                    //             $(this.el)
                    //                 .on('click', '.' + _clsPagerButton + '.' + _bndPagerNext, function() {
                    //                     d3d.paging.curPage++;
                    //                     updateEvent && core.moses.announce(updateEvent);
                    //                     console.log('next page', d3d.paging);
                    //                 })
                    //                 .on('click', '.' + _clsPagerButton + '.' + _bndPagerPrev, function() {
                    //                     d3d.paging.curPage--;
                    //                     updateEvent && core.moses.announce(updateEvent);
                    //                     console.log('prev page', d3d.paging);
                    //                 })
                    //                 .on('click', '.' + _clsPagerButton + '.' + _bndPagerOnPage, function(ev) {
                    //                     d3d.paging.curPage = $(this).data('page');
                    //                     updateEvent && core.moses.announce(updateEvent);
                    //                     console.log('jump to page', d3d.paging);
                    //                 });
                    //             d3d.paging.curPage = 0;
                    //         }
                    //         var pager =
                    //             d3d.pager &&
                    //             core_exp[d3d.pager].call(this, {
                    //                 curPage: curPage,
                    //                 // perPage: perPage,
                    //                 total: total
                    //             });
                    //         pagers.remove();
                    //         $(this.el).prepend(pager);
                    //         $(this.el).append(pager);
                    //         // pagers.find('.' + _clsPagerCurrentButton).removeClass(_clsPagerCurrentButton);
                    //         // pagers
                    //         //     .find('.' + _bndPagerOnPage + '[data-page="' + d3d.paging.curPage + '"]')
                    //         //     .addClass(_clsPagerCurrentButton);
                    //     } else {
                    //         pagers.length && pagers.remove();
                    //         // $(this.el).off('click');
                    //     }
                    els.html(function(d) {
                        return (!lf && core_exp[d3d.tpl].call(this, d, undefined, { b_item: d })) || null;
                    }).order();

                    d3d.each && els.each(core_exp[d3d.each]);

                    if (d3d.cnt.layoutProcessor) {
                        core_exp[d3d.cnt.layoutProcessor].call(null, this.el, els);
                    }
                }

                if (d3d.cnt.postProcessor) {
                    core_exp[d3d.cnt.postProcessor].call(this.el, data, els);
                }
            } else {
                els.exit().remove();
                if (!d3d.$spinner && $(this.el).find('div.spinner').length == 0) {
                    d3d.$spinner = $((d3d.spinner && core_exp[d3d.spinner]()) || tpls.components.spinner());
                }
                $(this.el).append(d3d.$spinner);
                // $('.' + _bndPager, this.el).remove();
            }
            return this;
        };
        this.get = function() {
            const d3d = this._d3data || [],
                els = d3.select(this.el).selectAll(d3d.cnt.full || d3d.cnt);
            return els;
        };
        // } else {
        //     this.set = function (d) {
        //         if (d == null) {
        //             this._binds.clearNSs();
        //             $(this.el).empty();
        //         } else {
        //             if (typeof(d) == 'string') {
        //                 this._binds.clearNSs();
        //                 $(this.el).empty().html(d);
        //             } else {
        //                 var s = [];
        //                 for (var i in d) {
        //                     if (d[i] == null && this._binds.isNS(i)) {
        //                         this._binds.ns(i).rmEl();
        //                         this._binds.removeNS(i);
        //                     } else if (this._binds.isNS(i)) {
        //                         this._binds.ns(i).set(d[i]);
        //                     } else {
        //                         var $el = $(core_exp[tpl](d[i]));
        //                         this._binds
        //                             .ns(i, $el)
        //                             .set(d[i]);
        //                         s.push($el);
        //                     }
        //                 }
        //                 $(this.el).append(s);
        //             }
        //         }
        //         return this;
        //     }
        //     this.applySort = function (sortFunc) {
        //
        //     }
        // }
    }

    if (sep != ':') {
        switch (true) {
            case target == undefined || target == '' || target == 'noesc':
                switch (el.tagName.toUpperCase()) {
                    case 'SELECT':
                    case 'INPUT':
                    case 'TEXTAREA':
                        this.escType = _beNoEscape;
                        target = '.value';
                        break;
                    case 'IMG':
                        this.escType = _beNoEscape;
                        target = '.src';
                        break;
                    case 'TEXT':
                        this.escType = target == 'noesc' ? _beNoEscape : _beHTMLEscape;
                        target = '.textContent';
                        break;
                    default:
                        this.escType = target == 'noesc' ? _beNoEscape : _beHTMLEscape;
                        target = '.innerHTML';
                        break;
                }
                !this.defGet && (this.defGet = true);
                break;
            case /^class/.test(target):
                target = ''; //target.replace(/^\.class/, '.classList');

                valSet = d => {
                    if (this._oldValue === d) {
                        return;
                    }

                    const nv = procValue(d);

                    if (this.el instanceof SVGElement) {
                        if (this._oldValue) {
                            d3.select(this.el).classed(this._oldValue, false);
                        }
                        if (nv) {
                            d3.select(this.el).classed(nv, true);
                        }
                    } else {
                        if (this._oldValue) {
                            $(this.el).removeClass(this._oldValue);
                        }
                        if (nv) {
                            $(this.el).addClass(nv);
                        }
                    }
                    this._oldValue = nv;
                };

                this.get = function() {
                    return this._oldValue;
                };
                break;
            case /^css/.test(target): // field@css.display=(none|block)
                target = target.replace(/^css/, '.style');
                valSet = d => {
                    $(this.el).css(this.prop, procValue(d));
                };
                break;
            case /^ds/.test(target):
                //                target = target.replace(/^ds/, '.dataset');
                target = target.replace(/^ds\./, '');
                this.set = function(d) {
                    $(this.el).data(this.prop, procValue(d));
                };
                this.get = function() {
                    return $(this.el).data(this.prop);
                };
                break;
            case /^attr\./.test(target): {
                const tmp = target.match(/^attr\.([\w:]+)$/);
                var prop = tmp[1];

                valSet = d => {
                    $(this.el).attr(prop, procValue(d));
                };

                this.get = function() {
                    return $(this.el).attr(prop);
                };
                break;
            }
            case /^xlink/.test(target): {
                const tmp = target.match(/^xlink\:([\w]+)/);
                let ns = null;
                const prop = tmp[1];

                if (prop == 'href') {
                    ns = 'http://www.w3.org/1999/xlink';
                }

                valSet = d => {
                    const nv = (prop == 'href' && `#${procValue(d)}`) || procValue(d);
                    this.el.setAttributeNS(ns, prop, d);
                };

                this.get = function() {
                    let r = this.el.getAttribureNS(ns, prop);
                    if (prop == 'href' && r) {
                        r = r.relpace(/^\#/, '');
                    }
                    return r;
                };
                break;
            }
        }
    } else {
        this.set = function() {
            let args = [this.el];
            const ar = Array.prototype.slice.call(arguments);

            if (/\./.test(this.field)) {
                const ns = this.field.replace(/\.\w+$/, '');
                args.push(ar[0]);
                // args.push(ns.namespace(arguments[1]));
                args.push(core_utils.getFromObj(procValue(ar[1]), ns));
                args = core_utils.concatArrays(args, ar.slice(2));
            } else {
                args = core_utils.concatArrays(args, ar);
            }
            core_exp[this.prop].apply(null, args);
        };
    }

    if (defVals) {
        switch (defVals[0]) {
            case '(':
                var subs = defVals.substr(1, defVals.length - 2).split('|') || undefined;
                this.set = function(d) {
                    const nv = procValue(d);
                    let v;
                    switch (true) {
                        case isNaN(nv):
                            if (subs.length == 2) {
                                v = subs[Number(!!nv)];
                            } else {
                                v = subs[Number(nv !== '' && nv != undefined)];
                            }
                            break;
                        default:
                            const tmp = Number(nv);
                            v = tmp >= subs.length ? subs.last() : subs[tmp];

                            break;
                    }

                    switch (v) {
                        case undefined:
                            v = '';
                            break;
                        case '%val%':
                            v = d;
                            break;
                    }

                    valSet(v);
                    return this;
                };
                break;
            case '[':
                var subs = JSON.parse(
                    defVals
                        .replace(/\|/g, ',')
                        .replace(/'/g, '"')
                        .replace(/\[/g, '{')
                        .replace(/\]$/, '}')
                );
                this.set = d => {
                    let tmp = subs[procValue(d)];
                    if (!tmp && subs[_gcBindsDefaultSubset] != undefined) {
                        tmp = subs[_gcBindsDefaultSubset];
                    }
                    valSet(tmp);
                };
                break;
            case '#':
                switch (defVals[1]) {
                    case 'd': // Date
                        this.set = function(d) {
                            const nv = procValue(d);
                            if (!nv && $(this.el).data('rollback')) {
                                valSet($(this.el).data('rollback'));
                            } else {
                                valSet(moment(nv).format(defVals.substr(2)));
                            }
                        };
                        break;
                    case 'c': // custom
                        const func = core_exp[defVals.substr(3)];
                        this.set = function(...args) {
                            valSet(func(...args));
                        };
                        this.get = () => {};
                        break;
                    case 't': // template
                        tpl = core_exp;
                        break;
                    case 'i': // Integer
                        this.set = d => {
                            //TODO: integer formating implementation
                        };
                        break;
                    case 'm': // Money
                        this.set = d => {
                            //TODO: money format implementation
                        };
                        break;
                    case 'r':
                        var m = defVals.match(/^#r:(\w+)\(([^\)]+)/);
                        DEBUG && console.assert(m);

                        this._data = {
                            func: m[1],
                            subs: m[2].split('|')
                        };

                        this.set = function(...args) {
                            valSet(this._data.subs[Number(core_exp[this._data.func].apply(null, args))]);
                        };
                        break;
                    case 'p': // process value through callback: field@=#p:someFunc
                        var m = defVals.match(/^#p:(\w+)/);
                        this.set = function(...args) {
                            valSet(core_exp[m[1]].apply(null, args));
                        };
                        break;
                }
                break;
        }
    }

    target = target.replace(/^\./, '').split('.');

    let n = el,
        i = 0;
    this.prop = target.pop();
    do {
        n = (!target[i] && n) || n[target[i]];
    } while (++i < target.length);

    this.el = el;
    this.field = field;
    this.bind = (n instanceof Function && n.bind(el)) || n;

    !valSet &&
        (valSet = v => {
            if (core.checkMutex && core.checkMutex(this.el)) {
                return;
            }

            let nv = procValue(v);

            if (this.bind && this.prop) {
                const m = this.bind[this.prop];
                if (nv == '' && $(this.el).data('rollback')) {
                    nv = $(this.el).data('rollback');
                }
                if (m instanceof Function) {
                    m.apply(this.bind, nv);
                } else if (this.bind instanceof Object && Object.isString(this.prop)) {
                    switch (this.escType) {
                        case _beHTMLEscape:
                            this.bind[this.prop] = soy.$$escapeHtml(nv);
                            break;
                        case _beValueEscape:
                            this.bind[this.prop] = soy.$$escapeHtmlAttribute(nv);
                            break;
                        case _beNoEscape:
                        default:
                            this.bind[this.prop] = nv;
                    }
                }
            }
        });

    !this.set &&
        (this.set = function(...args) {
            valSet(...args);
            return this;
        });

    !this.get &&
        (this.get = function(...args) {
            if (this.bind && !this.prop) {
                return this.el;
            } else if (this.bind && this.prop) {
                const m = this.bind[this.prop];
                if (m instanceof Function) {
                    return m.apply(this.bind, args);
                } else {
                    return m;
                }
            }
        });
    el = null;
}

/**
 * Set data to element through bindings
 * @private
 * @type {?Function}
 * @param {*} v data to set
 * @return {Bind2Element} pointer to object for chaining
 */
Bind2Element.prototype.set = null;

/**
 * Retrieve data from element
 * @private
 * @type {?Function}
 * @return {*}
 */
Bind2Element.prototype.get = null;

/**
 * Store element bind to
 * @private
 * @type {HTMLElement}
 */
Bind2Element.prototype.el = null;

/**
 * Store pointer for binded property of element
 * @private
 * @type {HTMLElement|Function}
 */
Bind2Element.prototype.bind = null;

/**
 * Store property name for access from bind
 * @private
 * @type {string}
 */
Bind2Element.prototype.prop = null;

/**
 * Delete element from DOM tree
 * @private
 */
Bind2Element.prototype.rmEl = function() {
    this.$elem().remove();
    this.free();
    return this;
};

/**
 * Release element from binding
 * @private
 */
Bind2Element.prototype.free = function() {
    if (this.set) delete this.set;
    if (this.get) delete this.get;
    this.el = null;
    this.bind = null;
    if (this._binds) {
        this._binds.free();
    }
    if (this.applySort) {
        delete this.applySort;
    }
    delete this.field;
    delete this.prop;
};

/** @constructor */
function BindsItem() {
    let observer = new $classes.Observer(false);
    /** @type {*} */
    let defGet = null;
    /** @type {Array<*>} */
    let storage = [];

    return {
        /** @param {*} obj */
        add(obj) {
            if (obj.defGet) {
                defGet = obj;
            }
            storage.push(obj);
            observer.subscribe(obj.set.bind(obj));
        },
        get() {
            if (defGet) {
                return defGet.get();
            } else {
                if (storage.length == 1) {
                    return storage[0].get();
                }
            }
        },
        /** @param {Array<*>} args */
        set(...args) {
            observer.publish(...args);
        },
        /** @return {HTMLElement=} */
        elem() {
            if (defGet) {
                return defGet.el;
            } else {
                if (storage.length == 1) {
                    return storage[0].el;
                }
            }
        },
        /** @return {JQuery} */
        $elem() {
            return $(this.elem());
        },
        resetMutex() {
            for (let i = storage.length; --i >= 0; ) {
                core.resetMutex(storage[i].el);
            }
        },
        free() {
            // observer = null;
            storage.forEach((_, idx) => storage[idx].free());
            storage.length = 0;
            defGet = null;
        }
    };
}

/**
 * Binds collection
 * @constructor
 * @public
 * @param {jQuery=} $el parent of elements to be processed
 */
function Binds($el) {
    /** @type {Object.<string,BindsItem>} */
    this._list = {};
    /** @type {Object.<string, Binds>} */
    this._nss = {};
    if ($el) {
        this.regBinds($el);
    }
}

/**
 * Process elements and create bindings
 * @public
 * @param {JQuery|HTMLElement} _ parent of elements to be processed
 */
Binds.prototype.regBinds = function(_) {
    /** @type {Array<JQuery<HTMLElement>>} */
    let els = [];

    const $el = (_ instanceof jQuery && _) || $(_);

    if ($el.length == 1) {
        els = $el.find('[data-bind]');

        if ($el.data('bind')) {
            els.push($el);
        }
    } else {
        for (var i = $el.length; --i >= 0; ) {
            this.regBinds($el.get(i));
        }
        return this;
    }

    for (let e, ff, i = els.length; --i >= 0; ) {
        e = els[i];

        ff = ((e instanceof jQuery && e) || $(e)).data('bind').split(',');
        !DEBUG && ((e instanceof jQuery && e) || $(e)).removeAttr('data-bind');

        for (let f, b, j = ff.length; --j >= 0; ) {
            if (ff[j]) {
                b = new Bind2Element((e instanceof jQuery && e[0]) || e, ff[j].trim());
                f = b.field;
                !this._list[f] && (this._list[f] = new BindsItem());
                this._list[f].add(b);
            }
        }
    }

    this.el = $el[0];

    return this;
};

/**
 * Set data to binded elements
 * @public
 * @param {Object.<string, *>|string} data
 * @param {boolean|*} resetMutex
 * @param {?boolean} arg
 * @return {Binds} for chainig
 */
Binds.prototype.set = function(data, resetMutex, arg) {
    const binds = this._list;

    if (typeof data == 'string') {
        const tmp = Object.create(null);
        tmp[data] = resetMutex;
        resetMutex = arg;
        data = tmp;
    }

    for (const n in binds) {
        const d = (n == '$' && data) || core_utils.ns(data, n);
        if (d !== undefined) {
            resetMutex && binds[n].resetMutex();
            binds[n].set(d, data);
        }
    }

    return this;
};

/**
 * Retrieve data from binded elements
 * @public
 * @param {string=} field name.
 *    if omitted whole set of bindings will be returned as object
 * @return {*}
 */
Binds.prototype.get = function(field) {
    if (field) {
        return this._list[field]?.get();
    } else {
        const obj = {};
        for (const i in this._list) {
            obj[i] = this._list[i].get();
        }
        return obj;
    }
};

/**
 * Return pointer to specified bind
 * @public
 * @param {string} field
 * @return {Bind2Element}
 */
Binds.prototype.item = function(field) {
    return this._list[field];
};

/**
 * Return element binded to field
 * @public
 * @param {string} field
 * @return {DOMElement}
 */
Binds.prototype.elem = function(field) {
    if (field) {
        return this._list[field] && this._list[field].elem();
    } else {
        return this.el;
    }
};

/**
 * Return element binded to field
 * @public
 * @param {string} field
 * @return {jQuery}
 */
Binds.prototype.$elem = function(field) {
    if (field) {
        return this._list[field] && this._list[field].$elem();
    } else {
        return $(this.el);
    }
};

/**
 * Create(if not exists) and return namespace
 * @param {string} ns name of the namespace
 * @param {?jQuery|DOMElement} el
 * @return {Binds}
 */
Binds.prototype.ns = function(ns, el) {
    return this._nss[ns] || (this._nss[ns] = new Binds(el));
};

/**
 * Is namespace exists?
 * @param {string} ns name of namespace
 * @return {boolean}
 */
Binds.prototype.isNS = function(ns) {
    return !!this._nss[ns];
};

/**
 * Free registered bindings of namespace and remove it
 * @param {string} ns name of namespace
 * @return {Binds}
 */
Binds.prototype.removeNS = function(ns) {
    if (this.isNS(ns)) {
        this.ns(ns).free();
        delete this._nss[ns];
    }
    return this;
};

/**
 * Remove all registered NSs
 * @return {Binds}
 */
Binds.prototype.clearNSs = function() {
    for (const i in this._nss) {
        this._nss[i].free();
        delete this._nss[i];
    }
    return this;
};

/**
 * Free collection
 */
Binds.prototype.free = function() {
    this.clearNSs();
    for (const i in this._list) {
        this._list[i].free();
        delete this._list[i];
    }
    this.el = null;
};

$classes.Binds = Binds;
