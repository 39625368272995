(function(core) {
    /**
     *
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtProjectFiles,
        _clAbstractWidget,
        // @lends wtProjectFiles
        {
            afterRegister: function() {
                core_DO.onFilesUpdate.subscribe(this.update.bind(this));
            },
            activate: function() {
                this.files = [null];
                this.update(true);

                if (!$scope[_bndCurrentProject]) {
                    this._super();
                    return;
                }

                var r;
                core.moses
                    .announce(
                        _rListFiles,
                        (r = {
                            workspaceId: $scope[_bndCurrentWorkspace].id,
                            projectId: $scope[_bndCurrentProject].id
                        })
                    )
                    .catch(function(o) {
                        switch (o.error) {
                            case enmErrorMessage.AccessDenied:
                                alert(core.getMsg(_msgAccessDenied));
                                break;
                            default:
                                __USE_ROLLBAR &&
                                    Rollbar.error('wtProjectFiles::activate::rListFiles fail response', {
                                        req: r,
                                        resp: o
                                    });
                                alert(core.getMsg(_msgUknownError, o));
                                break;
                        }
                    });
                this.$search.val('');
                // !this.$uploader && (this.$uploader = fileUploader(enmFileStreams.Project, null, this.$rendered));
                this._super();
            },
            // deactivate: function () {
            //     this._super();
            //     this.$uploader.fileupload('destroy');
            //     this.$uploader = null;
            // },
            update: function(mode) {
                if (this.pageController.activePane != 'files' && mode !== true) {
                    return;
                }

                var r;
                switch (mode) {
                    case false:
                        var v = this.$search.val();
                        v && (v = new RegExp(v, 'ig'));

                        r = this.files.filter(function(d) {
                            return v
                                ? v.test([d['fileName'] || '', d['ownerName'] || '', d['taskName'] || ''].join(' '))
                                : true;
                        });
                        break;
                    case true:
                        r = this.files;
                        break;
                    default:
                        r = this.files = core_DO.files().map(function(d) {
                            var c = new $classes[_cFileInfo](d);
                            var t = core_DO.task(c['objectId']);
                            var u = c['ownerId'] && core_DO.user(c['ownerId']);

                            c.ownerName = (c['ownerId'] && u && core.getMsg(_msgUserFLName, u)) || '<unknown user>';
                            c.taskName =
                                (c['objectId'] &&
                                    ((t && (t['name'] || core.getMsg(_msgUnnamedTask))) ||
                                        core.getMsg(_msgRemovedTask))) ||
                                '';
                            return c;
                        });
                }

                var obj = {};
                obj[_bndFiles] = r;
                obj[_bndEmpty] = r.length == 0 && mode !== false;
                obj[_bndEmptySearch] = r.length == 0 && mode === false;

                this._binds.set(obj);
            },
            render: function() {
                this.$rendered = core_dom.renderAsElement(tpls.widgets.projectFiles.decoration, {}, this._binds);

                var self = this;
                this.$search = this.$rendered
                    .find('input[type="search"]')
                    .on(
                        'keyup',
                        function() {
                            self.update(false);
                            return true;
                        }.debounce(300)
                    )
                    .on('search', function() {
                        self.update(false);
                        return true;
                    });

                this.$rendered.on('click', '.' + _idSymbolCircleXBlack, function(ev) {
                    if (window.confirm(core.getMsg(_msgProjectFileRemoveConfirm))) {
                        var data = d3.select($(this).parents('tr')[0]).datum();
                        var r;
                        core.moses
                            .announce(
                                _rRemoveFile,
                                (r = {
                                    fileId: data['id'],
                                    workspaceId: data['workspaceId'],
                                    projectId: data['projectId'],
                                    stream: data['stream'],
                                    objectId: data['objectId']
                                })
                            )
                            .catch(function(o) {
                                __USE_ROLLBAR &&
                                    Rollbar.error('projectFiles::render::rRemoveFile fail response', {
                                        req: r,
                                        resp: o
                                    });
                            });
                        core_DO.removeFile(data['id']);
                    }
                });

                return this._super();
            }
        }
    );
})(core);
