(function(core) {
    'use strict';

    /**
     * Box Popup style
     * @const
     * @enum {number}
     */
    var _POPUP_STYLE = {
        DROPDOWN: 1,
        POPUP: 2
    };

    /** @const */ var HIDE_EVENTS = 'click';

    var _instances = [];

    var hideAll = (core.clearMenus = function(ev) {
        // debugger;
        if (ev && ev.isDefaultPrevented && ev.isDefaultPrevented()) {
            return;
        }
        _instances.forEach(function(v) {
            v.hide();
        });
    });

    $(document).on([/*'click', */ 'contextmenu', _evClosePopups, _evEscPressed].join(' '), hideAll);

    /**
     * Abstract Popup Menu widget
     * @constructor
     * @class wtPopupMenu
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtPopupMenu,
        _clAbstractWidget,
        /** @lends wtPopupMenu */
        {
            options: {
                items: null,
                menuTitle: null,
                menuId: null,
                title: null,
                delayBeforePopup: 0,
                delayBeforeHide: 500,
                beforePopupCallback: null,
                onItemSelectCallback: null,
                afterRender: null,
                popupStyle: _POPUP_STYLE.POPUP,
                customHandler: null,
                noClickHandler: null,
                ignoreQuery: null
            },
            _create: function(id, opts, el) {
                if (opts && opts.items && Object.isArray(opts.items)) {
                    this.setItems(opts.items);
                }
                _instances.push(this);
                this._super(id, el, opts);
            },
            setItems: function(items) {
                if (Object.isArray(items)) {
                    var s = '';

                    for (var i = 0; i < items.length; i++) {
                        var c = [];

                        s += '<li';

                        if (items[i].classes) {
                            switch (true) {
                                case Object.isObject(items[i].classes):
                                    c = Object.keys(items[i].classes);
                                    break;
                                case Object.isArray(items[i].classes):
                                    c = Object.clone(items[i].classes);
                                    break;
                                case Object.isString(items[i].classes):
                                    c = items[i].classes.split(' ');
                                    break;
                            }
                        }

                        if (items[i].data) {
                            var l = items[i].data,
                                j = [];

                            if ('event' in l) {
                                c.push(_gcMosesWatchClass);
                            }

                            for (var k in l) {
                                j.push(core_utils.string.toAttr(k, l[k]));
                            }

                            if ('id' in l) {
                                j.push('id="' + l.id + '"');
                            }
                            s += ' ' + j.join(' ');
                        }

                        c.length && (s += ' class="' + c.join(' ') + '"');
                        s = s + '>' + items[i].label + '</li>';
                    }

                    items = s;
                }
                if (this.$rendered && typeof items == 'string') {
                    $(this.$rendered)
                        .find('ul')
                        .html(items);
                } else {
                    this.options.items = items;
                }
                return this;
            },
            attachTo: function($el, evType, subClass) {
                !evType && (evType = 'click');
                this._attached = {
                    evtype: evType,
                    f: function(ev) {
                        var $e = (this.$element.length > 1 && $(ev.currentTarget)) || this.$element;
                        var isShouldbeIgnored =
                            (ev.target instanceof SVGElement && ev.target.classList.contains(_clsPopupMenuIgnore)) ||
                            $(ev.target).hasClass(_clsPopupMenuIgnore) ||
                            ev.isDefaultPrevented();

                        if ($e.hasClass(_clsActive)) {
                            hideAll();
                            return false;
                        }
                        if (isShouldbeIgnored) {
                            return true;
                        }
                        $e.addClass(_clsActive);
                        this.popupAt($e);
                        return false;
                    }.bind(this)
                };
                if (subClass) {
                    $el.on(evType, subClass, this._attached.f);
                } else {
                    $el.on(evType, this._attached.f);
                }
                this.$element = $el;
                return this;
            },
            detach: function() {
                this._attached && this.$element.unbind(this._attached.evtype, this._attached.f);
            },
            _compute: function(pos) {
                var width = this.$rendered.outerWidth(),
                    height = this.$rendered.outerHeight(),
                    tx,
                    ty,
                    left,
                    bw = $(window.FullScreenElement || '#' + _clsWorkArea)[0].clientWidth,
                    bh = $(window.FullScreenElement || '#' + _clsWorkArea)[0].clientHeight;

                switch (this.options.popupStyle) {
                    case _POPUP_STYLE.DROPDOWN:
                        break;
                    case _POPUP_STYLE.POPUP:
                        var c = ~~(pos.width / 2) + pos.left,
                            mp = ~~(width / 2),
                            tc = c - mp,
                            ho = mp;

                        switch (true) {
                            case tc < 10:
                                ho = mp - Math.abs(tc);
                                tc = 10;
                                break;
                            case tc + width > bw:
                                ho += tc + width - bw + 10;
                                tc = bw - width - 10;
                                break;
                        }

                        this.$rendered
                            .css('left', tc)
                            .find('div.' + _clsPopupMenuHandle)
                            .css('left', ho);
                        break;
                }

                if (pos.bottom + 10 + height > bh) {
                    var bo = pos.bottom + 10 + height - bh,
                        bt = pos.top - 10 - height;

                    switch (true) {
                        case bt > 0:
                            ty = bt;
                            this.$rendered.addClass('bt');
                            break;
                        case bt < 0 && bo < height / 3:
                            break;
                    }
                } else {
                    ty = pos.bottom + 10;
                    this.$rendered.removeClass('bt');
                }

                this.$rendered.css('top', ty);
            },
            /**
             * @param  {jQuery} $el
             * @param  {*} data
             * @param  {boolean} resetElement
             */
            popupAt: function($el, data, resetElement) {
                // hideAll();
                $('body').trigger(_evClosePopups);

                var cb = $('.' + _clsCenterBox);
                if (cb.length) {
                    this.$rendered.insertAfter(cb);
                }

                // $('html').bind(HIDE_EVENTS, hideAll);

                switch (true) {
                    case window.FullScreenElement && /body/i.test(this.$rendered.parent()[0].tagName):
                        $(window.FullScreenElement).append(this.$rendered.detach());
                        break;
                    case !window.FullScreenElement && !/body/i.test(this.$rendered.parent()[0].tagName):
                        $('body').append(this.$rendered.detach());
                        break;
                }

                if (resetElement) {
                    if (this.$element && this.$element.hasClass(_clsActive)) {
                        this.$element.removeClass(_clsActive);
                    }
                    this.$element = $el;
                }

                data && (this._data = data);

                if (typeof this.options.beforePopupCallback == 'function') {
                    if (this.options.beforePopupCallback(this, this._data, $el) === false) {
                        return;
                    }
                }
                this.$element && this.$element[0] instanceof SVGElement
                    ? d3.select(this.$element[0]).classed(_clsActive, true)
                    : this.$element.addClass(_clsActive);
                this.$rendered.addClass('low');
                this._compute(core_dom.elementOffset($el));
                this.$rendered.addClass(_clsActive).removeClass('low');
                this.$rendered.find('.' + _clsScroller).each(function() {
                    this.scrollTop = 0;
                });
                // this.scroller = new iScroll('scrl-' + this.options.menuId, {
                //     hScroll: false,
                //     useTransform: true
                // });

                var cb = function() {
                    this.$element && this.$element[0] instanceof SVGElement
                        ? d3.select(this.$element[0]).classed(_clsActive, false)
                        : this.$element.removeClass(_clsActive);
                    this.hide();
                }.bind(this);

                core_dom.modalEscape(
                    this.$rendered.get(0),
                    function() {
                        hideAll();
                    },
                    this.options.ignoreQuery
                );

                return this;
            },
            setData: function(data) {
                this._data = data;
                return this;
            },
            render: function() {
                ($('#' + this.options.menuId) || $('#' + this.options.widgetName)).remove();

                this.$rendered = core_dom.renderAsElement(
                    tpls.widgets.popupMenu.decoration,
                    {
                        id: this.options.menuId || this.widgetName,
                        title: this.options.menuTitle,
                        items: this.options.items,
                        custom: this.options.custom
                    },
                    this._binds
                );

                if (this.options.noClickHandler == null) {
                    this.$rendered.on(
                        'click',
                        '.' + _clsPopupMenuItems,
                        function(ev) {
                            var $trg = (ev.target.tagName == 'LI' && $(ev.target)) || $(ev.target).parents('li');

                            if ($trg.hasClass(_clsNotSelectableItem) || $trg.hasClass(_clsPopupMenuHeader)) {
                                return false;
                            }

                            var data = $.extend(this._data || {}, $trg.data() || {});

                            if (this.options.customHandler) {
                                var r = this.options.customHandler.call($trg.get(0), ev, this, data, $trg);
                                ev.preventDefault();
                                ev.stopPropagation();
                                r && this.hide();
                                return;
                            }
                            this.hide();

                            switch (typeof this.options.onItemSelectCallback) {
                                case 'string':
                                    core.moses.announce(this.options.onItemSelectCallback, data);
                                    break;
                                case 'function':
                                    this.options.onItemSelectCallback.call(null, ev, this, data);
                                    break;
                            }
                        }.bind(this)
                    );
                }

                this.$rendered.appendTo('body');
                this.options.afterRender && this.options.afterRender.call(this);

                return this._super();
            },
            hide: function() {
                $(this.$rendered).removeClass(_clsActive);
                this.$element && this.$element[0] instanceof SVGElement
                    ? d3.select(this.$element[0]).classed(_clsActive, false)
                    : $(this.$element).removeClass(_clsActive);
                // $('html').unbind(HIDE_EVENTS, hideAll);
                return this;
            }
        }
    );
})(core);
