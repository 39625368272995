$filters[_fltSortUsers] = function(list, addFakeItem, id) {
    var r = list.slice().sort(function usersSort(a, b) {
        var _a = a.lastName.toLowerCase(a),
            _b = b.lastName.toLowerCase(b);
        switch (true) {
            case _a > _b:
                return 1;
            case _a < _b:
                return -1;
            case _a == _b:
                _a = a.firstName.toLowerCase();
                _b = b.firstName.toLowerCase();
                switch (true) {
                    case _a > _b:
                        return 1;
                    case _a < _b:
                        return -1;
                    case _a == _b:
                        return 0;
                }
        }
    });

    switch (addFakeItem) {
        case _fltAddLast:
            return [].concat(r, { id: id || _clsNewItem });
        case _fltAddFirst:
            return [].concat({ id: id || _clsNewItem }, r);
        default:
            return r;
    }
};

$filters[_fltSortTeams] = function(list, addFakeItem) {
    return list.slice().sort(teamSort);
};

$filters[_fltMapProjectMembers] = function(list, addFakeItem, id) {
    var r = list.map(function(o) {
        return core_DO.user(o.workspaceUserId);
    });
    switch (addFakeItem) {
        case _fltAddLast:
            return [].concat(r, { id: id || _clsNewItem });
        case _fltAddFirst:
            return [].concat({ id: id || _clsNewItem }, r);
        default:
            return r;
    }
};

$filters[_fltMapTasksQtyToUsers] = function(list) {
    return list.map(function(o) {
        var r = core_DO.tasksOfTheUser(o.id);
        o.tasksQty = r.$tasks.length;
        o.alerted = r.$alerted.length;
        return o;
    });
};

// $methods[_mtdACL] = function (mtd, val) {
//     return $ACL[mtd].call(null, val);
// }
//

function teamSort(a, b) {
    var _a = ((a.isEdit_ && a.oldValue_.name) || a.name).toLowerCase(),
        _b = ((b.isEdit_ && b.oldValue_.name) || b.name).toLowerCase();

    switch (true) {
        case a.isNew_ > b.isNew_:
            return -1;
        case a.isNew_ < b.isNew_:
            return 1;
        case a.isNew_ == b.isNew_:
            switch (true) {
                case _a > _b:
                    return 1;
                case _a < _b:
                    return -1;
                case _a == _b:
                    return 0;
            }
            break;
    }
}
/**
 * @param {cProjectTagInfo} a
 * @param {cProjectTagInfo} b
 */
function tagsSort(a, b) {
    return (a.order ?? Number.MAX_SAFE_INTEGER) - (b.order ?? Number.MAX_SAFE_INTEGER);
}
/**
 * @param {cProjectInfo} a
 * @param {cProjectInfo} b
 */
function sortProjectsByTagsAndNames(a, b) {
    let ta = max;
    if ([null, 'null'].includes(a.tagId) == false) {
        const tmp = $scope[_bndProjectTags].$item(a.tagId);
        ta = tmp?.order ?? max;
    }
    let tb = max;
    if ([null, 'null'].includes(b.tagId) == false) {
        const tmp = $scope[_bndProjectTags].$item(b.tagId);
        tb = tmp?.order ?? max;
    }
    switch (true) {
        case ta > tb:
            return 1;
        case ta < tb:
            return -1;
        default:
            return sortProjectsByName(a, b);
    }
}
/**
 * @param {cProjectInfo} a
 * @param {cProjectInfo} b
 */
function sortProjectsByName(a, b) {
    var na = a.name.toLowerCase(),
        nb = b.name.toLowerCase();
    switch (true) {
        case na > nb:
            return 1;
        case na < nb:
            return -1;
        default:
            return 0;
    }
}

/**
 * @param {cProjectInfo} a
 * @param {cProjectInfo} b
 */
function sortProjectsByCreationTime(a, b) {
    var ta = a.createdAt || 0;
    var tb = b.createdAt || 0;
    switch (true) {
        case ta > tb:
            return -1;
        case ta < tb:
            return 1;
        case ta == tb:
            return sortProjectsByName(a, b);
    }
}
