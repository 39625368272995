core.DM.registerNHandler(_nUserInfoUpdated, _cUserInfo, obj => {
    core.iam = { ...(core.iam || {}), ...obj };
    // imgs.user.add(obj['id'], obj['imageId'], undefined, false, [obj.firstName, obj.lastName, obj.middleName, '?']);
    imgs.userImage.add(obj['id'], obj['imageId'], undefined, [obj.firstName, obj.lastName, obj.middleName]);
    core.iam['contacts'] = core.iam['contacts'].unique(o => o.id);
    core_DO.onUserInfoUpdate.publish();
});

core.DM.registerNHandler(_nHistoryDelivery, _cHistoryStreamPackage, obj => {
    if (obj.objectId && obj.entries.length == 0) {
        core_DO.setHistoryEnd(obj.objectId);
        core_DO.history([]);
    } else {
        core_DO.history(obj['entries']);
    }
    return false;
});

core.DM.registerNHandler(_nHistoryEvent, _cHistoryEntryInfo, obj => {
    switch (obj['type']) {
        case enmHistoryEntryType.Status:
            core_DO.projectStats(obj['objectId'], obj['content']['stats']);
            break;
        case enmHistoryEntryType.Comment:
        case enmHistoryEntryType.Event:
            return core_DO.history([obj]);
            break;
    }
    return false;
});

core.DM.registerNHandler(_nUserAlert, _cUserAlertEntryInfo, obj => {
    core_DO.addNotification(obj);
});

core.DM.registerNHandler(_nCommentPosted, _cHistoryEntryInfo, obj => core_DO.history([obj]));

core.DM.registerNHandler(_nFilesDelivery, _cListFilesResponse, obj => {
    core_DO.addFile(obj['files']);
});

core.DM.registerNHandler(_nFileCreated, _cFileInfo, obj => {
    core_DO.addFile([obj]);
});

core.DM.registerNHandler(_nClipboardDelivery, _cClipboardEntryInfo, obj => {
    DEBUG && console.info('created clipboard entry', obj);
    core_DO.addClipboardEntry(obj);

    let meta;
    if (obj.preview && (meta = core_DO.clipboardMeta(obj.id))) {
        let rq;
        core.moses
            .announce(
                _rClipboardPreview,
                (rq = {
                    id: obj.id,
                    data: core_utils.encode64(JSON.stringify(meta)),
                    base64: true
                })
            )
            .catch(o => {
                __USE_ROLLBAR && Rollbar.error('nHandlers::nClipboardDelivery fail response', { req: rq, resp: o });
            });
    }
});
core.DM.registerNHandler(_nClipboardPreview, _cClipboardPreview, obj => {
    if (core_DO.clipboardMeta(id) == undefined) {
        let meta = obj.data;
        obj.base64 && (meta = core_utils.decode64(meta));
        switch (obj.type) {
            case enmClipboardPreviewType.metaData:
                meta = JSON.parse(meta);
                break;
        }
        core_DO.clipboardMeta(id, meta);
    }
});
// dm.registerNHandler(_nWorkspaceSettingsUpdated, _cWorkspaceSettingsInfo, function (/* cWorkspaceSettingsInfo */ obj) {
//     debugger;
// })

core.moses.subscribe([
    // [_nSessionLost, function (obj) {
    //     __USE_ROLLBAR && Rollbar.debug('nHandlers::nSessionLost');
    //     core.storage.$kill(_gcSESSION_ID_NAME);
    //     core.storage.$kill(_gcSESSION_ID_NAME, true);
    //     dm.killUserSession();
    //     cdo.cleanup();
    //     // TODO: show user alert for situation
    //
    //     if (core.channel) {
    //         core.channel.stop();
    //     }
    // }],
    [
        [_nWorkspaceUserUpdated, _nWorkspaceUserContactUpdated],
        obj => {
            core_DO.updateUser(obj);
        }
    ],
    [
        _nWorkspaceUserCreated,
        obj => {
            core_DO.addUser(obj);
        }
    ],
    [
        _nWorkspaceUserRemoved,
        id => {
            if (id instanceof Array) {
                id.forEach(i => {
                    core_DO.removeUser(i);
                });
            } else {
                core_DO.removeUser(i);
            }
        }
    ],
    [
        _nProjectMemberRemoved,
        obj => {
            core_DO.removeMember(obj.workspaceUserId, obj.projectId);
        }
    ],
    [
        _nProjectMemberAdded,
        obj => {
            core_DO.addMember(obj['workspaceUserId'], obj['role'], obj['projectId']);
        }
    ],
    [
        _nProjectMemberRoleChanged,
        obj => {
            core_DO.changeMemberRole(obj['workspaceUserId'], obj['role']);
        }
    ],
    [
        _nWorkspaceCreated,
        obj => {
            if (obj['workspaceOwner'] == undefined) {
                core.moses
                    .announce(_rWorkspaceInfo, {
                        workspaceId: obj.id,
                        details: enmWorkspaceInfoRequestDetails.values()
                    })
                    .then(d => {
                        core_DO.addWorkspace(d.workspace);
                    });
            } else {
                core_DO.addWorkspace(obj);
            }
        }
    ],
    [
        _nWorkspaceUpdated,
        obj => {
            core_DO.updateWorkspace(obj['id'], obj);
        }
    ],
    [
        _nWorkspaceOwnershipChanged,
        obj => {
            core_DO.changeWorkspaceOwner(obj['id'], obj['newOwner']);
        }
    ],
    [
        _nWorkspaceWritable,
        obj => {
            obj.forEach(id => {
                core_DO.updateWritableState4Workspace(id, true);
            });
        }
    ],
    [
        _nWorkspaceReadOnly,
        obj => {
            obj.forEach(id => {
                core_DO.updateWritableState4Workspace(id, false);
            });
        }
    ],
    [
        _nWorkspaceLimitsDelivery,
        obj => {
            core_DO.updateWorkspaceLimits(obj.id, obj);
        }
    ],
    [
        _nWorkspaceSettingsUpdated,
        obj => {
            core_DO.updateWorkspaceSettings(obj.id, obj);
        }
    ],
    [
        _nWorkspaceRemoved,
        id => {
            if (id instanceof Array) {
                id.forEach(i => {
                    core_DO.removeWorkspace(i);
                });
            } else {
                core_DO.removeWorkspace(id);
            }
        }
    ],
    [
        _nWorkspaceInfoDelivery,
        obj => {
            core.moses
                .announce(_rWorkspaceInfo, {
                    workspaceId: obj.id,
                    details: enmWorkspaceInfoRequestDetails.values()
                })
                .then(data => {
                    core_DO.$workspaces([data.workspace]);
                });
        }
    ],
    [
        _nProjectCreated,
        obj => {
            core_DO.updateProjectLTA(obj.id);
            core_DO.addProject(obj);
        }
    ],
    [
        _nProjectUpdated,
        /* cProjectInfo */ obj => {
            core_DO.updateProject(obj.id, obj);
        }
    ],
    [
        _nProjectRemoved,
        obj => {
            core_DO.removeProject(obj['id'], obj['workspaceId']);
        }
    ],
    [
        _nProjectRestored,
        obj => {
            core_DO.addProject(obj);
        }
    ],
    [
        _nProjectArchived,
        obj => {
            core_DO.updateProject(obj['id'], obj);
        }
    ],
    /** @param {!cNotification} obj */
    [
        _nStateChanged,
        obj => {
            if (
                !$scope[_bndCurrentWorkspace] ||
                !$scope[_bndCurrentProject] ||
                ($scope[_bndCurrentProject] && obj['projectId'] != $scope[_bndCurrentProject].id)
            ) {
                return;
            }

            const s = [
                    'ChangeDenied',
                    enmStateChangeTypes.CreateTask,
                    enmStateChangeTypes.DeleteTask,
                    enmStateChangeTypes.UpdateTask,
                    enmStateChangeTypes.AddTaskExecutor,
                    enmStateChangeTypes.RemoveTaskExecutor,
                    enmStateChangeTypes.ChangeTaskState,
                    enmStateChangeTypes.ChangeTaskType,
                    enmStateChangeTypes.UpdateTaskTiming,
                    enmStateChangeTypes.UpdateTaskValues,
                    enmStateChangeTypes.CreateTransition,
                    enmStateChangeTypes.DeleteTransition,
                    enmStateChangeTypes.UpdateTransition
                ],
                src = (obj['changes'] || [])
                    .sortBy(d => {
                        let t = (d['data'] && d['data']['type']) || (d['object'] && d['object']['type']);

                        if (t) {
                            t = (t == 'Group' && 'A') || 'Z';
                        } else {
                            t = '';
                        }

                        return s.indexOf(d['type']) + t + d['id'];
                    })
                    .groupBy(d => d['id']),
                aids = [];

            [core_DO.onTasksUpdate, core_DO.onDraftsUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                p.suspend(true);
            });

            for (const i in src) {
                let accTask = null,
                    accTran = null,
                    taskType = false;

                src[i].forEach(d => {
                    const state = d['object'] || d['data'] || {},
                        id = (state['id'] = d['id']);

                    switch (d['type']) {
                        case 'ChangeDenied':
                            let p;
                            try {
                                p = JSON.parse(
                                    state['rejected']
                                        .replace(/^StateChange/, '')
                                        .replace(/(\w+)=/g, '"$1"=')
                                        .replace(/=([\w\-]+)/g, '="$1"')
                                        .replace(/=,/g, '="",')
                                        .replace(/"=/g, '":')
                                );
                            } catch (e) {
                                __USE_ROLLBAR &&
                                    Rollbar.error('nHandlers::nStateChanged::ChangeDenied response parse error', state);
                                return;
                            }
                            switch (p['type']) {
                                case enmStateChangeTypes.CreateTask:
                                    accTask = false;
                                    core.moses.announce(_evCancelTaskNameEdit, d.id);
                                    core_DO.removeTask(d.id);
                                    break;
                                case enmStateChangeTypes.DeleteTask:
                                case enmStateChangeTypes.UpdateTask:
                                case enmStateChangeTypes.AddTaskExecutor:
                                case enmStateChangeTypes.RemoveTaskExecutor:
                                case enmStateChangeTypes.ChangeTaskState:
                                case enmStateChangeTypes.ChangeTaskType:
                                case enmStateChangeTypes.CreateTransition:
                                case enmStateChangeTypes.DeleteTransition:
                                case enmStateChangeTypes.UpdateTransition:
                            }

                            let msg;
                            const m = state['message'];
                            switch (true) {
                                case /^MAX_TASKS/.test(m):
                                    msg = core.getMsg(_msgMaxTasksLimitReached);
                                    break;
                                case /^MAX_NESTING/.test(m):
                                    msg = core.getMsg(_msgMaxNestingLimitReached);
                                    break;
                            }
                            msg &&
                                setTimeout(() => {
                                    alert(msg);
                                }, 0);
                            break;
                        case enmStateChangeTypes.CreateTask:
                            if (!core_DO.task(id) && !core_DO.isRemoved(id)) {
                                core_DO.addTask(state, undefined, aids);
                                accTask = state;
                            }
                            break;
                        case enmStateChangeTypes.UpdateTask:
                            if (core_DO.isRemoved(id)) {
                                accTask = false;
                                return;
                            }
                            accTask = new $classes[_cTaskInfo](state);
                            break;
                        case enmStateChangeTypes.DeleteTask:
                            accTask = false;
                            core_DO.hasFeature(_fndStoryEditor) &&
                                core_DO.task(d.id) &&
                                core.moses.announce(_rFeatureQuestion, {
                                    question: {
                                        featureName: enmFeatureQuestion.StoryEditor,
                                        question: enmFeatureQuestionEvent.DeleteStoryChapter,
                                        values: {
                                            projectId: $scope[_bndCurrentProject].id,
                                            chapterId: id
                                        }
                                    }
                                });
                            core_DO.removeTask(id);
                            break;
                        case enmStateChangeTypes.AddTaskExecutor:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            DEBUG && console.assert(state['workspaceUserId']);
                            core_DO.addExecutor(id, state['workspaceUserId']);
                            if (accTask) {
                                accTask['execs'] = core_DO.task(id).execs;
                            }
                            break;
                        case enmStateChangeTypes.RemoveTaskExecutor:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            DEBUG && console.assert(state['workspaceUserId']);
                            core_DO.removeExecutor(id, state['workspaceUserId']);
                            if (accTask) {
                                accTask['execs'] = core_DO.task(id).execs;
                            }
                            break;
                        case enmStateChangeTypes.ChangeTaskType:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            if (!accTask) {
                                core_DO.changeTaskType(id, state);
                            } else {
                                accTask.colId = state.colId || accTask.colId;
                                accTask.rowId = state.rowId || accTask.rowId;
                                accTask.type = state.type;
                                taskType = true;
                            }
                            break;
                        case enmStateChangeTypes.ChangeTaskState:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            !accTask && core_DO.changeTaskState(state);
                            break;
                        case enmStateChangeTypes.UpdateTaskTiming:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            core_DO.updateTaskTiming(id, state);
                            if (accTask) {
                                for (const k in state) {
                                    accTask[k] = state[k];
                                }
                            }
                            break;
                        case enmStateChangeTypes.UpdateTaskValues:
                            if (accTask === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            core_DO.updateTaskValues(id, state.values);
                            if (accTask) {
                                accTask.values = state.values;
                            }
                            break;
                        case enmStateChangeTypes.CreateTransition:
                            if (core_DO.isRemoved(id)) {
                                return;
                            }
                            !core_DO.transition(id) && core_DO.addTransition(state);
                            break;
                        case enmStateChangeTypes.UpdateTransition:
                            if (accTran === false || core_DO.isRemoved(id)) {
                                return;
                            }
                            core_DO.updateTransition(id, state);
                            break;
                        case enmStateChangeTypes.DeleteTransition:
                            accTran = false;
                            core_DO.removeTransition(id);
                            break;
                    }
                });

                accTask && ((taskType && core_DO.changeTaskType) || core_DO.updateTask)(accTask['id'], accTask);
            }

            core_DO.axisUpdate();
            [core_DO.onTasksUpdate, core_DO.onDraftsUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                p.resume();
            });
        }
    ],
    // [_nFileCreated, function (data) {
    //     cdo.addFile([data]);
    // }],
    [
        _nFileRemoved,
        data => {
            core_DO.removeFile(data['id'], data['workspaceId'], data['workspaceId']);
        }
    ],
    [_nFileUpdated, obj => {}],
    [
        _nClipboardCleared,
        id => {
            DEBUG && console.info('clear clipboard entry', id);
            core_DO.clearClipboardEntry(id);
        }
    ],
    [
        _nCommentRemoved,
        ids => {
            ids.forEach(id => {
                core_DO.removeComment(id);
            });
        }
    ],
    [
        'TrackEvent',
        obj => {
            /** TODO: for testing purposes. Have to be removed later */
            if (obj['id'] == 'Some Event') {
                return;
            }
            core_stat.track(obj['id'], obj['values']);
        }
    ],
    [
        _nTeamCreated,
        obj => {
            core_DO.addTeam(obj);
        }
    ],
    [
        _nTeamRemoved,
        ids => {
            ids.forEach(id => {
                core_DO.removeTeam(id);
            });
        }
    ],
    [
        _nTeamUpdated,
        obj => {
            core_DO.updateTeam(obj);
        }
    ], //{"sessionKey":"145t3ep947o209byxwe7ihuil145t3ep947o209byxwe7ihuil","notifications":[{"type":"AddedToTeam","targets":[{"projectId":"5njckaw7wk9wm8z7lo71yprvw","teamId":"23q24rzuan9n0fw2388zb6dfp","workspaceUserId":"8c8zwwin55fp9q32jn39943kk","role":"Leader"}],"values":{}}],"time":1409414847934,"sn":1409406934912}
    [
        _nAddedToTeam,
        obj => {
            core_DO.addMemberToTeam(obj);
        }
    ], //{"sessionKey":"zm7m369ctslrg14xooef6ueyzm7m369ctslrg14xooef6uey","notifications":[{"type":"RemovedFromTeam","targets":[{"projectId":"5njckaw7wk9wm8z7lo71yprvw","teamId":"coaeu9r1q6jxfvx9aqqhm6pvl","workspaceUserId":"1xsvrzxhdghfl8a63adnzj33e","role":"Leader"}],"values":{}}],"time":1409847052998,"sn":1409842889488}
    [
        _nRemovedFromTeam,
        obj => {
            core_DO.removeMemberFromTeam(obj['workspaceUserId'], obj['teamId']);
        }
    ],
    [
        _nFeatureEvent,
        obj => {
            switch (obj.id) {
                // {"calendarId":"bfbnoda37kptk2claa4qebhmd4@group.calendar.google.com","projectId":"c0my7v1bkuioup13rmckrketj"}
                case 'CalendarLinked':
                    core_DO.googleCalendars(obj.projectId, obj.calendarId);
                    break;
                case 'CalendarUnlinked':
                    core_DO.googleCalendars(obj.projectId, null);
                    break;
            }
        }
    ],
    [
        _nFeatureAuthResult,
        obj => {
            console.info(obj);
        }
    ],
    [
        [_nProjectTagCreated, _nProjectTagUpdated],
        /* cProjectTagInfo */ obj => {
            if ($scope[_bndCurrentWorkspace] && obj.workspaceId == $scope[_bndCurrentWorkspace].id) {
                /** @type {cProjectTagInfo} */
                let item = $scope[_bndProjectTags].$item(obj.id);
                if (item) {
                    item.color = obj.color;
                    item.name = obj.name;
                    item.order = obj.order;
                    $scope[_bndProjectTags].sort(tagsSort);
                } else {
                    $scope[_bndProjectTags].$push((item = new $classes[_cProjectTagInfo](obj)));
                }
                $scope[_bndCurrentWorkspace].categories.remove(d => d.id == item.id);
                $scope[_bndCurrentWorkspace].categories.push(item);
                core_DO.onProjectUpdate.publish();
            }
        }
    ],
    [
        _nProjectTagRemoved,
        id => {
            $scope[_bndProjectTags].$remove(id);
            $scope[_bndCurrentWorkspace].categories.remove(d => d.id == id);
            core_DO.onProjectUpdate.publish();
        }
    ]
    // [_nAddedToStandardTeam, function (obj) {
    // }],
    // [_nRemovedFromStandardTeam, function (obj) {
    // }],
    // [_nStandardTeamCreated, function (obj) {
    // }],
    // [_nStandardTeamUpdated, function (obj) {
    // }],
    // [_nStandardTeamRemoved, function (obj) {
    // }],
    // ,
]);
