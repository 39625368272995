(() => {
    const selectionRect = {
        /** @type {?d3_selectionPrototype} */
        element: null,
        /** @type number */
        currentY: 0,
        /** @type number */
        currentX: 0,
        /** @type number */
        originX: 0,
        /** @type number */
        originY: 0,
        /** @param {d3_selectionPrototype} ele */
        setElement(ele) {
            this.element = ele;
        },
        getNewAttributes() {
            const x = this.currentX < this.originX ? this.currentX : this.originX;
            const y = this.currentY < this.originY ? this.currentY : this.originY;
            const width = Math.abs(this.currentX - this.originX);
            const height = Math.abs(this.currentY - this.originY);
            return {
                x,
                y,
                width,
                height
            };
        },
        getCurrentAttributes() {
            // use plus sign to convert string into number
            const x = +this.element?.attr('x');
            const y = +this.element?.attr('y');
            const width = +this.element?.attr('width');
            const height = +this.element?.attr('height');
            return {
                x1: x,
                y1: y,
                x2: x + width,
                y2: y + height
            };
        },
        /**
         * @param {d3_selectionPrototype} node
         * @param {number} newX
         * @param {number} newY
         */
        init(node, newX, newY) {
            const rectElement = node
                .append('rect')
                .attr({
                    x: 0,
                    y: 0,
                    width: 0,
                    height: 0,
                    rx: 4,
                    ry: 4,
                    'stroke-width': 0.5,
                    'stroke-dasharray': '5,2'
                })
                .classed(_clsRectangularSelection, true);
            this.setElement(rectElement);
            this.originX = newX;
            this.originY = newY;
            this.update(newX, newY);
        },
        /**
         * @param {number} newX
         * @param {number} newY
         */
        update(newX, newY) {
            this.currentX = newX;
            this.currentY = newY;
            this.element?.attr(this.getNewAttributes());
        },
        /**
         * @param {number} dx
         * @param {number} dy
         */
        updateByDelta(dx, dy) {
            this.currentX += dx;
            this.currentY += dy;
            this.element?.attr(this.getNewAttributes());
        },
        remove() {
            this.element?.remove();
            this.element = null;
        }
    };

    function stop_propagation() {
        d3.event.stopPropagation();
    }

    /**
     * @constructor
     * @class Tree
     * @this Tree
     * @param {HTMLElement} svgContainer
     * @param {string=} groupId
     * @param {{onUpdate: function, onEvent: function}|function} events
     * @param {core_DO} dm
     */
    function Tree(svgContainer, groupId, events, dm) {
        const active = false;
        /** @type {d3_selectionPrototype} */
        let ntasks;
        /** @type {d3_selectionPrototype} */
        let nlinks;
        const that = this;
        /** @type {(arg1: enmStateChangeTypes, arg2: string, arg3:any) => void} */
        let updateCallback = events?.onUpdate ?? (() => {});
        let eventCallback = events?.onEvent ?? events ?? (() => {});
        /** @type {d3_selectionPrototype} */
        let view;
        let vnode;
        let layerbox;
        /** @type {false|{resolve:function,reject:function}} */

        /** @type {Object<string, SVGElement>} */
        const nodes_map = {};

        let svg =
            (svgContainer instanceof SVGElement && /^svg$/i.test(svgContainer.tagName) && d3.select(svgContainer)) ||
            d3
                .select(svgContainer)
                .append('svg')
                .attr({
                    width: '100%',
                    height: '100%'
                });

        const layers = {
            paperLayer: _idPaperLayer,
            linksLayer: _idLinksLayer,
            tasksLayer: _idTasksLayer,
            helperLayer: _idHelperLayer,
            ...((__INLINE_GROUP && { groupLayer: _idGroupLayer }) || {})
        };

        const nldiagonal = casualLink()
            .source(
                /** @this SVGElement */
                function() {
                    const d = d3.select(this).datum();
                    return {
                        x: d.xy[0] + _trTaskWidth / 2,
                        y: d.xy[1] + d.xy[2]
                    };
                }
            )
            .target(
                /** @this SVGElement */
                function() {
                    const d = d3.select(this).datum();
                    return (
                        (d.__isStart && {
                            x: d.end[0] + _trTaskWidth / 2,
                            y: d.end[1] + d.end[2]
                        }) || {
                            x: d.end[0],
                            y: d.end[1]
                        }
                    );
                }
            );

        let linkHandles;
        let $highlightArray;
        let zoomNPan;
        const binds = new $classes.Binds();
        let groupStack = {};
        let cuser;
        /** @type {Array<SVGElement>} */
        let selection = [];
        const transform = 'transform';
        const translate = 'translate';
        /** @type {Array<SVGPathElement>} */
        let dragLines = [];
        let newLinkNode;
        let newTaskNode;
        let linkHandleDrag;
        let newLinkDrag;
        let shadowDrag = null;
        let pasteDrag;
        let taskDrag;
        let dynGuide;
        /** @type {d3_selectionPrototype} */
        let markersLayer;
        let isTaskDragged = false;
        /** @type {{x: number, y: number, x1: number, y1: number}} */
        let groupPopupLocation;

        const guideLocks = {
            x: null,
            y: null
        };

        /** @type {d3_selectionPrototype} */
        let projectStartDate;
        /** @type {number} */
        let oldScale = 1;
        /** @type {Promise<any>[]} */
        const curDrawQueue = [];
        /** @type {d3_selectionPrototype} */
        let paper;
        const fndOldStyleScroll = dm.hasFeature(_fndOldStyleScroll);

        const diagonal = casualLink()
            .source(linkStartXY)
            .target(linkEndXY)
            .useBoxDim(_trTaskWidth + 1, 60)
            .useAbsoluteCoord(false);

        /**
         * @param {number} val
         * @param {number} sp
         * @param {number} lp
         */
        const between = (val, sp, lp) => val >= sp && val <= lp;

        /** @type {{ width: number; height: number; top?: number; left?: number; right?: number; bottom?: number; }} */
        let poffset;
        const updatePOffset = () => {
            poffset = core_dom.elementOffset(svg.node().parentNode);
        };

        const updateProjectStartDate = t => {
            // debugger;
            if (!projectStartDate) {
                return;
            }
            var t = dm
                .tasks(groupId)
                .$tasks.sort(tasksSortFn)
                .first();

            if (t) {
                const d =
                    ($scope[_bndCurrentProject].startDate &&
                        core_utils.unpackDate($scope[_bndCurrentProject].startDate)) ||
                    undefined;
                binds
                    .ns(_bndProjectStartDate)
                    .set(_bndProjectStartDate, core.getMsg(_msgProjectStartDate, { date: d }));
                projectStartDate.classed(_clsDateNotSet, !d);
                const b = projectStartDate.node().getBBox();
                projectStartDate.attr(
                    transform,
                    `${translate}(${[(t.x || 0) + (_trTaskWidth / 2 - b.width / 2), (t.y || 0) - 40]})`
                );
            } else {
                binds.ns(_bndProjectStartDate).set(_bndProjectStartDate, '');
            }
        };

        if (!__EMBED) {
            linkHandleDrag = d3.behavior
                .drag()
                .origin(Object)
                .on(
                    'dragstart',
                    /**
                     * @this SVGElement
                     */
                    function(d) {
                        if (d3.event.sourceEvent.button != 0) {
                            this.__skipDrag = true;
                            return;
                        }
                        const link = d3.select(nodes_map[d['data']['id']]).classed(_clsEditing, true);
                        const end = d['id'] == _tcLinkStartHandleID ? linkEndXY(d['data']) : linkStartXY(d['data']);
                        const clink = link.node().cloneNode();
                        const line = casualLink()
                            .useVersion(dm.hasFeature(_fndFlexibleArrows) ? 1 : 0)
                            .source(
                                d['id'] == _tcLinkStartHandleID
                                    ? () => ({
                                          x: this.__x,
                                          y: this.__y
                                      })
                                    : () => ({
                                          x: end.x + _trTaskWidth / 2,
                                          y: end.y + 60
                                      })
                            )
                            .target(
                                d['id'] == _tcLinkStartHandleID
                                    ? () => ({
                                          x: end.x + _trTaskWidth / 2,
                                          y: end.y
                                      })
                                    : () => ({
                                          x: this.__x,
                                          y: this.__y
                                      })
                            );

                        layers.helperLayer.node().appendChild(clink);

                        link.style('display', 'none');

                        this.__update = function(x, y) {
                            this.__x = x;
                            this.__y = y;
                            this.__link.attr('d', line);
                        };

                        this.__link = d3.select(clink).datum(link.datum());
                        this.__olink = link;
                    }
                )
                .on('drag', function(d) {
                    if (this.__skipDrag) {
                        return;
                    }
                    const od = d['data'];
                    const from = (d['id'] == _tcLinkStartHandleID && od['toId']) || od['fromId'];
                    const c = d3.select(this);
                    let x;
                    let y;

                    c.attr('cx', (x = Number(c.attr('cx')) + d3.event.dx));
                    c.attr('cy', (y = Number(c.attr('cy')) + d3.event.dy));
                    this.__update(x, y);

                    const targetTask = getNodeFromXY({ x, y });

                    if (targetTask) {
                        const tn = d3.select(targetTask),
                            td = tn.datum(),
                            ar = analyzeNewLink(from, td.id, true);

                        d.targetTask = targetTask;
                        c.classed(_clsForbidenLink, !ar);
                        this.__link.classed(_clsForbidenLink, !ar);
                        tn.classed(_clsHovered, ar);
                    } else {
                        d.targetTask && d3.select(d.targetTask).classed(_clsHovered, false);
                        d.targetTask = null;
                        c.classed(_clsForbidenLink, false);
                        this.__link.classed(_clsForbidenLink, false);
                    }

                    c.datum(d);
                })
                .on(
                    'dragend',
                    /** @this SVGElement */
                    function(d) {
                        if (this.__skipDrag) {
                            delete this.__skipDrag;
                            return;
                        }
                        const od = d['data'],
                            tid = (d.targetTask && d3.select(d.targetTask).datum()['id']) || null,
                            from = tid && d['id'] == _tcLinkStartHandleID ? tid : od['fromId'],
                            to = tid && d['id'] == _tcLinkStartHandleID ? od['toId'] : tid;

                        this.__link.classed(_clsForbidenLink, false);
                        d3.select(this).classed(_clsForbidenLink, false);

                        if (d.targetTask && analyzeNewLink(from, to) && (from != od.fromId || to != od.toId)) {
                            clearSelection();
                            d3.select(d.targetTask).classed(_clsHovered, false);
                            const ud = this.__link.datum(),
                                ur = ud['fromId'] == null || ud['toId'] == null;

                            if (ur) {
                                this.__link.remove();
                            } else {
                                dm.updateTransition(od['id'], {
                                    fromId: from,
                                    toId: to
                                });

                                ud['fromId'] = from;
                                ud['toId'] = to;

                                const olink = this.__olink;
                                olink.attr('d', this.__link.attr('d')).style('display', '');

                                this.__link.remove();

                                olink
                                    .data(ud)
                                    .transition()
                                    .attr('d', diagonal)
                                    .duration(200)
                                    .ease('bounce');
                            }

                            updateCallback(enmStateChangeTypes.UpdateTransition, od['id'], {
                                fromId: from,
                                toId: to
                            });
                        } else {
                            const //c = d['id'] == _tcLinkStartHandleID ? linkStartXY(d['data']) : linkEndXY(d['data']),
                                link = this.__olink;

                            link.attr('d', this.__link.attr('d')).style('display', '');
                            this.__link.remove();

                            link.classed(_clsEditing, false);
                            const p = diagonal(link.datum(), 0, true);

                            d3.select(this)
                                .transition()
                                .attr('cx', d['id'] == _tcLinkStartHandleID ? p.x : p.x1)
                                .attr('cy', d['id'] == _tcLinkStartHandleID ? p.y : p.y1)
                                .duration(200)
                                .ease('bounce');

                            link.transition()
                                .attr('d', diagonal)
                                .duration(200)
                                .ease('bounce');
                        }
                        delete this.__x;
                        delete this.__y;
                        delete this.__update;
                        delete this.__link;
                        delete this.__olink;
                    }
                );

            newLinkDrag = d3.behavior
                .drag()
                .origin(function() {
                    const el = d3.select(this);
                    const d = d3.select(this.parentNode).datum();
                    const xy = Object.values(linkStartXY(d['id']));
                    const nd = { id: d['id'] };

                    nd.xy = xy;
                    nd.end = xy;
                    nd.__isStart = true;
                    el.datum(nd);
                    nldiagonal.useVersion(dm.hasFeature(_fndFlexibleArrows) ? 1 : 0);

                    return {
                        x: xy[0],
                        y: xy[1]
                    };
                })
                .on('dragstart', function() {
                    if (d3.event.sourceEvent.button != 0) {
                        this.__skipDrag = true;
                        return;
                    }
                    const d = d3.select(this).datum();
                    newLinkNode = layers.helperLayer
                        .append('svg:path')
                        .classed(_clsDraftLink, true)
                        .datum(d)
                        .attr({
                            d: nldiagonal,
                            'marker-end': `url(#${_clsMarkerArrow})`
                        });

                    newTaskNode = layers.helperLayer
                        .append('svg:rect')
                        .classed(_clsDraftTask, true)
                        .attr({
                            x: d.xy[0] - 15,
                            y: d.xy[1],
                            width: 30,
                            height: 12,
                            rx: 4,
                            ry: 4
                        });
                    newTaskNode.show = function() {
                        this.classed(_clsActive, true);
                    };
                    newTaskNode.hide = function() {
                        this.classed(_clsActive, false);
                    };
                })
                .on('drag', function() {
                    if (this.__skipDrag) {
                        return;
                    }
                    const d = d3.select(this).datum(),
                        p = normalizeCoord();

                    d.end = [p.x, p.y];
                    if (d.__isStart) {
                    }
                    d.__isStart = false;

                    newTaskNode.attr({
                        x: p.x - 15,
                        y: p.y
                    });

                    const targetTask = getNodeFromXY(p);

                    if (targetTask) {
                        newTaskNode.hide();
                        d.newtask = false;
                        const tn = d3.select(targetTask),
                            td = tn.datum(),
                            ar = analyzeNewLink(d.id, td.id);

                        d.targetTask = targetTask;
                        newLinkNode
                            .attr('marker-end', `url(#${(ar && _clsMarkerArrow) || _clsMarkerCircle})`)
                            .classed(_clsForbidenLink, !ar);
                        tn.classed(_clsHovered, ar);
                        d.newlink = ar;
                    } else {
                        newTaskNode.show();
                        d.newtask = true;
                        d.targetTask && d3.select(d.targetTask).classed(_clsHovered, false);
                        d.targetTask = null;
                        newLinkNode.attr('marker-end', `url(#${_clsMarkerArrow})`).classed(_clsForbidenLink, false);
                    }

                    d3.select(this).datum(d);
                    newLinkNode.attr('d', nldiagonal);
                })
                .on('dragend', function() {
                    if (this.__skipDrag) {
                        delete this.__skipDrag;
                        return;
                    }
                    const d = d3.select(this).datum();
                    if (d.newtask) {
                        eventCallback(_evCreateTaskWithLink, {
                            task: {
                                cr: XY2CR(adjustXY(d.end[0] - _trTaskWidth / 2, d.end[1])),
                                groupId: groupId == _dcNoGroup ? undefined : groupId
                            },
                            link: {
                                fromId: d.id
                            },
                            node: this
                        });
                    } else if (d.newlink) {
                        const nd = {
                            fromId: d.id,
                            toId: d3.select(d.targetTask).datum().id
                        };
                        eventCallback(_evCreateLink, nd);
                    }
                    newLinkNode.remove();
                    newLinkNode = null;
                    newTaskNode.remove();
                    newTaskNode = null;
                    // updateTasks();
                    // updateLinks();
                    redraw();
                });

            (taskDrag = d3.behavior
                .drag()
                .origin(null)
                .on('dragstart', function(d) {
                    if (!core.mayI.updateTask(d) || d3.event.sourceEvent.button != 0) {
                        this.__skipDrag = true;
                        return;
                    }
                    ntasks.moveOnTop(this).order();
                    this.__taskDragged = false;
                    this.__oxy = getTranslate.call(this);
                    this.__tz = zoomNPan.translate().map(v => v * zoomNPan.scale());
                    groupPopupLocation = eventCallback(_evGetGroupPopupLocation);
                })
                .on(
                    'drag',
                    /**
                     * @this SVGElement
                     * @param d {cTaskInfo}
                     */
                    function(d) {
                        if (!core.mayI.updateTask(d) || this.__skipDrag) {
                            return;
                        }
                        const ev = d3.event;
                        isTaskDragged = true;
                        if (!this.__taskDragged) {
                            this.__taskDragged = true;
                            hoveredNodes.start();
                            dragLines = [];
                            if (selection.length && selection.indexOf(this) > -1) {
                                selection.forEach(v => {
                                    v.__oxy = getTranslate.call(v);
                                    dragLines = dragLines.concat(
                                        getLinksOfNode(d3.select(v).datum())
                                            .unique()
                                            .map(n => nodes_map[n])
                                    );
                                });
                            } else {
                                clearSelection();
                                d3.select(this).classed(_clsSelectedNode, true);
                                selection = [this];
                                this.__selectionByDrag = true;
                                dragLines = getLinksOfNode(d).map(n => nodes_map[n]);
                            }
                        }

                        const zt = this.__tz || zoomNPan.translate().map(v => v * zoomNPan.scale());

                        const zx = zt[0] + ev.x;
                        const zy = zt[1] + ev.y;
                        let p;
                        let dx = ev.dx;
                        let dy = ev.dy;

                        if (d3.event.sourceEvent.shiftKey) {
                            if (shadowDrag) {
                                shadowDrag.parentNode && shadowDrag.parentNode.removeChild(shadowDrag);
                                shadowDrag = null;
                            }
                            const r = processDynGuide(this);
                            if (r.c.length || r.r.length) {
                                if (!guideLocks.x) {
                                    const gx = r.c.min(d => d[1]);

                                    if (gx && gx[1] <= _trGridSnap) {
                                        const gl = (guideLocks.x = {
                                            orig: 0
                                        });
                                        const { x, y } = getTranslate.call(this, true);
                                        let ddx = x - gx[0];

                                        if (Math.abs(ddx) >= _trTaskWidth / 2) {
                                            ddx = x + _trTaskWidth - gx[0];
                                        }
                                        gl.orig = ev.x;
                                        dx = -ddx;
                                    }
                                } else if (Math.abs(guideLocks.x.orig - ev.x) > _trGridSnap) {
                                    dx = (guideLocks.x.orig - ev.x < 0 ? _trGridSnap + 2 : -(_trGridSnap + 2)) + ev.dx;
                                    guideLocks.x = null;
                                } else {
                                    dx = 0;
                                }

                                if (!guideLocks.y) {
                                    const gy = r.r.min(d => d[1]);
                                    if (gy && gy[1] <= _trGridSnap) {
                                        const gl = (guideLocks.y = {
                                            orig: 0,
                                            oof: 0
                                        });
                                        const bb = GBBox(this, false, true);
                                        let ddy = bb.y - gy[0];

                                        if (Math.abs(ddy) > bb.height / 2) {
                                            ddy = bb.y + bb.height - gy[0] - 1;
                                        }
                                        gl.orig = ev.y;
                                        gl.oof = ddy;
                                        dy = -ddy;
                                    }
                                } else if (Math.abs(guideLocks.y.orig - ev.y) > _trGridSnap) {
                                    dy = (guideLocks.y.orig - ev.y < 0 ? _trGridSnap + 2 : -(_trGridSnap + 2)) + ev.dy;
                                    guideLocks.y = null;
                                } else {
                                    dy = 0;
                                }
                            }
                        } else if (guideLocks.x || guideLocks.y) {
                            guideLocks.x = null;
                            guideLocks.y = null;
                        }

                        d3.selectAll(selection).each(
                            /**
                             * @this SVGElement
                             * @param d {cTaskInfo}
                             */
                            function(d) {
                                const el = d3.select(this);
                                const { x, y } = getTranslate.call(el, true);

                                el.attr(transform, `${translate}(${[x + dx, y + dy]})`);
                                this.__taskDragged = true;
                            }
                        );

                        d3.selectAll(dragLines).attr('d', diagonal);

                        if (!dynGuide) {
                            p =
                                groupPopupLocation &&
                                core_utils.isPointInside(
                                    [ev.sourceEvent.pageX, ev.sourceEvent.pageY],
                                    groupPopupLocation
                                );

                            hoveredNodes.check([ev.x, ev.y], this, id => {
                                d3.event = ev;
                                taskDrag.on('drag').call(this);
                            });
                        }

                        switch (true) {
                            case groupId && !shadowDrag && groupPopupLocation && !p && !d3.event.sourceEvent.shiftKey: // && !this.__altDrag:
                            case !groupId && !shadowDrag && groupPopupLocation && p && !d3.event.sourceEvent.shiftKey: // && !this.__altDrag:
                                const rect = rectOfSelection();
                                shadowDrag = createShadowDrag(rect);
                                shadowDrag.sourceGroup = groupId;

                                const po = $(svg.node().parentNode).offset();
                                // const z = zoomNPan.scale();

                                eventCallback(_evShadowDragCreated, shadowDrag, {
                                    groupId,
                                    xy: [rect.x + zt[0] + po?.left, rect.y + zt[1] + po?.top],
                                    autoPopup: false
                                });
                                break;
                            // case !shadowDrag && this.__altDrag:
                            //     var rect = rectOfSelection();
                            //
                            //     d3.select(shadowDrag = createShadowDrag(rect, true))
                            //         .attr(transform, translate + '(' + [rect.x, rect.y] + ')')
                            //         .attr('filter', 'url(#' + _clsAltDrag + ')');
                            //     break;
                            case !!shadowDrag:
                                const { x, y } = getTranslate.call(shadowDrag, true);
                                d3.select(shadowDrag).attr(transform, `${translate}(${[x + dx, y + dy]})`);
                                shadowDrag.__inplace =
                                    /*this.__altDrag ? true : */ !shadowDrag.__rejected && (groupId ? !p : p);
                        }
                    }
                )
                .on('dragend', function(d) {
                    if (this.__skipDrag) {
                        delete this.__skipDrag;
                        return;
                    }
                    if (!this.__taskDragged) {
                        return;
                    }
                    isTaskDragged = false;
                    hoveredNodes.cancel();
                    processDynGuide();
                    guideLocks.x = guideLocks.y = null;
                    dynGuide = null;

                    const changed = {};
                    let oxy = this.__oxy;
                    const nxy = getTranslate.call(this);

                    if (shadowDrag && shadowDrag.__inplace) {
                        const el = d3.select(shadowDrag);
                        const d = el.datum();
                        const bxy = getTranslate.call(shadowDrag);
                        var xy = adjustXY(bxy[0], bxy[1]);
                        const data = {};

                        el.selectAll(`g.${enmTaskInfoTypes.Task}, g.${enmTaskInfoTypes.Group}`).each(function(d) {
                            data[d3.select(this).datum()['id']] = getTranslate
                                .call(this)
                                .map((c, i) => c + xy[['x', 'y'][i]]);
                        });

                        // this.__altDrag
                        //     ? eventCallback(_evCloneByAltDrag, data, groupId, d, that)
                        //     :
                        eventCallback(_evShadowDragDrop, data, shadowDrag.targetGroup, d.redundantLinks);
                    } else if (shadowDrag && shadowDrag.__rejected /* && !this.__altDrag*/) {
                        selection.forEach(n => {
                            d3.select(n) //.transition()
                                .attr(transform, `${translate}(${n.__oxy})`);
                            // .duration(250);
                            delete n.__oxy;
                        });
                        updateLinks();
                    } else {
                        d3.selectAll(selection).attr(transform, function(d) {
                            const oxy = getTranslate.call(this);
                            const xy = adjustXY(oxy[0], oxy[1]);
                            const cr = XY2CR(xy);
                            const nxy = oxy.clone();

                            if (d['colId'] != cr.col) {
                                d['colId'] = cr.col;
                                !changed[d['id']] && (changed[d['id']] = {});
                                changed[d['id']]['colId'] = cr.col;
                            }
                            if (d['rowId'] != cr.row) {
                                d['rowId'] = cr.row;
                                !changed[d['id']] && (changed[d['id']] = {});
                                changed[d['id']]['rowId'] = cr.row;
                            }
                            d.x = xy.x;
                            d.y = xy.y;

                            delete this.__taskDragged;
                            return `${translate}(${[xy.x, xy.y]})`;
                        });

                        d3.selectAll(dragLines).attr('d', diagonal);
                    }

                    if (this.__autoOpenGroup && shadowDrag && (!shadowDrag.__inplace || shadowDrag.__rejected)) {
                        setTimeout(() => {
                            eventCallback(_evCloseGroup);
                        }, 250);
                        delete this.__autoOpenGroup;
                    } else if (this.__selectionByDrag) {
                        dm.setCTID(d['id'], _wtTree);
                        delete this.__selectionByDrag;
                    }

                    if (shadowDrag) {
                        shadowDrag.parentNode && shadowDrag.parentNode.removeChild(shadowDrag);
                        shadowDrag = null;
                    }

                    for (const k in changed) {
                        updateCallback(enmStateChangeTypes.UpdateTask, k, changed[k]);
                        dm.updateTask(k, changed[k]);
                    }

                    xy = linkStartXY(this);

                    delete this.__oxy;
                    delete this.__tz;
                    delete this.__vp;
                })),
                (pasteDrag = d3.behavior
                    .drag()
                    .origin(null)
                    .on('drag', function() {
                        const ev = d3.event,
                            xy = getTranslate.call(this);

                        d3.select(this).attr(transform, `${translate}(${[xy[0] + ev.dx, xy[1] + ev.dy]})`);
                    }));
        }
        /**
         * Create initial state of SVG with required layers and objects
         */
        (() => {
            zoomNPan = d3.behavior
                .zoom()
                .scaleExtent((__NOZOOM && [1, 1]) || [0.5, 1])
                .on('zoom', () => {
                    const ev = d3.event;
                    const sev = ev.sourceEvent;

                    if (
                        (!fndOldStyleScroll || __EMBED) &&
                        !!sev &&
                        sev instanceof MouseEvent &&
                            ((!sev.ctrlKey && !sev.metaKey && sev.defaultPrevented) || sev.button != 0)
                    ) {
                        return;
                    }

                    setTransform(ev.translate, ev.scale);
                    oldScale != ev.scale && (eventCallback(_evZoom, ev.scale), (oldScale = ev.scale));
                });

            if (!__EMBED) {
                svg.on('click', () => {
                    if (d3.event.defaultPrevented) {
                        return;
                    }
                    const p = normalizeCoord();

                    dm.getCTID() && dm.setCTID(undefined, _wtTree);
                    clearSelection();
                    isLinkClicked(p);
                    return false;
                });

                svg.on('contextmenu', () => {
                    !d3.event.defaultPrevented && eventCallback(_evShowContextMenu, undefined, that);
                });

                svg.on('dragover', () => {
                    const ev = d3.event;
                    ev.dataTransfer.dropEffect = 'move';
                    ev.preventDefault();
                    return false;
                });

                svg.on(
                    'mousedown',
                    () => {
                        // /** @type {HTMLElement|null} */
                        const el = document.activeElement;
                        if (el && /textarea|input/i.test(el.tagName)) {
                            $(el).trigger('focusout');
                        }
                        // core.clearMenus && core.clearMenus();
                        $(document).trigger(_evClosePopups);
                        if (d3.event.button == 2 && /svg/.test(d3.event.currentTarget.tagName)) {
                            d3.event.preventDefault();
                            return;
                        }
                        if (svg.node() == d3.event.target && (d3.event.ctrlKey || d3.event.metaKey)) {
                            d3.event.preventDefault();
                            const origin = normalizeCoord();
                            // const { pageX, pageY } = d3.event;
                            // const [xt, yt, s] = getTranslate.call(view.node());
                            // const { left: x, top: y } = $(svg.node()).offset();
                            // const origin = {
                            //     x: ~~(pageX - x - xt * s),
                            //     y: ~~(pageY - y - yt * s)
                            // };
                            // console.log({ o, origin });
                            let isDragging = false;
                            svg.on('mousemove', () => {
                                const { movementX: dx, movementY: dy } = d3.event;
                                const { x, y } = normalizeCoord();
                                if ((Math.abs(dx) > 3 || Math.abs(dy) > 3) && isDragging == false) {
                                    isDragging = true;
                                    selectionRect.init(layers.helperLayer, origin.x, origin.y);
                                    // selectionRect.updateByDelta(dx, dy);
                                    selectionRect.update(x, y);
                                } else if (isDragging == true) {
                                    // selectionRect.updateByDelta(dx, dy);
                                    selectionRect.update(x, y);
                                }
                            }).on('mouseup', () => {
                                svg.on('mousemove', null).on('mouseup', null);
                                if (isDragging) {
                                    isDragging = false;
                                    const finalAttributes = selectionRect.getCurrentAttributes();
                                    const old_selection = dm.getCTID() || [];
                                    const sel = ntasks
                                        .filter(
                                            /** @this HTMLElement */
                                            function() {
                                                const { x, y, height, width } = GBBox(this);
                                                return (
                                                    x >= finalAttributes.x1 - 2 &&
                                                    x + width <= finalAttributes.x2 + 2 &&
                                                    y >= finalAttributes.y1 - 2 &&
                                                    y + height - 11 <= finalAttributes.y2 + 2
                                                );
                                            }
                                        )
                                        .map(
                                            /** @param {cTaskInfo} d */
                                            d => d['id']
                                        );

                                    setTimeout(() => {
                                        dm.setCTID(sel.concat(old_selection).unique(), _wtTree);
                                    }, 0);
                                }
                                selectionRect.remove();
                            });
                        }
                    },
                    true
                );

                svg.on('drop', () => {
                    let cpd, d;
                    try {
                        (cpd = d3.event.dataTransfer.getData((core.isIE && 'text') || _gcClipboardId)),
                            (d = (cpd && JSON.parse(cpd)) || {
                                o: { x: 0, y: 0 }
                            });
                    } catch (e) {}

                    if (!d && !d['o']) {
                        return false;
                    }

                    const m = d3.mouse(svg.node());
                    const p = normalizeCoord(m[0], m[1], d['o'].x, d['o'].y);
                    const xy = adjustXY(p.x, p.y);

                    if (ntasks.count() && !ntasks[0].length) {
                        dm.axis(groupId).margin(xy.x, xy.y);
                    }

                    const cr = XY2CR(xy);
                    const dataCTT = {
                        rowId: cr.row,
                        colId: cr.col,
                        type: enmTaskInfoTypes.Task,
                        projectId: $scope[_bndCurrentProject].id,
                        groupId: groupId == _dcNoGroup ? undefined : groupId
                    };
                    /** @type {cTaskInfo} */
                    const dataUT = new $classes[_cTaskInfo](d).$update(dataCTT);

                    if (d['dragMode'] != undefined) {
                        updateCallback(enmStateChangeTypes.CreateTask, d['id'], dataUT);
                        dm.addTask(dataUT);
                        // updateTasks();
                        redraw().then(() => {
                            const el = d3
                                .select(nodes_map[d['id']])
                                .select(`text.${_clsTaskName}`)
                                .node();
                            eventCallback(_evTaskNameEdit, d, el, true);
                            dm.setCTID(d['id'], _wtTree);
                        });
                    } else {
                        updateCallback(enmStateChangeTypes.ChangeTaskType, d['id'], dataCTT);
                        dm.changeTaskType(d['id'], dataUT);
                        // FIXME: ChangeTaskType not accept groupId yet, need to be updated
                        // (groupId != _dcNoGroup && groupId != undefined) && (updateCallback(enmStateChangeTypes.UpdateTask, d['id'], {'groupId': groupId}),
                        dm.updateTask(d['id'], { groupId });
                        // updateTasks();
                        redraw();
                    }

                    d3.event.preventDefault();
                    d3.event.stopPropagation();

                    core_stat.incAndTrack(_mpcTaskCreated);
                    return false;
                });
            }

            svg.call(zoomNPan);
            svg.on('dblclick.zoom', null);

            view = svg
                .append('g')
                .classed(_clsTreeView, true)
                .attr(transform, `${translate}(0,0) scale(1)`);
            vnode = view.node();

            const gs = view.selectAll('g').data(Object.keys(layers));

            gs.enter()
                .append('g')
                .attr('class', function(d) {
                    const c = layers[d];
                    layers[d] = d3.select(this);
                    return c;
                });

            svg.selectAll(`g.${_clsMarkersLayer}`)
                .data([_clsMarkersLayer])
                .enter()
                .append('g')
                .classed(_clsMarkersLayer, true);

            markersLayer = svg.select(`g.${_clsMarkersLayer}`).moveOnTop();

            $(markersLayer.node()).on('click', 'path', ev => {
                const cc = zoomNPan.translate();
                switch (d3.select(ev.target).attr('class')) {
                    case _clsTopMarker:
                        cc[1] += _gcTreeMarkerInc;
                        break;
                    case _clsRightMarker:
                        cc[0] -= _gcTreeMarkerInc;
                        break;
                    case _clsBottomMarker:
                        cc[1] -= _gcTreeMarkerInc;
                        break;
                    case _clsLeftMarker:
                        cc[0] += _gcTreeMarkerInc;
                        break;
                }
                setTransform(cc, undefined, true);
                return false;
            });

            !__EMBED &&
                (paper = layers.paperLayer
                    .append('rect')
                    .classed(_clsProjectPaper, true)
                    .attr('fill', `url(#${_idPatternCanvasFillVectr})`)
                    .attr('x', 0)
                    .attr('y', 0)
                    .attr('width', 0)
                    .attr('height', 0));
        })();

        const updateMarkers = function updateMarkers() {
            const mHeight = 10;
            const mWidth = 70;
            if (that.active !== true) {
                return;
            }
            const cr = poffset;
            const vbb = layerbox;
            const [tx, ty, s] = getTranslate.call(view);
            const data = [];

            // [_clsTopMarker, _clsRightMarker, _clsBottomMarker, _clsLeftMarker]
            if (groupId || __SHOW_MARKERS) {
                if (ty + vbb.y < 0) {
                    data.push(_clsTopMarker);
                }
                if (ty + vbb.y + vbb.height * s > cr.height) {
                    data.push(_clsBottomMarker);
                }
                if (tx + vbb.x < 0) {
                    data.push(_clsLeftMarker);
                }
                if (tx + vbb.x + vbb.width * s > cr.width) {
                    data.push(_clsRightMarker);
                }
            }

            const ms = markersLayer.selectAll('path').data(data, d => d);
            ms.enter()
                .append('path')
                .attr('class', d => d);

            ms.exit().remove();

            ms.each(
                /**
                 * @this SVGElement
                 * @param {string} d
                 */
                function(d) {
                    // return n[2] + cc[0] / s > 0 && cc[0] / s - vbb.width / s + n[0] < 0
                    //     && n[3] + cc[1] / s - 12 > 0 && cc[1] / s - vbb.height / s + n[1] + 40 / s < 0
                    let s;
                    switch (d) {
                        case _clsTopMarker:
                            s = `M${(cr.width - mWidth * 2) / 2},${(__EMBED && !groupId ? 40 : 0) +
                                mHeight * 2}l${mWidth},-${mHeight}l${mWidth},${mHeight}`;
                            break;
                        case _clsRightMarker:
                            s = `M${cr.width - mHeight * 2},${(cr.height - 40 - mWidth * 2) /
                                2}l${mHeight},${mWidth}l-${mHeight},${mWidth}`;
                            break;
                        case _clsBottomMarker:
                            s = `M${(cr.width - mWidth * 2) / 2},${cr.height -
                                40 -
                                mHeight * 2}l${mWidth},${mHeight}l${mWidth},-${mHeight}`;
                            break;
                        case _clsLeftMarker:
                            s = `M${mHeight * 2},${(cr.height - 40 - mWidth * 2) /
                                2}l-${mHeight},${mWidth}l${mHeight},${mWidth}`;
                            break;
                    }
                    d3.select(this).attr('d', s);
                }
            );
        };
        const updateMarkersDbns = updateMarkers.throttle(50);

        /** @type {Function} */
        let updatePaper;
        !__EMBED &&
            (updatePaper = function updatePaper() {
                const vbb = layerbox;
                paper
                    .attr('x', vbb.x - poffset.width)
                    .attr('y', vbb.y - poffset.height)
                    .attr('width', vbb.width + poffset.width * 2)
                    .attr('height', vbb.height + poffset.height * 2);
            });
        // var updatePaperDbns = updatePaper.throttle(50);

        /** @type {Function} */
        var createShadowDrag;
        !__EMBED &&
            (that.shadowDrag = createShadowDrag =
                /**
                 * @param {{x:number, y: number, x1:number, y1: number}} rect
                 * @param {boolean} noDetach
                 * @return {SVGElement}
                 */
                function createShadowDrag(rect, noDetach = false) {
                    let links;
                    /** @type {Array<string>} */
                    const gids = [];

                    const holder = svg
                        .append('g')
                        .classed(_clsShadowDragNodes, true)
                        .node();

                    const link = casualLink()
                        .useBoxDim(_trTaskWidth, 60)
                        .useVersion(dm.hasFeature(_fndFlexibleArrows) ? 1 : 0)
                        .source(
                            /** @this SVGElement */
                            function() {
                                const d = linkStartXY(getNode(d3.select(this).datum()['fromId']));
                                return {
                                    x: d.x - rect.x,
                                    y: d.y - rect.y
                                };
                            }
                        )
                        .target(
                            /** @this SVGElement */
                            function() {
                                const d = linkEndXY(getNode(d3.select(this).datum()['toId']));
                                return {
                                    x: d.x - rect.x,
                                    y: d.y - rect.y
                                };
                            }
                        );

                    const sel = selection.map(n => d3.select(n).datum()['id']);

                    !rect && (rect = rectOfSelection());

                    links = d3
                        .selectAll(dragLines)
                        .filter(
                            /** @param {cTransitionInfo} d */
                            d => sel.indexOf(d.fromId) > -1 && sel.indexOf(d.toId) > -1
                        )
                        .each(
                            /** @this SVGElement */
                            function() {
                                const n = this.cloneNode();
                                d3.select(n)
                                    .datum(d3.select(this).datum())
                                    .attr('d', link);
                                holder.appendChild(n);
                            }
                        )
                        .map(
                            /** @param {cTransitionInfo} d */
                            d => d.id
                        );

                    selection.forEach(n => {
                        const node = n.cloneNode(true);
                        const { x, y } = getTranslate.call(node, true);
                        let d;

                        d3.select(node)
                            .datum((d = d3.select(n).datum()))
                            .attr(transform, `${translate}(${[x - rect.x, y - rect.y]})`);

                        d['type'] == enmTaskInfoTypes.Group && gids.push(d['id']);

                        holder.appendChild(node);
                    });

                    d3.select(holder).datum({
                        redundantLinks: dragLines.map(d => d3.select(d).datum()['id']).subtract(links),
                        links,
                        nodes: selection.map(d => d['id'])
                    });
                    holder.__gids = gids;

                    return (noDetach && holder) || holder.parentNode.removeChild(holder);
                });

        function atoi(str) {
            return str != null ? parseInt(str, 10) : 'n';
        }

        !__EMBED &&
            (that.meta2Node = (data, applyDrag, offset) => {
                // debugger;
                const node = layers.helperLayer.append('g').classed(_clsPasteHelper, true),
                    but = (text, y, padding, sufClass) => {
                        const r = node.append('rect'),
                            t = node
                                .append('text')
                                .text(text)
                                .attr({
                                    x: data.w / 2,
                                    y,
                                    class: _clsButtonText + (sufClass ? ` ${sufClass}` : '')
                                }),
                            bb = t.node().getBBox();

                        r.attr({
                            x: bb.x - padding,
                            y: bb.y - padding / 2,
                            width: bb.width + padding * 2,
                            height: bb.height + padding,
                            rx: padding,
                            ry: padding,
                            class: _clsButtonRect + (sufClass ? ` ${sufClass}` : '')
                        });

                        return {
                            y: bb.y + bb.height + padding,
                            nodes: d3.selectAll([r.node(), t.node()])
                        };
                    };

                if (!offset) {
                    offset = [];
                    const dim = poffset;
                    const tr = zoomNPan.translate();
                    const sc = zoomNPan.scale();

                    if (data.w > dim.width) {
                        offset[0] = -tr[0] / sc;
                    } else {
                        offset[0] = (dim.width / sc - data.w / sc) / 2 - tr[0] / sc;
                    }

                    if (data.h > dim.height) {
                        offset[1] = -tr[1] / sc;
                    } else {
                        offset[1] = (dim.height / sc - data.h / sc) / 2 - tr[1] / sc;
                    }
                }

                node.attr(transform, `${translate}(${offset || zoomNPan.translate().map(v => -v)})`)
                    .append('rect')
                    .classed(_clsSelectionRect, true)
                    .attr({
                        x: -5,
                        y: -5,
                        width: data.w + 5,
                        height: data.h + 5,
                        'stroke-dasharray': '5,3'
                    });
                node.append('line').attr({
                    x1: -5,
                    y1: -5,
                    x2: data.w,
                    y2: data.h,
                    'stroke-dasharray': '5,3'
                });
                node.append('line').attr({
                    x1: data.w,
                    y1: -5,
                    x2: -5,
                    y2: data.h,
                    'stroke-dasharray': '5,3'
                });

                const tasksNode = node.append('g');

                if (applyDrag) {
                    node.on('mousedown', stop_propagation)
                        .on('touchstart', stop_propagation)
                        .call(pasteDrag);
                }

                tasksNode /*.append('g')*/
                    .attr(transform, `${translate}(${data.ofs.join(',')})`)
                    .node().innerHTML = data.d;

                const d = but(core.getMsg(_msgPasteFrameApply), data.h / 2, 10);
                d.nodes.on('click', () => {
                    eventCallback(_evApplyPaste);
                });
                but(core.getMsg(_msgPasteFrameCancel), d.y + 15, 5, 'reg').nodes.on('click', () => {
                    eventCallback(_evCancelPaste);
                });

                return node;
            });

        !__EMBED &&
            (that.createMetaData = sel => {
                sel = sel ? sel.map(v => (v instanceof SVGElement && v) || nodes_map[v]) : selection;
                let links = [];
                const rect = rectOfSelection(false, sel);
                const hash = {};
                let data = sel
                    .map(n => {
                        const d = d3.select(n).datum();
                        links = links.concat(getLinksOfNode(d));
                        const c = n.cloneNode(true);
                        d3.select(c).attr('data-id', d.id);
                        hash[d.id] = true;
                        const r = c.outerHTML;
                        return r;
                    })
                    .reduce((s, v) => {
                        s += v;
                        return s;
                    }, '');

                data =
                    links
                        .unique()
                        .filter(id => {
                            const d = core_DO.transition(id);
                            return d.fromId in hash && d.toId in hash;
                        })
                        .map(id => nodes_map[id].outerHTML)
                        .reduce((s, v) => {
                            s += v;
                            return s;
                        }, '') + data;

                return {
                    ofs: [-rect.x, -rect.y],
                    w: rect.x1 - rect.x,
                    h: rect.y1 - rect.y,
                    d: data
                };
            });

        /**
         * Calculates nodes virtual coordinates and provide info package
         * @param {D3_Selection}
         * @return {Array.<{cTaskPastePosition}>}
         */
        !__EMBED &&
            (that.calculatePasteInfo = node => {
                const oxy = getTranslate.call(node.node());
                const xy = adjustXY(oxy[0], oxy[1]);
                const ofs = getTranslate.call(node.node().querySelector('g'));
                const data = [];

                node.selectAll(`g.${enmTaskInfoTypes.Task}, g.${enmTaskInfoTypes.Group}`).each(function(d) {
                    const nxy = getTranslate.call(this).map((c, i) => c + ofs[i] + xy[['x', 'y'][i]]);
                    const cr = XY2CR(adjustXY(nxy[0], nxy[1]));
                    data.push({
                        id: d3.select(this).attr('data-id'),
                        colId: cr.col,
                        rowId: cr.row
                    });
                });

                return data;
            });

        if (!__EMBED) {
            var dmOnChangeCTID = (id, caller) => {
                    DEBUG && console.log('tree::dmOnChangeCTID', id, caller);
                    const sel = (id instanceof Array && id) || (id && [id]) || [];

                    switch (caller) {
                        case _wtTree:
                            setSelection(sel);
                            updateMarkers();
                            break;
                        case _pcProject:
                        case _wtTasksList:
                            if (sel[0] && this.active) {
                                setTimeout(
                                    id => {
                                        setSelection(sel);
                                        scrollToTask(id);
                                    },
                                    70,
                                    sel[0]
                                );
                            }
                            break;
                    }
                },
                dmOnTasksUpdate = (id, flags) => {
                    if (id) {
                        const n = getNode(id);
                        const td = dm.task(id);
                        let tmp;

                        if (td && td.type == enmTaskInfoTypes.Draft && !n) {
                            return;
                        }

                        // (flags & _dupCoordChanged || flags & _dupCreated) && groupId == td['groupId'] && (axis.add(td['rowId'], td['colId'], true));

                        if (
                            flags & _dupRemoved &&
                            selection &&
                            (tmp = selection.map(n => d3.select(n).datum().id)).indexOf(id) > -1
                        ) {
                            selection.removeAt(tmp.indexOf(id));
                        }
                        // if (flags & _dupGroupChanged) {
                        //     selection.forEach(function (n, i, ar) {
                        //
                        //     })
                        // }
                    }
                    redraw(); //updateTasks();
                },
                dmOnTransitionsUpdate = () => {
                    redraw(); //updateLinks()
                };
        }

        /**
         * @param {number=} x
         * @param {number=} y
         * @param {number=} offsetX
         * @param {number=} offsetY
         */
        function normalizeCoord(x, y, offsetX, offsetY) {
            const [xt, yt, s] = getTranslate.call(view.node());
            /** @type {number} */
            let _x;
            /** @type {number} */
            let _y;
            switch (true) {
                case !y && x instanceof Array:
                    [_x, _y] = x;
                    break;
                case y != undefined && x != undefined:
                    _x = x ?? 0;
                    _y = y ?? 0;
                    break;
                default:
                    const m = d3.mouse(svg.node());
                    _x = m[0];
                    _y = m[1];
                    break;
            }

            return {
                x: (_x - (offsetX || 0) - xt) / s,
                y: (_y - (offsetY || 0) - yt) / s
            };
        }

        __INLINE_GROUP &&
            (() => {
                const groupResizeDrag = d3.behavior.drag().on('drag', function(...args) {
                        const ev = d3.event;
                        const g = this.parentNode;
                        const s = GBBox(g);
                        const attr = {};
                        let dx;
                        let dy;
                        switch (args[1]) {
                            // case 0:
                            //     s.y += ev.y;
                            //     attr[transform] = translate + '(' + [s.x, s.y] + ')';
                            //     updateGroupHolder(g, undefined, -ev.y);
                            //     d3.select(g).attr(attr);
                            //     break;
                            case 0:
                                // s.x += ev.x;
                                // attr[transform] = translate + '(' + [s.x, s.y] + ')';
                                dx = ev.dx;
                                break;
                            case 1:
                                dy = ev.dy;
                                break;
                            case 2:
                                s.x += ev.x;
                                attr[transform] = `${translate}(${[s.x, s.y]})`;
                                dx = -ev.x;
                                break;
                        }
                        updateGroupHolder(g, dx, dy);
                        attr != {} && d3.select(g).attr(attr);
                        // console.log(d3.event, attr);
                        // updateGroupHolder(g);
                    }),
                    groupDrag = d3.behavior
                        .drag()
                        .origin(function() {
                            const bb = GBBox(this);
                            return [bb.x, bb.y];
                        })
                        .on('dragstart', function() {
                            d3.select(this)
                                .transition()
                                .style('opacity', 0.8)
                                .duration(100);
                        })
                        .on('drag', function() {
                            const ev = d3.event,
                                s = GBBox(this);
                            d3.select(this).attr(transform, `${translate}(${[s.x + ev.dx, s.y + ev.dy]})`);
                        })
                        .on('dragend', function() {
                            d3.select(this)
                                .transition()
                                .style('opacity', 1)
                                .duration(100);
                        });

                function updateGroupHolder(g, dWidth, dHeight) {
                    const $g = d3.select(g);

                    if (arguments.length > 1) {
                        $g.selectAll(`rect.o.${_clsGroupRects}, .${_clsGroupWrapperFO}`).each(function() {
                            const e = d3.select(this);
                            dWidth && e.attr('width', e.attr('width') * 1 + dWidth);
                            dHeight && e.attr('height', e.attr('height') * 1 + dHeight);
                        });

                        if (dWidth != undefined) {
                            const e = $g.select(`use.${_clsIconClose}`);
                            e.attr('x', e.attr('x') * 1 + dWidth);
                        }
                    }

                    const b = $g.selectAll(`.${_clsGroupResize}`),
                        s = GBBox($g.select('rect.o').node());

                    b.each(function(...args) {
                        const attr = {},
                            w = s.width,
                            h = s.height;
                        switch (args[1]) {
                            // case 0:
                            //     attr['width'] = w - 30;
                            //     break;
                            case 0:
                                attr['x'] = w - 5;
                                attr['height'] = h - 35;
                                break;
                            case 1:
                                attr['y'] = h - 5;
                                attr['width'] = w - 10;
                                break;
                            case 2:
                                attr['height'] = h - 10;
                                break;
                        }
                        d3.select(this).attr(attr);
                    });
                }

                function createGroupHolder(gid) {
                    if (!groupStack[gid]) {
                        const geo = core_dom.elementOffset(svg.node()),
                            gl = layers.groupLayer
                                .append('g')
                                .attr({
                                    id: `gid-${gid}`,
                                    class: _clsGroupContainer
                                })
                                .attr(transform, `${translate}(${zoomNPan.translate().map(v => -v + 50)})`),
                            n = soy.renderAsElement(tpls.project.groupContainerTemplate, {
                                width: geo.width - 100,
                                height: geo.height - 100,
                                groupCaption: dm.task(gid)['name'] || core.getMsg(_msgUnnamedGroup)
                            }),
                            trg = gl.node();

                        while (n.firstChild) {
                            trg.appendChild(n.removeChild(n.firstChild));
                        }

                        groupStack[gid] = {
                            instance: new $classes.Tree(gl.select('svg').node(), gid, events, dm),
                            gEl: gl
                        };

                        gl.selectAll(`.${_clsGroupResize}`).call(groupResizeDrag);
                        gl.call(groupDrag);
                        updateGroupHolder(gl.node());

                        $(trg)
                            .find(`.${_clsIconClose}`)
                            .on('click', function(ev) {
                                const gid = $(this)
                                    .parent()
                                    .attr('id')
                                    .replace(/^gid\-/, '');
                                groupStack[gid].gEl.remove();
                                groupStack[gid].instance.destroy();
                                delete groupStack[gid];
                            });
                    }
                    groupStack[gid].instance.setData().activate();
                }
            })();

        /**
         * Get translate coordinates of node
         * @this {SVGElement | d3}
         * @param {boolean=} asObject
         * @return {[number,number,number]|{x:number,y:number,scale:number}}
         */
        function getTranslate(asObject = false) {
            // if (core.isFirefox) {
            //     const r = d3.transform(((this instanceof d3.selection && this) || d3.select(this)).attr(transform))
            //         .translate;
            //     return asObject ? { x: r[0], y: r[1] } : r;
            // } else {
            /** @type {SVGElement} */
            const el = ((this instanceof d3.selection && this.node()) || this);
            const matrix = el.transform.baseVal.consolidate()?.matrix;
            return (
                (asObject && {
                    x: matrix.e,
                    y: matrix.f,
                    scale: matrix.a
                }) || [matrix.e, matrix.f, matrix.a]
            );
            // }
        }
        /**
         * Set transform attribute and sync values with D3
         * @param {?Array} trans
         * @param {?Number} scale
         * @param {?Boolean} animate
         */
        function setTransform(trans, scale, animate = false) {
            const s = [
                translate,
                '(',
                trans || (trans = zoomNPan.translate()),
                ') scale (',
                scale || (scale = zoomNPan.scale() || 1),
                ')'
            ].join('');

            zoomNPan.translate(trans).scale(scale);

            if (animate) {
                view.transition()
                    .attr(transform, s)
                    .ease();
            } else {
                vnode.setAttributeNS(null, transform, s);
                // var m = vnode.transform.baseVal.consolidate().matrix;
                // m.e = trans[0];
                // m.f = trans[1];
                // vnode.style.transform = translate + '3d(' + trans[0] + 'px,' + trans[1] + 'px,0)';
            }
            updateMarkersDbns();
        }

        var isLinkClicked;
        /**
         * Determine click on SVG by colisions with links
         * @param {Object} c click coordinates
         * @return {D3Selection}
         */
        !__EMBED &&
            (isLinkClicked = function isLinkClicked(c) {
                // clicker.attr('cx', c.x).attr('cy', c.y);
                const mayIUpdate = core.mayI.updateTransition();
                DEBUG && console.log('Tree::isLinkClicked', JSON.stringify({ x: c.x, y: c.y, mayIUpdate }));

                if (!nlinks || !mayIUpdate) {
                    return;
                }

                // c = new Circle(clicker.node());
                // debugger;
                const circle = {
                    getIntersectionParams: () => {
                        return new IntersectionParams('Circle', [new Point2D(c.x, c.y), 8]);
                    }
                };
                const link = nlinks
                    .filter(function(d) {
                        // debugger;
                        const e = Intersection.intersectPathShape(new Path(this), circle);
                        return e.status == 'Intersection' ? this : null;
                    })
                    .node();
                // debugger;
                // const { intersect, shape } = window.intersections;
                // const circle = shape('circle', { cx: c.x, cy: c.y, r: 8 });
                // const res = nlinks.map(e => {
                //     debugger;
                //     const d = nodes_map[e.id]?.getAttributeNS(null, 'd');
                //     const path = shape('path', { d });
                //     Intersection.intersect(circle, path);
                // });
                // console.log(res);
                // console.log('intersection', intersect.apply(null, [
                //     shape('circle', { cx: c.x, cy: c.y, r: 8 }),
                //     ...nlinks.map(e =>
                //         shape('path', { d: nodes_map[e.id]?.getAttributeNS(null, 'd') })
                //     )
                // ]))

                link && selectLink(link);
                link && console.log('Tree::isLinkClicked:found', JSON.stringify(d3.select(link).data()));
            });

        let clearLinkHandles;
        /**
         * Remove link edit handlers from paper
         * @this {D3Selection}
         */
        !__EMBED &&
            (clearLinkHandles = function clearLinkHandles() {
                this.transition()
                    .attr('r', 0)
                    .duration(150)
                    .remove();

                linkHandles && (linkHandles.linkNode = null);
            });

        let calcDynGuide;
        !__EMBED &&
            (calcDynGuide = function calcDynGuide() {
                const vbb = poffset,
                    cc = zoomNPan.translate(),
                    s = zoomNPan.scale(),
                    r = { c: [], r: [] };

                ntasks
                    .filter(function(d) {
                        if (selection.indexOf(this) > -1) {
                            return false;
                        }
                        const n = GBBox(this, true, true);
                        return (
                            n[2] + cc[0] / s > 0 &&
                            cc[0] / s - vbb.width / s + n[0] < 0 &&
                            n[3] + cc[1] / s - 12 > 0 &&
                            cc[1] / s - vbb.height / s + n[1] + 40 / s < 0
                        );
                    })
                    .each(function(d) {
                        const n = GBBox(this, true, true);
                        r.c.push(n[0], n[2]);
                        r.r.push(n[1], n[3]);
                    });

                const st = (a, b) => a - b;

                r.c.sort(st);
                r.r.sort(st);
                r.c = r.c.unique();
                r.r = r.r.unique();
                r.h = vbb.height / s;
                r.w = vbb.width / s;
                r.cc = cc;

                return r;
            });

        DEBUG && !__EMBED && (that.calcDynGuide = calcDynGuide);

        let checkDynGuide;
        !__EMBED &&
            (checkDynGuide = function checkDynGuide(grid, xy) {
                const frt = grid.r.filter(a => {
                        const y = xy[1];
                        return a + _trGridGap > y && a - _trGridGap < y;
                    }),
                    frl = grid.r.filter(a => {
                        const h = xy[3];
                        return a + _trGridGap > h && a - _trGridGap < h;
                    }),
                    fcl = grid.c.filter(a => {
                        const x = xy[0];
                        return a + _trGridGap > x && a - _trGridGap < x;
                    }),
                    fcr = grid.c.filter(a => {
                        const w = xy[2];
                        return a + _trGridGap > w && a - _trGridGap < w;
                    });

                return {
                    r: core_utils
                        .findMinInArray(frt, d => Math.abs(xy[1] - d))
                        .concat(core_utils.findMinInArray(frl, d => Math.abs(xy[3] - d))),
                    c: core_utils
                        .findMinInArray(fcl, d => Math.abs(xy[0] - d))
                        .concat(core_utils.findMinInArray(fcr, d => Math.abs(xy[2] - d)))
                };
            });

        var processDynGuide;
        !__EMBED &&
            (processDynGuide = function processDynGuide(el) {
                if (!el) {
                    dynGuide = null;
                    layers.helperLayer.selectAll(`line.${_clsVerticalGuides},line.${_clsHorizontalGuides}`).remove();
                    return;
                }

                !dynGuide && (dynGuide = calcDynGuide());

                const c = checkDynGuide(dynGuide, GBBox(el, true, true)),
                    s = zoomNPan.scale(),
                    vguides = layers.helperLayer.selectAll(`line.${_clsVerticalGuides}`).data(c.c, d => d[0]),
                    hguides = layers.helperLayer.selectAll(`line.${_clsHorizontalGuides}`).data(c.r, d => d[0]);

                vguides
                    .enter()
                    .append('line')
                    .attr({
                        x1(d) {
                            return d[0];
                        },
                        y1: -dynGuide.cc[1] / s,
                        x2(d) {
                            return d[0];
                        },
                        y2: dynGuide.h - dynGuide.cc[1]
                    })
                    .classed(_clsVerticalGuides, true);

                hguides
                    .enter()
                    .append('line')
                    .attr({
                        x1: -dynGuide.cc[0] / s,
                        y1(d) {
                            return d[0];
                        },
                        x2: dynGuide.w - dynGuide.cc[0],
                        y2(d) {
                            return d[0];
                        }
                    })
                    .classed(_clsHorizontalGuides, true);

                vguides.exit().remove();
                hguides.exit().remove();

                return c;
            });

        /**
         * Determine G box
         * @param {HTMLElement} el
         * @param {boolean} asArray
         * @param {boolean} correctDimension
         * @returns {[number, number, number, number] | {x: number, y: number, width: number, height: number}}
         */
        function GBBox(el, asArray = false, correctDimension = false) {
            const $el = d3.select(el);
            let { width, height } = $el.bbox();
            const [x, y] = getTranslate.call(el);

            if (correctDimension) {
                if ($el.datum().type == enmTaskInfoTypes.Group) {
                    width -= _trGroupWidthCor;
                }
                height -= _trTaskHeightCor;
            }

            if (asArray) {
                return [x, y, width + x, height + y];
            } else {
                return {
                    x,
                    y,
                    width,
                    height
                };
            }
        }

        if (!__EMBED) {
            var hoveredNodes = (() => {
                let active = false;
                let node = null;
                let mask = null;
                let callback;

                function select(node, snode) {
                    DEBUG && console.log('hoveredNodes::select', node);
                    const n = d3.select(nodes_map[node]);
                    n.classed(_clsHovered, true)
                        .transition()
                        .attr(
                            transform,
                            `${translate}(${getTranslate
                                .call(n)
                                .slice(0, 2)
                                .map((d, i) => d - [11, 5][i])}) scale (1.1)`
                        )
                        .duration(100);

                    core_utils.timers.add(
                        1000,
                        id => {
                            DEBUG && console.log('hoveredNodes::popup', id);
                            active = false;
                            deselect();
                            that.openGroup(id, true);
                            groupPopupLocation = eventCallback(_evGetGroupPopupLocation);
                            snode.__autoOpenGroup = true;
                            callback && callback.call(null, id);
                        },
                        [node],
                        node
                    );
                }

                function deselect(n) {
                    const id = n || node;
                    DEBUG && console.log('hoveredNodes::deselect', id);
                    if (id) {
                        core_utils.timers.stop(id);
                        groupPopupLocation = null;
                        var n = d3.select(nodes_map[id]);
                        if (n && n.classed(_clsHovered)) {
                            n.classed(_clsHovered, false)
                                .transition()
                                .attr(
                                    transform,
                                    `${translate}(${getTranslate
                                        .call(n)
                                        .slice(0, 2)
                                        .map((d, i) => d + [11, 5][i])})`
                                )
                                .duration(100);
                        }
                    }
                }

                return {
                    start() {
                        if (active) {
                            return;
                        }
                        DEBUG && console.log('hoveredNodes::start');
                        active = true;
                        callback = null;
                        this.update();
                    },
                    update() {
                        mask = Object.create(null);
                        ntasks
                            .filter(function(d) {
                                return !this.__taskDragged && d['type'] == enmTaskInfoTypes.Group;
                            })
                            .each(function(d) {
                                mask[d['id']] = GBBox(this, true, true);
                            });
                    },
                    check: ((xy, snode, _callback) => {
                        if (!active) {
                            return;
                        }

                        callback = _callback;

                        const onode = node;

                        node = null;
                        // node = getNodeFromXY({x: xy[0], y: xy[1]}, function (d) { return !nodes_map[d['id']].__taskDragged && d['type'] == enmTaskInfoTypes.Group });
                        for (const id in mask) {
                            const bb = mask[id];
                            if (xy[0] > bb[0] && xy[0] < bb[2] && xy[1] > bb[1] && xy[1] < bb[3]) {
                                node = id;
                            }
                        }

                        // node && (node = d3.select(node).datum()['id']);

                        node && onode != node && select(node, snode);
                        onode && node != onode && deselect(onode);
                    }).throttle(50),
                    cancel() {
                        DEBUG && console.log('hoveredNodes::cancel');
                        active = false;
                        node && deselect();
                        callback = null;
                        mask = null;
                    },
                    resetCurrentTarget() {
                        node = null;
                    }
                };
            })();
        }

        function getNode(id) {
            return nodes_map[id];
        }

        function linkStartXY(d, lostLinkXY) {
            //             debugger;
            if (!d) {
                return { x: 0, y: 0 };
            }
            const n = (d instanceof SVGElement && d) || getNode((typeof d == 'string' && d) || d['fromId']);
            /** @type cTaskInfo */
            let e = d3.select(n).datum();
            const h = e && core_exp[_ienCalcHeight](_idRoleRect, undefined, e);
            const alertedBySchedule =
                dm.hasFeature(_fndTaskDuration) &&
                (e.values || {}).willFinishLate &&
                e.state != enmTaskInfoStates.Completed;

            if (n.__taskDragged) {
                e = Object.assign({}, e, getTranslate.call(n, true));
            }

            if (!e) {
                if (lostLinkXY) {
                    const exy = linkEndXY(d);
                    return {
                        x: exy.x,
                        y: exy.y - 20,
                        h: exy.h
                    };
                } else {
                    return false;
                }
            } else {
                return {
                    x: e.x,
                    y: e.y,
                    h: h.height + (alertedBySchedule ? 6.25 : 0)
                };
            }
        }

        function linkEndXY(d, lostLinkXY) {
            //             debugger;
            const n = (d instanceof SVGElement && d) || getNode((typeof d == 'string' && d) || d['toId']);
            let e = d3.select(n).datum();
            const alertedBySchedule = dm.hasFeature(_fndTaskDuration) && e.startDate && (e.values || {}).willStartLate;

            if (n.__taskDragged) {
                e = Object.assign({}, e, getTranslate.call(n, true));
            }

            if (!e) {
                if (lostLinkXY) {
                    const sxy = linkStartXY(d);
                    return {
                        x: sxy.x,
                        y: sxy.y + 20
                    };
                } else {
                    return false;
                }
            } else {
                return {
                    x: e.x, //bb.x,// + bb.width / 2,
                    y: e.y - (alertedBySchedule ? 6.25 : 0) //bb.y// - 2
                };
            }
        }

        function adjustXY(x, y) {
            const nx = Math.round(x / _trCOL_WIDTH),
                ny = Math.round(y / _trROW_HEIGHT);

            return {
                x: nx * _trCOL_WIDTH,
                y: ny * _trROW_HEIGHT
            };
        }

        function CR2XY(row, col) {
            return {
                x: dm.axis(groupId).x.axisById(col),
                y: dm.axis(groupId).y.axisById(row)
            };
        }

        function XY2CR(x, y) {
            if (Object.isObject(x)) {
                y = x.y;
                x = x.x;
            } else if (Object.isArray(x)) {
                y = x[1];
                x = x[0];
            }
            return {
                col: dm.axis(groupId).x.idByAxis(x, true),
                row: dm.axis(groupId).y.idByAxis(y, true)
            };
        }

        let moveNode2CR;
        !__EMBED &&
            (moveNode2CR = function moveNode2CR(node, col, row, animate) {
                const xy = CR2XY(row, col);

                node.transition()
                    .attr(transform, `${translate}(${[xy.x, xy.y]})`)
                    .duration(250)
                    .ease('bounce');
            });

        let moveNode2XY;
        !__EMBED &&
            (moveNode2XY = function moveNode2XY(node, x, y, animate) {
                if (animate) {
                    // node.transition()
                    //     .attr('x', x)
                    //     .attr('y', y)
                    //     .duration(250)
                    //     .ease('bounce');
                } else {
                    node.attr(transform, `${translate}(${[x, y]})`);
                }
            });

        function getLinksOfNode(node) {
            return dm.taskTransitions(node['id']);
        }

        var clearSelection;
        !__EMBED &&
            (clearSelection = function clearSelection(announce) {
                ntasks && ntasks.classed(_clsSelectedNode, false);
                nlinks && nlinks.classed(_clsSelectedLink, false);

                __USE_LINKS_HIGHLIGHT &&
                    nlinks &&
                    nlinks.classed(_clsIncomeLinkHightlight, false).classed(_clsOutcomeLinkHightlight, false);

                svg.selectAll(`circle.${_clsLinkHandle}`).call(clearLinkHandles);
                selection = [];
            });

        var setSelection = function setSelection(data) {
            ntasks && ntasks.classed(_clsSelectedNode, false);
            if (data) {
                Promise.all(curDrawQueue).then(() => {
                    selection =
                        (ntasks &&
                            ntasks
                                .filter(d => data.indexOf(d['id']) != -1)
                                .classed(_clsSelectedNode, true)
                                .each(function() {
                                    ntasks.moveOnTop(this);
                                })
                                .order()
                                .map(function(d) {
                                    return this;
                                })) ||
                        [];
                });
            }
        };
        that.setSelection = setSelection;

        function scrollToTask(id, animate) {
            if (!id || !svg) {
                return;
            }
            Promise.all(curDrawQueue).then(() => {
                if (!svg) {
                    return;
                }
                const node = nodes_map[id];

                if (!node) {
                    const t = dm.task(id);
                    if (t && t['groupId']) {
                        scrollToTask(t['groupId']);
                        return true;
                    } else {
                        DEBUG && console.error('no node', id);
                        return false;
                    }
                }

                const cr = poffset;
                const vbb = layerbox;
                const nr = GBBox(node);
                const sc = zoomNPan.scale();
                const cp = zoomNPan.translate();
                const abs = Math.abs;

                const vp = {
                    x: -cp[0] / sc,
                    y: -cp[1] / sc,
                    x1: (-cp[0] + cr.width) / sc,
                    y1: (-cp[1] + (cr.height - cr.top)) / sc
                };

                const nw = nr.x + nr.width * sc;
                const nh = nr.y + nr.height * sc;
                let nx;
                let ny;

                if (!__EMBED) {
                    if (!(nw < vp.x1 && nr.x > vp.x)) {
                        nx = (cr.width - nr.width * sc) / 2 - nr.x * sc;
                    }

                    if (!(nh < vp.y1 && nr.y > vp.y)) {
                        ny = (cr.height - nr.height * sc) / 2 - nr.y * sc;
                    }
                } else {
                    nx = (-(nr.x + nr.width / 2) + cr.width / 2) / sc;

                    const ns = Object.values(nodes_map)
                            .filter(n => {
                                const d = d3.select(n).datum();
                                return d['rowId'] && d['groupId'] == groupId;
                            })
                            .sortBy(n => {
                                const d = d3.select(n).datum();
                                return d['rowId'];
                            }),
                        i = ns.findIndex(n => d3.select(n).datum()['id'] == id),
                        rd = ~~(ns.length / 3);

                    switch (true) {
                        case i >= 0 && i < rd:
                            ny = -(nr.y - nr.height) / sc;
                            break;
                        case (i >= rd && i <= rd * 2) || rd == 1:
                            ny = (-(nr.y + nr.height / 2) + cr.height / 2) / sc;
                            break;
                        case i >= rd * 2:
                            ny = -(vp.y1 - nr.height);
                            break;
                    }
                }

                (nx || ny) && setTransform([nx || cp[0], ny || cp[1]], sc, animate);
                updateMarkers();
            });
        }

        var analyzeNewLink;
        !__EMBED &&
            (analyzeNewLink = function analyzeNewLink(src, trg, ignorePrevState) {
                if (src == trg) {
                    return false;
                }

                const list = core_DO.transitions(groupId);
                for (let i = list.length; --i >= 0; ) {
                    const v = list[i];
                    if (
                        ((v.fromId == src && v.toId == trg) || (v.toId == src && v.fromId == trg)) &&
                        !ignorePrevState
                    ) {
                        return false;
                    }
                }

                return true;
            });

        function isChildOf(cNode, pNode) {
            if (pNode === cNode) {
                return true;
            }

            while (cNode && cNode !== pNode) {
                cNode = cNode.parentNode;
            }

            return cNode === pNode;
        }

        var getNodeFromXY;
        !__EMBED &&
            (getNodeFromXY = function getNodeFromXY(p, callback) {
                return ntasks[0].find(d => {
                    const bb = GBBox(d);
                    return (
                        (callback ? callback(d3.select(d).datum()) : true) &&
                        p.x > bb.x &&
                        p.x < bb.x + bb.width &&
                        p.y > bb.y &&
                        p.y < bb.y + bb.height
                    );
                });
            });

        let highlightLinks;
        __USE_LINKS_HIGHLIGHT &&
            (highlightLinks = id => {
                clearSelection();
                nlinks
                    .filter(d => d.toId == id)
                    .classed(_clsIncomeLinkHightlight, true)
                    .moveOnTop();
                nlinks
                    .filter(d => d.fromId == id)
                    .classed(_clsOutcomeLinkHightlight, true)
                    .moveOnTop();
            });

        const updateTasks = () => {
            let qi = core.q(rslv => {
                DEBUG && console.time('tree::updateTasks');
                const $tasks = dm.tasks(groupId).$tasks;
                const selids = selection.map(n => d3.select(n).datum()['id']);

                ntasks = layers.tasksLayer
                    .selectAll([`g.${enmTaskInfoTypes.Task}`, `g.${enmTaskInfoTypes.Group}`])
                    .data($tasks, d => `${[d['id'], d['type']]}`);

                ntasks
                    .exit()
                    .each(d => {
                        delete nodes_map[d['id']];
                        binds.removeNS(d['id']);
                    })
                    .remove();

                const ne = ntasks
                    .enter()
                    .append('g')
                    .each(function(d) {
                        nodes_map[d['id']] = this;
                        const n =
                            (d['type'] == enmTaskInfoTypes.Task &&
                                soy.renderAsElement(tpls.project.taskTemplate, {
                                    task: d,
                                    debugMode: $scope[_bndTaskDebugMode]
                                })) ||
                            soy.renderAsElement(tpls.project.groupTemplate, {
                                debugMode: $scope[_bndTaskDebugMode]
                            });

                        binds.ns(d['id']).regBinds(n);

                        while (n.firstChild) {
                            this.appendChild(n.removeChild(n.firstChild));
                        }
                    });

                let lastTimeClick = 0;
                let dblclickTimer = null;
                const update = ne.node() != null;

                !__EMBED &&
                    update &&
                    ne
                        .on('mousedown', stop_propagation)
                        .on('touchstart', stop_propagation)
                        .on('contextmenu', d => {
                            d3.event.preventDefault();
                            eventCallback(_evShowContextMenu, d, that);
                        })
                        .on('click', function(data) {
                            // console.log('click');
                            if (d3.event.defaultPrevented) {
                                return;
                            }
                            const t = new Date().getTime();
                            if (t - lastTimeClick < 250) {
                                return;
                            }
                            lastTimeClick = t;

                            const ev = d3.event;
                            const trg = ev.target;
                            let n;
                            switch (true) {
                                default:
                                    const sel = dm.getCTID() || [];

                                    if (linkHandles && linkHandles.linkNode) {
                                        svg.selectAll(`circle.${_clsLinkHandle}`).call(clearLinkHandles);
                                        nlinks.classed(_clsSelectedLink, false);
                                        linkHandles.linkNode = null;
                                    }

                                    __USE_LINKS_HIGHLIGHT &&
                                        nlinks
                                            .classed(_clsIncomeLinkHightlight, false)
                                            .classed(_clsOutcomeLinkHightlight, false);

                                    switch (true) {
                                        case (ev && ((core.isApple && ev.metaKey) || (!core.isApple && ev.ctrlKey))) ||
                                            ev.shiftKey:
                                            if (sel.indexOf(data['id']) == -1) {
                                                sel.push(data['id']);
                                            } else {
                                                sel.splice(sel.indexOf(data['id']), 1);
                                            }

                                            dm.setCTID(sel, _wtTree);
                                            break;
                                        case ev && __USE_LINKS_HIGHLIGHT && ev.altKey:
                                            highlightLinks(data['id']);
                                        default:
                                            dm.setCTID(data['id'], _wtTree);
                                            break;
                                    }
                                    break;
                                case !!(n = core_dom.parentsHasClass(trg, `g.${_clsExecuters}`)): //trg.getAttribute('xlink:href') == '#' + _idSymbolAssignUser:
                                case core.isFirefox &&
                                    between(ev.offsetX, 195, 195 + 40) &&
                                    between(ev.offsetY, 5, 5 + 40) &&
                                    !!(n = $(this)
                                        .find(`g.${_clsExecuters}`)
                                        .get(0)):
                                    // if (core.mayI.addExecutor(data)) {
                                    eventCallback(_evSetExecuters, $(n), data);
                                    // }
                                    break;
                                case core_dom.hasClass(trg, _clsIcon) || core_dom.hasClass(trg, _clsDeadline):
                                    eventCallback(
                                        (data['state'] == enmTaskInfoStates.Completed && _evChangeCompleteDate) ||
                                            _evSetDeadline,
                                        $(trg),
                                        data,
                                        enmTaskDateMode.deadline
                                    );
                                    break;
                                case core_dom.hasClass(trg, _clsInlineEdit):
                                    const f = () => {
                                        const pel = d3.select(trg.parentNode);
                                        const el = pel.select(`text.${_clsTaskName}`).node();
                                        if (!dm.getCTID().includes(data.id)) {
                                            dm.setCTID(data['id'], _wtTree);
                                        }
                                        eventCallback(_evTaskNameEdit, data, el);
                                    };
                                    if (data.type == enmTaskInfoTypes.Group) {
                                        dblclickTimer = setTimeout(f, 250);
                                    } else {
                                        f();
                                    }
                                    break;
                            }
                            ev.preventDefault();
                        })
                        .on('dblclick', data => {
                            // console.info('>>!>>dblclick event groupid', data.id);
                            lastTimeClick = new Date().getTime();
                            if (data['type'] == enmTaskInfoTypes.Group) {
                                if (dblclickTimer) {
                                    clearTimeout(dblclickTimer);
                                    dblclickTimer = null;
                                }
                                that.openGroup(data['id']);
                            }
                            // else {
                            //     eventCallback(_evShowTaskDetails);
                            // }
                            d3.event.preventDefault();
                        });

                __EMBED &&
                    update &&
                    ne
                        .on((core.isIOS && 'touchend') || 'click', data => {
                            if (d3.event.defaultPrevented) {
                                return;
                            }
                            eventCallback(_evShowTaskDetails, data);
                        })
                        .on('dblclick', data => {
                            data['type'] == enmTaskInfoTypes.Group && that.openGroup(data['id'], true);
                        });

                if (!__EMBED && core.mayI.createTransition()) {
                    update &&
                        ne
                            .selectAll(`use.${_clsNewLink}`)
                            .on('mousedown', stop_propagation)
                            .on('touchstart', stop_propagation)
                            .call(newLinkDrag);
                }

                !__EMBED && core.mayI.changeState() && update && ne.call(taskDrag);
                // .selectAll('use.' + _clsInlineEdit)
                //     .on('click', function () {
                //         if (d3.event.defaultPrevented) { return }
                //         var pel = d3.select(this.parentNode),
                //             el = pel.select('text.' + _clsTaskName).node();
                //         eventCallback(_evTaskNameEdit, pel.datum(), el);
                //         d3.event.preventDefault();
                //         return false;
                //     }),

                DEBUG && console.time('tree::updateTasks::coordinates');
                ntasks
                    .filter(function() {
                        return this.__taskDragged ? null : this;
                    })
                    .attr(transform, d => `${translate}(${[d.x, d.y]})`);
                DEBUG && console.timeEnd('tree::updateTasks::coordinates');

                ntasks
                    .attr('class', function(d) {
                        const c = [d['type'], d['state']];

                        (d3.select(this).classed(_clsSelectedNode) || selids.indexOf(d['id']) > -1) &&
                            c.push(_clsSelectedNode);
                        d.isDeadlineAlert() && c.push(_clsAlerted);

                        if (d['type'] == enmTaskInfoTypes.Group) {
                            dm.groupStat(d['id']).tasksQty == 0 && c.push(_clsEmptyGroup);
                        }

                        !d['name'] && c.push(_clsTaskNoName);

                        if ($highlightArray) {
                            $highlightArray[d.id] && c.push(_clsTaskHighlight);
                        } else if (d.type == enmTaskInfoTypes.Group && d3.select(this).classed(_clsGroupIsOpen)) {
                            c.push(_clsGroupIsOpen);
                        }

                        return c.join(' ');
                    })
                    .each(d => {
                        binds.ns(d['id']).set(d);
                    });

                DEBUG && console.timeEnd('tree::updateTasks');
                rslv();
            });
            qi = qi.then(() => (layerbox = layers.tasksLayer.node().getBBox()));
            curDrawQueue.push(qi);
            return qi;
        };
        //.debounce(25);

        var updateLinks = () => {
            const qi = core.q(rslv => {
                DEBUG && console.time('tree::updateLinks');
                nlinks = layers.linksLayer
                    .selectAll(`path.${_clsLink}`)
                    .data(dm.tasks(groupId).$transitions, d => d['id']);

                const ne = nlinks
                    .enter()
                    .append('path')
                    .classed(_clsLink, true)
                    .attr({
                        'marker-end': function(d) {
                            return `url(#${(!dm.task(d['toId']) && _clsMarkerCircle) || _clsMarkerArrow})`;
                        },
                        'marker-start': function(d) {
                            return (!dm.task(d['fromId']) && `url(# ${_clsMarkerCircle})`) || null;
                        }
                    })
                    .each(function(d) {
                        nodes_map[d['id']] = this;
                    });

                !__EMBED &&
                    core.mayI.updateTransition() &&
                    ne.on('click', function(d) {
                        DEBUG &&
                            console.log('path.click', {
                                d,
                                event: d3.event.defaultPrevented
                            });
                        if (d3.event.defaultPrevented) {
                            return;
                        }
                        clearSelection();
                        selectLink(this, d);
                        d3.event.preventDefault();
                    });

                nlinks
                    .exit()
                    .each(d => {
                        delete nodes_map[d['id']];
                    })
                    .remove();

                nlinks.attr('d', diagonal.useVersion(dm.hasFeature(_fndFlexibleArrows) ? 1 : 0)).classed(
                    _clsAlertedLink,
                    /* cTransitionInfo */ d => {
                        if (!dm.hasFeature(_fndTaskDuration)) {
                            return false;
                        }
                        const f = dm.task(d.fromId) || {};
                        const fvs = f.values || {};
                        const t = dm.task(d.toId) || {};
                        const tvs = t.values || {};
                        // if ((fvs.willStartLate || fvs.willFinishLate) && f.state != enmTaskInfoStates.Completed) {
                        return fvs.path && tvs.path;
                    }
                );
                DEBUG && console.timeEnd('tree::updateLinks');
                rslv();
            });
            curDrawQueue.push(qi);
            return qi;
        };
        //.debounce(50);

        function selectNode(node, data, silent) {
            // svg.selectAll('circle.' + _clsLinkHandle).call(clearLinkHandles);
        }

        function getNodeXY(node) {
            const tr = getTranslate.call(node);
            return {
                x: tr[0],
                y: tr[1]
            };
        }

        var selectLink;
        !__EMBED &&
            (selectLink = function selectLink(link, data) {
                if (dm.getCTID()) {
                    dm.setCTID(undefined, _wtTree);
                }

                const c = [];
                if (link && !data) {
                    data = d3.select(link).data()[0];
                }

                DEBUG && console.log('selectLink', data);

                const cc = $(link).data();
                c.push({ id: _tcLinkStartHandleID, c: cc.s, data });
                c.push({ id: _tcLinkEndHandleID, c: cc.e, data });

                d3.select(link).classed(_clsSelectedLink, true);

                linkHandles = layers.helperLayer
                    .selectAll(`circle.${_clsLinkHandle}`)
                    .data(c, d => d.id + d.c.x + d.c.y);

                linkHandles
                    .enter()
                    .append('circle')
                    .classed(_clsLinkHandle, true)
                    .attr('cx', d => d.c.x)
                    .attr('cy', d => d.c.y)
                    .attr('r', 1)
                    .on('mousedown', stop_propagation)
                    .on('touchstart', stop_propagation)
                    .call(linkHandleDrag)
                    .transition()
                    .attr('r', 5)
                    .duration(250)
                    .ease('bounce');

                linkHandles.exit().call(clearLinkHandles);
                linkHandles.linkNode = link;

                eventCallback(_evTreeLinkSelected, data);
            });

        function prepareTasks() {
            // dm.tasks(groupId).$tasks.forEach(function (v) {
            //     axis.add(v['rowId'], v['colId'], false);
            // });
            // DEBUG && console.time('tree::prepareTasks->axis::update')
            // axis.update(true);
            // DEBUG && console.timeEnd('tree::prepareTasks->axis::update')
        }

        /** @type {Function} */
        var rectOfSelection;
        !__EMBED &&
            (that.rectOfSelection = rectOfSelection =
                /**
                 * @param {boolean} inCR
                 * @param {Array<string>} sel
                 * @return {{x:number, y: number, x1: number, y1: number}}
                 */
                function rectOfSelection(inCR = false, sel) {
                    let max = -Number.MAX_SAFE_INTEGER;
                    let mix = __AXIS_VER
                        ? dm.axis(groupId).x.last()
                        : dm
                              .axis(groupId)
                              .x.last()
                              .value();
                    let may = -Number.MAX_SAFE_INTEGER;
                    let miy = __AXIS_VER
                        ? dm.axis(groupId).y.last()
                        : dm
                              .axis(groupId)
                              .y.last()
                              .value();

                    (sel || selection || []).forEach(d => {
                        const bb = GBBox((d instanceof SVGElement && d) || getNode(d));

                        max < bb.x + bb.width && (max = bb.x + bb.width);
                        mix > bb.x && (mix = bb.x);
                        may < bb.y + bb.height && (may = bb.y + bb.height);
                        miy > bb.y && (miy = bb.y);
                    });

                    if (inCR) {
                        const crxy = XY2CR(adjustXY(mix, miy));
                        const cr1 = XY2CR(adjustXY(max, may));
                        mix = crxy.col;
                        miy = crxy.row;
                        max = cr1.col;
                        may = cr1.row;
                    }

                    return {
                        x: mix,
                        y: miy,
                        x1: max,
                        y1: may
                    };
                });

        that.adjustXY = adjustXY;
        that.XY2CR = XY2CR;

        that.setData = function(wsUser, sDate) {
            this.cleanup();
            groupStack = {};
            cuser = wsUser || $scope[_bndCurrentWorkspaceUser].id;

            if (dm.hasFeature(_fndImprovedProjectDeadlines) && !groupId) {
                projectStartDate && projectStartDate.remove();
                projectStartDate = layers.tasksLayer
                    .append('g')
                    .classed(`${_clsProjectStartDateGWrapper} ${_gcMosesWatchClass}`, true)
                    .attr('data-event', _evProjectDateStartEdit);

                const n = soy.renderAsElement(tpls.project.projectStartDateTpl, { date: sDate }),
                    no = projectStartDate.node();

                while (n.firstChild) {
                    no.appendChild(n.removeChild(n.firstChild));
                }
                binds.ns(_bndProjectStartDate).regBinds(no);
                core.mayI.changeProjectState() &&
                    $(projectStartDate.node()).datepicker({
                        offset: 17
                    });
            } else {
                projectStartDate && projectStartDate.remove();
            }

            updatePOffset();
            return redraw().then(() => {
                updateProjectStartDate();
                return true;
            });
        };

        var redraw = (that.redraw = function redraw() {
            return updateTasks()
                .then(updateLinks)
                .then(() => {
                    curDrawQueue.length = 0;
                    updateProjectStartDate();
                    updatePaper && updatePaper();
                });
        });
        const selGroups = [];

        that.openGroup = (id, holdSelection) => {
            !holdSelection && scrollToTask(id);
            d3.select(getNode(id)).classed(_clsGroupIsOpen, true);
            selGroups.push(id);
            if (__INLINE_GROUP) {
                // @ts-ignore
                createGroupHolder(id);
            } else {
                eventCallback(_evOpenGroup, id, holdSelection);
            }
        };

        that.closeGroup = id => {
            const n = getNode(id);
            if (shadowDrag) {
                d3.select(shadowDrag).remove();
                shadowDrag = null;
                groupPopupLocation = null;
                hoveredNodes.resetCurrentTarget();
            }
            d3.selectAll((n && [n]) || selGroups.map(i => getNode(i))).classed(_clsGroupIsOpen, false);
        };

        that.groupId = () => groupId;

        that.onUpdate = callback => {
            updateCallback = callback;
            return that;
        };

        that.onEvent = callback => {
            eventCallback = callback;
            return that;
        };

        that.centerView = animate => {
            const cr = poffset;
            const vbb = layerbox;
            let cc = zoomNPan.translate();
            let x;
            let y;

            switch (true) {
                case vbb.width <= cr.width:
                    x = (cr.width - vbb.width) / 2;
                    break;
                case vbb.width > cr.width:
                    x = 0;
                    break;
            }

            cc = [-(vbb.x - x), -(vbb.y - 50)];

            setTransform(cc, 1, (animate == undefined && true) || animate);
        };

        that.zoom = (p, center, animate) => {
            if (p != undefined) {
                // if (center) {
                //     var cr = core_dom.elementOffset(svg.node().parentNode),
                //         tr = zoomNPan.translate(),
                //         c = [cr.width / 2 + tr[0], cr.height / 2 + tr[1]];
                //     zoomNPan.center(c);
                // }
                setTransform(undefined, p, animate || center == undefined ? true : center);
                oldScale = p;
                // zoomNPan.center(undefined);
            } else {
                return zoomNPan.scale();
            }
        };

        that.initialCentering = function() {
            this.centerView(false);
        };

        !__EMBED &&
            (that.highlightTasks = val => {
                svg.classed(_clsTaskHighlight, val && val.length > 0);
                $highlightArray = (val && core_utils.makeQHash(val, true, 'id')) || undefined;
                // updateTasks();
                redraw();
            });

        that.selectTask = id => {
            // var node = ntasks.filter(function (d) { return d['id'] == id; }).node();
            // node && selectNode(node);
            setSelection([id]);
        };

        __EMBED &&
            (that.scrollToXY = (x, y) => {
                setTransform([x, y], undefined, true);
            });

        that.scrollToTask = scrollToTask;

        that.cleanup =
            (__EMBED &&
                function() {
                    return this;
                }) ||
            function(warm) {
                clearSelection();

                if (!warm) {
                    // axis && axis.clear();
                    ntasks && ntasks.remove();
                    nlinks && nlinks.remove();

                    groupStack = {};
                }

                return this;
            };

        !__EMBED &&
            ((that.removeSelected = function() {
                const sel = dm.getCTID();
                if (sel && sel.length) {
                    eventCallback(_evRemoveSelectedTasks);
                } else {
                    const id = linkHandles && linkHandles.linkNode && d3.select(linkHandles.linkNode).datum()['id'];
                    id && this.removeLink(id);
                }
            }),
            (that.removeTask = id => {
                eventCallback(_evRemoveTask, id);
            }),
            (that.removeLink = id => {
                if (eventCallback(_evRemoveTransition, id)) {
                    if (linkHandles && linkHandles.linkNode && d3.select(linkHandles.linkNode).data()[0]['id'] == id) {
                        clearSelection();
                    }
                }
            }),
            (that.clearSelection = () => {
                clearSelection();
            }),
            (that.convertCoordinatesRelatedToPageToTree = (xy, inCR) => {
                const ofs = $(svg.node())
                    .parent()
                    .offset();
                const hofs =
                    core_dom.elementOffset(
                        $(svg.node())
                            .parent()
                            .find(`div.${_clsGroupNameHeader},div.${_clsTreeToolbar}`)
                            .first()
                    ).height + 1;
                const tr = zoomNPan.translate();
                const s = zoomNPan.scale();
                const x = (xy[0] - ofs.left) / s - tr[0] / s;
                const y = (xy[1] - ofs.top - hofs) / s - tr[1] / s;
                let cr;
                const nxy = adjustXY(x, y);

                return inCR ? XY2CR(nxy) : [nxy.x, nxy.y];
            }),
            (that.selectedLink = () =>
                linkHandles && linkHandles.linkNode && d3.select(linkHandles.linkNode).datum()['id']),
            (that.exportSVG = (header, subheader, asElement, includeDefStyle) =>
                Promise.all(curDrawQueue).then(() => {
                    const el = d3.select(
                        $(
                            '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" />'
                        ).get(0)
                    );
                    $(svg.node())
                        .parent()
                        .append(el.node());
                    el.append('style').text('text {font-family: Helvetica}');
                    const _defs = el.append('defs').node();
                    const _view = el.append('g').node();
                    const _text = el.append('g').node();
                    const resources = [];

                    nlinks.each(function() {
                        const el = cloneCmpAttr(this);
                        const cel = this;
                        ['marker-end', 'marker-start'].forEach(n => {
                            const r = cel.getAttributeNS(null, n);
                            let m;
                            r && (m = r.match(/url\((#\w+)\)/)) && resources.push(m[1]);
                        });
                        _view.appendChild(el);
                    });

                    let mx = 0;
                    let my = 0;
                    const $slice = Array.prototype.slice;
                    ntasks.each(function() {
                        const e = cloneCmpAttr(this);
                        const t = getTranslate.call(this, true);
                        mx = Math.min(mx || t.x, t.x);
                        my = Math.min(my || t.y, t.y);
                        _view.appendChild(e);
                    });

                    const imgPadding = 30;
                    let scale = 1;

                    if (Math.max(layerbox.width, layerbox.height) > _gcMaxPaperSize) {
                        scale = _gcMaxPaperSize / Math.max(layerbox.width, layerbox.height);
                    }
                    const width = layerbox.width * scale;
                    const height = layerbox.height * scale;

                    d3.select(_view).attr(transform, `${translate}(${[-mx + 50, -my + 80]}) scale(${scale})`);

                    const rect = el.append('rect').attr({
                        x: imgPadding,
                        y: imgPadding * 2,
                        width: layerbox.width + imgPadding * 2,
                        height: layerbox.height + imgPadding,
                        stroke: '#c7d2d6',
                        'stroke-dasharray': '3,2',
                        'stroke-width': 1,
                        fill: 'none'
                    });

                    d3.select(_view)
                        .selectAll('use')
                        .each(function() {
                            const h = (this.attributes['href'] || this.attributes['xlink:href']).nodeValue;
                            if (h == `#${_idSymbolAssignUser}` || h == '') {
                                this.parentNode.removeChild(this);
                            } else {
                                resources.push(h);
                            }
                        });

                    const ks = [...new Set(resources)];
                    //         Object.keys(
                    //     resources.reduce((obj, v) => {
                    //         v && (obj[v] = true);
                    //         return obj;
                    //     }, {})
                    // );
                    ks.forEach(k => {
                        const e = document.querySelector(k);
                        e && _defs.appendChild(e.cloneNode(true));
                    });

                    const r = [
                        '<?xml version="1.0" encoding="UTF-8" standalone="no"?>',
                        // '<?xml-stylesheet href="' + _CODE_PATH + '/svg.css" type="text/css"?>',
                        '<!DOCTYPE svg PUBLIC "-//W3C//DTD SVG 1.1//EN" "http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd">',
                        ''
                    ];

                    const h = Math.floor(height) + imgPadding * 4;
                    const w = Math.floor(width) + imgPadding * 4; // + _trTaskWidth;
                    // var tw = 50 + Math.floor((layerbox.width + 60) / 2);

                    el.attr({
                        width: w,
                        height: h,
                        viewBox: `0 0 ${w} ${h}`
                    });

                    d3.select(_text)
                        .append('text')
                        .attr({
                            x: imgPadding,
                            y: subheader ? 20 : 40,
                            class: _clsHeader,
                            'font-size': subheader ? 16 : 25,
                            // 'text-anchor': 'middle',
                            'font-weight': 'bold',
                            'font-family': 'Helvetica-Bold'
                        })
                        .text(header);

                    if (subheader) {
                        d3.select(_text)
                            .append('text')
                            .attr({
                                x: imgPadding,
                                y: 45,
                                class: _clsSubHeader,
                                // 'text-anchor': '',
                                'font-weight': 'bold',
                                'font-size': 12,
                                'font-family': 'Helvetica-Bold'
                            })
                            .text(subheader);
                    }

                    d3.select(_text)
                        .append('text')
                        .attr({
                            x: imgPadding,
                            y: h - 15,
                            class: _clsFooter,
                            'font-size': 10,
                            'font-family': 'Helvetica'
                        })
                        .text('http://casual.pm');

                    return asElement
                        ? el.node()
                        : {
                              svg: r.join('\n') + new XMLSerializer().serializeToString(el.node()),
                              width: w,
                              height: h,
                              node: el.node()
                          };
                })));

        that.getSVG = () => svg;

        that.getNode = id => nodes_map[id];

        function dynGuideKeyHelperKD(ev) {
            if (that.active && ev.shiftKey && isTaskDragged && !dynGuide) {
                processDynGuide(selection.filter(el => el.__taskDragged)[0]);
                return false;
            }
        }

        function dynGuideKeyHelperKU(ev) {
            if (that.active && isTaskDragged && dynGuide) {
                processDynGuide();
            }
        }

        that.onParentUpdate = () => {
            updatePOffset();
            updateMarkers();
        }; //.throttle(50)

        that.activate = function() {
            DEBUG && console.log('tree:activate');
            this.active = true;
            updatePOffset();
            dm && dmOnChangeCTID && dm.onChangeCTID.subscribe(dmOnChangeCTID);
            dm && dmOnTasksUpdate && dm.onTasksUpdate.subscribe(dmOnTasksUpdate);
            dm && dmOnTransitionsUpdate && dm.onTransitionsUpdate.subscribe(dmOnTransitionsUpdate);
            dm && dmOnTransitionsUpdate && dm.onProjectUpdate.subscribe(updateProjectStartDate);
            const sel = dm.getCTID();
            sel && dmOnChangeCTID && dmOnChangeCTID(sel, _wtTasksList);
            !__EMBED &&
                $('body').on({
                    keydown: dynGuideKeyHelperKD,
                    keyup: dynGuideKeyHelperKU
                });
            $(window).on('resize', updatePOffset);
            // export function checkBoundries(target: PointType, box: RectType, viewPort: ClientRect, scale: number): PointType {
            //     let { x: dx, y: dy } = target;
            //     let { width: bw, height: bh } = box;
            //     let { width: vpw, height: vph } = viewPort;
            //
            //     const isMobile = !im.greaterThan('mobile');
            //
            //     const TOP_BOUNDRY = isMobile ? 10 : 50;
            //     const BOTTOM_BOUNDRY = isMobile ? 10 : 50;
            //     const LEFT_BOUNDRY = isMobile ? 10 : 50;
            //     const RIGHT_BOUNDRY = isMobile ? 10 : 50;
            //
            //     if ((bw > vpw && dx > LEFT_BOUNDRY) || (bw < vpw && dx < LEFT_BOUNDRY)) {
            //         dx = LEFT_BOUNDRY;
            //     }
            //     if ((bw > vpw && (dx + bw) - vpw < -RIGHT_BOUNDRY) || (bw < vpw && dx + bw > vpw - RIGHT_BOUNDRY)) {
            //         dx = vpw - bw - RIGHT_BOUNDRY;
            //     }
            //     if ((bh > vph && dy > TOP_BOUNDRY) || (bh < vph && dy < TOP_BOUNDRY)) {
            //         dy = TOP_BOUNDRY;
            //     }
            //     if ((bh > vph && (dy + bh) - vph < -BOTTOM_BOUNDRY) || (bh < vph && dy + bh > vph - BOTTOM_BOUNDRY)) {
            //         dy = vph - bh - BOTTOM_BOUNDRY;
            //     }
            //
            //     return {
            //         x: dx,
            //         y: dy
            //     }
            // }

            if (!dm.hasFeature(_fndOldStyleScroll) || __EMBED) {
                svg.on('wheel', () => {
                    const oev = d3.event;
                    if (oev.ctrlKey || oev.metaKey) {
                        return;
                    }
                    const c = getTranslate.call(vnode);
                    const cr = poffset;
                    const vp = layerbox;
                    const p = oev['deltaMode'] ? 60 : 1;
                    let dx = c[0] + -oev['deltaX'] * p,
                        dy = c[1] + -oev['deltaY'] * p;
                    const yq = cr.height * 0.7;
                    const xq = cr.width * 0.7;

                    if (oldScale === undefined) {
                        oldScale = 1;
                    }

                    if (dy > yq) {
                        dy = yq;
                    } else if (dy < -(vp.y + vp.height - cr.height / 4)) {
                        dy = -(vp.y + vp.height - cr.height / 4);
                    }

                    if (dx > xq) {
                        dx = xq;
                    } else if (dx < -(vp.x + vp.width - cr.width / 4)) {
                        dx = -(vp.x + vp.width - cr.width / 4);
                    }

                    setTransform([dx, dy], oldScale);
                    oev.preventDefault();
                    // $(this).triggerHandler(_gcOnSvgPanEvent);
                });
            }
            this.redraw();
            return this;
        };

        that.deactivate = function() {
            DEBUG && console.log('tree:deactivate');
            this.active = false;
            dm && dmOnChangeCTID && dm.onChangeCTID.unsubscribe(dmOnChangeCTID);
            dm && dmOnTasksUpdate && dm.onTasksUpdate.unsubscribe(dmOnTasksUpdate);
            dm && dmOnTransitionsUpdate && dm.onTransitionsUpdate.unsubscribe(dmOnTransitionsUpdate);
            dm && dmOnTransitionsUpdate && dm.onProjectUpdate.unsubscribe(updateProjectStartDate);
            !__EMBED &&
                $('body').off({
                    keydown: dynGuideKeyHelperKD,
                    keyup: dynGuideKeyHelperKU
                });
            $(window).off('resize', updatePOffset);
            svg.on('wheel', null);
            return this;
        };

        that.destroy = function(skipSVGRemoval) {
            this.cleanup().deactivate();
            // axis =
            nodes_map.length = 0;
            ntasks = nlinks = null;
            dmOnChangeCTID = null;
            dmOnTasksUpdate = null;
            dmOnTransitionsUpdate = null;
            view.remove();
            markersLayer.remove();
            // defs.remove();
            !skipSVGRemoval && svg.remove();
            view = svg = null;
        };

        that.translate = () => {
            // debugger;
            return getTranslate.call(view);
        };
        // zoomNPan
        //     .translate()
        //     .clone()
        //     .add(zoomNPan.scale());

        !__EMBED &&
            (that.appendShadowDragNode = function(node, x, y) {
                if (node.__gids && node.__gids.indexOf(groupId) > -1) {
                    node.__rejected = true;
                    return;
                }
                if (!__MLGROUPS && node.__gids && node.__gids.length && groupId != undefined) {
                    node.__rejected = true;
                    return;
                }
                layers.helperLayer.node().appendChild(node);
                d3.select(node).attr(transform, `${translate}(${[x, y]})`);
                node.zs = zoomNPan.scale();
                node.targetGroup = groupId;
                this.__shadow = true;
            });

        that.transform = (trans, scale) => {
            if (trans instanceof Array && trans.length == 3) {
                scale = trans.pop();
            }
            setTransform(trans, (oldScale = scale));
        };

        !__EMBED &&
            (that.setSquareBitsActive = function(mode) {
                if (mode) {
                    return new Promise((resolve, reject) => {
                        squareBitsActive = {
                            resolve,
                            reject
                        };
                    });
                } else {
                    squareBitsActive = false;
                }
            });

        return that;
    }

    $classes.Tree = Tree;

    /**
     * @param el SVGElement
     * @param attrs Array<string>
     * @param attr Object<string, string>
     * @return Object<string, Object>
     */
    function getCmpAttr(el, attrs, attr) {
        if (!attr) {
            attr = getComputedStyle(el);
        }
        return attrs.reduce((o, id) => {
            o[id] = attr[id];
            return o;
        }, {});
    }
    /**
     * @param el SVGElement
     * @param attrs Object<string, Object>
     * @return void
     */
    function applyCmpAttr(el, attrs) {
        Object.keys(attrs).forEach(id => {
            let v = attrs[id];
            switch (id) {
                case 'x':
                case 'y':
                case 'dx':
                case 'dy':
                case 'height':
                case 'width':
                case 'rx':
                case 'ry':
                    if (v == undefined || v == 'auto') {
                        return;
                    }
                    v = v.replace(/px$/, '');
                    break;
            }
            el.setAttributeNS(null, id, v);
        });
    }

    /**
     * @param src SVGElement
     * @return SVGElement
     */
    function cloneCmpAttr(src) {
        const trg = document.createElementNS('http://www.w3.org/2000/svg', src.nodeName);
        const cstyle = [];
        const attrs = [];
        const gcstyle = getComputedStyle(src);
        let reqChildren = false;
        if (gcstyle.display == 'none' || gcstyle.width == '0px' || gcstyle.opacity == '0') {
            return null;
        }
        switch (src.nodeName.toString().toUpperCase()) {
            case 'G':
                attrs.push('transform');
                reqChildren = true;
                break;
            case 'RECT':
                cstyle.push('rx', 'ry', 'width', 'height', 'fill', 'stroke', 'stroke-width', 'opacity');
                attrs.push('x', 'y');
                break;
            case 'CIRCLE':
                cstyle.push('r', 'fill', 'stroke', 'stroke-width');
                attrs.push('cx', 'cy');
                break;
            case 'TSPAN':
            case 'TEXT':
                cstyle.push('fill', 'font-size', 'font-weight', 'line-height', 'text-anchor');
                attrs.push('x', 'y', 'dx', 'dy');
                src.children.length == 0 && attrs.push('innerHTML');
                break;
            case 'SVG':
                attrs.push('x', 'y', 'width', 'height');
                reqChildren = true;
                break;
            case 'USE':
                attrs.push('xlink:href', 'x', 'y', 'width', 'height');
                break;
            case 'PATH':
                attrs.push('d', 'marker-end', 'marker-start');
                cstyle.push('fill', 'stroke', 'stroke-width');
                break;
        }
        const childs = Array.prototype.slice
            .call(src.children)
            .map(cloneCmpAttr)
            .filter(Boolean);

        if (reqChildren && childs.length == 0) {
            return null;
        }

        if (cstyle.length) {
            const a = getCmpAttr(src, cstyle, gcstyle);
            applyCmpAttr(trg, a);
        }

        attrs.forEach(an => {
            if (an == 'innerHTML') {
                trg.innerHTML = src.innerHTML;
            } else {
                d3.select(trg).attr(an, d3.select(src).attr(an));
            }
        });

        childs.forEach(n => {
            trg.appendChild(n);
        });
        return trg;
    }
})();
