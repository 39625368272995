(function(core) {
    var defOptions = {
        items: {},
        parent: 'body',
        onSelect: null
    };

    /**
     * Menu item typdef
     * @typedef {{
     *  label: string,
     *  disabled: boolean
     *  isLine: boolean
     *  callback: function
     * }}
     */
    var tMenuItem;

    $(window).on('mousedown contextmenu click', function(ev) {
        var $f = $('div.' + _clsContextMenu);
        if (ev.type == 'click' && ev.buttons == 0 && ev.button == 2) {
            return;
        }
        if ($f.length && !$(ev.target).hasClass(_clsContextMenuItem)) {
            $f.each(function() {
                $(this)
                    .data('instance')
                    .close();
            });
        }
        // $('body').trigger(_evClosePopups);
    });

    $('body').on(_evEscPressed, function() {
        $('div.' + _clsContextMenu).each(function() {
            $(this)
                .data('instance')
                .close();
        });
    });

    /**
     *
     * @constructor
     */
    $classes.Class(_wtContextMenu, _clAbstractWidget, {
        options: {},
        $el: null,
        _create: function(id, opts) {
            // this.options.items = $.extend(true, defOptions, opts);
            this._super(id, undefined, opts);
        },
        popup: function(x, y, data, caller, target) {
            $(window).trigger('contextmenu');
            var _items = this.options.items;
            var rs = [];
            var self = this;

            switch (typeof _items) {
                case 'object':
                    for (var l = _items.length, i = 0; i < l; i++) {
                        var it = Object.clone(_items[i]);
                        if (it.callback) {
                            var r = it.callback.call(it, data, it, caller);
                            if (r === false) {
                                continue;
                            } else if (r === 0) {
                                it.disabled = true;
                            }
                        }
                        rs.push(it.isLine ? '<hr />' : tpls.widgets.contextMenuItem(it));
                    }
                    break;
                case 'string':
                    rs.push(_items);
                    break;
            }

            rs = rs.join('');

            if (this.$el) {
                this.$el.remove();
            }
            this.$el = $(tpls.widgets.contextMenu())
                // .appendTo('body')
                .appendTo(target || this.options.parent)
                .on('click', 'div.' + _clsContextMenuItem + ':not(.' + _clsDisabled + ')', function(ev) {
                    var trg = ev.target,
                        d = $(trg).data(),
                        data = $(ev.delegateTarget).data();

                    self.close();
                    self.options.onClick && self.options.onClick.call(trg, d, data.data, data.openPoint, data.caller);
                });
            // }

            var br = $(target || this.options.parent)
                .get(0)
                .getBoundingClientRect();
            this.$el
                .html(rs)
                .css('display', 'block')
                .css({
                    left: x - br.x,
                    top: y - br.y,
                    zIndex: 10000
                }) /*.position({
                    my: "center top",
                    at: "center bottom",
                    of: at,
                    offset: "0 5",
                    collision: "fit"
            })*/
                .css('opacity', 1)
                .data({
                    data: data,
                    openPoint: [x, y],
                    caller: caller,
                    instance: this
                });
        },
        close: function() {
            if (this.$el) {
                this.$el
                    .css({
                        opacity: 0,
                        display: 'none'
                    })
                    .data({
                        instance: this
                    });
                // .on(_gcTransitionEnd, function () {
                //     this.$el.remove();
                //     this.$el = null;
                // });
                // setTimeout(function ($el) {
                //     if ($el) {
                //         $el.remove();
                //         $el = null;
                //     }
                // }, 250, this.$el);
            }
        }
    });
})(core);
