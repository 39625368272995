const /** @const */ xmlns = 'http://www.w3.org/2000/svg';
const /** @const */ xlinkns = 'http://www.w3.org/1999/xlink';
/**
 * @param {string} src
 * @param {string} id
 * @return {Promise}
 */
function imgLoader(src, id) {
    return core.q((rslv, rjct) => {
        let img = new Image();

        DEBUG == 2 && console.log('requested image load', id, src);

        function toHandler() {
            DEBUG == 2 && console.warn('::imgLoader:timeout', src);
            img.src = '';
            setTimeout(() => {
                img && (img.src = src);
            }, 1000);
        }

        img.__to = setTimeout(toHandler, _gcImageLoadTimeout);
        img.__retry = 0;

        img.onload = function() {
            if (this.complete) {
                clearTimeout(this.__to);
                DEBUG == 2 && console.log('::imgLoader:onload, calling callback', this);
                rslv({ id, img: this });
            }
        };

        img.onerror = function() {
            this.__to && (clearTimeout(this.__to), (this.__to = null));
            if (++this.__retry < _gcImageLoadErrorRetry) {
                DEBUG == 2 && console.log('::imgLoader:fail, initiate retry', this.__retry, src);
                setTimeout(() => {
                    DEBUG == 2 && console.log('::imgLoader reinitiate load', src);
                    if (img) {
                        img.src = src;
                        img.__to = setTimeout(toHandler, _gcImageLoadTimeout);
                    }
                }, _gcImageLoadErrorDelay);
            } else {
                DEBUG == 2 && console.warn('image loading failed', this);
                img = null;
                rjct({ img: this, error: `image(${this.src}) load fail due timeout` });
            }
        };

        img.crossOrigin = 'anonymous';

        img.src = src;
    });
}

const picSize = window.devicePixelRatio > 1 ? 'l' : 'm';
/**
 * @param {string} stream
 * @param {string} pid
 * @param {string?} fid
 * @param {string} size
 * @return {string}
 */
function url(stream, pid, fid) {
    if (fid && /^http/.test(fid)) {
        return fid;
    } else {
        DEBUG && console.assert(stream);
        DEBUG && console.assert(pid);
        DEBUG && console.assert(fid);
        return [_CONF_AV_HOSTER, stream, pid, `${fid}_${picSize}`].join('/');
    }
}
/**
 * Make SVG namespace element
 * @param {string} src
 * @param {Object<string, *>} attr
 * @return {SVGElement}
 */
function mkSvg(src, attr) {
    const el = document.createElementNS(xmlns, src);
    attr && a(el, attr);
    return el;
}
/**
 * set attribute
 * @param {SVGElement} el
 * @param {string|Object<string, *>} attr
 * @param {string?} value
 */
function a(el, attr, value) {
    if (typeof attr == 'object' && !value) {
        Object.keys(attr).forEach(k => {
            switch (k) {
                case 'innerHTML':
                case 'textContent':
                    el[k] = attr[k];
                    break;
                case 'childs':
                    const o = attr[k];
                    Object.keys(o).forEach(ko => {
                        const r = mkSvg(ko, o[ko]);
                        r && el.appendChild(r);
                    });
                    break;
                case 'xlink:href':
                    el.setAttributeNS(xlinkns, 'xlink:href', attr[k]);
                    break;
                case 'className':
                    el.classList.add(attr[k]);
                    break;
                default:
                    attr[k] != void 0 && el.setAttributeNS(null, k, attr[k]);
                    break;
            }
        });
    } else {
        el.setAttributeNS(null, attr, value);
    }
}

/**
 * Add symbol to SVG
 * @param {SVGElement} container
 * @param {string} id
 * @param {Array<number>} viewbox
 * @param {string} content
 */
function addSymbol(container, id, content, viewbox) {
    const e = mkSvg('symbol', {
        id,
        viewBox: (viewbox && `0 0 ${viewbox.join(' ')}`) || void 0,
        childs: Object.assign(
            {
                rect: {
                    x: 0,
                    y: 0,
                    rx: 1,
                    ry: 1,
                    fill: '#fff',
                    width: '100%',
                    height: '100%'
                }
            },
            content
        )
    });
    return container.appendChild(e);
}
/**
 * Update symbol
 * @param {SVGElement} symbol
 * @param {Object<string, *>} content
 * @param {Array<number>} viewbox
 */
function updateSymbol(symbol, content, viewbox) {
    // debugger;
    clean(symbol);
    a(symbol, {
        viewBox: (viewbox instanceof Array && `0 0 ${viewbox.join(' ')}`) || viewbox,
        childs: Object.assign(
            {
                rect: {
                    x: 0,
                    y: 0,
                    rx: 1,
                    ry: 1,
                    fill: '#fff',
                    width: '100%',
                    height: '100%'
                }
            },
            content
        )
    });
    return symbol;
}
/**
 * Empty childs
 * @param {SVGElement} el
 */
function clean(el) {
    const c = el.childNodes;
    for (let i = c.length; --i >= 0; ) {
        el.removeChild(c[i]);
    }
}
/**
 * @param {string|Array<string>} info
 * @return {string}
 */
function makeInitials(info) {
    return (
        (info instanceof Array &&
            info
                .map(v => v && v[0])
                .filter(v => !!v)
                .slice(0, 2)
                .join('')) ||
        info
    );
}

/**
 * @typedef IdsMapType
 * @property {string} pid
 * @property {string} fid
 * @property {string} info
 * @property {SVGElement} el
 */

/**
 * @this {ImgCacheCollectionV4}
 * @constructor
 * @param {string} stream
 * @param {string} ns
 * @ param {string} txtStyle
 * @param {string} defImage
 * @param {string} loadImage
 * @param {string} failImage
 */
function ImgCacheCollectionV4(stream, ns, /*txtStyle, */ defImage, loadImage, failImage) {
    /** @type {Object.<string,IdsMapType>} */
    this.IDsMap = {};
    this.length = 0;
    this.stream = stream;
    this.ns = ns;
    this.loadImage = loadImage;
    this.defImage = defImage;
    this.failImage = failImage;
    this.status = ImgCacheCollectionV4.STATUS.INIT;
    // this.txtStyle = txtStyle;
    const c = (this.container = document.createElementNS(xmlns, 'svg'));
    c.classList.add(_clsSVGResource);
    document.querySelector('body')?.appendChild(c);
}

ImgCacheCollectionV4.STATUS = {
    INIT: 0,
    LOADING: 1,
    READY: 2,
    EMPTY: 3,
    FAIL: 4
};

ImgCacheCollectionV4.attr = a;
ImgCacheCollectionV4.clean = clean;
ImgCacheCollectionV4.mkSvg = mkSvg;

/**
 * @this {ImgCacheCollectionV4}
 * @param {ImgCacheCollectionV4} p
 * @param {string} id
 */
function getDef(p, id) {
    const d = this.IDsMap[id];
    const loading = {
        use: {
            'xlink:href': `#${this.loadImage}`
        }
    };

    const info = {
        text: {
            x: '50%',
            y: '70%',
            width: 32,
            lengthAdjust: 'spacing',
            'text-anchor': 'middle',
            style: 'font-family: Impact, Arial; font-size: 20px;font-weight: bold; fill: rgb(183, 199, 201)',
            textContent: makeInitials(d.info)
        }
    };
    const def = {
        use: {
            'xlink:href': `#${this.defImage}`
        }
    };
    const fail = {
        use: {
            'xlink:href': `#${this.failImage}`
        }
    };
    switch (p.status) {
        case ImgCacheCollectionV4.STATUS.INIT:
        case ImgCacheCollectionV4.STATUS.LOADING:
            return this.loadImage ? loading : d.info ? info : def;
            break;
        case ImgCacheCollectionV4.STATUS.READY:
        case ImgCacheCollectionV4.STATUS.EMPTY:
            return;
            break;
        case ImgCacheCollectionV4.STATUS.FAIL:
            break;
    }
}

/**
 * @this {ImgCacheCollectionV4}
 * @param {string} id
 * @return {string}
 */
ImgCacheCollectionV4.prototype.makeId = function(id) {
    return `${this.ns}_${id}`;
};

/**
 * @this {ImgCacheCollectionV4}
 * @param {string} pid
 * @param {?string} fid
 * @param {?string} id
 * @param {?Array<string>|string} info
 */
ImgCacheCollectionV4.prototype.add = function(pid, fid = null, id = null, info = null) {
    !id && (id = pid);
    let link;
    let vb;

    if (fid && this.loadImage) {
        link = {
            use: {
                'xlink:href': `#${this.loadImage}`
            }
        };
    } else if (info) {
        link = {
            text: {
                x: '50%',
                y: '70%',
                width: 32,
                lengthAdjust: 'spacing',
                'text-anchor': 'middle',
                style:
                    'font-family: Arial; font-size: 18px;font-weight: bold; fill: rgb(183, 199, 201);letter-spacing: -.05em;',
                textContent: makeInitials(info)
            }
        };
        vb = 32;
    } else {
        link = {
            use: {
                'xlink:href': `#${this.defImage}`
            }
        };
    }

    const o = this.IDsMap[id];

    if (o && pid == o.pid && fid == o.fid && makeInitials(info) == o.info) {
        return;
    }

    const el = o && o.el;

    this.IDsMap[id] = {
        pid,
        fid,
        info: makeInitials(info),
        el: el
            ? updateSymbol(el, link, vb && [vb, vb])
            : addSymbol(this.container, this.makeId(id), link, vb && [vb, vb])
    };
    fid && this.load(id);
};

/**
 * @this {ImgCacheCollectionV4}
 * @param {string} id
 */
ImgCacheCollectionV4.prototype.load = function(id) {
    const d = this.IDsMap[id];
    const self = this;
    imgLoader(url(this.stream, d.pid, d.fid), id)
        .then(obj =>
            core.q(
                /**
                 * @param {function} rslv
                 * @param {function} rjct
                 */
                (rslv, rjct) => {
                    try {
                        const img = obj.img;
                        const c = document.createElement('canvas');
                        const ctx = c.getContext('2d');
                        c.width = img.width;
                        c.height = img.height;
                        ctx?.drawImage(img, 0, 0);
                        rslv({ id: obj.id, img: c.toDataURL('image/png'), width: img.width, height: img.height });
                    } catch (e) {
                        rjct({ id: obj.id, e });
                    }
                }
            )
        )
        .then(obj => {
            updateSymbol(
                d.el,
                {
                    image: {
                        'xlink:href': obj.img,
                        x: 0,
                        y: 0,
                        width: obj.width,
                        height: obj.height
                    }
                },
                [0, 0, obj.width, obj.height].join(' ')
            );
        })
        .catch(obj => {
            // console.error('oops', obj);
            updateSymbol(d.el, {
                use: {
                    'xlink:href': `#${self.failImage || self.defImage}`
                }
            });
        });
};

/**
 * @this {ImgCacheCollectionV4}
 * @param {string} id
 */
ImgCacheCollectionV4.prototype.remove = function(id) {
    const d = this.IDsMap[id];
    if (d) {
        d.el.parentNode?.removeChild(d.el);
        delete this.IDsMap[id];
    }
};

/**
 * Replace image with default one
 * @this {ImgCacheCollectionV4}
 * @param {string} id
 */
ImgCacheCollectionV4.prototype.release = function(id) {
    const o = { ...this.IDsMap[id] };
    delete o.el;
    this.remove(id);
    this.add(o.pid, null, id, o.info);
};

core.ImgCacheCollectionV4 = ImgCacheCollectionV4;
