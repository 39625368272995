const $timers = new Armap();
const $oldStates = new Armap();

function stateChange($id, $projectId, $state) {
    core.moses.announce(_rProjectStatePush, {
        projectId: $projectId,
        changes: [
            {
                type: enmStateChangeTypes.ChangeTaskState,
                id: $id,
                data: { state: $state }
            }
        ]
    });
}

function setCompleted($id, push2Server) {
    /** @type {cTaskInfo} */
    let t;
    if ((t = this.$tasks.$item($id))) {
        $oldStates.$push({ id: $id, state: t.state });
        t.state = enmTaskInfoStates.Completed;
        t.onhold = true;
    }
    const self = this,
        pid = t.projectId;

    findTaskElement.call(this, $id).addClass(_clsProcessing);

    $timers.$push({
        id: $id,
        timer: setTimeout(() => {
            $timers.$remove($id);
            $oldStates.$remove($id);
            const $elements = findTaskElement.call(self, $id);
            $elements.addClass(_clsRemoving).bind(_gcTransitionEnd, function() {
                const p = $(this)
                    .parent()
                    .find(`.${_clsTaskRow}`).length;
                $(this).remove();
                if (p == 1) {
                    self.update(pid);
                } else {
                    self.msnry.layout();
                }
            });

            self.$tasks.$remove($id);
        }, _gcTaskFadeTimeout)
    });

    DEBUG && console.assert(pid, 'no projectId defined');

    push2Server && stateChange($id, pid, enmTaskInfoStates.Completed);
}

function rollbackCompleted($id) {
    const td = $timers.$item($id);
    $timers.$remove($id);
    td && clearTimeout(td.timer);

    let t;
    const /*at, */ os = $oldStates.$item($id);

    if (!os) {
        return;
    }

    $oldStates.$remove($id);

    if (os && (t = this.$tasks.$item($id))) {
        t.state = os.state;
    }
    this.update();

    os && stateChange($id, t /* || at)*/.projectId, os.state);
}

function findTaskElement($id) {
    return $(
        d3
            .select(this.$rendered[0])
            .selectAll(`div.${_clsTaskRow}`)
            .filter(d => d && d.id == $id)[0]
    );
}

function isUpcoming(date) {
    if (!date) {
        return false;
    }
    // debugger;

    const days = parseInt(
        core_DO.hasFeature(_fndOverviewHorizont)
            ? core_DO.settings(_skeyOverviewHorizontKey + $scope[_bndCurrentWorkspaceUser].id) ||
                  _gcOverviewHorizontDefaultValue
            : _gcOverviewHorizontDefaultValue,
        10
    );

    let endTime = new Date();
    endTime.setDate(endTime.getDate() + days);
    endTime = endTime.getTime();

    const now = new Date();
    const startTime = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime();

    return date >= startTime && date <= endTime;
}

/** @param {cWorkspaceOverviewForUserResponse} $data */
function processResponse($data, pid) {
    const self = this;

    if (pid) {
        this.$tasks.$removeByIndex({ projectId: pid });
    } else {
        this.$tasks.$empty(item => !item.onhold);
    }

    // $data.overview.projectUserDigests.forEach(
    //     /** @param {cOverviewForUserInProject} p */
    //     function(p) {
    //         $blocks = p.blocks;
    //         p.currentTasks.forEach(
    //             /** @param {cTaskInfo} d */
    //             function(d) {
    //                 if (!core_DO.isTaskMine(undefined, $scope[_bndWorkspaceOverviewActiveUser].id, false, d)) {
    //                     return;
    //                 }
    //                 if (d.type == enmTaskInfoTypes.Group) {
    //                     return;
    //                 }
    //                 if (d.deadline) {
    //                     d.project = core_DO.projectInfo(d.projectId);
    //                 }
    //                 d.blocks = (($blocks[d.id] && $blocks[d.id].blocks) || []).length;
    //                 self.$tasks.$push(d);
    //             }
    //         );
    //         p.nextTasks.forEach(
    //             /** @param {cTaskInfo} t */
    //             function(d) {
    //                 if (!core_DO.isTaskMine(undefined, $scope[_bndWorkspaceOverviewActiveUser].id, false, d)) {
    //                     return;
    //                 }
    //                 if (d.type == enmTaskInfoTypes.Group) {
    //                     return;
    //                 }
    //                 d.blocks = (($blocks[d.id] && $blocks[d.id].blocks) || []).length;
    //                 if (d.deadline) {
    //                     d.project = core_DO.projectInfo(d.projectId);
    //                     self.$tasks.$push(d);
    //                 }
    //             }
    //         );
    //     }
    // );
    const mapFunc = (/* string */ blockType, /* object */ $blocks) => /* cTaskInfo */ d => {
        if (d.type == enmTaskInfoTypes.Group) {
            return null;
        }
        return $.extend({}, d, {
            blockType,
            blocks: (($blocks && $blocks[d.id] && $blocks[d.id].blocks) || []).length
        });
    };

    this.$tasks.$concat(
        (($data && $data.overview && $data.overview.projectUserDigests) || [])
            .map(p => {
                const r = [];
                const $blocks = p.blocks;
                [].push.apply(r, (p.currentTasks || []).map(mapFunc('current', $blocks)).filter(o => !!o));
                [].push.apply(r, (p.nextTasks || []).map(mapFunc('next', $blocks)).filter(o => !!o));
                return r;
            })
            .reduce((a, v) => a.concat(v), [])
    );
    this.update(pid);
}

/**
 *
 * @constructor
 * @extends AbstractWidget
 */
// @ts-ignore
new $classes.Class(
    _wtWorkspaceOverview,
    _clAbstractWidget,
    // @lend wtWorkspaceOverview
    {
        active: null,
        upcoming: null,
        busy: null,
        cwsid: null,
        init() {
            this.$tasks = new Armap('id', {
                projectId: undefined,
                blockType: undefined,
                /**
                 * alerted
                 * @param {cTaskInfo} d
                 */
                alerted(_, d) {
                    return d.isDeadlineAlert();
                },
                /**
                 * upcoming
                 * @param {cTaskInfo} d
                 */
                upcoming(_, d) {
                    return isUpcoming(d.deadline);
                },
                old(_, d) {
                    return d.isDeadlineAlert() && d.deadline < Date.now();
                }
                // execs: function (_,d) {
                //     return d.execs || []
                // }
            });

            core_DO.onCWSChange.subscribe(() => {
                if (this.cwsid != $scope[_bndCurrentWorkspace].id && this.isActive()) {
                    this.requestData();
                }
            });

            core_DO.onProjectStatsUpdate.subscribe($id => {
                if (!this.status) {
                    return;
                }
                const p = this._binds.get(_bndOverviewList).filter(d => d.id == $id);

                if (p.length) {
                    $(p[0]).addClass(_clsProcessing);
                    const self = this;
                    let rq;
                    core.moses
                        .announce(
                            _rWorkspaceOverviewForUser,
                            (rq = {
                                workspaceId: $scope[_bndCurrentWorkspace].id,
                                workspaceUserId: $scope[_bndWorkspaceOverviewActiveUser].id,
                                projectIds: [$id]
                            })
                        )
                        .then(data => {
                            $(p[0]).removeClass(_clsProcessing);
                            processResponse.call(self, data, $id);
                        })
                        .catch(o => {
                            $(p[0]).removeClass(_clsProcessing);
                            __USE_ROLLBAR &&
                                Rollbar.error('wtWorkspaceOverview::rWorkspaceOverviewForUser fail response', {
                                    req: rq,
                                    resp: o
                                });
                        });
                }
            });

            core_DO.onProjectUpdate.subscribe((pid, flags) => {
                if (pid && flags == _dupRemoved) {
                    this.$tasks.$removeByIndex({ projectId: pid });
                    this.update();
                }
            });

            this._super();
        },
        generateListen(r) {
            r[_nStateChanged] = obj => {
                const self = this;
                (obj['changes'] || []).forEach(data => {
                    const d = data['object'] || data['data'] || {},
                        $task = self.$tasks.$item(d['id']);

                    if ($task) {
                        switch (data['type']) {
                            case enmStateChangeTypes.ChangeTaskState:
                                if ($task['state'] != d['state']) {
                                    switch (d['state']) {
                                        case enmTaskInfoStates.Completed:
                                            setCompleted.call(self, d['id']);
                                            break;
                                        default:
                                            rollbackCompleted.call(self, d['id']);
                                            break;
                                    }
                                }
                                break;
                            case enmStateChangeTypes.DeleteTask:
                                self.$tasks.$remove(d.id);
                                break;
                            case enmStateChangeTypes.UpdateTask:
                                if (
                                    d['name'] != $task['name'] ||
                                    d['deadline'] != $task['deadline'] ||
                                    d['rowId'] != $task['rowId'] ||
                                    d['colId'] != $task['colId']
                                ) {
                                    /** @type {cTaskInfo} */
                                    const o = new $classes[_cTaskInfo]($task).$update(d);
                                    self.$tasks.$push(o);
                                    self.$tasks.$reindex();
                                }
                                break;
                        }
                    }
                });
                this.update();
            };
            r[_evSelectorSelectUser] = function(id) {
                core.uriHandler.setUri(
                    {
                        id: _umWorkspace,
                        link: 'overview',
                        tuser: (id != $scope[_bndCurrentWorkspaceUser].id && id) || undefined
                    },
                    true,
                    undefined,
                    true
                );
                this.requestData();
            };
        },
        requestData() {
            const self = this;
            const wsuid = $scope[_bndWorkspaceOverviewActiveUser].id || undefined;
            this.$tasks.$empty();
            this.busy = true;
            this.update();
            let rq;
            core.moses
                .announce(
                    _rWorkspaceOverviewForUser,
                    (rq = { workspaceId: $scope[_bndCurrentWorkspace].id, workspaceUserId: wsuid })
                )
                .then($data => {
                    this.busy = false;
                    processResponse.call(this, $data);
                })
                .catch(o => {
                    self.busy = false;
                    switch (o.error) {
                        case enmErrorMessage.NoSuchSession:
                            alert(core.getMsg(_msgNoSuchSessionMessage));
                            resetSession();
                            break;
                        case enmErrorMessage.Error:
                            Alertify.log.error(core.getMsg(_msgSomeErrorOnServerWhileRetrievingData, o));
                            break;
                        case enmErrorMessage.AccessDenied:
                            Alertify.log.error(core.getMsg(_msgAccessDeniedOnWorkspaceOverview));
                            break;
                        default:
                            DEBUG && console.error(o);
                            __USE_ROLLBAR &&
                                Rollbar.error(
                                    'wtWorkspaceOverview::activate::rWorkspaceOverviewForUser fail response',
                                    {
                                        req: rq,
                                        resp: o
                                    }
                                );
                            break;
                    }
                    self.update();
                });
        },
        activate() {
            this._super();
            if (!this.busy) {
                this.requestData();
            } else {
                this.update();
            }
        },
        update(pid) {
            if (!this.isActive()) {
                return;
            }
            const self = this;
            const active = this.$tasks;
            const upcoming = []
                .concat(
                    this.$tasks.$keysByAggregateKey({ old: true }),
                    this.$tasks.$keysByAggregateKey({ upcoming: true })
                )
                .map(id => {
                    const v = self.$tasks.$item(id);
                    return Object.assign({}, v, {
                        showProject: true,
                        upcoming: true,
                        alerted: v.isDeadlineAlert(),
                        project: core_DO.projectInfo(v.projectId)
                    });
                });
            // this.$tasks.$valuesByAggregateKeys({ upcoming: true, old: true }).map(function(v) {
            //     return Object.assign({}, v, { showProject: true, upcoming: true, alerted: v.isDeadlineAlert() });
            // });
            const $projects = active
                .$aggregatedKeys('projectId')
                .filter(
                    $id =>
                        !!core_DO.projectInfo($id) &&
                        active.$keysByAggregateKey({ projectId: $id, blockType: 'current' }).length > 0
                )
//
                .map($id=>core_DO.projectInfo($id))
                .sort(sortProjectByLTA())
                .map($prj=>$prj.id)
//
                .map($id =>
                    $.extend(false, core_DO.projectInfo($id), {
                        $list: active
                            .$valuesByAggregateKeys({ projectId: $id, blockType: 'current' })
                            .sort(tasksSortFn)
                            .map(v =>
                                Object.assign({}, v, {
                                    upcoming: isUpcoming(v.deadline),
                                    alerted: v.isDeadlineAlert()
                                })
                            )
                    })
                );

            const now = new Date().getTime();
            let obj = [];

            if (upcoming.length) {
                obj = upcoming.sort((a, b) => a.deadline - b.deadline);
            } else if (this.busy) {
                obj = [null];
            }

            this._binds.set(_bndOverviewList, $projects);
            this._binds.set(_bndUpcoming, obj);

            const tr = this._binds.get(_bndOverviewList),
                tt = tr
                    .selectAll(`.${_clsProjectTasks}`)
                    .selectAll(`.${_clsTaskRow}`)
                    .data(function(d) {
                        const dd = d3.select(this.parentNode.parentNode).datum();
                        return (dd.$list && dd.$list.slice(0, 8)) || [];
                    });

            tt.enter()
                .append('div')
                .classed(_clsTaskRow, true);
            tt.exit().remove();
            tt.html(d => tpls.widgets.workspaceOverview.taskTemplate(d));
            tt.classed(_clsProcessing, d => d.state == enmTaskInfoStates.Completed);

            this.msnry && this.msnry.destroy();
            this.msnry = new Masonry(this._binds.elem(_bndOverviewList), {
                gutter: 40,
                columnWidth: _gcProjectOverviewWidth,
                itemSelector: `.${_clsTasksBlock}, .${_clsTasksBlockPreserved}`
            });
        },
        render($owner) {
            this.$rendered = core_dom.renderAsElement(tpls.widgets.workspaceOverview.decoration, {}, this._binds);
            $owner.find(`#${_clsWSOverviewPane} .${_clsWhiteRoundWrapper}`).append(this.$rendered);

            const self = this;

            this.$rendered.on('click', `div.${_clsCheckbox}`, function() {
                const d = d3.select($(this).parents(`div.${_clsTaskRow}`)[0]).datum();
                switch (d['state']) {
                    case enmTaskInfoStates.Completed:
                        rollbackCompleted.call(self, d['id'], true);
                        break;
                    case enmTaskInfoStates.Blocked:
                        break;
                    default:
                        setCompleted.call(self, d['id'], true);
                        break;
                }
            });
        }
    }
);
