Vue.component(_cmpWSOverviewUserSelector, {
    template: tpls.wsOverviewUserSelector(),
    replace: true,
    inherit: true,
    created: function () {
        var self = this;
        // core.moses.subscribe(_evClosePopups, function () {
        //     self[_bndOpenPopup] = false;
        // });
        var self = this;
        $(document).on(['click', 'contextmenu', _evClosePopups, _evEscPressed].join(' '), function (ev) {
            if (ev && ev.isDefaultPrevented && ev.isDefaultPrevented()) { return }
            self[_bndOpenPopup] && (self[_bndOpenPopup] = false);
        });
    },
    methods: function () {
        var r = {};
        r[_evSelectorClick] = function (ev) {
            $scope[_bndShowClickCaptor] = false;
            if (this[_bndCurrentWorkspaceUsersFiltered].length == 0) { return false; }
            if (this.$data[_bndOpenPopup] === undefined) {
                this.$data.$add(_bndOpenPopup, true);
            } else {
                this.$data[_bndOpenPopup] = !this.$data[_bndOpenPopup];
            }
            $scope[_bndShowClickCaptor] = this.$data[_bndOpenPopup];
            ev.preventDefault();
        }
        r[_evSelectorSelectUser] = function (id) {
            $scope[_bndWorkspaceOverviewActiveUser] = $scope[_bndWorkspaceUsers].$item(id);
            this.$data[_bndOpenPopup] = false;
            core.moses.announce(_evSelectorSelectUser, id);
        }
        return r;
    }(),
    computed: function () {
        var c = {};
        c[_bndCurrentWorkspaceUsersFiltered] = {
            get: function () {
                return $scope[_bndWorkspaceUsers].$filter(function (/* cWorkspaceUser */ d) {
                    return d.id != $scope[_bndWorkspaceOverviewActiveUser].id && /*d.status == enmWorkspaceUserStatus.Confirmed && */$scope[_bndCurrentWorkspace] && d.workspaceId == $scope[_bndCurrentWorkspace].id
                }, []);
            }
        }
        return c;
    }()
});
