core.featureAuth = (fname, provider) => {
    const win = window.open(
        window['appConfig']['config']['api'] +
            'client/in/auth?sessionKey={key}&workspaceId={wsid}&featureName={feature}&providerId={provider}'.format({
                key: core.sessionId(),
                wsid: $scope[_bndCurrentWorkspace].id,
                feature: fname,
                provider: provider
            }),
        '_blank'
    );

    return new Promise((rslv, rjct) => {
        core.moses.watch(_nFeatureAuthSuccess, _gcFeatureAuthSuccessQueueId, () => {
            core.moses.cancelWatch(_gcFeatureAuthFailQueueId);
            rslv();
        });
        core.moses.watch(_nFeatureAuthFailure, _gcFeatureAuthFailQueueId, () => {
            core.moses.cancelWatch(_gcFeatureAuthSuccessQueueId);
            rjct();
        });
    });
};
