(function($) {
    /**
     * @param {JQuery} $selected
     * @param {JQuery} $item
     */
    const putSelected = function($selected, $item) {
        $selected.empty();
        const d = $item.data();
        // if (d.path) { $selected.append($('<img />').attr('src', d.path)) }
        $selected.css('background-image', d.path ? 'url(' + d.path + ')' : '');
        if (d.text) {
            $selected.append($('<span />').text(d.text));
        }
    };

    /**
     * @param {string} method
     */
    $.fn.imageSelector = function(method) {
        if (methods[method]) {
            return methods[method].apply(this, Array.prototype.slice.call(arguments, 1));
        } else if (typeof method === 'object' || !method) {
            return methods.init.apply(this, arguments);
        }
    };

    /**
     * @type {Object.<string, function>}
     */
    const methods = {};
    /**
     * @return {JQuery}
     */
    methods.init = function() {
        return this.each(
            /**
             * @this HTMLElement
             */
            function() {
                debugger;
                const $p = $(this);
                const oldDisplay = $p.css('display');

                $p.css('display', 'none');

                const $container = $('<div />')
                    .addClass(_clsImageSelector_container)
                    .addClass($p.get(0).className);
                const $selected = $('<div />').addClass(_clsImageSelector_selected);
                const $list = $('<ul />').addClass(_clsImageSelector_list);
                $container.append($selected);
                $container.append($list);

                $p.data('obj', $container);
                $p.data('oldDisplay', oldDisplay);

                const $options = $p.find('option');
                /** @type {string} */
                const dsel = $p.data('select');
                let $sel = $options.filter(dsel ? `[value="${dsel}"]` : ':selected');

                if (dsel) {
                    $p.val(dsel);
                    $p.data('select', null);
                }

                ($sel.length ? $sel : $options.first()).attr('selected', 'true');

                $options.each(
                    /**
                     * @this HTMLOptionElement
                     */
                    function() {
                        const $item = $('<li />');
                        const $p = $(this);
                        /** @type {string} */
                        const path = encodeURI($p.prop('value'));

                        $item.data({
                            path,
                            text: $p.text()
                        });

                        path && $item.css('background-image', 'url(' + path + ')');

                        if ($p.attr('selected')) {
                            $item.addClass(_clsSelected);
                            $sel = $item;
                        }

                        $item.append('<span>' + $p.text() + '</span>');
                        $list.append($item);
                    }
                );

                if ($sel.get(0)) {
                    putSelected($selected, $sel);
                }

                $container.insertAfter($p);
                /** @type {Function|null} */
                let popup;

                $container
                    .on('click', function() {
                        var $el = $(this);
                        if ($el.hasClass(_clsActive)) {
                            $('.' + _clsImageSelector_list).css('opacity', 0);
                            popup && (popup(), (popup = null));
                            setTimeout(function() {
                                $el.removeClass(_clsActive);
                            }, 250);
                        } else {
                            $el.addClass(_clsActive);
                            $('.' + _clsImageSelector_list).css('opacity', 1);
                            popup = core_dom.modalEscape($el.get(0), function() {
                                $container.trigger('click');
                            });
                        }
                        return false;
                    })
                    .on('click', 'li', function() {
                        $list.find('li').removeClass(_clsSelected);
                        $(this).addClass(_clsSelected);
                        $p.val($(this).data('path'));
                        putSelected($selected, $(this));
                        $p.trigger('change');
                    });
            }
        );
    };

    methods.destroy = function() {
        return this.each(
            /** @this HTMLElement */
            function() {
                const $container = $(this).data('obj');
                /** @type {string} */
                const oldDisplay = $(this).data('oldDisplay');

                oldDisplay && $(this).css('display', oldDisplay);
                $container && $container.remove();
            }
        );
    };
})(jQuery);
