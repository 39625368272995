Vue.directive('cat', {
    update: function (newValue, oldValue) {
        /** cProjectTagInfo */
        var tag = newValue && $scope[_bndProjectTags].$item && $scope[_bndProjectTags].$item(newValue) || null;
        $(this.el)
            .html(tag && tag.name || '---')
            .css({
                'backgroundColor': tag && tag.color || '',
                'color': core_utils.makeFColorVisible(tag.color)
            });
    }
});

Vue.component(_cmpProjectTags, {
    template: tpls.dashboard.projectTagsTpl(),
    replace: true,
    inherit: true
})

Vue.component(_cmpProjectTagListItem, {
    template: tpls.dashboard.projectTagsItemTpl(),
    replace: true,
    inherit: true,
    methods: function () {
        var r = {};
        r[_evRemoveTag] = function (ev) {
            ev.preventDefault();
            if (this[_flgMarkedForRemoval] === undefined) {
                this.$data.$add(_flgMarkedForRemoval, true);
            } else {
                this[_flgMarkedForRemoval] = !this[_flgMarkedForRemoval];
            }
        }
        return r;
    }()
});
