var instances_ = [],
    $shield = null;

function checkInstances_() {
    if (instances_.length) {
        $(
            instances_.map(function(d) {
                return d[0];
            })
        ).hide(); //fadeOut();
    }
}

function restorePrevInstance_() {
    if (instances_.length) {
        $(instances_[instances_.length - 1][0]).show(); //fadeIn();
    }
}

$('body').on(_evEscPressed, function() {
    var i = instances_.last();
    i && i[0].find('.' + _idSymbolCloseIcon).trigger('click');
});

// var resizeHandler = function () {
//         instances_.forEach(function (v) {
//             // v[0].find('.body').css('max-height', windowHeight - 200);
//             // v[3].tp = core_dom.centerBox(v[2], v[0], true, v[3].valign);
//             var h = core_dom.elementOffset(v[0].find('.header')),
//                 $bd = v[0].find('.body'),
//                 bd = core_dom.elementOffset($bd),
//                 // f = core_dom.elementOffset(v[0].find('.buttons')),
//                 b = v[0].find('.' + _clsDialog)[0].clientHeight;
//
//             $bd.css('height', 'auto');
//
//             if ($bd[0].clientHeight > b) {
//                 $bd.css('height', b - h.height - 55);
//             }
//         });
//         return true;
//     }
//
var resizeHandler = function() {
    instances_.forEach(function(v) {
        var b = v[0]
                .find('.' + _clsDialogBody)
                .children()
                .first()[0], //v[0].find('.' + _clsDialogBody)[0],
            d = v[0].find('.' + _clsDialog);

        d.css(
            'width',
            v[3].width == 'auto'
                ? Math.max(b.offsetWidth, b.scrollWidth) + ((!v[3].noBodyPadding && 50) || 0)
                : v[3].width
        );

        d.css(
            'height',
            (v[3].height == 'auto' ? Math.max(b.offsetHeight, b.scrollHeight) : v[3].height) +
                ((v[3].header && 40) || 0) +
                ((((v[3].buttons && v[3].buttons.length) || v[3].customButtons) && 74) || 0)
        );
    });
};

$(window).on('resize', resizeHandler.debounce(250));

/**
 * Modal Dialog widget
 * @constructor
 * @extends AbstractWidget
 */
// @ts-ignore
new $classes.Class(
    _wtModalDialog,
    _clAbstractWidget,
    // @lend AbstractWidget
    {
        options: {
            type: 'modal',
            width: 'auto',
            height: 'auto',
            color: _mdcBlack,
            animate: 500,
            animateType: 'ease-in-out',
            modal: true,
            attachTo: 'body',
            buttons: [_drbOK, _drbCancel],
            buttonsLabel: null,
            id: null,
            onClose: null,
            afterShow: null,
            beforeShow: null,
            content: null,
            header: null,
            valign: _BOX_VALIGN_MIDDLE,
            autoScroll: true,
            resizable: false,
            draggable: false,
            noBodyPadding: false,
            clickThrough: false
        },
        _create: function(name, opts) {
            this._super.apply(this, [name, null, opts]);
            this.options.id = (opts && opts.id) || this.widgetName;

            if (this.options.buttonsLabel) {
                this.options.buttonsLabel = core_utils.array2HashObj(this.options.buttonsLabel, 'id');
            }
        },
        show: function(content, opts) {
            var o = $.extend(true, this.options, opts || {}),
                $el,
                $s;

            if ($('#' + o.id).length) return;

            checkInstances_();

            content && (o.content = content);

            if (o.buttonsLabel instanceof Array) {
                o.buttonsLabel = new Armap(o.buttonsLabel).$hash();
            }

            $el = $(tpls.widgets.modalDialog.decoration(o));
            this.$rendered && this.$rendered.append($el.find('div.' + _clsDialogBody));

            if (o.noBodyPadding) {
                $el.find('.' + _clsDialog).addClass(_clsNoBodyPadding);
            }

            if (!$shield && o.attachTo == 'body') {
                $shield = $('<div id="' + _clsDialogShield + '" />').appendTo('body');
                if (o.modal) {
                    $('body').addClass('fade');
                    $shield.addClass(_clsDialogModal);
                }
            }

            // if (o.attachTo != 'body') {
            //     $el.css('position', 'absolute');
            // }
            $el.appendTo(o.attachTo).hide();

            // !instances_.length && ($('body').bind('keydown', escHandler), DEBUG && console.log('binded'));

            instances_.push([$el, this, $shield, o]);

            // o.tp = core_dom.centerBox($shield, $el, undefined, o.valign);

            (this.$rendered = $el).data('opts', o);
            this._binds.regBinds(this.$rendered);

            if (o.beforeShow instanceof Function) {
                o.beforeShow($el, this);
            }

            var self = this;

            $el.show()
                .find('.' + _clsDialog)
                .addClass('in')
                .on('click', 'button.' + _clsDialogClose + ', .' + _idSymbolCloseIcon, function(ev) {
                    var $el = $(this),
                        role = $el.data('role');

                    if ($el.hasClass('disabled')) {
                        return false;
                    }
                    role == _drbOK && $el.addClass('disabled process');

                    if (self.close(role) === false) {
                        if (role == _drbOK) {
                            $el.removeClass('disabled process');
                        }
                        ev.preventDefault();
                        return false;
                    } else {
                        instances_.pop();
                        restorePrevInstance_();
                        // if (instances_.length == 0) {
                        //     $('body').unbind('keydown', escHandler);
                        // }
                    }
                })
                .on('submit', 'form', function(ev) {
                    $(this)
                        .parents('.' + _clsDialog)
                        .find('button.' + _clsDialogClose + '[data-role="' + _drbOK + '"]')
                        .triggerHandler('click');

                    ev.preventDefault();
                    return false;
                });

            if (o.clickThrough === true) {
                $el.on('click', function(ev) {
                    if (ev.currentTarget != ev.target) {
                        return true;
                    }
                    $(this).css('pointer-events', 'none');
                    var $e = $(document.elementFromPoint(ev.clientX, ev.clientY));
                    if ($e.length) {
                        $e.trigger('click');
                    }
                    $(this).css('pointer-events', '');
                    return false;
                });
            }

            if (o.afterShow instanceof Function) {
                o.afterShow.call(this, $el, this);
            }
            this.active = true;
            resizeHandler();
            (o.draggable || o.resizable) &&
                core_dom.resizable(this.$rendered, {
                    dragHandle: o.draggable && '.' + _clsDialogHeader,
                    changeTop: o.resizable,
                    changeBottom: o.resizable,
                    changeLeft: o.resizable,
                    changeRight: o.resizable
                });
        },
        align: function() {
            instances_.last()[0].scrollTop(0);
            resizeHandler();
        },
        close: function(role, _callback) {
            // debugger;
            if (this.$rendered) {
                var o = this.$rendered.data('opts');
                if (o.onClose && !_callback) {
                    if (Object.isFunction(o.onClose)) {
                        var r = o.onClose.call(this, role, this.$rendered.find('div.' + _clsDialogBody));
                        if (r === false) {
                            return false;
                        } else if (r instanceof Promise) {
                            r.then(
                                function() {
                                    this.close(null, true);
                                }.bind(this)
                            );
                            return false;
                        }
                    } else {
                        core.moses.announce(o.onClose, { role: role, el: this });
                    }
                }

                this._binds.free();

                var self = this;
                this.$rendered
                    .on(_gcTransitionEnd, function() {
                        self.$rendered && self.$rendered.remove();
                        self.$rendered = null;
                    })
                    .find('.' + _clsDialog)
                    .removeClass('in');
                setTimeout(function() {
                    self.$rendered && self.$rendered.remove();
                    self.$rendered = null;
                }, 500);

                if ($shield) {
                    $shield
                        .on(_gcTransitionEnd, function() {
                            $shield.remove();
                            $shield = null;
                        })
                        .css('opacity', 0);
                    setTimeout(function() {
                        $shield && $shield.remove();
                        $shield = null;
                    }, 500);
                }
            }
            $('body').removeClass('fade');
            this.active = false;
        }
    }
);
