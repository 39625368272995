/** @const */ const cnMutex = 'mutex';

$(document)
    .on('focusin', '[data-mutexable]', function(ev) {
        //             DEBUG && console.log('mutexable::focusin', this);
        $(this).data(cnMutex, {
            oldValue: $(this).val(),
            mutexed: true
        });
        return true;
    })
    .on('focusout', '[data-mutexable]', function(ev) {
        //             DEBUG && console.log('mutexable::focusout', this);
        const d = $(this).data(cnMutex);
        if (d) {
            d.mutexed = false;
            $(this).data(cnMutex, d);
        }
        return true;
    });

/**
 * Reset mutexed from specified parent
 * @param {jQuery|String} $parent
 * @return {void}
 */
core.resetMutexByParent = $parent => {
    (($parent instanceof jQuery && $parent) || $($parent)).find('[data-mutexable]').each(function() {
        core.resetMutex(this);
    });
};

/**
 * Reset mutex by control
 * @param {DOMElement|jQuery} ctrl
 */
core.resetMutex = ctrl => {
    ((ctrl instanceof jQuery && ctrl) || (ctrl instanceof HTMLElement && $(ctrl)) || $('[data-mutexable]')).data(
        cnMutex,
        null
    );
};

/**
 * Check is control mutexed and/or data was changed
 * @param {DOMElement|jQuery} ctrl
 * @return {boolean} true if mutexed(or value was changed) and false if is not
 */
core.checkMutex = ctrl => {
    const $c = (ctrl instanceof jQuery && ctrl) || $(ctrl),
        m = $c.data('mutexable') != undefined,
        d = (m && $c.data(cnMutex)) || false;

    // if mutex is set
    if (d) {
        // is mutex active?
        if (d.mutexed) {
            return true;
        }
        // it is not active but is value was changed?
        const v = $c.val();
        return v != d.oldValue;
    }
    return false;
};

/**
 * Get old data from mutex
 * @param {DOMElement|jQuery} ctrl
 * @return {string} old value
 */
core.getOldValueFromMutex = ctrl => {
    const $c = (ctrl instanceof jQuery && ctrl) || $(ctrl),
        d = $c.data(cnMutex) || false;

    return (d && d.oldValue) || null;
};
