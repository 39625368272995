function min(src) {
    var m;
    (src || []).forEach(function(e) {
        if ((m != undefined && m > e) || m === undefined) {
            m = e;
        }
    });
    return m;
}

function max(src) {
    var m;
    (src || []).forEach(function(e) {
        if ((m != undefined && m < e) || m === undefined) {
            m = e;
        }
    });
    return m;
}

// function unique (src) {
//     var obj = {};
//     (src || []).forEach(function (e) {
//         obj[e] = true;
//     })
//     return Object.keys(obj);
// }

function last(src) {
    return src[src.length - 1];
}

class Axis {
    _margin;
    _step;
    _axisStep;
    _startId;
    _cached = {};
    _cachedRevert = {};
    _initQty;
    constructor(margin, startId, step, qty, axisStep) {
        this._margin = margin || 0;
        this._step = step || 1;
        this._axisStep = axisStep || 1;
        this._startId = startId || this._axisStep;
        this._cached = {};
        this._cachedRevert = {};
        this._initQty = qty;

        this.init();
    }

    init() {
        this.container = [];
        this.hash = null;
        this.$changed = true;

        if (this._initQty) {
            for (let t = this._startId, i = t; i < t + (this._initQty + 1) * this._axisStep; i += this._axisStep) {
                this.container.push(i);
            }
        }
    }
    add(id) {
        if ((this.hash && this.hash[id] === undefined) || this.container.indexOf(id) == -1) {
            this.container.push(id);
            this.count = this.container.length;
            this.hash = null;
            this.$changed = true;
            return true;
        }
        return false;
    }
    remove(id) {
        this.container.remove(id);
        this.hash = null;
        this.count = this.container.length;
        this.$changed = true;
    }
    axisById(id) {
        const i = this.hash ? this.hash[id] : this.container.indexOf(id);
        if (i == undefined) {
            this.add(id);
            this.balanceSpace();
            this.update();
            return this.axisById(id);
        }
        return i * this._step + this._margin;
    }
    idByAxis(x, create) {
        let id = (x - this._margin) / this._step;

        if (this.container[id] === undefined) {
            const f = this.container[0];
            const fc = this._margin;
            const l = last(this.container);
            const lc = (this.container.length - 1) * this._step + this._margin;

            if (fc > x || lc < x) {
                if (lc < x) {
                    const sr = (x - lc) / this._step;
                    this.append((~~sr < sr && ~~sr + 1) || ~~sr);
                } else if (fc > x) {
                    let sr = (fc - x) / this._step;
                    sr = ~~sr < sr ? ~~sr - 1 : ~~sr;

                    for (var k = f - this._axisStep; sr-- > 0; k -= this._axisStep) {
                        this.container.push(k);
                    }

                    if (x < this._margin) {
                        this._margin = x;
                    }
                    id = 0;
                }
                this.update();
            }
        }

        return this.container[id];
    }
    update(adjustMargin) {
        let mid, ma;
        if (adjustMargin) {
            mid = this.container[0];
            ma = this._margin;
        }

        this.hash = {};
        this.container = this.container
            .sort(function(a, b) {
                return a - b;
            })
            .unique();

        this.container.forEach((k, i) => (this.hash[k] = i));
        this.count = this.container.length;
        this.$changed = false;

        if (adjustMargin && mid > this.container[0]) {
            const om = this.axisById(mid);
            const nm = this.axisById(this.container[0]);
            this._margin -= om - nm;
        }
    }
    balanceSpace() {
        if (this.count == 0) {
            return this;
        }

        const f = min(this.container);
        const l = max(this.container);

        let i = ~~(f / this._axisStep) * this._axisStep;
        while (l > i) {
            this.container.push(i);
            i += this._axisStep;
        }

        this.hash = null;
        this.$changed = true;
        return this;
    }
    append(qty) {
        const mn = last(this.container);
        let t = mn / this._axisStep;

        t = t != ~~t ? ~~t * this._axisStep : mn;

        for (let i = t + this._axisStep; i < t + (qty + 1) * this._axisStep; i += this._axisStep) {
            this.container.push(i);
        }
        this.hash = null;
        this.$changed = true;
        return this;
    }
    margin(nm) {
        if (nm != undefined) {
            this._margin = nm;
        }
        return this._margin;
    }
    clear() {
        this.container = [];
        this.hash = null;
        return this;
    }
    max() {
        return max(this.container);
    }
    min() {
        return min(this.container);
    }
    last() {
        return last(this.container);
    }
    first() {
        return this.container[0];
    }
}

class AxisGrid {
    x;
    y;
    $updated = false;

    constructor(marginX, marginY, startIdX, startIdY, stepX, stepY, qtyX, qtyY, axisStepX, axisStepY) {
        this.x = new Axis(marginX, startIdX, stepX, qtyX, axisStepX);
        this.y = new Axis(marginY, startIdY, stepY, qtyY, axisStepY);
        this.$updated = false;
    }

    margin(marginX, marginY) {
        if ((marginX == marginY) == undefined) {
            return {
                left: this.x.margin(),
                top: this.y.margin()
            };
        }
        if (marginX == this.x._margin && marginY == this.y._margin) return;
        this.x._margin = marginX;
        this.y._margin = marginY;
        this.x.update(true);
        this.y.update(true);
    }

    addRow(qty) {
        this.y.append(qty).update();
    }

    addCol(qty) {
        this.x.append(qty).update();
    }

    insertRow(id) {
        this.y.add(id);
        this.y.update();
    }

    insertCol(id) {
        this.x.add(id);
        this.x.update();
    }

    clear() {
        this.x.clear().init();
        this.y.clear().init();
    }

    add(row, col, update) {
        let c = this.x.add(col);
        c |= this.y.add(row);

        if (update !== false || (update == undefined && c)) {
            this.x.update();
            this.y.update();
        }

        return !!c;
    }

    update(balanceSpace, onlyRequired, adjustMargin) {
        if (balanceSpace) {
            ((onlyRequired && this.x.$changed) || !onlyRequired) && this.x.balanceSpace();
            ((onlyRequired && this.y.$changed) || !onlyRequired) && this.y.balanceSpace();
        }
        ((onlyRequired && this.x.$changed) || !onlyRequired) && this.x.update(adjustMargin);
        ((onlyRequired && this.y.$changed) || !onlyRequired) && this.y.update(adjustMargin);
        this.$updated = true;
    }
}
