(function(core) {
    ('use strict;');

    core.setupDatepicker = function(container) {
        var settings = $scope[_bndCurrentWorkspaceSettings] || {};
        var isSundayBeginsTheWeek = settings.firstDayOfWeek == 7;
        var workDays = core_utils.compileWorkWeekInfo(settings.schedule);

        /**
         * @param  {Date} d
         * @return {boolean}
         */
        var dateOffFilter = function(d) {
            return !workDays.includes(d.getDay());
        };

        $.fn.datepicker.setDefaults({
            mutedClass: _clsCalendarMuted,
            pickedClass: _clsCalendarSelected,
            disabledClass: _clsCalendarDisabled,
            highlightedClass: _clsCalendarHighlighted,
            weekStart: isSundayBeginsTheWeek ? 0 : 1,
            autoHide: true,
            // filter: dateFilter,
            zIndex: 1100,
            template: tpls.widgets.taskSchedule.calendar(),
            dayOffFilter: dateOffFilter,
            dayOffClass: _clsCalendarDayOff,
            dayOffSelectable: true,
            // inline: true,
            container: container
        });
    };

    const DATEPICKER_DEF = 'span[datepicker]';
    const DURATION_INPUT_DEF = '.' + _clsDurationSelector + ' input';

    // @ts-ignore
    new $classes.Class(
        _wtTaskSchedule,
        _clAbstractWidget,
        /** @lends wtTaskSchedule */
        {
            /**
             * @memberof wtTaskSchedule
             * @type {?string}
             */
            taskId: null,
            /** @type {?function} */
            stateChangeCallback: null,
            // deactivate: function () {
            //     this._super();
            //     this.$rendered
            //         .find(DATEPICKER_DEF)
            //             .datepicker('destroy')
            // },
            /**
             * set widget info
             * @param  {string} taskId
             * @param  {cTaskInfo} taskData
             * @this wtTaskSchedule
             * @return {void}
             */
            setData(taskId, taskData) {
                this.taskId = taskId;
                this._binds.set(taskData);
                taskId && this.update();
            },
            update() {
                const task = core_DO.task(this.taskId);
                const vs = task?.values || {};
                const deadline = task?.deadline;
                const startDate = task?.startDate;
                /**
                 * @param  {Date} d
                 * @this jQuery
                 * @return {Array<string>}
                 */
                const classesFilter = function(d) {
                    const r = [];
                    const m = moment(d);
                    const name = this.get(0).name;

                    // if (workDays.includes(d.getDay()) == false) {
                    //     r.push(_clsCalendarDisabled);
                    // }

                    switch (name) {
                        case 'startDate':
                            if (m.isBefore(vs?.canStart)) {
                                r.push(_clsCalendar_IsTooEarly);
                            }
                            if (deadline && m.isAfter(deadline)) {
                                r.push(_clsCalendar_IsTooLate);
                            }
                            break;
                        case 'deadline':
                            if (deadline && m.isBefore(deadline)) {
                                r.push(_clsCalendar_IsTooEarly);
                            }
                            break;
                    }

                    return r;
                };

                this.$rendered
                    .find(DATEPICKER_DEF)
                    .datepicker('destroy')
                    .datepicker({
                        classes: classesFilter
                    });

                this._super();
            },
            /**
             * @return {string}
             */
            getDuration() {
                return this.$rendered
                    .find(DURATION_INPUT_DEF)
                    .toArray()
                    .map(function(e) {
                        var v = e.value;
                        return (parseInt(v, 10) && e.value + e.name) || false;
                    })
                    .filter(function(v) {
                        return !!v;
                    })
                    .join(' ')
                    .trim();
            },
            storeDuration() {
                var d = this.$rendered.find('.' + _clsDurationSelector).data('_old');
                var nd = this.getDuration();
                d !== nd && this.sendUpdate({ durationDef: nd });
            },
            /** @param {object} data */
            sendUpdate(data) {
                this.options.stateChangeCallback(enmStateChangeTypes.UpdateTaskTiming, this.taskId, data);
                core_DO.updateTaskTiming(this.taskId, data);
            },
            /** @param {jQuery} $parent */
            render($parent) {
                const self = this;
                this.$rendered = $parent;
                core_dom.renderIntoElement($parent, tpls.widgets.taskSchedule.base, {}, this._binds);

                this.$rendered
                    .on(
                        'input update',
                        DURATION_INPUT_DEF,
                        /** @this HTMLElement */
                        function() {
                            this.style.width = '1.5em';
                            if (this.scrollWidth > this.clientWidth) {
                                var w = this.scrollWidth + 3 + 'px';
                                this.style.width = w;
                            }
                        }
                    )
                    .on(
                        'focusout',
                        DURATION_INPUT_DEF,
                        /** @param {FocusEvent} ev */
                        ev => !ev?.target?.parentNode.contains(ev.relatedTarget) && this.storeDuration()
                    )
                    .on(
                        'keydown',
                        DURATION_INPUT_DEF,
                        /**
                         * @this HTMLInputElement
                         * @param {KeyboardEvent} ev
                         */
                        function(ev) {
                            const v = this.value;
                            switch (true) {
                                case ev.key == 'ArrowUp':
                                    this.value = ((parseInt(v, 10) || 0) + 1).toString();
                                    return false;
                                case ev.key == 'ArrowDown':
                                    const cv = (parseInt(v, 10) || 1) - 1;
                                    this.value = (!!cv && cv.toString()) || '';
                                    return false;
                                case ev.key == 'Enter':
                                    self.storeDuration();
                                    return false;
                                case /^\D$/.test(ev.key):
                                    return false;
                            }
                        }
                    )
                    .on(
                        'show.datepicker',
                        DATEPICKER_DEF,
                        /** @this HTMLSpanElement */
                        function() {
                            if (
                                $(this)
                                    .parents('.' + _clsSchedule_Wrapper)
                                    .hasClass(enmTaskInfoStates.Completed)
                            ) {
                                return false;
                            }
                            const d = $(this).data();
                            const td = d.date;
                            const picker = d.datepicker;
                            if (td) {
                                picker.setDate(new Date(core_utils.unpackDate(td)), true);
                                var c;
                                switch (d.name) {
                                    case 'deadline':
                                        c = _clsDeadline;
                                        break;
                                    case 'startDate':
                                        c = _clsStartDate;
                                        break;
                                }
                                picker.$picker
                                    .find('.' + _clsDatePicker_ActionPanel)
                                    .addClass(c)
                                    .on('click', function(ev) {
                                        /** @type {string} */
                                        const e = $(ev.target).data('event');
                                        const o = {};
                                        switch (e) {
                                            case _evClearDeadline:
                                                o.deadline = null;
                                                break;
                                            case _evClearTaskStartDate:
                                                o.startDate = null;
                                                break;
                                        }
                                        self._binds.set({ task: o });
                                        self.sendUpdate(o);
                                        picker.hide();
                                        return true;
                                    });
                            }
                        }
                    )
                    .on(
                        'pick.datepicker',
                        DATEPICKER_DEF,
                        /**
                         * @this HTMLSpanElement
                         * @param {MouseEvent} ev
                         */
                        function(ev) {
                            const date = core_utils.packDate(ev.date);
                            const $el = $(this);
                            const d = $el.data();
                            if (d.date != date) {
                                $el.data('date', date);
                                const o = { task: {} };
                                o.task[d.name] = date;
                                self._binds.set(o);
                                self.sendUpdate(o.task);
                            }
                        }
                    )
                    // .on('hide.datepicker', DATEPICKER_DEF, function () {
                    //     $(this).datepicker('destroy');
                    // })
                    .on(
                        'click',
                        '.' + _clsSchedule_Value + ' .' + _clsSVGIcon,
                        /** @this HTMLElement */
                        function() {
                            let f;
                            switch ($(this).data('role')) {
                                case _idRoleClearStartDate:
                                    f = 'startDate';
                                    break;
                                case _idRoleClearFinishDate:
                                    f = 'deadline';
                                    break;
                            }
                            const o = { task: {} };
                            o.task[f] = null;
                            self._binds.set(o);
                            self.sendUpdate(o.task);
                            return false;
                        }
                    );
                // .on(_evEscPressed, DATEPICKER_DEF, function () {
                //     var d = $(this).data();
                //     d.datepicker.hide();
                // })
                return this._super();
            }
        }
    );
})(core);

core_utils.intExport(
    _ienDurationDef,
    /**
     * @param {HTMLElement} el
     * @param {string} val
     */
    function(el, val) {
        const $el = $(el);
        if ($el.data('_old') == val && val != '') {
            return;
        }

        const m = core_utils.duration2Values(val);
        Array.prototype.slice.call(el.querySelectorAll('input')).forEach(function(e) {
            e.value = m[e.name] || '';
            $(e).triggerHandler('update');
        });
        $el.data('_old', val);
    }
);
core_utils.intExport(
    _ienDuration2Text,
    /** @param {string} data */
    function(data) {
        return (data || '').replace(/(\d+)(\w)/g, function(_, q, v) {
            const d = parseInt(q || '0', 10);
            switch (v) {
                case 'd':
                    return (d ? d + ' ' : '') + ((d > 1 && 'days') || 'day');
                case 'h':
                    return (d ? d + ' ' : '') + ((d > 1 && 'hours') || 'hour');
                case 'm':
                    return (d ? d + ' ' : '') + ((d > 1 && 'mins') || 'min');
            }
        });
    }
);
