/**
 * @author Jacky Shikerya
 */

const PANES_tabProcess = '#project-planner';
const PANES_tabUsers = '#project-users';
const PANES_tabFiles = '#project-files';
const PANES_tabDiscussion = '#project-discussions';

let dashboardLastViewMode = null;
let firstRun = true;

/**
 * @ extends AbstractPageController
 */ 
// @ts-ignore
new $classes.Class(_pcGlobalPageController, _clAbstractPageController, 
    // @lend pcGlobalPageController
    () => {
    const list = {};
    let defController = null;
    let activePage;
    let defPage;
    const onlineState = _nsOnline;
    let networkStateBanner;

    function drawNotifications(widget, data, backNav) {
        let csp = widget._binds.get('csp') || 0,
            list;

        backNav && (csp -= _gcNotificationsDialogLimit * 2);

        widget._binds.set({
            list: (list = (data || []).slice(csp, csp + _gcNotificationsDialogLimit)),
            back: csp - _gcNotificationsDialogLimit >= 0,
            more: data.length - csp - _gcNotificationsDialogLimit > 0,
            csp: csp + _gcNotificationsDialogLimit
        });

        list.forEach(d => {
            d.data.read = true;
        });
        core_DO.onNotificationsUpdate.debouncedPublish();

        widget.align();
    }

    return {
        ctrl: null,
        init() {
            DEBUG && console.log('GlobalPageController::init');
            this.listen[_MOSES_DATA_READY] = function() {
                DEBUG && console.log('rendering interface');
                if (
                    /ip(hone|ad)|android/i.test(navigator.userAgent) &&
                    core.storage.$get(_gcMobileBanner, true) == null
                ) {
                    var $this = this;
                    $('body').addClass(_clsMobileMode);
                    $('#loadWrapper')
                        .html(tpls.components.mobile())
                        .on('click', `.${_bndIWantMobile}`, () => {
                            $('body').removeClass(_clsMobileMode);
                            $this.renderPage();
                            core.moses.announce(_MOSES_IFACE_READY);
                            core.uriHandler.init();
                        });
                    core.storage.$set(_gcMobileBanner, true, true);
                } else {
                    this.renderPage();
                    // core.avatar.applyHandlers();
                    core.moses.announce(_MOSES_IFACE_READY);
                    core.uriHandler.init();
                }
            };

            this.listen[_evNetworkStatusChange] = state => {
                switch (state) {
                    case _nsOnline:
                        if (networkStateBanner) {
                            networkStateBanner.close();
                            networkStateBanner = null;
                            Alertify.log.success(core.getMsg(_msgNetworkGoesOnline));
                        }
                        break;
                    case _nsOffline:
                        if (!networkStateBanner) {
                            networkStateBanner = Alertify.log.error(
                                core.getMsg(_msgNetworkGoesOffline, { stayCalm: activePage == _pidProject }),
                                0,
                                true
                            );
                        }
                        break;
                }
            };

            this.registerWidget($classes.$factory(_wtWsToolBar));
            this.registerWidget($classes.$factory(_wtUserToolBar));

            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiToolbarWsMenu, {
                    menuId: _clsWorkspaceMenu,
                    menuTitle: core.getMsg(_msgWSnPRsHeader),
                    custom: tpls.components.WorkspacesPopupMenu(),
                    afterRender() {
                        this.$rendered.on('click', `.${_clsBackButton}`, () => {
                            this._binds.set(_bndPopupAddClass, _clsWorkspaces);
                            return false;
                        });
                        // .on('click', '.' + _clsShowMore, function () {
                        //     debugger;
                        //     $(document).trigger(_evClosePopups);
                        // })
                    },
                    beforePopupCallback: ctx => {
                        const b = ctx._binds;
                        const wsl = core_DO.$workspaces();
                        const cws = $scope[_bndCurrentWorkspace] || wsl[0];

                        switch (true) {
                            // case activePage == _pidWorkspace:
                            // case activePage == _pidManageBillingWorkspaces:
                            //     core.uriHandler.setUri({'id': _umWorkspace, 'wsId': wsl[0].id, 'link': 'projects', 'archived': $scope[_bndProjectListViewMode] == enmProjectStatus.Archived});
                            //     return false;
                            case activePage == _pidProject:
                                core.uriHandler.setUri({
                                    id: _umWorkspace,
                                    wsId: cws.id,
                                    link: 'projects',
                                    archived: $scope[_bndProjectListViewMode] == enmProjectStatus.Archived
                                });
                                return false;
                        }

                        b.set(_bndPopupAddClass, _clsWorkspaces);
                        b.set(_bndWorkspaces, wsl);
                        b.set(_bndActiveWorkspace, cws && cws.name);

                        // var pl = core_DO.projects(undefined, _prsbLTA);
                        // b.set(_bndProjects, pl.slice(0, 8))
                        // b.set(_bndShowMore, pl.length > 8);
                        // b.set(_bndShowMoreLink, core.uriHandler.mkLink({'id': _umWorkspace, 'wsId': cws && cws.id, 'link': 'projects'}));
                    },
                    customHandler(ev, ctx, data, $trg) {
                        switch (true) {
                            case $trg.hasClass(_clsWorkspaceMenuItem):
                                // /** @type {cWorkspaceInfo} */
                                const d = d3.select(this).datum();
                                // if (d.active) {
                                //     ctx._binds.set(_bndPopupAddClass, _clsProjects);
                                //     return false;
                                // } else {
                                core.uriHandler.setUri({
                                    id: _umWorkspace,
                                    wsId: d.id,
                                    link: 'projects' /*dashboardLastViewMode*/
                                });
                                // }
                                break;
                            // case $trg.hasClass(_clsWorkspaceMenuProjectItem):
                            //     core.uriHandler.setUri({'id': _umProject, 'prId': d3.select(this).datum()['id']});
                            //     break;
                        }
                        return true;
                    }
                })
            );

            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiHelpMenu, {
                    menuTitle: core.getMsg(_msgHelpMenuTitle),
                    beforePopupCallback(ctx) {
                        core_stat.track(_mpcHelpButtonClicked);
                        ctx.setItems(tpls.components.helpMenu({ context: activePage }));
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiNotifications, {
                    menuTitle: core.getMsg(_msgNotificationsTitle),
                    menuId: _clsNotifications,
                    beforePopupCallback(ctx) {
                        const ons = core_DO.notifications(),
                            ns = ons.splice(0, _gcNotificationsPopupLimit),
                            items = ns.map(v => {
                                const wsd = core_DO.workspace(v['workspaceId']),
                                    s = tpls.components.notificationItem({ ws: wsd, data: v }),
                                    r = v.read_;

                                v.read_ = true;
                                return {
                                    label: s,
                                    classes: (r === false && _clsUnreadNotification) || ''
                                };
                            });

                        if (ons.length) {
                            items.push({
                                label: `<button class="${_gcMosesWatchClass} white" data-event="${_evNotificationsShowAll}">${core.getMsg(
                                    _msgNotificationsShowAll
                                )}</button>`,
                                classes: `noline ${_clsAlignCenter}`
                            });
                        }

                        if (!items.length) {
                            items.push({
                                label: core.getMsg(_msgNotificationsEmpty)
                            });
                        }

                        ctx.setItems(items);
                        core_DO.onNotificationsUpdate.debouncedPublish();
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiUsrMenu, {
                    // menuTitle: core.getMsg(_msgUserMenuTitle),
                    // items: tpls.components.userToolBarMenu()
                    custom: tpls.components.userToolBarMenuV2(),
                    afterRender() {
                        this._binds.regBinds(this.$rendered);
                    },
                    beforePopupCallback(ctx) {
                        /** @type {cWorkspaceInfo} */
                        const ws = $scope[_bndCurrentWorkspace];
                        /** @type {cWorkspaceUserInfo} */
                        const cu = $scope[_bndCurrentWorkspaceUser];
                        ctx._binds.set(
                            _bndIsMyWorkspace,
                            ws && cu && ws.workspaceOwner && ws.workspaceOwner.id == cu.id
                        );
                        ctx._binds.set('u', core.iam);
                    }
                    // customHandler: function (ev, ctx) {
                    //     return false;
                    // }
                })
            );

            this.registerWidget($classes.$factory(_wtUserProfile));

            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiFeedback, {
                    header: core.getMsg(_msgFeedbackDialogHeader),
                    // color: _mdcWhite,
                    modal: false,
                    resizable: false,
                    draggable: false,
                    content: tpls.iface.feedback($('html').data()),
                    buttons: [_drbOK, _drbCancel],
                    buttonsLabel: [
                        { id: _drbOK, label: core.getMsg(_msgBugReportNextButtonLabel) },
                        { id: _drbCancel, label: core.getMsg(_msgBugReportCancelButtonLabel) }
                    ],
                    afterShow($el) {
                        $el.find('textarea').focus();
                    },
                    onClose(role, $el) {
                        if (role == _drbCancel) {
                            return true;
                        }
                        const $form = $el.find('form');

                        if ($form.get(0)?.checkValidity?.() === false) {
                            alert(core.getMsg(_msgCheckInputField));
                            return false;
                        }

                        const data = core_utils.serializeForm($form);

                        data['userId'] = core.iam['id'];
                        data['workspaceUserId'] =
                            $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id;
                        data['userAgent'] = window.navigator.userAgent;
                        data['uiPath'] = window.location.toString();
                        const ver = $('html').data();
                        data[
                            'data'
                        ] = 'Screen:{width}x{height}x{color}/{vwidth}x{vheight}\nappVersion: {version}\nbuildstamp: {buildstamp}'.format(
                            {
                                width: screen.width,
                                height: screen.height,
                                color: screen.colorDepth,
                                vwidth: window.innerWidth,
                                vheight: window.innerHeight,
                                version: ver.appversion,
                                buildstamp: `${ver.buildstamp}(${new Date(ver.buildstamp * 1)})`
                            }
                        );

                        data['type'] = enmFeedbackTypes.Bug;
                        console.getMessages && (data['logs'] = console.getMessages());

                        __USE_ROLLBAR && Rollbar.debug('BugReport', data);

                        const h = Alertify.log.info(tpls.components.spinner() + core.getMsg(_msgSendingFeedback), 0),
                            r = new $classes[_cAddUserFeedback](data).toString();

                        $.ajax({
                            url: [_CONF_BASEURL, 'feedback/add'].join('/'),
                            data: r,
                            dataType: 'json',
                            type: 'POST',
                            contentType: 'application/json'
                        })
                            .done(() => {
                                Alertify.log.success(core.getMsg(_msgFeedbackSent), 2000);
                                try {
                                    Intercom('showNewMessage', core.getMsg(_msgBugReport, data));
                                } catch (e) {
                                    __USE_ROLLBAR && Rollbar.debug('Intercom::showNewMessage as feedback', { e, data });
                                }
                            })
                            .fail(o => {
                                Alertify.log.error(core.getMsg(_msgFeedbackFail), 2000);
                                __USE_ROLLBAR &&
                                    Rollbar.error('interface::wiFeedback::sendFeedback fail response', {
                                        req: r,
                                        resp: o
                                    });
                            })
                            .always(() => {
                                h.close();
                            });

                        return true;
                    }
                })
            );

            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiLabs, {
                    modal: true,
                    buttons: null,
                    valign: _BOX_VALIGN_TOP34,
                    width: 800,
                    height: window.innerHeight / 1.2,
                    noBodyPadding: true,
                    header: core.getMsg(_msgLabsDialogHeader),
                    onClose() {
                        window.location.reload();
                    }
                })
            );

            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiFeedbackMenu, {
                    items: tpls.iface.feedbackMenu(),
                    menuTitle: core.getMsg(_msgFeedbackMenuTitle),
                    onItemSelectCallback: (ev, ctx, data) => {
                        switch (data['role']) {
                            case _rlSendComment:
                                try {
                                    Intercom('showNewMessage');
                                } catch (e) {
                                    __USE_ROLLBAR && Rollbar.debug('Intercom::showNewMessage', { e });
                                }
                                break;
                            case _rlReportBug:
                                this.widget(_wiFeedback).show();
                                break;
                            case _rlViewMessages:
                                try {
                                    Intercom('showMessages');
                                } catch (e) {
                                    __USE_ROLLBAR && Rollbar.debug('Intercom::showMessages', { e });
                                }
                                break;
                            case _rlLabs:
                                this.widget(_wiLabs).show(
                                    tpls.iface.labs({
                                        url: `${window['appConfig']['config']['billing']}labs?key=${core.sessionId()}`
                                    })
                                );
                                break;
                        }
                    }
                })
            );

            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiNotificationsDialog, {
                    header: core.getMsg(_msgNotificationsDialogHeader),
                    // color: _mdcYellow,
                    content: tpls.components.notificationsDialog(),
                    id: _wiNotificationsDialog,
                    buttons: [_drbClose],
                    afterShow() {
                        const self = this,
                            data = core_DO.notifications().map(d => ({
                                ws: core_DO.workspace(d['workspaceId']),
                                data: d
                            }));

                        drawNotifications(this, data);

                        this.$rendered
                            .on('click', `div.${_clsNavWrapper}`, ev => {
                                const $b = $(ev.target);

                                if ($b.hasClass(_clsDisabled)) {
                                    return false;
                                }

                                switch ($b.data('role')) {
                                    case '-':
                                        drawNotifications(self, data, true);
                                        break;
                                    case '+':
                                        drawNotifications(self, data);
                                        break;
                                }
                            })
                            .addClass('a');
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiShareProject, {
                    modal: true,
                    buttons: null,
                    id: _wiShareProject,
                    valign: _BOX_VALIGN_TOP34,
                    header: core.getMsg(_msgShareProjectHeader),
                    customButtons: tpls.dashboard.shareProjectButtons(),
                    content: tpls.dashboard.shareProject(),
                    beforeShow($el, self) {
                        const obj = {};
                        obj[_bndProjectShareList] = [null];
                        obj[_bndProjectShareCouldAddMore] = 2;
                        self.activeTab = obj[_bndProjectShareActiveTab] = 0;
                        self._binds.set(obj);
                        self.backTab = null;
                        self.changeTab = function(newTab, backTab) {
                            this._binds.set(_bndProjectShareActiveTab, newTab);
                            this.activeTab = backTab == undefined ? newTab : backTab;
                            this.align();
                        };
                    },
                    afterShow($el, self) {
                        // var data = self._binds.get(_bndProjectShareList);
                        $el.on('click', `button.${_clsAddNewShare}`, () => {
                            self.changeTab(1);
                        })
                            .on('click', `button[data-role="${_evButtonClose}"]`, () => {
                                if (self.activeTab == 0) {
                                    self.close();
                                } else {
                                    self.changeTab(self.backTab != null ? self.backTab : self.activeTab - 1);
                                }
                            })
                            .on('click', `button.${_clsCreateProjectShare}`, function() {
                                const $form = $(this)
                                    .parents(`.${_clsDialog}`)
                                    .find('ul li:visible form');

                                if ($form.get(0)?.checkValidity?.() === false) {
                                    return;
                                }

                                const $el = $(this).hide();
                                let data = core_utils.serializeForm($form);
                                const type = data['type'];

                                const $throbber = $(`<div class="${_clsProcessing}"/>`)
                                    .append(tpls.components.spinner())
                                    .insertBefore($form);

                                // addClass([_clsThrobber, _clsButtonReplace].join(' ')).insertBefore(this);

                                delete data['type'];
                                data = Object.values(data)
                                    .map(v => (v && v) || undefined)
                                    .compact();

                                let r;
                                core.moses
                                    .announce(
                                        _rCreateShare,
                                        (r = {
                                            projectId: self.projectId,
                                            type,
                                            contents: data
                                        })
                                    )
                                    .then(obj => {
                                        $throbber.remove();
                                        $el.show();

                                        const tmp = self._binds.get(_bndProjectShareList).data();
                                        tmp.push(obj.created);
                                        self._binds.set(_bndProjectShareList, tmp);
                                        self._binds.set(
                                            _bndProjectShareCouldAddMore,
                                            tmp.length < _gcSharesPerProjectLimit
                                        );
                                        self._binds.set(
                                            _bndProjectShareCode,
                                            tpls.dashboard.ProjectShareCode(obj.created)
                                        );
                                        self._binds.set(_bndProjectShareLink, _CONF_SHARE + obj.created.key);

                                        self.changeTab(2, 1);
                                    })
                                    .catch(o => {
                                        __USE_ROLLBAR &&
                                            Rollbar.error('interface::wiShareProject::rCreateShare fail response', {
                                                req: r,
                                                resp: o
                                            });
                                        alert(`CreateProjectShare error:\n${obj['error']}`);
                                    });
                                return false;
                            })
                            .on('click', `.${_clsProjectSharesList} .${_idSymbolCircleX}`, function() {
                                /** @type {cSharedContentInfo} */
                                const d = d3
                                    .select(
                                        $(this)
                                            .parents('tr')
                                            .get(0)
                                    )
                                    .datum();
                                $(this).addClass(_clsThrobber);
                                let r;
                                core.moses
                                    .announce(_rRemoveShare, (r = { projectId: d.projectId, shareKey: d.key }))
                                    .then(obj => {
                                        const tmp = self._binds.get(_bndProjectShareList).data();
                                        tmp.remove(v => v['key'] == d.key);
                                        self._binds.set(_bndProjectShareList, tmp);
                                        self._binds.set(
                                            _bndProjectShareCouldAddMore,
                                            tmp.length < _gcSharesPerProjectLimit
                                        );
                                    })
                                    .catch(obj => {
                                        alert(`Error due removing project share\n${obj['message']}`);
                                        __USE_ROLLBAR &&
                                            Rollbar.error('interface::wiShareProject::rRemoveShare fail response', {
                                                req: r,
                                                resp: obj
                                            });
                                    });
                                return false;
                            })
                            .on('click', `.${_clsProjectShareListItem}`, function() {
                                const obj = d3.select(this).datum();
                                self._binds.set(_bndProjectShareCode, tpls.dashboard.ProjectShareCode(obj));
                                self._binds.set(_bndProjectShareLink, _CONF_SHARE + obj.key);
                                self.changeTab(2, 1);
                            });
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiRemoveAccountWizard, {
                    modal: true,
                    buttons: null,
                    valign: _BOX_VALIGN_TOP34,
                    header: core.getMsg(_msgRemoveAccount),
                    customButtons: tpls.userProfile.removeAccountButtons(),
                    content: tpls.userProfile.removeAccount(),
                    beforeShow($el, self) {
                        const obj = {};
                        obj[_bndRAErrors_WrongPassword] = false;
                        obj[_bndRAErrors_NoReasonProvided] = false;
                        self.activeTab = obj[_bndRAActiveTab] = 1;
                        self._binds.set(obj);
                        self.backTab = null;
                        self.changeTab = function(newTab, backTab) {
                            this._binds.set(_bndRAActiveTab, newTab);
                            this.activeTab = newTab;
                            this.backTab = backTab;
                            this.align();
                        };
                        self.changeTabBack = function() {
                            this.backTab && this.changeTab(this.backTab);
                        };
                    },
                    afterShow($el, self) {
                        $el.on('click', 'button[data-role]', function() {
                            const role = $(this).data('role');
                            switch (role) {
                                case _evButtonNext:
                                    switch (self.activeTab) {
                                        case 1:
                                            // clsRAActiveTab_Password
                                            const pwd = self._binds.$elem(_bndRAPassword).val();
                                            if (!pwd) {
                                                self._binds.set(_bndRAErrors_WrongPassword, true);
                                                self.align();
                                                return;
                                            } else {
                                                self._binds.set(_bndRAErrors_WrongPassword, false);
                                                self.align();
                                            }

                                            self.changeTab(0, undefined);

                                            core.moses
                                                .announce(_rValidatePassword, { password: pwd })
                                                .then(r => {
                                                    self.__pwd = pwd;
                                                    self.changeTab(2, 1);
                                                })
                                                .catch(r => {
                                                    self._binds.set(_bndRAErrors_WrongPassword, true);
                                                    self._binds.$elem(_bndRAPassword).val('');
                                                    self.changeTab(1);
                                                });
                                            break;
                                        case 2:
                                            // clsRAActiveTab_Reason
                                            const reason = self._binds.$elem(_bndRAReason).val();
                                            self._binds.set(_bndRAErrors_NoReasonProvided, !reason);
                                            reason && self.changeTab(3, 2);
                                            break;
                                        case 3:
                                            // clsRAActiveTab_Confirm
                                            self.changeTab(4, 3);
                                            break;
                                    }
                                    break;
                                case _evButtonBack:
                                    self.changeTabBack();
                                    break;
                                case _evButtonClose:
                                    self.close();
                                    break;
                                case _evRemoveAccountConfirm:
                                    self.changeTab(0);
                                    const data = {};
                                    data['userId'] = core.iam['id'];
                                    data['workspaceUserId'] =
                                        $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id;
                                    data['userAgent'] = window.navigator.userAgent;
                                    data['uiPath'] = window.location.toString();
                                    data['type'] = enmFeedbackTypes.Feedback;
                                    data['description'] = `User deleted account!\nReason: ${self._binds
                                        .$elem(_bndRAReason)
                                        .val()}`;
                                    data['title'] = 'User Deleted Account';

                                    self.__pwd &&
                                        core.moses
                                            .announce(_rAddUserFeedback, data)
                                            .then(() =>
                                                core.moses.announce(_rRemoveUserAccount, {
                                                    password: self.__pwd,
                                                    feedbackMessage: data['description']
                                                })
                                            )
                                            .then(() => {
                                                self.changeTab(5);
                                                setTimeout(() => {
                                                    core.moses.announce(_rLogout);
                                                });
                                            })
                                            .catch(e => {
                                                DEBUG && console.error(e);
                                                __USE_ROLLBAR &&
                                                    Rollbar.debug('Interface::rRemoveUserAccount fail', { error: e });
                                                core.moses.announce(_rLogout);
                                            });
                                    break;
                            }
                            return false;
                        });
                    }
                })
            );

            core.moses.subscribe([
                [
                    _eShowUserProfile,
                    el => {
                        this.widget(_wtUserProfile).show();
                    }
                ],
                [
                    _evRemoveAccountWizard,
                    () => {
                        this.widget(_wiRemoveAccountWizard).show();
                    }
                ],
                [
                    _eSubscribeNow,
                    () => {
                        const w = $scope[_bndCurrentWorkspace],
                            obj = { wsid: w.id };
                        if (w.workspaceLimits && w.workspaceLimits.subscriptionId) {
                            obj.sid = w.workspaceLimits.subscriptionId;
                            obj.manage = false;
                        }
                        core.moses.announce(_evCreateSubscription, obj);
                    }
                ],
                [
                    _eFeedbackModal,
                    () => {
                        core_stat.track(_mpcFeedbackOpened);
                        this.widget(_wiFeedback).show();
                    }
                ],
                [
                    _evNotificationsShowAll,
                    () => {
                        this.widget(_wiNotificationsDialog).show();
                    }
                ],
                [
                    _evShareProject,
                    data => {
                        const wi = this.widget(_wiShareProject);
                        wi.show();
                        let r;
                        core.moses
                            .announce(_rListShares, (r = { projectId: (wi.projectId = data.id) }))
                            .then(obj => {
                                const d = {};
                                d[_bndProjectShareList] = obj.shares;
                                d[_bndProjectShareCouldAddMore] = obj.shares.length < _gcSharesPerProjectLimit;
                                wi._binds.set(d);
                                if (obj.shares.length == 0) {
                                    wi.changeTab(1, 0);
                                }
                                wi.align();
                            })
                            .catch(o => {
                                alert('cant get shares info');
                                __USE_ROLLBAR &&
                                    Rollbar.error('interface::evShareProject::rListShares fail response', {
                                        req: r,
                                        resp: o
                                    });
                                wi.close(_drbClose);
                            });
                    }
                ],
                [
                    _evOpenProjects,
                    () => {
                        core.uriHandler.setUri({ id: _umWorkspace, link: 'projects' });
                    }
                ],
                [
                    _evHideMOTD,
                    () => {
                        core_DO.settings(_skeyMOTDStatus, false);
                        $scope[_bndMOD] = false;
                    }
                ],
                [
                    _evShowMOTD,
                    () => {
                        $scope[_bndMOD] = {
                            description: tpls.components.spinner()
                        };
                        core_DO.settings(_skeyMOTDStatus, true);
                        core.moses.announce(_rGetMessageOfTheDay).then(
                            /* cMessageOfTheDayResponse */ obj => {
                                $scope[_bndMOD] = obj;
                            }
                        );
                    }
                ]
            ]);

            core.uriHandler.registerDefault(uri => {
                DEBUG && console.log('GlobalPageController::init register default uri handler');
                this.handleUri(defPage, uri);
            });

            this._super();
        },
        registerController(pid, instance) {
            if (arguments.length == 1) {
                if (Object.isString(pid)) {
                    instance = $classes.$factory(pid);
                } else {
                    instance = pid;
                }
                pid = instance.id;
            }

            DEBUG && console.log(`globalPageController::registerController(${pid})`);

            list[pid] = instance;

            instance.isDefault && (defController = instance) && (defPage = pid);
            if (instance.um == null) {
                throw new Error('UM is not defined for', pid);
            }
            core.uriHandler.registerHandler(instance.um, (navigate, uri) => {
                this.handleUri(pid, navigate, uri);
            });
            // instance.render();
        },
        tutorial(ctrl, args, readyFn) {
            const tutorialData = core.tutorial.getData();
            const cameFromStories = !!core_DO.settings(_skFromStoriesFlag);

            /** @type {cWorkspaceInfo} */
            const ws = $scope[_bndCurrentWorkspace];

            if (tutorialData.has(_skTutorialEnums_New) && ws && ws.writable == true) {
                /** @type {string} */
                let pid = core_DO.settings(_skTutorialProjectIdKey.replace('%', _skTutorialEnums_TDec15));
                /** @type {number} */
                let mode = 0;
                /** @type {cWorkspaceUserInfo} */
                const wsu = $scope[_bndCurrentWorkspaceUser];
                /** @type {cUserOriginInfo} */
                // const origin = core.iam.origin;

                switch (true) {
                    case wsu && wsu.role == enmWorkspaceUserRole.Owner:
                        mode = _tutOwnerTutorial | _tutCloseTutorialButton;
                        break;
                    case wsu && [enmWorkspaceUserRole.Leader, enmWorkspaceUserRole.Participant].indexOf(wsu.role) > -1:
                        mode = _tutLeaderTutorial | _tutCloseTutorialButton;
                        if (ctrl == _pidProject) {
                            mode |= _tutConfirmVideoShow;
                            pid = args[1];
                        } else {
                            mode |= _tutIntroMessage;
                        }
                        break;
                    case wsu && wsu.role == enmWorkspaceUserRole.Spectator:
                        core.tutorial.removeFlag(_skTutorialEnums_New, true);
                        return;
                }

                if (cameFromStories) {
                    mode |= _tutFromStories;
                    if (ctrl == _pidProject) {
                        pid = args[1];
                    }
                }

                core.tutorial(
                        mode,
                        () =>
                            core.q((rslv, rjct) => {
                                if (cameFromStories) {
                                    const p = pid ? $scope[_bndProjects].$item(pid) : $scope[_bndProjects][0];
                                    core.storage.$set(_skeyTutorialParams, { ctrl, args });
                                    core.uriHandler.setUri({
                                        mode: _upmManagerTL,
                                        id: _umProject,
                                        prId: p.id
                                    });
                                    rslv(p);
                                } else {
                                    const hndl = obj => {
                                        core.storage.$set(_skeyTutorialParams, {ctrl, args})
                                        core.uriHandler.setUri({
                                            mode: _upmManagerTL,
                                            id: _umProject,
                                            prId: obj.id
                                        }, false, true);
                                        pid = obj.id;
                                        core.moses.unsubscribe(_nProjectCreated, hndl);

                                        core_DO.settings(
                                            _skTutorialProjectIdKey.replace('%', _skTutorialEnums_TDec15),
                                            pid
                                        );
                                        rslv($scope[_bndProjects].$item(pid));
                                    };
                                    const workspaceLimitsDeliveryWatch = () => {
                                        core.moses.subscribe(_nProjectCreated, hndl);
                                        core.moses
                                            .announce(_rCreateProject, {
                                                name: core.getMsg(_msgFirstUserProjectName, core.iam),
                                                workspaceId: ws.id
                                            })
                                            .catch(o => {
                                                rjct(o);
                                            });
                                        core.moses.unsubscribe(_nWorkspaceLimitsDelivery, workspaceLimitsDeliveryWatch);
                                    };

                                    if (pid && !$scope[_bndProjects].$item(pid)) {
                                        pid = undefined;
                                    }

                                    // TODO: give user an idea WTF is going om
                                    if (!pid) {
                                        if (ws.workspaceLimits.enabled == false) {
                                            DEBUG && console.info('Setup workspaceLimitsDeliveryWatch');
                                            core.moses.subscribe(
                                                _nWorkspaceLimitsDelivery,
                                                workspaceLimitsDeliveryWatch
                                            );
                                        } else {
                                            workspaceLimitsDeliveryWatch();
                                        }
                                    } else {
                                        hndl.call(null, { id: pid });
                                    }
                                }
                            }),
                        readyFn
                    )
                    .then(() =>
                        core.q(rslv => {
                            core.tutorial.removeFlag(_skTutorialEnums_New);
                            core.tutorial.addFlag(
                                cameFromStories ? _skTutorialEnums_TStories : _skTutorialEnums_TDec15,
                                true
                            );

                            core_stat.track(_mpcOnboardingTutorialFinish);

                            let $getout = $(tpls.iface.getoutPopup()).appendTo('body');
                            const cb = () => {
                                if (!$getout) {
                                    return;
                                }
                                $getout.remove();
                                $getout = null;
                                rslv();
                            };
                            const cb1 = () => {
                                $getout && $getout.on(_gcTransitionEnd, cb).css('opacity', 0);
                                setTimeout(cb, 300);
                            };
                            $getout.css('opacity', 1).on('click', cb1);
                            const $items = $(`.${_clsWSSelButton},ul.${_clsHeadMenu_Container}`).on(
                                'click',
                                function getoutHandler() {
                                    $items.off('click', getoutHandler);
                                    cb1();
                                }
                            );
                        })
                    )
                    .catch(reason => {
                        if (typeof reason == 'object') {
                            if (reason.skipped === true) {
                                core.tutorial.removeFlag(_skTutorialEnums_New);
                                !cameFromStories && core.tutorial.addFlag(_skTutorialBeenSkeeped);
                                core.storage.$set(_skTutorialBeenSkeeped, 1);
                                core.tutorial.saveTutorialData();
                            }
                        }
                    });
            } else {
                readyFn && readyFn();
            }
        },
        handleUri(controller, navigate, uri) {
            const c = list[controller];

            if (c) {
                if (navigate == false && c.uriHandler) {
                    // if handler returns true - handler been rerouted
                    if (c.uriHandler(uri)) {
                        DEBUG && console.info('uriHandler been rerouted');
                        return;
                    }
                }
                if (firstRun) {
                    firstRun = false;
                    this.tutorial(controller, uri, () => {
                        setTimeout(() => {
                            $('#loadWrapper')
                                .css('opacity', 0)
                                .on(_gcTransitionEnd, function() {
                                    $(this).remove();
                                });
                            setTimeout(() => {
                                $('#loadWrapper').remove();
                            }, 300);
                        }, 500);
                    });
                }
                this.activate(controller, uri, navigate);
            } else {
                // TODO: show error message about wrong uri
                __USE_ROLLBAR && Rollbar.error('interface::handleUri wrong url', uri);
            }
        },
        isActive(name) {
            return name && list[name] && list[name].isActive();
        },
        activate(name, args, navigate) {
            DEBUG && console.log(`globalPageController::activate(${name})`);
            if (!name) name = activePage || defPage;

            core.moses.announce(_MOSES_PAGE_CHANGED, name);

            $(`#${_clsHeadMenu}`)
                .find('> div')
                .css({ display: 'none' })
                .removeClass(_clsActive)
                .end()
                .find(`> div.${name}`)
                .css({ display: 'block' })
                .addClass(_clsActive);

            const pageObj = list[name];

            if (pageObj) {
                if (activePage && list[activePage].isActive() && name != activePage) {
                    list[activePage].deactivate();
                    this.$holder.removeClass(activePage);
                }
                pageObj.activate(args, navigate);
                document.title =
                    (pageObj.pageTitle instanceof Function && pageObj.pageTitle()) ||
                    (pageObj.pageTitle && pageObj.pageTitle) ||
                    _gcProjectTitle;
                activePage = name;
                this.$holder && this.$holder.addClass(name);
            }
        },
        deactivate(name) {
            if (!name) {
                name = activePage;
            }
        },
        page(id) {
            return list[id];
        },
        widget(name) {
            var w = this._super(name);

            if (w) return w;

            if (Object.isArray(name)) {
                return list[name[0]].widget(name[1]);
            } else if (/\./.test(name)) {
                const s = name.split('.');
                return list[s[0]].widget(s[1]);
            } else {
                var w;
                for (const i in list) {
                    w = list[i].widget(name);
                    if (w) return w;
                }
            }
        },
        activeHeadButton() {
            return $(`#${_clsHeadMenu} > div.${_clsActive} li.${_clsActive}`).data('event');
        },
        setActiveHeadButton(ev, fireEvent) {
            const $li = $(`#${_clsHeadMenu} > div.${_clsActive}`)
                .find('li')
                .removeClass(_clsActive)
                .end()
                .find(`li[data-event="${ev}"]`)
                .addClass(_clsActive);
            fireEvent && $li.triggerHandler('click');
        },
        renderPage() {
            DEBUG && console.log('GlobalPageController::renderPage()');
            // $('body').html(tpls.iface.body());
            $('#loadWrapper').replaceWith(tpls.iface.body());

            [_wtWsToolBar, _wtUserToolBar].forEach(n => {
                this.widget(n)
                    .render()
                    .update()
                    .$rendered.appendTo($(`#${n}`).empty());
            });

            this.$holder = $(`#${_clsWorkArea}`);

            for (const i in list) {
                DEBUG && console.log('GlobalPageController::renderPage for', i);
                this.$holder.append(list[i].render().$rendered);
            }

            this.widget(_wiUsrMenu)
                .attachTo(
                    $(`#${_wtUserToolBar} span.${_clsTrigger}`).on('click', ev => {
                        core_stat.track(_mpcOpenUserMenu);
                        let e;
                        const hc = ev.target.classList;
                        switch (true) {
                            case hc.contains(_clsUploadBanner) && !!(e = $(ev.target).data('event')):
                                core.moses.announce(e, ev.target);
                                return false;
                            case hc.contains(_clsUserPic):
                                core_stat.track(_mpcClickAvatar);
                                return true;
                        }
                    }),
                    'click'
                )
                .render();
            this.widget(_wiToolbarWsMenu)
                .attachTo(
                    $(`#${_wtWsToolBar} div.${_clsWSSelButton},#${_wtWsToolBar} .${_clsManageBillingWorkspaces}`).on(
                        'click',
                        ev => {
                            let e;
                            const hc = ev.target.classList;
                            switch (true) {
                                case hc.contains(_clsWorkspacePic):
                                    core_stat.track(_mpcClickLogo);
                                    break;
                                case hc.contains(_clsBackButton):
                                    core_stat.track(_mpcClickLogo, { when: 'Back button' });
                                    break;
                                case hc.contains(_clsUploadBanner) && !!(e = $(ev.target).data('event')):
                                    core.moses.announce(e, ev.target);
                                    return false;
                                default:
                                    core_stat.track(_mpcWorkspaceButton);
                                    break;
                            }
                            return true;
                        }
                    ),
                    'click'
                )
                .render();
            this.widget(_wiHelpMenu)
                .attachTo(
                    $(`#${_wtUserToolBar} .${_idSymbolToolbarHelp}`).on('click', () => {
                        core_stat.track(_mpcHelpButtonClicked);
                        return true;
                    }),
                    'click'
                )
                .render();
            this.widget(_wiNotifications)
                .attachTo(
                    $(`#${_wtUserToolBar} .${_clsNotificationWidget}`).on('click', () => {
                        core_stat.track(_mpcOpenNotifications, { where: 'Main menu' });
                        return true;
                    }),
                    'click'
                )
                .render();
            this.widget(_wiFeedbackMenu)
                .attachTo($('.feedback-link'))
                .render();
            this.widget(_wiFeedback).render();
            this.widget(_wiShareProject).render();

            $('body').on('click', `.feedback-link,.${_clsFeedback},.${_clsCreateProject}`, ev => {
                const $el = $(ev.target);
                switch (true) {
                    case $el.hasClass('feedback-link'):
                        core_stat.track(_mpcFeedbackButton, { where: 'Main menu' });
                        break;
                    case $el.hasClass(_clsFeedback):
                        core_stat.track(_mpcGettingStarted, { element: 'feedback button' });
                        break;
                }
                return true;
            });

            this.ctrl = new Vue({
                el: $('body').get(0),
                data: $scope,
                inherit: true,
                methods: $methods,
                filters: $filters,
                computed: (() => {
                    const c = {};

                    c[_bndIsTrial] = {
                        get() {
                            /** @type {cWorkspaceInfo} */
                            const ws = $scope[_bndCurrentWorkspace];
                            /** @type {cWorkspaceLimitsInfo} */
                            const wsl = ws && ws.workspaceLimits;
                            // return wsl && wsl.inTrial && wsl.trialEnds && moment(wsl.trialEnds).subtract(5, 'd').isBefore() && !wsl.cardPresent || false;
                            return (
                                (ws &&
                                    wsl &&
                                    ((wsl.inTrial && ((wsl.trialEnds && Date.now() < wsl.trialEnds) || true)) ||
                                        !ws.writable)) ||
                                false
                            );
                        }
                    };
                    c[_bndTrialEndsSoon] = () => {
                        /** @type {cWorkspaceInfo} */
                        const ws = $scope[_bndCurrentWorkspace];
                        /** @type {cWorkspaceLimitsInfo} */
                        const wsl = ws && ws.workspaceLimits;
                        const m = wsl.trialEnds && moment(wsl.trialEnds);
                        return wsl && wsl.inTrial && wsl.trialEnds && m.subtract(5, 'd').isBefore() && m.isAfter();
                    };
                    c[_bnd2DaysFromTrialStarts] = {
                        get() {
                            if (c[_bndIsTrial]) {
                                /** @type {cWorkspaceInfo} */
                                const ws = $scope[_bndCurrentWorkspace];
                                return !!(
                                    ws &&
                                    ws.createdAt &&
                                    moment(ws.createdAt)
                                        .add(2, 'd')
                                        .isBefore(new Date())
                                );
                            }
                            return false;
                        }
                    };
                    c[_bndIsOwner] = {
                        get() {
                            /** @type {cWorkspaceInfo} */
                            const ws = $scope[_bndCurrentWorkspace];
                            return (ws && ws.workspaceOwner.id == ws.workspaceUser.id) || false;
                        }
                    };
                    c[_bndIsWorkspaceWritable] = {
                        get() {
                            /** @type {cWorkspaceInfo} */
                            const ws = $scope[_bndCurrentWorkspace];
                            return (ws && ws.writable) || false;
                        }
                    };

                    return c;
                })()
            });

            // noBounceScroll();

            // this.widget(_wiUserEmailsAction).render();
        }
    };
});

core.pages = $classes.$factory(_pcGlobalPageController);
