/**
 * default bidings of general events for Moses
 * @author Jacky Shikerya
 */

core.moses.subscribe(
    [_cLoginResponse, _MOSES_NO_SESSION],
    /* cLoginResponse */ obj => {
        DEBUG && console.log('login processing', obj);

        if (obj && obj.status == enmLoginStatus.OK) {
            core.moses.announce(_MOSES_LOGIN);
            __USEAJAX && core.channel.start();
            __USE_ROLLBAR && Rollbar.configure({ payload: { sessionId: core.sessionId() } });
        } else {
            __USE_ROLLBAR && Rollbar.debug('User reauth required');
            core.sessionWatchDog.suspend();
            try {
                core.storage.$set('url2return', window.location.href);
            } catch (e) {}
            window.location.href = _CONF_ROOTURL;
        }
    }
);

core.moses.subscribe(
    _cErrorMessage,
    /* cErrorMessage */ obj => {
        if (obj.error == enmErrorMessage.NoSuchSession) {
            resetSession();
        }
    }
);

function resetSession() {
    try {
        __USE_INTERCOM && Intercom('shutdown');
    } catch (e) {
        __USE_ROLLBAR && Rollbar.debug('Intercom::shutdown fail', e);
    }
    core.DM.killUserSession();
    core_DO.cleanup();
    core.moses.announce(_eDataReloadRequired);
}

let ignoreFetchState = true;

core.moses.subscribe(_nSessionLost, () => {
    __USE_ROLLBAR && Rollbar.debug('User Session lost');
    resetSession();
});
core.moses.subscribe(_nFetchState, () => {
    if (ignoreFetchState === false) {
        __USE_ROLLBAR && Rollbar.debug('User Session reset requested');
        resetSession();
    } else {
        DEBUG && console.info('ignoring FetchState for now');
    }
});

core.moses.watch([_MOSES_LOGIN, _MOSES_CORE_INIT, _MOSES_CORE_START], _gcCoreReadyWatcherID, _MOSES_CORE_READY);

/** @type {?Object<string,any>} */
let IntercomBootData = [];

core.moses.watch([_MOSES_LOGIN, _MOSES_CORE_READY, _nChannelOpened], _gcCoreDataReadyWatcherID, () => {
    Promise.all([
        core.moses.announce(_rUserInfo),
        core.moses.announce(_rListWorkspaces),
        core.moses.announce(_rListUserProperties)
    ]).then(([uinfo, wss, ups]) => {
        /** @type {cUserInfo} */
        $scope[_bndIAM] = core.iam = $classes.$check(_cUserInfo, uinfo.user);
        core.iam.timeUserCreated = uinfo.timeCreated;
        core.iam.dailyDigest = uinfo.dailyDigest;
        core.iam.intercomHash = uinfo.intercomHash;
        core.iam.ownedWorkspaces = uinfo.ownedWorkspaces;
        core.iam.origin = uinfo.originInfo;
        imgs.userImage.add(core.iam.id, core.iam.imageId, undefined, [core.iam.firstName, core.iam.lastName]);
        core_DO.$workspaces(wss.workspaces);
        core_DO.settings(null, ups.properties);
        let vs = parseInt(core_DO.settings(_skeyProjectsAccessVersion), 10) || 0;
        if (vs < 3) {
            const o = core_DO.settings();
            let d = o[_skeyProjectsAccess] || {};
            typeof d == 'string' && (d = core_utils.decData(d, false));
            Object.keys(d).forEach(id => {
                o[`${_skeyProjectIdPrefix}:${id}`] = (~~((d[id] * 1) / 1000) - _gcProjectTimeOffset).toString(36);
            });
            delete o[_skeyProjectsAccess];
            core_DO.settings(_skeyProjectsAccess, null);
            o[_skeyProjectsAccessVersion] = vs = 3;
            core_DO.settings(null, o);
            core.moses.announce(_rSetUserProperties, { properties: o });
            __USE_ROLLBAR && Rollbar.debug('upgrade settings to version 3');
        }
        if (vs == 3) {
            core_DO.settings(_skeyProjectsAccess, null);
            core_DO.settings(_skeyProjectsAccessVersion, (vs = 4));
        }
        if (vs == 4) {
            const d = core_DO.settings();
            const r = new RegExp(`^${_skeyProjectIdPrefix}:`);
            const min = new Date('2011-01-01').getTime();
            const now = Date.now();
            const o = Object.keys(d).reduce(
                /**
                 * @param {Object.<string, string>} acc
                 * @param {string} id
                 */
                (acc, id) => {
                    if (r.test(id) == true) {
                        const n = parseInt(d[id], 36); // + 1546293600;
                        let v;
                        switch (true) {
                            case n < min:
                                v = n + _gcProjectTimeOffset * 1000;
                                break;
                            default:
                                v = n * 1000;
                                break;
                        }
                        if (v > now) {
                            v = 0;
                        }
                        acc[id] = core_utils.packLTA(v);
                    } else {
                        acc[id] = d[id];
                    }
                    return acc;
                },
                {}
            );
            core_DO.settings(null, o);
            core.moses.announce(_rSetUserProperties, { properties: o });
            core_DO.settings(_skeyProjectsAccessVersion, (vs = 4));
            __USE_ROLLBAR && Rollbar.debug('upgrade settings to version 5');
        }

        let iobj;
        if (core.storage.$get('stats') != 'off') {
            if (__USE_MIXPANEL) {
                window.mixpanel.identify(core.iam.id);
                window.mixpanel.people.set({
                    firstName: core.iam.firstName,
                    lastName: core.iam.lastName,
                    realm: window.location.hostname.toLowerCase(),
                    name: `${core.iam.firstName} ${core.iam.lastName}`
                });
                window.mixpanel.name_tag(`${core.iam.firstName} ${core.iam.lastName}`);
            }

            const email = core_DO.userPrimaryEmail();
            DEBUG && console.assert(email, 'User primary contact lookup failed');

            const obj = {
                app_id: window.appConfig.config.intercomid,
                email,
                name: `${core.iam.firstName} ${core.iam.lastName}`,
                user_id: core.iam.id,
                lastSessionKey: core.sessionId(),
                dailyDigest: core.iam.dailyDigest,
                ownedWorkspaces: core.iam.ownedWorkspaces,
                /** @type {*} */
                created_at: undefined,
                user_hash: undefined,
                userOrigin: undefined,
                invitedToWorkspace: undefined,
                invitedByUser: undefined
            };

            core.iam.timeUserCreated && (obj.created_at = core.iam.timeUserCreated / 1000);
            core.iam.intercomHash && (obj.user_hash = core.iam.intercomHash);

            iobj = { ...obj };

            if (uinfo.userOrigin) {
                iobj.userOrigin = uinfo.userOrigin.source;
                uinfo.userOrigin.invitedToWorkspaceId &&
                    (iobj.invitedToWorkspace = uinfo.userOrigin.invitedToWorkspaceId);
                uinfo.userOrigin.invitedByUserId && (iobj.invitedByUser = uinfo.userOrigin.invitedByUserId);
            }
        } else {
            DEBUG && console.info('Statistics been switched off');
            core.skip_stats = true;
        }

        core_stat.track(_mpcUILoaded);
        ignoreFetchState = false;

        __USE_ROLLBAR &&
            Rollbar.configure({
                payload: {
                    user: {
                        email: core_DO.userPrimaryEmail(),
                        id: core.iam.id
                    },
                    person: {
                        email: core_DO.userPrimaryEmail(),
                        id: core.iam.id
                    }
                }
            });

        IntercomBootData?.push(iobj);

        document.addEventListener('click', sendInteractionEvent, true);
        core.moses.announce(_MOSES_DATA_READY);
    });
});

var sendInteractionEvent = function sendInteractionEvent() {
    core_stat.track(_mpcUIinteracted);
    document.removeEventListener('click', sendInteractionEvent, true);
};

core.moses.watch([_MOSES_DATA_READY, _MOSES_IFACE_READY], () => {
    if (__USE_INTERCOM) {
        DEBUG && console.info('Request intercom widget...');
        core_utils
            .loadScript(`//widget.intercom.io/widget/${appConfig.config.intercomid}`)
            .then(() => {
                DEBUG && console.info('Intercom widget loaded, initializing...');
                const o = IntercomBootData?.shift();
                try {
                    DEBUG && console.info('Intercom::boot', o);
                    Intercom('boot', o);
                } catch (e) {
                    DEBUG && console.error('Intercom::boot failed', e);
                    __USE_ROLLBAR && Rollbar.debug('Intercom::boot failed', { e, obj: o });
                }
                if (IntercomBootData?.length) {
                    IntercomBootData?.forEach(
                        /** @param {*} o */ o => {
                            try {
                                DEBUG && console.log('Intercom::update/queue', o);
                                Intercom('update', o);
                            } catch (e) {
                                DEBUG && console.error('Intercom::update failed', e);
                                __USE_ROLLBAR && Rollbar.debug('Intercom::update/queue failed', { e, obj: o });
                            }
                        }
                    );
                    IntercomBootData = null;
                }
            })
            .catch(e => {
                const et = 'Intercom script failed to load';
                DEBUG && console.error(et, e);
                __USE_ROLLBAR && Rollbar.warning(et, e);
            });
    }
});

core.moses.subscribe(_eDataReloadRequired, () => {
    // alert(core.getMsg(_msgPageReloadRequired));
    window.location.reload();
});

core.moses.subscribe(_nDebugLogsRequest, () => {
    const data = {};
    data.userId = core.iam.id;
    data.workspaceUserId = $scope[_bndCurrentWorkspaceUser] && $scope[_bndCurrentWorkspaceUser].id;
    data.userAgent = window.navigator.userAgent;
    data.uiPath = window.location.toString();
    const ver = $('html').data();
    data[
        'data'
    ] = 'Screen:{width}x{height}x{color}/{vwidth}x{vheight}\nappVersion: {version}\nbuildstamp: {buildstamp}'.format({
        width: screen.width,
        height: screen.height,
        color: screen.colorDepth,
        vwidth: window.innerWidth,
        vheight: window.innerHeight,
        version: ver.appversion,
        buildstamp: `${ver.buildstamp}(${new Date(ver.buildstamp * 1)})`
    });

    data['type'] = enmFeedbackTypes.Bug;
    console.getMessages && (data['logs'] = console.getMessages());

    data['title'] = 'DEBUG AUTOSEND LOGS';
    core.moses.announce(_rAddUserFeedback, data);
});

// var warnDialogShown = false;
const verCheckTimer = !__DEV__
    ? setInterval(() => {
          $.ajax([location.origin + _CONF_APPROOTURL].join('/'))
              .done(s => {
                  const src = $('html').data();
                  __DEV__ && /^\d+#/.test(src.buildstamp) == false && (src.buildstamp = null);
                  const [, version] = s.match(/<html.*data\-appversion=\"([\w]+)\"/);
                  const [, buildstamp] = s.match(/<html.*data\-buildstamp=\"([\d]+)\"/);
                  console.log('version check', { version, buildstamp });
                  if (version != src.appversion || buildstamp != src.buildstamp) {
                      clearInterval(verCheckTimer);
                      Alertify.log.warn(tpls.iface.reloadMessage(), 0);
                  }
              })
              .fail(e => {
                  DEBUG && console.warn('version check fail', e);
              });
      }, VERSION_CHECK_INTERVAL)
    : null;
// core.moses.watch([_cUserInfoResponse, _cUserPropertiesResponse, _cListOfWorkspacesResponse], function () {
// });
