'use strict';

var closeWindowSubscribers = new $classes.Observer(false);

window.addEventListener('beforeunload', function(e) {
    closeWindowSubscribers.publish();
    if (!core.DM.isPoolEmpty()) {
        var confirmationMessage = core.getMsg(_msgCloseWindowConfirm);

        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
    }
});
