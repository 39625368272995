/**
 * @class wtUserProfile
 * @extends AbstractWidget
 */
// @ts-ignore
new $classes.Class(
    _wtUserProfile,
    _wtModalDialog,
    /** @lend wtUserProfile */
    {
        pane: _acctabProfile,
        /** @this wtUserProfile */
        init: function() {
            this.options.header = core.getMsg(_msgUserAccountDialogHeader);
            this.options.buttons = [_drbClose, _drbSave, _drbSoftCancel];
            this.options.autoScroll = false;
            this._super();

            this.changeWatch = function() {
                this.pane == _acctabEmails && this.changePane(this.pane);
            }.bind(this);
        },
        generateListen: function(r) {
            r[_nUserInfoUpdated] = function(obj) {
                if (core.iam && core.iam['id'] == obj['id']) {
                    obj['language'] && (obj['language'] = obj['language'].match(/^(\w{2})/)[1]);
                    this._binds.set(obj);
                    this.changePane();
                }
            };
            r[_evUserAccountTabChange] = function(tab) {
                if (this.$rendered.find('.' + _clsDialog).hasClass(_clsShowButtons)) {
                    this.$rendered.find('button[data-role="' + _drbSoftCancel + '"]').trigger('click');
                }
                this.changePane(tab);
            };
            r[_evChangeUserAvatar] = function() {
                core.avatarUploader(_avSNUserPics, core.iam.id);
            };
            if (__DEV__) {
                r[_evResetTutorialFlag] = function() {
                    core_DO.settings(_skTutorialKey, 'new').then(function() {
                        if (confirm('reload?')) {
                            window.location.reload();
                        }
                    });
                };
                r[_evResetTutorialFlagAndTutPid] = function() {
                    core_DO.settings(_skTutorialProjectIdKey.replace('%', _skTutorialEnums_TDec15), null);
                    core_DO.settings(_skTutorialKey, 'new');
                    core_DO.settings(_gcSelectedWorkspace, null).then(function() {
                        window.location = '/';
                    });
                };
            }
        },
        changePane: function(id) {
            if (id) {
                this.$rendered
                    .find('.' + _clsDialog)
                    .removeClass(_clsShowButtons)
                    .find('li.' + _clsTab)
                    .removeClass(_clsActive)
                    .end()
                    .find('li.' + id)
                    .addClass(_clsActive);
                this.pane = id;
            } else {
                id = this.pane;
            }

            switch (id) {
                // case _acctabEmailNotify:
                //     this.$rendered.addClass(_clsShowButtons);
                //     break;
                case _acctabEmails:
                    // cemails = emails.filter(function (v) {
                    //     return v.status == enmContactInfoInfoStatus.Confirmed;
                    // });

                    this._binds.set(_bndEmailsList, core_exp[_ienUserEmails](false, true));
                    // this._binds.set(_bndEmailsListFiltered, emails.filter(function (i) { return i['status'] == enmContactInfoInfoStatus.Confirmed}));
                    // this._binds.set(_bndContactsList, core_DO.$workspaces()
                    //     /** @param {cWorkspaceInfo} v */
                    //     .map(function (v) {
                    //         return {
                    //             id: v.workspaceUser.id,
                    //             firstName: v.workspaceUser.firstName,
                    //             lastName: v.workspaceUser.lastName,
                    //             wsName: v.name,
                    //             def: v.workspaceUser.contactInfoId,
                    //             emails: cemails
                    //         }
                    //     }));
                    break;
            }

            // this.align();
        },
        disableTabs: function(mode) {
            var $tabs = this.$rendered.find('.' + _clsHeadMenu_Container + ' .' + _clsToggle);
            if (mode) {
                $tabs.addClass(_clsDisabled);
            } else {
                $tabs.removeClass(_clsDisabled);
            }
        },
        show: function() {
            var self = this,
                defValues;

            core_stat.track(_mpcMyAccountVisited);

            this._super(tpls.userProfile.form({ events: enmAlertEvents.values() }), {
                modal: true,
                beforeShow: function() {
                    core.moses.announce(_rGetUserAlertsSettings).then(function(data) {
                        self._binds
                            .$elem('uns')
                            .find('input[type="checkbox"]')
                            .attr('checked', false);

                        var d = data['settings'];
                        d['all'] = rset(d['all']);
                        d['email'] = data['email'];

                        defValues = data['defaults'];
                        defValues['all'] = rset(defValues['all']);
                        self._binds.set(d);
                    });
                },
                afterShow: function($el, dialog) {
                    core.iam['language'] && (core.iam['language'] = core.iam['language'].match(/^(\w{2})/)[1]);
                    var o = new $classes[_cUserInfo](core.iam);
                    // o[_bndFOWSetup] = !!(core_DO.settings(_gcFOWKeyStore) * 1);
                    this._binds.set(o);

                    core_DO.onUserInfoUpdate.subscribe(this.changeWatch);
                    $('#' + _clsAddNewEmail)
                        .off('keydown')
                        .on('keydown', function(ev) {
                            ev.stopPropagation();
                        });

                    $el.on('click', 'button.' + _clsChangePasswordButton, function() {
                        $el.find('form[data-role="' + _acctabProfile + '"]').css('display', 'none');
                        $el.find('form[data-role="' + _acctabPassword + '"]').css('display', 'block');
                        self.align();
                        self.$rendered.find('.' + _clsDialog).addClass(_clsShowButtons);
                        self.disableTabs(true);
                    })
                        .on('click', 'button.' + _clsLogoutOtherSessions, function() {
                            if (window.confirm(core.getMsg(_msgLogoutOtherSessionConfirmation))) {
                                core.moses
                                    .announce(_rInvalidateSessions, { keepCurrentSession: true })
                                    .then(function(/* cLogoutResponse */ o) {
                                        Alertify.log.success(core.getMsg(_msgLogoutOtherSessionResult));
                                    })
                                    .catch(function(/* cErrorMessage */ o) {
                                        Alertify.log.error(core.getMsg(_msgLogoutOtherSessionFail, o));
                                        DEBUG &&
                                            console.error('wtUserProfile::clsLogoutOtherSessions fail response', {
                                                resp: o
                                            });
                                        __USE_ROLLBAR &&
                                            Rollbar.error('wtUserProfile::clsLogoutOtherSessions fail response', {
                                                resp: o
                                            });
                                    });
                            }
                        })
                        .on('click', '.' + _clsCommand + ', span[data-role]', function() {
                            var data = d3
                                .select(
                                    $(this)
                                        .parents('tr.' + _clsEmailItem)
                                        .get(0)
                                )
                                .datum();
                            var /** @const */ classes = [_clsIcon, _clsPending, _clsMakePrimary, _clsTrashcan].join(
                                    ' '
                                );
                            var $el = $(this.parentNode.parentNode);

                            if ($(this).hasClass(_clsPrimary)) {
                                return false;
                            }

                            if (/svg/i.test(this.nodeName)) {
                                var oldClasses = Array.prototype.slice.call(this.classList),
                                    $icon = $(this)
                                        .removeClass(classes)
                                        .addClass(_clsThrobber),
                                    r = new RegExp(oldClasses.join('|'));
                            } else if (/span/i.test(this.nodeName)) {
                                var $icon = $(),
                                    r = new RegExp($(this).data('role')),
                                    oldClasses = '';
                            }

                            var rq;
                            switch (true) {
                                case r.test(_cnstResendConfirmation): //r.test(_clsPending):
                                    core.moses
                                        .announce(_rResendEmailVerification, (rq = { email: data.email }))
                                        .then(function(r) {
                                            // $icon.removeClass(_clsThrobber).addClass(oldClasses);
                                            Alertify.log.success(
                                                core.getMsg(_msgConfirmationEmailResend, { email: data.email }),
                                                _gcAlertifyTimeout
                                            );
                                        })
                                        .catch(function(o) {
                                            $icon.removeClass(_clsThrobber).addClass(oldClasses.join(' '));
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    Alertify.log.error(
                                                        core.getMsg(_msgConfirmationError, o),
                                                        _gcAlertifyTimeout
                                                    );
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'wtUserProfile::rResendEmailVerification fail response',
                                                            { req: rq, resp: o }
                                                        );
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        });
                                    break;
                                case r.test(_clsMakePrimary):
                                    core.moses
                                        .announce(_rSetPrimaryContactInfo, (rq = { contactInfoId: data.id }))
                                        .then(function(r) {
                                            // $icon.removeClass(_clsThrobber).addClass(oldClasses);
                                            Alertify.log.success(
                                                core.getMsg(_msgContactSetAsPrimary, { email: data.email }),
                                                _gcAlertifyTimeout
                                            );
                                        })
                                        .catch(function(o) {
                                            $icon.removeClass(_clsThrobber).addClass(oldClasses.join(' '));
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    Alertify.log.error(
                                                        core.getMsg(_msgContactSetAsPrimaryError, o),
                                                        _gcAlertifyTimeout
                                                    );
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'wtUserProfile::rSetPrimaryContactInfo fail response',
                                                            { req: rq, resp: o }
                                                        );
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        });
                                    break;
                                case r.test(_clsTrashcan):
                                    core.moses
                                        .announce(
                                            _rRemoveContactInfo,
                                            (rq = { contactInfoId: data.id, reassignToPrimary: true })
                                        )
                                        .then(function(r) {
                                            // $icon.removeClass(_clsThrobber).addClass(oldClasses);
                                            Alertify.log.success(
                                                core.getMsg(_msgContactRemoved, { email: data.email }),
                                                _gcAlertifyTimeout
                                            );
                                            $el.remove();
                                        })
                                        .catch(function(o) {
                                            $icon.removeClass(_clsThrobber).addClass(oldClasses.join(' '));
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    Alertify.log.error(
                                                        core.getMsg(_msgRemoveContactError, o),
                                                        _gcAlertifyTimeout
                                                    );
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'wtUserProfile::rRemoveContactInfo fail response',
                                                            {
                                                                req: rq,
                                                                resp: o
                                                            }
                                                        );
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        });
                                    break;
                            }

                            return false;
                        })
                        // .on('selectBoxChange', '.' + _clsDefEmailForAccountList + ' tr', function (ev) {
                        //     var $el = $(ev.target).siblings(),
                        //         d = d3.select(this).datum(),
                        //         $icon = $('<div />').addClass(_clsThrobber),
                        //         $id = ev.value,
                        //         email = d.emails.find(function (e) { return e.id == $id }).email;
                        //
                        //     $el.parent().after($icon).hide();
                        //
                        //     // core_DO.updateUser({
                        //     //     'id': d.id,
                        //     //     'contactInfoId': $el.val()
                        //     // });
                        //
                        //     var rq;
                        //     core.moses.announce(_rAssignContactInfoToWorkspaceUser, rq = {
                        //         'contactInfoId': $id,
                        //         'workspaceUserId': d.id
                        //     }).catch(function (o) {
                        //         $icon.remove();
                        //         $el.parent().show();
                        //         var old = d.emails.find(function (v) { return v.isDefault; });
                        //         old && $el.find('select').val(old.id);
                        //         switch (o.error) {
                        //             case enmErrorMessage.AccessDenied:
                        //                 alert(core.getMsg(_msgAccessDenied));
                        //                 break;
                        //             default:
                        //                 __USE_ROLLBAR && Rollbar.error('wtUserProfile::rAssignContactInfoToWorkspaceUser fail response', {'req': rq, 'resp': o});
                        //                 alert(core.getMsg(_msgUknownError, o));
                        //                 break;
                        //         }
                        //     })
                        // })
                        .on('click', 'button.' + _clsEdit, function() {
                            self._binds.set(_bndWorkspacesList, core_DO.$workspaces());
                            $(this)
                                .parents('form')
                                .addClass(_clsEdit);
                            self.align();
                            self.$rendered.find('.' + _clsDialog).addClass(_clsShowButtons);
                            self.disableTabs(true);
                        })
                        .on('click', 'button.' + _clsAddNewEmail, function() {
                            if (
                                $(this)
                                    .parent()
                                    .get(0)
                                    .checkValidity?.()
                            ) {
                                var $input = $(this)
                                        .parent()
                                        .find('#' + _clsAddNewEmail),
                                    email = $input.val();

                                $input.attr('disabled', true).val('');
                                var rq;
                                core.moses
                                    .announce(_rAddNewEmail, (rq = { email: email }))
                                    /** @param {cAddNewEmailResponse} r */
                                    .then(function(r) {
                                        $input.removeAttr('disabled');
                                        switch (r.status) {
                                            case enmAddNewEmailResponseStatus.EmailAdded:
                                                self.changePane(_acctabEmails);
                                                break;
                                            case enmAddNewEmailResponseStatus.EmailVerificationSent:
                                                Alertify.log.success(
                                                    core.getMsg(_msgEmailVerificationSent, { email: email })
                                                );
                                                break;
                                            case enmAddNewEmailResponseStatus.EmailAlreadyInSystem:
                                                alert(core.getMsg(_msgEmailAlreadyInSystem));
                                                break;
                                            case enmAddNewEmailResponseStatus.EmailVerificationPending:
                                                alert(core.getMsg(_msgEmailVerificationPending));
                                                break;
                                        }
                                    })
                                    .catch(function(o) {
                                        DEBUG && console.error(o);
                                        $input.removeAttr('disabled');
                                        switch (o.error) {
                                            case enmErrorMessage.AccessDenied:
                                                alert(core.getMsg(_msgAccessDenied));
                                                break;
                                            default:
                                                __USE_ROLLBAR &&
                                                    Rollbar.error('wtUserProfile::rAddNewEmail fail response', {
                                                        req: rq,
                                                        resp: o
                                                    });
                                                alert(core.getMsg(_msgUknownError, o));
                                                break;
                                        }
                                    });
                            }
                        })
                        .on('change', 'input#' + _clsSelectAll, function() {
                            var v = $(this).is(':checked');
                            $(this)
                                .parents('table')
                                .find('.' + _clsWSUserInfoItem + ' input[type="checkbox"]')
                                .prop('checked', v);
                        })
                        .on('change', '.' + _acctabEmailNotify + ' input', function() {
                            self.$rendered.find('.' + _clsDialog).addClass(_clsShowButtons);
                        })
                        .on('change', 'input.email', function(ev) {
                            $(this)
                                .siblings('input.online')
                                .get(0).checked = this.checked;
                        });
                    // .on('change', '.' + _bndFOWSetup, function () {
                    //     core_DO.settings(_gcFOWKeyStore, $(this).is(':checked') ? 1 : 0);
                    // });
                },
                onClose: function(role, $el) {
                    this.disableTabs(false);

                    const $form = $el.find('.' + _clsTab + '.' + _clsActive + ' form:visible');
                    const gd = $form.serializeArray();
                    const data = core_utils.serialized2Object(gd);
                    const frole = $form.data('role');
                    let processed = false;

                    switch (frole) {
                        case _acctabProfile:
                            switch (role) {
                                case _drbSave:
                                    var ids = gd
                                            .map(function(v) {
                                                if (v.name == 'id') return v.value;
                                            })
                                            .compact(),
                                        pageHaveToBeReloaded = false;

                                    data.updateWorkspaceUsers = ids;
                                    data.userId = core.iam.id;
                                    if (core.iam['language'] != data['language']) {
                                        $.cookie('lang', data['language']);
                                        pageHaveToBeReloaded = true;
                                    }

                                    core.moses
                                        .announce(_rUpdateUserInfo, data)
                                        .then(function() {
                                            if (pageHaveToBeReloaded) {
                                                window.location.reload();
                                            }
                                        })
                                        .catch(function(o) {
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    alert(core.getMsg(_msgAccessDenied));
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error('wtUserProfile::rUpdateUserInfo fail response', {
                                                            req: data,
                                                            resp: o
                                                        });
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        });
                                    $form.removeClass(_clsEdit);
                                    processed = true;
                                    break;
                                case _drbSoftCancel:
                                    $form.removeClass(_clsEdit)[0].reset();
                                    processed = true;
                                    break;
                            }
                            break;
                        case _acctabPassword:
                            if (role == _drbSave) {
                                core.moses
                                    .announce(_rChangePassword, {
                                        oldPassword: data['oldPassword'],
                                        newPassword: data['newPassword']
                                    })
                                    .then(function(o) {
                                        if (o['error'] == 'OK') {
                                            Alertify.log.success(core.getMsg(_msgPasswordChanged));
                                        } else {
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    alert(core.getMsg(_msgAccessDenied));
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.debug('wtUserProfile::rChangePassword error message', {
                                                            resp: o
                                                        });
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        }
                                    })
                                    .catch(function(o) {
                                        switch (o['error']) {
                                            case enmErrorMessage.AccessDenied:
                                                alert(core.getMsg(_msgPasswordChangeError));
                                                break;
                                            default:
                                                __USE_ROLLBAR &&
                                                    Rollbar.error('wtUserProfile::rChangePassword fail response', {
                                                        resp: o
                                                    });
                                                alert(core.getMsg(_msgUknownError, o));
                                                break;
                                        }
                                    });
                            }
                            if ([_drbSoftCancel, _drbSave].indexOf(role) > -1) {
                                $el.find('form[data-role="' + _acctabPassword + '"]')
                                    .css('display', 'none')
                                    .find('input')
                                    .val('');
                                $el.find('form[data-role="' + _acctabProfile + '"]').css('display', 'block');
                                processed = true;
                            }
                            break;
                        case _acctabEmailNotify:
                            switch (role) {
                                case _drbReset:
                                case _drbSoftCancel:
                                    self._binds
                                        .$elem('uns')
                                        .find('input[type="checkbox"]')
                                        .attr('checked', false);
                                    self._binds.set(defValues);
                                    processed = true;
                                    break;
                                case _drbSave:
                                    var pd = {},
                                        all = [];

                                    gd.forEach(function(v) {
                                        !pd[v['name']] && (pd[v['name']] = []);
                                        pd[v['name']].push(v['value']);
                                    });

                                    delete pd['sendDigest'];
                                    delete pd['email'];

                                    $.each(pd, function(i, v) {
                                        all.push({
                                            name: i,
                                            options: v
                                        });
                                    });

                                    var rq;
                                    core.moses
                                        .announce(
                                            _rUpdateUserAlertsSettings,
                                            (rq = {
                                                mailId: data['email'],
                                                settings: {
                                                    sendDigest: !!data['sendDigest'],
                                                    all: all
                                                }
                                            })
                                        )
                                        .catch(function(o) {
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    alert(core.getMsg(_msgAccessDenied));
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'wtUserProfile::rUpdateUserAlertsSettings fail response',
                                                            { req: rq, resp: o }
                                                        );
                                                    alert(core.getMsg(_msgUknownError, o));
                                                    break;
                                            }
                                        });
                                    break;
                            }
                            break;
                    }

                    if (processed) {
                        this.align();
                        this.$rendered.find('.' + _clsDialog).removeClass(_clsShowButtons);
                        return false;
                    }

                    core_DO.onUserInfoUpdate.unsubscribe(this.changeWatch);
                }
            });
        }
    }
);

function rset(data) {
    var d = {};
    data.forEach(function(v) {
        !d[v['name']] && (d[v['name']] = {});
        v['options'].forEach(function(o) {
            d[v['name']][o] = true;
        });
    });

    return d;
}
