if (!__EMBED) {
    core_utils.intExport(_ienWorkspaceLink, id => core.uriHandler.mkLink({
        id: _umWorkspace,
        wsId: id,
        link: dashboardLastViewMode,
        archived: $scope[_bndProjectListViewMode] == enmProjectStatus.Archived
    }));

    core_utils.intExport(_ienTaskPath, (pid, gid, id) => {
        const obj = { id: _umProject };
        obj['prId'] = pid;
        obj['mode'] = _upmManagerTL;
        obj['gid'] = gid;
        obj['tid'] = id;
        return core.uriHandler.mkLink(obj);
    });

    core_utils.intExport(_ienUpdateAutoexpand, (el, v) => {
        el.style.height = 'auto';
        el.value = v;
        setTimeout(() => {
            el.style.height = `${el.scrollHeight}px`;
        }, 0);
    });
    core_utils.intExport(_ienUpdateRightsForTask, (el, v) => {
        const $el = $(el);
        (core.mayI.updateTask(v) ? $el.removeAttr : $el.attr).apply($el, ['readonly', true]);
    });

    core_utils.intExport(_ienProjectPath, pid => {
        const obj = {
            id: _umProject,
            prId: pid,
            mode: _upmManagerTL
        
        }
        return core.uriHandler.mkLink(obj);
    });

    core_utils.intExport(_ienUserAvatarHref, id => `#${imgs.userImage.makeId(id)}`);

    core_utils.intExport(_ienUsersAvatarHref, id => `#${imgs.usersImage.makeId(id)}`);

    core_utils.intExport(_ienWSAvatarHref, id => `#${imgs.workspaceImage.makeId(id)}`);

    if (tpls.project) {
        core_utils.intExport(_ienProjectCommentTpl, tpls.project.comment);
        core_utils.intExport(_ienFileTpl, tpls.project.fileItemTPL);
    }

    if (tpls.dashboard) {
        core_utils.intExport(_ienWSItemTPL, tpls.dashboards.wsItem);
        core_utils.intExport(_ienProjectItem, tpls.dashboard.projectItem);
        core_utils.intExport(_ienProjectShareListItem, tpls.dashboard.ProjectShareListItem);
        core_utils.intExport(_ienProjectsRecycleBinItemTpl, tpls.dashboard.projectsRecycleBinItemTpl);
    }

    if (tpls.manageBillingWorkspaces) {
        core_utils.intExport(_ienMngWSItemTPL, tpls.manageBillingWorkspaces.wsItem);
    }
    if (tpls.widgets && tpls.widgets.WSPeopleList) {
        core_utils.intExport(_ienWSUserItem, tpls.widgets.WSPeopleList.userItem);
    }
    if (tpls.widgets && tpls.widgets.taskInfo) {
        core_utils.intExport(_ienBlockTpl, tpls.widgets.taskInfo.blocks);
        core_utils.intExport(_ienUserCard, tpls.widgets.taskInfo.userCard);
    }

    if (tpls.widgets && tpls.widgets.projectFiles) {
        core_utils.intExport(_ienProjectFileTpl, tpls.widgets.projectFiles.itemTpl);
        core_utils.intExport(_ienPFSpinner, tpls.widgets.projectFiles.spinner);
    }
    if (tpls.widgets && tpls.userProfile) {
        core_utils.intExport(_ienWSUserInfo, tpls.userProfile.wsUserInfoItem);
        core_utils.intExport(_ienEmailItem, tpls.userProfile.emailItem);
        // core_utils.intExport(_ienEmailForWSItem, tpls.userProfile.defEmailForWSItem);
    }

    if (tpls.components) {
        core_utils.intExport(_ienNotificationsItem, tpls.components.notificationItem);
        core_utils.intExport(_ienWSItemPopupTpl, tpls.components.wsItem);
        core_utils.intExport(_ienPRItemPopupTpl, tpls.components.prItem);
    }
    if (tpls.widgets && tpls.widgets.workspaceOverview) {
        core_utils.intExport(_ienTaskTemplate, tpls.widgets.workspaceOverview.taskTemplate);
        core_utils.intExport(_ienProjectItemTemplate, tpls.widgets.workspaceOverview.projectRow);
        core_utils.intExport(_ienTaskItemTemplate, tpls.widgets.workspaceOverview.taskTemplate);
    }
    core_utils.intExport(_ienOverviewUpcomingEmpty, () => core.getMsg(_msgNoOverviewAndUpcoming));
    if (tpls.manageBillingWorkspaces) {
        core_utils.intExport(_ienSubscriptionTPL, tpls.manageBillingWorkspaces.subscriptionItem);
        core_utils.intExport(_ienWorkspaceUsageItem, tpls.manageBillingWorkspaces.usageItemTpl);
    }
    // if (tpls.widgets && tpls.widgets.manageProjectUsers) {
    //     core_utils.intExport(_ienProjectMember, tpls.widgets.manageProjectUsers.userCard);
    //
    // }

    core_utils.intExport(_ienWorkspaceActiveDecorate, function(d) {
        d3.select(this).classed(_clsActive, d.active);
    });

    core_utils.intExport(
        _ienGetUserFLName,
        (core_utils.getUserFLName = id => {
            const u = core_DO.user(id) || core_DO.getTeams(true).$item(id);
            if ($scope[_bndCurrentWorkspaceUser].id == id) {
                return '';
            }
            return (u && u.firstName && [u['firstName'], u['lastName']].join(' ')) || (u && u.name) || 'unknown';
        })
    );
    core_utils.intExport(_ienUserFLName, (id, u) => (u && [u['firstName'], u['lastName']].join(' ')) || 'unknown');
    core_utils.intExport(_ienOverviewUpcomingPostprocessing, (data, els) => {});

    core_utils.intExport(
        _ienNotificationsItemClass,
        function(d) {
            d.data.read === false ? $(this).addClass('unread') : $(this).removeClass('unread');
        },
        false
    );

    core_utils.intExport(_ienLayoutProcess, (el, collection) => {
        if (collection[0].length == 0) {
            DEBUG && console.info('processing-layout skip due zero length');
            $(el)
                .css('height', 'auto')
                .removeClass(_clsScrollable);
            return;
        }

        DEBUG && console.time('processing-layout');

        const c = collection[0];

        const s = core_dom.getCStyle(c[0]);
        const cw = c[0].offsetWidth + parseInt(s['marginLeft']) + parseInt(s['marginRight']);
        const ch = c[0].offsetHeight + parseInt(s['marginBottom']) + parseInt(s['marginTop']);
        const w = el.clientWidth;
        const h = el.clientHeight;
        const sq = ~~(w / cw);
        let fc = $(el).data('reserve-first') ? 1 : 0;
        const oh = Math.ceil((c.length + fc) / sq) * ch;

        $(el).css('height', oh);
        if (el.clientHeight !== el.scrollHeight) {
            $(el).addClass(_clsScrollable);
        }

        for (let i = 0, x = cw * fc, y = 0; i < c.length; i++) {
            if (fc % sq == 0 && fc > 0) {
                y += ch;
                x = 0;
            }

            $(c[i]).css({
                left: x,
                top: y
            });

            x += cw;
            fc++;
        }
        DEBUG && console.timeEnd('processing-layout');
    });

    core_utils.intExport(_ienUserFL, data => tpls.components.userFL(data || {}));
    core_utils.intExport(_ienTranslateRole, role => tpls.translateRole({ role }));
    core_utils.intExport(_ienSettingsCheckValue, (el, id, data) => {
        el.checked = data[el.value];
    });
    core_utils.intExport(_ienInputRadio, (el, value) => {
        el.checked = el.value == value;
    });

    core_utils.intExport(_ienTranslateLang, data => tpls.translateLang({ lang: data }));
}

core_utils.intExport(_ienFmtTimestamp, data => (data && core_utils.fmtTplDate(data)) || '');

core_utils.intExport(_ienFmtTimestampLog, data => {
    const m = moment(data).utc();
    return core_utils.getFmt(data, m.format('HHmm') != '0000');
});

core_utils.intExport(_ienGetDate, data => (data && core_utils.fmtUTCDate(core_utils.unpackDate(data))) || '');

core_utils.intExport(
    _ienN2br,
    ($filters[_ienN2br] = str => (str && str.toString().replace(/\n/g, '<br />')) || '')
);

core_utils.intExport(
    _ienTmpValuesTexts,
    (el, id, data) => {
        const v = (data && data.values) || {};
        el.style.display = v[el.dataset['field']] ? '' : 'none';
    }
);

core_utils.intExport(
    _ienTaskNameCaption,
    (el, val, data) => {
        let v;

        if (!data) {
            $(el).text('');
            return;
        }

        switch (data['type']) {
            case enmTaskInfoTypes.Group:
                v = val || core.getMsg(_msgUnnamedGroup);
                break;
            case enmTaskInfoTypes.Task:
                v = val || core.getMsg(_msgUnnamedTask);
                break;
            default:
                v = val || '';
                break;
        }

        $(el).text(v);
    }
);

function setTextContent(el, val) {
    // if (el.textContent != val) {
        el.textContent = val;
    // }
}

core_utils.intExport(
    _ienBublesIndicator,
    (el, _, data) => {
        const counters = data.counters;
        const cn = Array.prototype.slice.call(el.children || el.childNodes);
        const v = [(counters['c'] || 0) * 1, (counters['f'] || 0) * 1];
        let x = 0;
        const isGroup = data.type == 'Group';
        cn.forEach((e, i) => {
            e.style.display = (v[i] && 'block') || 'none';
            if (v[i]) {
                const txt = (e.children || e.childNodes)[1];
                const ico = (e.children || e.childNodes)[0];
                txt.textContent = v[i];
                ico.setAttributeNS(null, 'x', x);
                x += 15;
                txt.setAttributeNS(null, 'x', x);
                let w;
                try {
                    w = txt.getComputedTextLength();
                } catch (e) {
                    w = 6.2 * v[i].toString().length;
                }
                x += w;
            }
        });
        el.setAttributeNS(null, 'transform', `translate(${[isGroup ? 240 - 8 - x : 240 - 40 - 8 - 8 - x, 39]})`);
    }
);

core_utils.intExport(_ienPenByStatus, (el, state, taskData) => {
    let an;
    if (taskData.type == enmTaskInfoTypes.Group && state == enmTaskInfoStates.Completed) {
        an = core_DO.groupStat(taskData.id).tasksQty == 0 ? 'u' : 'c';
    } else {
        an = state.substr(0, 1).toLowerCase();
    }
    el.setAttributeNS(xlinkns, 'href', `#${_idUseInlineEdit}${an}`);
});

core_utils.intExport(
    _ienSVGStatusLine,
    (el, id, data) => {
        const d = core_DO.groupStat(id);
        window[_ienSVGStatusLine](el, [
            d[enmTaskInfoStates.Completed] || 0,
            d[enmTaskInfoStates.Unblocked] || 0,
            d[enmTaskInfoStates.Started] || 0,
            d[_gcTaskStateAlerted] || 0,
            d[enmTaskInfoStates.Blocked] || 0
        ]);
    }
);

/**
 * does object have some of keys
 * @param  {Array<string>}  keys
 * @param  {Object<string, *>}  obj
 * @return {Boolean}
 */
function isObjectHas(keys, obj) {
    const ps = Object.keys(obj);
    return ps.intersect(keys).length > 0;
}

core_utils.intExport(
    _ienDeadlineState,
    (el, id, data) => {
        let qty = 0;
        const isDeadlineAlert = data.isDeadlineAlert();
        const isGroup = data.type == enmTaskInfoTypes.Group;
        const isCompleted = data.state == enmTaskInfoStates.Completed;

        if (data.type == enmTaskInfoTypes.Group) {
            qty = core_DO.groupStat(data.id).tasksQty;
        }

        switch (el.tagName) {
            case 'use':
                let h;
                if (isCompleted && (!isGroup || (isGroup && qty > 0))) {
                    h = _idSymbolDoneMark;
                }
                //  else if (isDeadlineAlert) {
                //     h = _idSymbolDeadlineAlert;
                // } else if (data.deadline) {
                //     h = _idSymbolDeadline;
                // } else if (data.type == enmTaskInfoTypes.Task && __EMBED == 0 && core.mayI.setDeadline(data)) {
                //     h = _idSymbolCalendar;
                // }
                el.setAttributeNS(xlinkns, 'href', h ? `#${h}` : '');
                const d = d3
                    .select(el)
                    .classed(
                        /*[*/ _idSymbolDoneMark /*, _idSymbolDeadlineAlert, _idSymbolDeadline, _idSymbolCalendar].join(' ')*/,
                        false
                    );
                if (h) {
                    d.classed(h, true);
                }
                break;
            case 'text':
                const role = $(el).data('role');
                let t;
                const dv = data.values || {};
                // have to alert
                let fa = false;
                // permanent text show
                let pa = false;
                // preserve space for icon
                let ps = false;
                if (core_DO.hasFeature(_fndTaskDuration)) {
                    const isHaveTimingInfo =
                        data.startDate ||
                        isObjectHas(['blockedByTiming', 'willFinishLate', 'canStart'], data.values || {});
                    if (role == _idRoleDeadlineValue) {
                        if (isCompleted && (!isGroup || (isGroup && qty > 0))) {
                            t = data['timeCompleted'] && core_exp[_ienGetDate](data['timeCompleted']);
                            pa = true;
                            ps = true;
                        } else if (isDeadlineAlert || data.deadline || data.durationDef) {
                            switch (true) {
                                case !data.deadline && !!data.durationDef:
                                    t = data.durationDef;
                                    break;
                                case !!data.deadline && !!data.durationDef:
                                    t = `${data.durationDef}, ${core_exp[_ienGetDate](data['deadline'])}`;
                                    break;
                                case !!data.deadline && !data.durationDef:
                                    t = core_exp[_ienGetDate](data['deadline']);
                                    break;
                            }
                            pa = true;
                            fa = isDeadlineAlert;
                        } else if (
                            data['type'] == enmTaskInfoTypes.Task &&
                            __EMBED == 0 &&
                            core.mayI.setDeadline(data)
                        ) {
                            t = core.getMsg(_msgTaskScheduleLabel);
                        }
                    } else if (role == _idRoleDeadlineHover) {
                        t = core.getMsg(_msgTaskScheduleLabelHover, {
                            duration: data.durationDef,
                            deadline: data.deadline
                        });
                        setTextContent(el, t || '');
                        el.setAttributeNS(null, 'x', ps ? 18 : 0);
                        return;
                    }
                } else {
                    if (isCompleted && (!isGroup || (isGroup && qty > 0))) {
                        t = data['timeCompleted'] && core_exp[_ienGetDate](data['timeCompleted']);
                        pa = true;
                        ps = true;
                    } else if (isDeadlineAlert || data.deadline) {
                        t = core_exp[_ienGetDate](data['deadline']);
                        pa = true;
                        fa = isDeadlineAlert;
                    } else if (data['type'] == enmTaskInfoTypes.Task && __EMBED == 0 && core.mayI.setDeadline(data)) {
                        t = core.getMsg(_msgAddDeadline);
                    }
                }
                setTextContent(el, t || '');
                el.setAttributeNS(null, 'x', ps ? 18 : 0);
                if (fa) {
                    d3.select(el.parentNode).classed(_clsAlerted, true);
                } else {
                    d3.select(el.parentNode).classed(_clsAlerted, false);
                }
                if (pa || fa) {
                    d3.select(el.parentNode).classed(_clsPermanentShow, true);
                } else {
                    d3.select(el.parentNode).classed(_clsPermanentShow, false);
                }

                break;
        }
    }
);

core_utils.intExport(
    _ienCalcHeight,
    (el, id, data) => {
        const role = (el instanceof Element && $(el).data('role')) || el;
        const isGroup = data.type == enmTaskInfoTypes.Group;
        const tqty = (isGroup && core_DO.groupStat(data['id']).tasksQty) || 0;
        const isCompleted = isGroup
            ? data.state == enmTaskInfoStates.Completed && tqty
            : data.state == enmTaskInfoStates.Completed;
        const isHaveCounters = data.counters && (data.counters['c'] || data.counters['f']);
        const isAlerted = data.isAlerted;
        const haveDeadline = !!data['deadline'];
        const durationEnabled = core_DO.hasFeature(_fndTaskDuration);
        const attr = {};
        let ei;

        switch (role) {
            case _idRoleRect:
                var v;
                if (isGroup) {
                    ((isCompleted || haveDeadline || isHaveCounters) && (v = 75)) || (v = 60);
                } else {
                    v = 58;
                }
                attr['height'] = v;
                break;
            case _idRoleStatusLine:
                attr['y'] = isCompleted || haveDeadline || isHaveCounters ? 53 : 38;
                break;
            case _idRoleNewLink:
                var v;
                if (isGroup) {
                    (((isCompleted && tqty > 0) || haveDeadline || isHaveCounters) && (v = 75)) || (v = 60);
                } else {
                    v = 58;
                }
                attr['y'] = v - 10;
                break;
            case _idDeadlineBlock:
                var v = 3 + 14 * 2;
                attr['transform'] = `translate(8,${v})`;
                break;
            case _idRoleBottomBubble:
                var v;
                if (isGroup) {
                    ((isCompleted || haveDeadline || isHaveCounters) && (v = 75)) || (v = 60);
                } else {
                    v = 58;
                }
                attr['transform'] = `translate(0,${v - 1})`;
                break;
            case _idRoleTmpText:
                var v;
                if (isGroup) {
                    ((isCompleted || haveDeadline || isHaveCounters) && (v = 75)) || (v = 60);
                } else {
                    v = 58;
                }
                attr['y'] = v + 11;
                break;
        }

        el instanceof SVGElement &&
            Object.keys(attr).forEach(k => {
                el.setAttributeNS(null, k, attr[k]);
            });

        return attr;
    }
);

core_utils.intExport(
    _ienDeadlineBlockClass,
    (el, id, data) => {
        const isGroup = data.type == enmTaskInfoTypes.Group;
        const isCompleted = data.state == enmTaskInfoStates.Completed;
        const haveDeadline = !!data['deadline'];
        // var tqty = isGroup && core_DO.groupStat(data['id']).tasksQty || 0;
        d3.select(el).classed(_clsPermanentShow, !!haveDeadline || isCompleted);
    }
);

function mesureAndAlign(g, text, rect) {
    const textDim = text.getBoundingClientRect();
    rect.setAttributeNS(null, 'width', textDim.width + 20);
    // rect.setAttributeNS(null, 'height', textDim.height);
    rect.setAttributeNS(null, 'x', _trTaskWidth / 2 - textDim.width / 2 - 10);
    // rect
    // rect.y
}

function taskDate(el, date, late) {
    d3.select(el).classed(_clsDateIsLate, late); //.style.color = late ? '#ff5800' : 'inherited';
    // el.innerHTML = moment(date).format('DD MMM');
    el.innerHTML = core_utils.fmtUTCDate(core_utils.unpackDate(date));
}

function taskText(el, text, late) {
    d3.select(el).classed(_clsDateIsLate, late); //.style.color = late ? '#ff5800' : 'inherited';
    el.innerHTML = text;
}

core_utils.intExport(
    _ienTaskBubbles,
    (el, d, data) => {
        // debugger;
        const vs = data.values || {};
        el.querySelectorAll('g').forEach(g => {
            const r = g.dataset['role'];
            const rect = g.querySelector('rect');
            const text = g.querySelector('text');
            switch (r) {
                case _idRoleTopBubble:
                    if (data.startDate) {
                        g.style.display = '';
                        taskDate(text, data.startDate, vs.willStartLate);
                        mesureAndAlign(g, text, rect);
                    } else {
                        g.style.display = 'none';
                    }
                    break;
                case _idRoleBottomBubble:
                    if (vs.lateDays) {
                        g.style.display = '';
                        taskText(text, `${vs.lateDays} late`, true);
                        mesureAndAlign(g, text, rect);
                    } else {
                        g.style.display = 'none';
                    }
                    break;
            }
        });
    }
);

core_utils.intExport(
    _ienTaskExecs,
    (el, id, data) => {
        const hash = data.execsHash();
        const io = core.ImgCacheCollectionV4;
        const ex = data.execIds() || [];
        if (!el.__hash || el.__hash != hash) {
            io.clean(el);
            el.__hash = hash;
            switch (ex.length) {
                case 0:
                    if (__EMBED && !ex.length) {
                        return;
                    }
                case 1:
                    el.appendChild(
                        io.mkSvg('use', {
                            'xlink:href': `#${(ex.length && imgs.usersImage.makeId(ex[0])) || _idSymbolAssignUser}`,
                            className: _clsExecutorItem,
                            x: 0,
                            y: 0,
                            width: 40,
                            height: 40
                        })
                    );
                    break;
                case 2:
                    [0, 20].forEach((x, i) => {
                        el.appendChild(
                            io.mkSvg('svg', {
                                viewBox: '10 0 20 40',
                                x,
                                y: 0,
                                width: 20,
                                height: 40,
                                childs: {
                                    use: {
                                        'xlink:href': `#${imgs.usersImage.makeId(ex[i])}`,
                                        className: _clsExecutorItem,
                                        width: 40,
                                        height: 40
                                    }
                                }
                            })
                        );
                    });
                    break;
                default:
                    ex.slice(0, 4).forEach((id, i) => {
                        const x = [0, 20, 0, 20][i];
                        const y = [0, 0, 20, 20][i];
                        el.appendChild(
                            io.mkSvg('use', {
                                x,
                                y,
                                width: 20,
                                height: 20,
                                className: _clsExecutorItem,
                                'xlink:href': `#${imgs.usersImage.makeId(id)}`
                            })
                        );
                    });
                    break;
            }
        }
    }
);

/** @const */ const svgNS = 'http://www.w3.org/2000/svg';

function ctSpan(value, x, y, cName) {
    const el = document.createElementNS(svgNS, 'tspan');
    x != undefined && el.setAttributeNS(null, 'x', x);
    y != undefined && el.setAttributeNS(null, 'dy', y);
    cName && (el.className.baseVal = cName);
    el.appendChild(document.createTextNode((value || '').unescapeHTML()));
    return el;
}

function empty(p) {
    while (p.firstChild) {
        p.removeChild(p.firstChild);
    }
}

/** @const */ const WORD_SPLITER = /([\x20-\x2f\x3a-\x40])/;

/**
 * @param {SVGElement} el
 * @param {string} value
 * @param {number} width
 * @param {boolean} cut
 */
function fitInto(el, value, width, cut) {
    if (value.length && el.getComputedTextLength() > width) {
        let words = value.split(WORD_SPLITER).compact(true);
        var v = '';
        let i = 1;
        let al;

        do {
            el.firstChild.nodeValue = words.slice(0, i).join('');
        } while ((al = el.getComputedTextLength()) < width && words.length > i++);

        if (al >= width) {
            if (cut) {
                const v = el.firstChild.nodeValue;
                var avg = al / v.length;
                var rl = Math.floor(width / avg) - 2;
                el.firstChild.nodeValue = `${v.substr(0, rl)}…`;
            } else {
                if (words.length > i && i < 3) {
                    var v = el.firstChild.nodeValue,
                        avg = al / v.length,
                        rl = Math.floor(width / avg) - 1;
                    el.firstChild.nodeValue = v.substr(0, rl).trim();
                    el.insertBefore(ctSpan('⤸', undefined, 1, 'cr'), el.firstChild.nextSibling);
                    words.unshift(v.substr(rl));
                } else if (al - width > 5 || !WORD_SPLITER.test(words[i - 1])) {
                    el.firstChild.nodeValue = words
                        .slice(0, --i)
                        .join('')
                        .trim();
                    words = words.slice(i);
                } else {
                    words = words.slice(i);
                }
            }
        }

        return words.join('');
    }

    return '';
}

core_utils.intExport(_ienScrollDetect, function() {
    const o = $(this).parents(`.${_clsScrollWrapper}`);
    let c;
    let $c;
    if (o.length) {
        $c = o.parent();
        c = o.get(0);
    } else return;
    if (c.clientHeight !== c.scrollHeight) {
        $c.addClass(_clsScrollable);
    } else {
        $c.removeClass(_clsScrollable);
    }
});

core_utils.intExport(
    _ienTextFlow,
    (el, value, data) => {
        const d = $(el).data();
        const x = d3.select(el).attr('x') * 1 || 0;
        const mw = __EMBED ? ((data['execs'].length && d['width']) || d['altWidth'] || d['width']) - x : d['width'] - x;
        var r = '';

        if (d['old'] != value || !mw) {
            empty(el);
            $(el).data('old', value);

            const v =
                          value
                              .unescapeHTML()
                              .trim()
                              .replace(/\n\r|\n|\s{2,}|\t{1,}/g, ' ') ||
                          core.getMsg((data['type'] == enmTaskInfoTypes.Group && _msgUnnamedGroup) || _msgUnnamedTask),
                  firstLine = el.appendChild(ctSpan(v, x, '14')),
                  secondLineValue = fitInto(firstLine, v, mw).trim();

            if (secondLineValue) {
                const secondLine = el.appendChild(ctSpan(secondLineValue, x, '14')), r = fitInto(secondLine, secondLineValue, mw, true);
            }

            d3.select(el).attr('title', r ? value : '');
        }
    }
);

core_utils.intExport(_ienNumber, v => v.toString());

core_utils.intExport(
    _ienSVGStatusLinePostProcessor,
    function(d) {
        window[_ienSVGStatusLine](
            $(this)
                .find(`svg.${_clsSVGStatusLine}`)
                .get(0)
        );
    },
    false
);

core_utils.intExport(_ienDefUserEmail, ($emails, $id) => {
    const obj = ($emails || []).find(e => e.id == $id);

    return (obj && obj.email) || '';
});

core_utils.intExport(_ienUserEmails, (confirmed, globalPrimary) => {
    const sort = [
        enmContactInfoInfoStatus.Confirmed,
        enmContactInfoInfoStatus.Pending,
        enmContactInfoInfoStatus.Disabled
    ];
    const o = new $classes[_cUserInfo](core.iam);
    const emails = o.contacts
        .map(v => (v['type'] == 'email' && {
        id: v['id'],
        status: v['status'],
        email: v['value'],
        isDefault:
            v['id'] ==
            (globalPrimary ? core.iam.primaryContactId : $scope[_bndCurrentWorkspaceUser].contactInfoId)
    }) ||
    undefined)
        .compact()
        .sort((a, b) => {
            const as = sort.indexOf(a.status), bs = sort.indexOf(b.status);
            switch (true) {
                // make current email topmost
                // case a.isDefault: return -1;
                // case b.isDefault: return 1;
                case as == bs:
                    switch (true) {
                        case a.email > b.email:
                            return 1;
                        case a.email < b.email:
                            return -1;
                    }
                    return 0;
                case as > bs:
                    return 1;
                case as < bs:
                    return -1;
            }
        });
    return confirmed
        ? emails.filter(v => v.status == enmContactInfoInfoStatus.Confirmed)
        : emails;
});

core_utils.intExport(_ienShouldSeeWorkspaceSettingsPane, (/* Node */ el, /* cWorkspaceInfo */ data) => {
    const limits = data && data.workspaceLimits;
    const feature = limits && limits.indexOf(_fndTaskDuration) > -1;
    const v = feature && core.mayI.changeWorkspaceInfo(data) && !!data.settings;
    $(el).css('display', v ? 'block' : 'none');
});

core_utils.intExport(_ienWorkspaceSchedule, /* string */ data => // entry=[W]-[T]-[T]
// W - weekday number (1 - Monday, 7 - Sunday)
// T=[hh]:[mm] - time
// Example:
//   1-09:00-17:00;2-09:00-17:00;3-09:00-17:00
// debugger;
data
    .split(';')
    .filter(v => !!v)
    .map(p => p.split('-'))
    .sort((a, b) => a[0] - b[0])
    .map(v => {
        const dw = v[0];
        const start = v[1];
        const end = v[2];
        return `<tr><td>${moment()
    .day(dw)
    .format('dddd')}</td><td>${start}-${end}</td><td class="${_gcMosesWatchClass}" data-event="${_evWorkspaceSettingsTimeslotRemove}" data-id="${v.join('-')}">${tpls.Icon({ id: _idSymbolCircleX })}</td></tr>`;
    })
    .join(''));

window[_ienSVGStatusLine] = (g, data) => {
    const els = {};

    function setLabel(id, value, gx) {
        const el = els[`l${id}`];
        el.textContent = value;
        let b;

        let w = Math.ceil(el.getComputedTextLength()) + 5;

        if (value < 10) {
            sa(`l${id}`, 'x', 6);
            w += 5;
        }

        sa(`r${id}`, 'width', w);
    }
    function sa(id, name, value) {
        if (arguments.length == 3) {
            els[id].setAttributeNS(null, name, value);
        } else if (arguments.length == 2 && typeof name == 'object') {
            for (const i in name) {
                sa(id, i, name[i]);
            }
        }
    }
    function addCls(el, cname) {
        const l = el.className.baseVal.split(' ');
        l.push(cname);
        el.className.baseVal = l.unique().join(' ');
    }
    function removeCls(el, cname) {
        const l = el.className.baseVal.split(' '), i = l.indexOf(cname);

        if (i == -1) {
            return;
        }
        l.splice(i, 1);
        el.className.baseVal = l.join(' ');
    }
    function hasCls(el, cname) {
        return new RegExp(cname).test(el.className.baseVal);
    }
    function hide(id) {
        if (typeof id == 'object') {
            id.forEach(i => {
                hide(i);
            });
            return;
        } else {
            addCls(els[`g${id}`], 'nv');
        }
    }
    function unhide(id) {
        if (typeof id == 'object') {
            id.forEach(i => {
                unhide(i);
            });
            return;
        } else {
            removeCls(els[`g${id}`], 'nv');
        }
    }
    function transform(id, x) {
        sa(`g${id}`, 'transform', `translate(${x},0)`);
    }
    function transformAndUpdate(id, obj, value) {
        transform(id, value);
        obj.x = value;
        obj['rx'] = obj.x + obj.width;
    }
    function bbox(el) {
        let r;
        try {
            r = el.getBBox();
        } catch (e) {
            r = {
                x: parseInt(el.getAttribute('x'), 10) || 0,
                y: parseInt(el.getAttribute('y'), 10) || 0,
                width: parseInt(el.getAttribute('width'), 10) || 0,
                height: parseInt(el.getAttribute('height'), 10) || 0
            };
        }
        return r;
    }
    function attr(el, name) {
        return el.getAttribute(name);
    }
    function reset() {
        dmap.forEach(id => {
            sa(id, {
                width: 0,
                x: 0,
                rx: 0,
                ry: 0
            });
            hide(id);
            els[`g${id}`].removeAttributeNS(null, 'transform');
        });
    }

    /**
     * Indexes of data
     * 0/c - completed
     * 1/u - unblocked
     * 2/s - started
     * 3/a - alerted
     * 4/b - blocked
     */
    // debugger;
    data = data || attr(g, 'data-cub').split(',');

    let wq = 0;
    const pl = 0;
    const dmap = ['c', 'u', 'a', 'b'];
    const rd = [];

    ((() => {
        const es = g.querySelectorAll('[data-id]');
        for (let i = es.length; --i >= 0; ) {
            const id = attr(es[i], 'data-id');
            els[id] = es[i];
        }
    }))();
    // looping to convert data and calculate summ
    data.forEach((v, i) => {
        data[i] = v * 1;
        wq += data[i];
    });

    // add started qty to unblocked and remove started from data block
    data[1] += data[2];
    data.splice(2, 1);

    // looping through dmap for corect order of data
    let wp = 0;
    reset();
    dmap.forEach((i, p) => {
        const v = (data[p] * 100) / wq;
        if (data[p]) {
            unhide(i);
            sa(i, {
                width: `${v}%`,
                x: `${wp}%`
            });
            setLabel(i, data[p]);
            wp += v;
            rd.push(i);
        } else {
            hide(i);
            sa(i, {
                width: 0,
                x: 0
            });
        }
    });

    // oops, empty? lets show it
    if (!rd.length) {
        sa('b', 'width', '100%');
        unhide('b');
        setLabel('b', 'Empty');
        rd.push('b');
    }

    // align labels
    if (rd.length == 1) {
        const id = rd[0], bl = bbox(els[id]), bg = bbox(els[`g${id}`]), t = (bl.width - bg.width) / 2;
        transform(id, t);
        sa(id, { rx: 5, ry: 5 });
    } else {
        const tb = [];
        rd.forEach((id, i) => {
            let bl = bbox(els[id]);
            const bg = bbox(els[`g${id}`]);
            let t = 0;

            if (i == 0) {
                t = 0;
            } else if (i == rd.length - 1) {
                bl = bbox(g);
                t = bl.width - bg.width;
            } else {
                t = bl.x + (bl.width - bg.width) / 2;
            }

            transform(id, t);
            tb.push({
                x: bg.x + t,
                rx: bg.x + t + bg.width,
                width: bg.width
            });
        });

        // check for overlapped labels
        if (rd.length == 3) {
            if (tb[0]['rx'] > tb[1].x) {
                transform(rd[1], tb[0]['rx'] + 1);
            } else if (tb[1]['rx'] > tb[2].x) {
                transform(rd[1], tb[2].x - tb[1].width);
            }
        } else if (rd.length == 4) {
            const br = false;
            while (true) {
                switch (true) {
                    case br == true:
                        return;
                    case tb[0]['rx'] > tb[1].x:
                        transformAndUpdate(rd[1], tb[1], tb[0]['rx'] + 1);
                        break;
                    case tb[2]['rx'] > tb[3].x:
                        transformAndUpdate(rd[2], tb[2], tb[3].x - (tb[2].width + 1));
                        break;
                    case tb[1]['rx'] > tb[2].x && tb[1].x - tb[0]['rx'] > tb[3].x - tb[2]['rx']:
                        transformAndUpdate(rd[1], tb[1], tb[2].x - (tb[1].width + 1));
                        break;
                    case tb[1]['rx'] > tb[2].x && tb[1].x - tb[0]['rx'] < tb[3].x - tb[2]['rx']:
                        transformAndUpdate(rd[2], tb[2], tb[1]['rx'] + 1);
                        break;
                    default:
                        return;
                }
            }
        }
    }
};
