core.sessionWatchDog = (core => {
    /** @type {NodeJS.Timeout | undefined} */
    let wdid;

    function handler() {
        core.isSessionActive()
            ? core.moses.announce(_rSessionPing).then(obj => {
                  if (obj instanceof $classes[_cErrorMessage]) {
                      console.warn('something wrong goes on server side', obj);
                      __USE_ROLLBAR && Rollbar.error('sessionWatchDog something wrong goes on server side', obj);
                  }
              })
            : DEBUG && console.log('::sessionWatchDog::handler', 'skipped as far there no session yet');
    }

    return {
        suspend() {
            if (wdid) {
                DEBUG && console.log('::sessionWatchDog::suspend');
                clearInterval(wdid);
                wdid = undefined;
            }
        },
        start() {
            // DEBUG && console.log('::sessionWatchDog::start');
            if (wdid) {
                // DEBUG && console.log('::sessionWatchDog::start', 'restarting WatchDog');
                clearInterval(wdid);
            }
            wdid = setInterval(handler.bind(this), _gcSessionWatchDogInterval);
        },
        status() {
            return !!wdid;
        }
    };
})(core);
