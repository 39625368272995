(function() {
    /**
     * Initialise a Google Driver file picker
     */
    function FilePicker (options) {
        // Config
        this.apiKey = options.apiKey;
        this.clientId = options.clientId;

        // Events
        this.onSelect = options.onSelect;

        // Load the drive API
        gapi.client.setApiKey(this.apiKey);
        gapi.client.load('drive', 'v2', this._driveApiLoaded.bind(this));
        gapi.load('picker', '1', { callback: this._pickerApiLoaded.bind(this) });
    }

    FilePicker.prototype = {
        /**
         * Open the file picker.
         */
        open: function() {
            // Check if the user has already authenticated
            var token = gapi.auth.getToken();
            if (token) {
                this._showPicker();
            } else {
                // The user has not yet authenticated with Google
                // We need to do the authentication before displaying the Drive picker.
                this._doAuth(false, function() { this._showPicker(); }.bind(this));
            }
        },

        /**
         * Show the file picker once authentication has been done.
         * @private
         */
        _showPicker: function() {
            var accessToken = gapi.auth.getToken().access_token;
            this.picker = new google.picker.PickerBuilder()
                // .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
                .addViewGroup(
                        new google.picker.ViewGroup(
                                new google.picker.DocsView().
                                setIncludeFolders(true).
                                setOwnedByMe(true)
                                ).
                        addView(new google.picker.DocsUploadView()).
                        addView(google.picker.ViewId.RECENTLY_PICKED)
                )
                .addViewGroup(
                        new google.picker.ViewGroup(google.picker.ViewId.DOCS).
                        addView(google.picker.ViewId.DOCUMENTS).
                        addView(google.picker.ViewId.PRESENTATIONS).
                        addView(google.picker.ViewId.SPREADSHEETS).
                        addView(google.picker.ViewId.PDFS).
                        addView(google.picker.ViewId.DOCS_IMAGES_AND_VIDEOS)
                )
//                .addView(google.picker.ViewId.MAPS)
                .setDeveloperKey('AIzaSyDAOWjqyl8IY7GFZT3Qqpgl9sVvThQ0bQ0')
                .setAppId(this.clientId)
                .setOAuthToken(accessToken)
                .setCallback(this._pickerCallback.bind(this))
                .build()
                .setVisible(true);
        },

        /**
         * Called when a file has been selected in the Google Drive file picker.
         * @private
         */
        _pickerCallback: function(data) {
            if (data[google.picker.Response.ACTION] == google.picker.Action.PICKED) {
                // var file = ,
                //     id = file[google.picker.Document.ID],
                //     request = gapi.client.drive.files.get({
                //         fileId: id
                //     });
                //
                // request.execute(this._fileGetCallback.bind(this));
                this._fileGetCallback(data[google.picker.Response.DOCUMENTS][0]);
            }
        },
        /**
         * Called when file details have been retrieved from Google Drive.
         * @private
         */
        _fileGetCallback: function(file) {
            if (this.onSelect) {
                this.onSelect(file);
            }
        },

        /**
         * Called when the Google Drive file picker API has finished loading.
         * @private
         */
        _pickerApiLoaded: function() {
            // this.buttonEl && (this.buttonEl.disabled = false);
        },

        /**
         * Called when the Google Drive API has finished loading.
         * @private
         */
        _driveApiLoaded: function() {
            this._doAuth(true, function (authResult) {
                if (authResult && authResult.error && authResult.error == 'immediate_failed') {
                    this._doAuth(false);
                }
            }.bind(this));
        },

        /**
         * Authenticate with Google Drive via the Google JavaScript API.
         * @private
         */
        _doAuth: function(immediate, callback) {
            gapi.auth.authorize({
                client_id: this.clientId,
                scope: 'https://www.googleapis.com/auth/drive.file',
                immediate: immediate
            }, callback);
        }
    };

    window.FilePicker = FilePicker;
}());
