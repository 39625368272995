function calcArc($c, $t, val) {
    const r = $c.attr('r');
    const c = Math.PI * (r * 2);

    const pct = ((100 - val) / 100) * c;

    $c.css({ strokeDashoffset: pct });
    $t.text(`${val}%`);
}
function calcArea(b) {
    let x;
    let y;
    let x1;
    let y1;
    const ma = Math.max;
    const mi = Math.min;
    if (b[0] > b[1]) {
        x = (b[0] - b[1]) / 2;
        y = 0;
        y1 = b[1];
        x1 = x + b[1];
    } else {
        y = (b[1] - b[0]) / 2;
        x = 0;
        x1 = b[0];
        y1 = y + b[0];
    }
    return [x, y, x1, y1];
}
function stream2target(stream) {
    let target;
    switch (stream) {
        case _avSNUserPics:
            target = _avTNUser;
            break;
        case _avSNWSPics:
            target = _avTNWorkspace;
            break;
        case _avSNPrPics:
            target = _avTNProject;
            break;
        case _avSNWSUserPics:
            target = _avTNWorkspaceUser;
            break;
    }
    return target;
}

core.avatarUploader = (stream, id) => {
    const $form = $(tpls.components.fileUploaderForm({ name: 'image' })).appendTo('body');
    let $shield;
    let $progressBar;
    let $progressTitle;
    const target = stream2target(stream);
    let shouldRemoveForm = true;
    const $release = () => {
        setTimeout(() => {
            shouldRemoveForm && $form.remove();
            $(window).off('focus', $release);
        }, 500);
    };

    return core
        .q((rslv, rjct) => {
            $form
                .find('input')
                .fileupload({
                    url: _avUploadUrl,
                    dataType: 'json',
                    singleFileUploads: true,
                    limitConcurrentUploads: 1,
                    type: 'POST',
                    formData() {
                        shouldRemoveForm = false;
                        const data = [
                            { name: 'sessionKey', value: core.sessionId() },
                            { name: 'target', value: target },
                            { name: 'targetId', value: id }
                        ];
                        return data;
                    },
                    start(e, data) {
                        $shield = $(tpls.avatar.uploadBanner()).appendTo('body');
                        $progressBar = $shield.find(`.${_clsUploadBannerShield_progressBar}`);
                        $progressTitle = $shield.find(`.${_clsUploadBannerShield_progressTitle}`);
                        calcArc($progressBar, $progressTitle, 0);
                    },
                    done(e, data) {
                        rslv(data);
                    },
                    progress(e, data) {
                        const v = Math.floor((data['loaded'] / data['total']) * 100);
                        calcArc($progressBar, $progressTitle, v);
                    },
                    fail(e, data) {
                        rjct(data);
                    },
                    always() {
                        shouldRemoveForm = true;
                        $release();
                    }
                })
                .trigger('click');
            $(window).on('focus', $release);
        })
        .then(data => {
            const r = data.result;
            if (r.error) {
                throw new Error(r.errorMessage);
            } else
                return core.q((rslv, rjct) => {
                    const img = new Image();
                    img.onload = () => {
                        if (Math.min(img.width, img.height) < 40) {
                            rjct('The image size has to be at least 40x40 pixels');
                            return;
                        }
                        rslv({
                            fileId: r.fileId,
                            target,
                            targetId: id,
                            img
                        });
                    };
                    img.onerror = e => {
                        rjct(e);
                    };
                    img.src = [_CONF_AV_HOSTER, _avSNUploads, id, `${r.fileId}_${_avSVOriginal}`].join('/');
                });
        })
        .then(obj =>
            core.q((rslv, rjct) => {
                const rnd = Math.round;
                let selection, japi;
                $shield.find('svg').replaceWith(tpls.avatar.editor());

                let $pl = $shield.find(`.${_clsPlaceHolder}`);
                const c = $shield.get(0).getBoundingClientRect();
                $pl.replaceWith(obj.img);
                $pl = null;

                const $img = $shield.find('img');
                const $ok = $shield.find('button:first-child');
                $img.Jcrop(
                    {
                        aspectRatio: 1,
                        minSize: [40, 40],
                        boxWidth: rnd(c.width - c.width / 6),
                        boxHeight: rnd(c.height - c.height / 6),
                        bgColor: 'silver',
                        bgOpacity: 0.4,
                        onSelect(c) {
                            selection = c;
                            $ok.removeClass(_clsDisabled);
                        },
                        onRelease() {
                            selection = null;
                            $ok.addClass(_clsDisabled);
                        }
                    },
                    function() {
                        japi = this;
                        japi.setSelect(calcArea(japi.getBounds()));
                    }
                );

                $shield.on('click', 'button', function(ev) {
                    const $el = $(this);
                    switch (true) {
                        case $el.hasClass(_clsButton_Blue) && !$el.hasClass(_clsDisabled):
                            rslv(
                                Object.assign(obj, {
                                    x: rnd(selection.x),
                                    y: rnd(selection.y),
                                    w: rnd(selection.w),
                                    h: rnd(selection.h)
                                })
                            );
                            break;
                        case $el.hasClass(_clsButton_White):
                            rjct({ canceled: true });
                            break;
                    }
                    return false;
                });
            })
        )
        .then(obj =>
            core.moses.announce(_rProcessUploadedPicture, obj).then(() => {
                $shield.hide().remove();
                return obj;
            })
        )
        .catch(e => {
            DEBUG && console.error('avatarEditor', e);
            if (e && !e.canceled) {
                __USE_ROLLBAR && Rollbar.debug('AvatarUploader error', e);
                alert('Error uploading picture. Please try again.');
            }
            $shield.hide().remove();
            $form.remove();
            return e.message;
        });
};

core.moses.subscribe(_eRemoveAvatar, el => {
    if (!window.confirm(core.getMsg(_msgConfirmAvatarRemove))) {
        return;
    }
    const $el = $(el).parents(`.${_clsLogoImage}`);
    const data = $el.data();
    const obj = {
        target: stream2target(data['stream']),
        fileId: data['fid'],
        targetId: data['uid'] || data['pid']
    };
    DEBUG &&
        (console.assert(obj.target, 'obj.target'),
        console.assert(obj.fileId, 'obj.fileId'),
        console.assert(obj.targetId, 'obj.targetId'),
        console.assert(data.stream, 'data.stream'));
    let o;
    switch (data['stream']) {
        case _avSNUserPics:
            o = imgs.userImage;
            break;
        case _avSNWSUserPics:
            o = imgs.usersImage;
            break;
        case _avSNPrPics:
            o = imgs.projectImage;
            break;
        case _avSNWSPics:
            o = imgs.workspaceImage;
            break;
    }

    o && o.release(obj.targetId);

    core.moses.announce(_rRemovePicture, obj).then(res => {
        DEBUG && console.log(res);
    });
});

core.moses.subscribe(_eUploadAvatar, el => {
    const $el = $(el).parents(`.${_clsLogoImage}`);
    const data = $el.data();
    DEBUG && (console.assert(data.stream, 'data.stream'), console.assert(data.uid, 'data.uid'));
    core.avatarUploader(data['stream'], data['uid']).then(r => {
        console.info(r);
    });
});
