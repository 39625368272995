'use strict;';

const /** @const */ _upmManagerUL = 'u';
const /** @const */ _upmManagerTL = 'l';
const /** @const */ _upmExecutor = 'e';
const /** @const */ _upmUsers = 't';
const /** @const */ _upmFiles = 'f';

const dragImage = new Image();

(img => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const fill = '#fff';
    const stroke = '#56c3da';
    const x = 1;
    const y = 1;
    //core.isWebKit ? 1 : window.devicePixelRatio,
    const factor = 1;
    const radius = 5;
    const width = _trTaskWidth;
    const height = 60;

    canvas.width = (width + 2) * factor;
    canvas.height = (height + 2) * factor;
    ctx.scale(factor, factor);

    ctx.beginPath();
    ctx.lineWidth = 2;
    ctx.moveTo(x, y + radius);
    ctx.lineTo(x, y + height - radius);
    ctx.quadraticCurveTo(x, y + height, x + radius, y + height);
    ctx.lineTo(x + width - radius, y + height);
    ctx.quadraticCurveTo(x + width, y + height, x + width, y + height - radius);
    ctx.lineTo(x + width, y + radius);
    ctx.quadraticCurveTo(x + width, y, x + width - radius, y);
    ctx.lineTo(x + radius, y);
    ctx.quadraticCurveTo(x, y, x, y + radius);
    ctx.fillStyle = fill;
    ctx.fill();
    ctx.strokeStyle = stroke;
    ctx.stroke();
    img.src = canvas.toDataURL();
    $(img).attr({
        width: `${width}px`,
        height: `${height}px`
    });
})(dragImage);

const uploadsData = new Armap();

function fileUploader(stream, task, dropZone) {
    let $form = $(tpls.components.fileUploaderForm({multiple: true, name: 'file'})).appendTo('body');

    $form
        .find('input')
        .on('change', function () {
            Array.prototype.slice.call(this.files).forEach(f => {
                fileActualUploader(stream, task, f);
            });
            $form.remove();
            $form = null;
        })
        .trigger('click');
}

function fileActualUploader(stream, task, file) {
    const id = core_utils.uuid(),
        item = {
            id,
            data: {
                workspaceId: $scope[_bndCurrentWorkspace].id,
                projectId: $scope[_bndCurrentProject].id,
                stream,
                type: enmFileType.Upload,
                fileName: file.name,
                contentType: file.type,
                fileSize: file.size,
                sid: id
            },
            file
        };

    if (task) {
        const tid = core_DO.getCTID()[0];
        tid && (item.data['objectId'] = tid);
    }

    uploadsData.$push(item);

    let r;
    core.moses
        .announce(_rUploadTicket, (r = {file: item.data, method: 'post'}))
        .then(obj => {
            if (obj.billingStatus == enmBillingResponseStatus.OutOfLimits) {
                __USE_ROLLBAR &&
                Rollbar.info('project//fileUploader::rUploadTicket out of limits', {req: r, resp: obj});
                alert(core.getMsg(_msgFileUploadOutOfLimits, {name: item.data.fileName, size: item.data.fileSize}));
            }
        })
        .catch(obj => {
            switch (obj.error) {
                case 'AccessDenied':
                    msg = core.getMsg(_msgFileUploadErrorAccessDenied);
                    break;
                case 'ReadOnly':
                    msg = core.getMsg(_msgFileUploadErrorReadOnly);
                    break;
                case 'NoRecipient':
                    msg = core.getMsg(_msgFileUploadErrorNoRecipient);
                    break;
                default:
                    msg = core.getMsg(_msgServerError);
                    __USE_ROLLBAR &&
                    Rollbar.error('project//fileUploader::rUploadTicket fail response', {req: r, resp: obj});
                    break;
            }
            alert(msg);
        });
}

core.DM.registerNHandler(_nUploadTicketDelivery, _cUploadTicketInfo, obj => {
    const item = uploadsData.$item(obj.file.sid);
    if (!item) {
        return;
    }
    let sender = $(tpls.components.fileUploaderForm({multiple: true, name: 'file'}))
        .appendTo('body')
        .find('input')
        .fileupload({
            url: obj.postPath,
            dataType: 'json',
            paramName: 'file',
            singleFileUploads: true,
            limitConcurrentUploads: 1,
            type: 'POST',
            formData() {
                return [
                    {name: 'key', value: obj.key},
                    {name: 'acl', value: obj.acl},
                    {name: 'AWSAccessKeyId', value: obj.accessKey},
                    {name: 'Policy', value: obj.policy},
                    {name: 'Signature', value: obj.signature}
                ];
            },
            add(e, data) {
                item.xhqr = data.submit();
            },
            start(e, data) {
                item.banner = Alertify.log.info(
                    tpls.project.fileUploadBanner({name: item.data.fileName, sid: item.id}),
                    0
                );
                $(item.banner.el).off('click');
                item.$progress = $(item.banner.el).find(`.${_clsProgressBar}`);
            },
            done(e, data) {
                core.moses
                    .announce(_rNewFile, {
                        file: item.data
                    })
                    .then(() => {
                        Alertify.log.success(`file ${soy.$$escapeHtml(item.data.fileName)} uploaded`);
                    })
                    .catch(() => {
                        Alertify.log.error(`file ${item.data.fileName} failed to register`);
                    });
            },
            progress(e, data) {
                item.$progress.html(`${parseInt((data['loaded'] / data['total']) * 100, 10)}%`);
            },
            fail(e, data) {
                Alertify.log.error(`file ${item.data.fileName} failed to upload`);
            },
            always() {
                sender.parent().remove();
                sender = null;
                item.banner.close();
                item.banner = null;
                uploadsData.$remove(item.id);
            }
        });

    item.data.id = obj.file.id;
    sender.fileupload('add', {files: [item.file]});
});

$('body').on('click', `.${_clsFileUploadBanner} .${_clsIcon}`, function (ev) {
    const sid = $(this)
            .parent()
            .data('sid'),
        item = uploadsData.$item(sid);

    item.xhqr.abort();
    uploadsData.$remove(sid);
    return false;
});

(core => {
    let $groupHolder = null;
    const $tasksHighLighted = undefined;
    let __DropBoxLoaded = false;
    let __BoxLoaded = false;
    let __GoogleClientLoaded = false;
    let __OneDriveLoaded = false;

    /** @type {Promise<any>} */
    let storyEditorScript;
    let storyEditorClose;

    /**
     * @extends AbstractPageController
     */
    // @ts-ignore
    new $classes.Class(
        _pcProject,
        _clAbstractPageController,
        /** @lend pcProject */
        {
            id: _pidProject,
            um: _umProject,
            pageTitle: 'Project',
            shouldActivateWidgets_: false,
            queue: null,
            _tree: null,
            activePane: null,
            plannerTab: null,
            __ozf: 1,
            _ptn: null,
            groupID: undefined,
            groupHolder: null,
            storyEditor: null,
            calendar: null,
            /** @this pcProject */
            _create() {
                this._binds = new $classes.Binds();
                this._draftBinds = new $classes.Binds();

                this._sendChanges = (() => {
                    let r;
                    core.moses
                        .announce(
                            _rProjectStatePush,
                            (r = {
                                changes: this.pckt,
                                projectId: this.pckt.pid || ($scope[_bndCurrentProject] && this.pid)
                            })
                        )
                        .catch(o => {
                            // FIXME: AccessDenied Error ReadOnly
                            switch (o['error']) {
                                case enmErrorMessage.ReadOnly:
                                    Alertify.log.error(core.getMsg(_msgWorkspaceIsReadOnly));
                                    break;
                                case enmErrorMessage.AccessDenied:
                                    Alertify.log.error(core.getMsg(_msgAccessDenied));
                                    break;
                                case enmErrorMessage.Error:
                                    Alertify.log.error(core.getMsg(_msgSomeErrorOnServerWhileSavingChanges, o));
                                    break;
                                // case enmErrorMessage.IllegalMessage:
                                default:
                                    DEBUG &&
                                    console.error('project::_sendChanges::rProjectStatePush fail response', {
                                        req: r,
                                        resp: o
                                    });
                                    __USE_ROLLBAR &&
                                    Rollbar.error('project::_sendChanges::rProjectStatePush fail response', {
                                        req: r,
                                        resp: o
                                    });
                                    break;
                            }
                        });
                    this.pckt = null;
                }).debounce(_gcPROJECT_STATE_CHANGES_PUSH_DELAY);

                core_exp.ProjectLink = core_exp[_ienProjectLink] = this.mkUri.bind(this);

                var self = this;
                [
                    _wtProjectUsers,
                    _wtTasksList,
                    _wtTaskInfo,
                    _wtManageProjectUsers,
                    _wtManageTeams,
                    _wtProjectFiles
                ].forEach(n => {
                    self.registerWidget($classes.$factory(n));
                });
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiAddUserToProjectMenu, {
                        menuId: _clsProjectAddUserMenu,
                        menuTitle: core.getMsg(_msgAddUser),
                        beforePopupCallback: ctx => {
                            const users = core_DO.users().filter(d => d['role'] != enmWorkspaceUserRole.Resource),
                                resources = core_DO.users().filter(d => d['role'] == enmWorkspaceUserRole.Resource),
                                self = this,
                                s = [],
                                hash = core_DO.membersHash();

                            if (
                                [enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader].indexOf(
                                    $scope[_bndCurrentWorkspaceUser].role
                                ) > -1
                            ) {
                                s.push({
                                    label: `<form>${tpls.Icon({
                                        id: _idSymbolAddUsers
                                    })}<input name="email" placeholder="${core.getMsg(
                                        _msgInviteUser
                                    )}" type="email" /></form>`,
                                    classes: [_clsNotSelectableItem]
                                });
                            }

                            s.push({
                                label: core.getMsg(_msgAddUserMenuHeader).format({qty: users.length}),
                                classes: [_clsPopupMenuHeader]
                            });

                            (users || []).forEach(u => {
                                const obj = {
                                    data: {
                                        uid: u['id']
                                    },
                                    classes: ['user'],
                                    label:
                                        tpls.Avatar({id: u.id, namespace: _clsUserPic}) +
                                        soy.$$escapeHtml(`${u['firstName']} ${u['lastName']}`)
                                };

                                if (hash.indexOf(u['id']) > -1) {
                                    obj.classes.push(_clsNotSelectableItem, _clsShaded);
                                    obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                }

                                s.push(obj);
                            });

                            if (resources.length) {
                                s.push({
                                    label: core.getMsg(_msgAddResourceMenuHeader).format({qty: resources.length}),
                                    classes: [_clsPopupMenuHeader]
                                });
                                (resources || []).forEach(u => {
                                    const obj = {
                                        data: {
                                            uid: u['id']
                                        },
                                        classes: ['user'],
                                        label:
                                            tpls.Avatar({id: u.id, namespace: _clsUserPic}) +
                                            soy.$$escapeHtml(`${u['firstName']} ${u['lastName']}`)
                                    };

                                    if (hash.indexOf(u['id']) > -1) {
                                        obj.classes.push(_clsNotSelectableItem, _clsShaded);
                                        obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                    }

                                    s.push(obj);
                                });
                            }
                            ctx.setItems(s);
                        },
                        customHandler: (ev, ctx, data) => {
                            if (ev.type == 'click' && ev.target.tagName == 'INPUT') {
                                return false;
                            }
                            const id = data['uid'];
                            const uarole = core_DO.user(id).role;
                            let vr;
                            // index = enmWorkspaceUserRole.indexOf(uarole),
                            // LeaderIndex = enmWorkspaceUserRole.indexOf(enmWorkspaceUserRole.Leader),
                            // vr = LeaderIndex > index && uarole || enmWorkspaceUserRole.Leader;

                            switch (uarole) {
                                case enmWorkspaceUserRole.Resource:
                                case enmWorkspaceUserRole.Spectator:
                                    vr = enmWorkspaceUserRole.Spectator;
                                    break;
                                default:
                                    vr = enmWorkspaceUserRole.Participant;
                                    break;
                            }

                            // core_DO.addMember(id, vr);
                            let r;
                            core.moses
                                .announce(
                                    _rProjectMembership,
                                    (r = {
                                        workspaceUserId: id,
                                        projectId: $scope[_bndCurrentProject].id,
                                        become: vr
                                    })
                                )
                                .catch(o => {
                                    switch (o.error) {
                                        case enmErrorMessage.AccessDenied:
                                            alert(core.getMsg(_msgAccessDenied));
                                            break;
                                        case enmErrorMessage.ReadOnly:
                                            alert(core.getMsg(_msgWorkspaceIsReadOnly));
                                            break;
                                        default:
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'project::wiAddUserToProjectMenu::rProjectMembership fail response',
                                                {req: r, resp: o}
                                            );
                                            alert(core.getMsg(_msgUknownError, o));
                                            break;
                                    }
                                    core_DO.removeMember(id);
                                });

                            return true;
                        }
                    })
                );
                this.registerWidget(
                    /**
                     * @constructor
                     * @class wiAddMemberToTeam
                     * @extends wtPopupMenu
                     */
                    $classes.$factory(
                        _wtPopupMenu,
                        _wiAddMemberToTeam,
                        /** @lands wiAddMemberToTeam */
                        {
                            menuTitle: core.getMsg(_msgAddMemberToTeamTitle),
                            /**
                             * @param {wiAddMemberToTeam} ctx
                             * @this wiAddMemberToTeam
                             */
                            beforePopupCallback(ctx) {
                                const users = $scope[_bndProjectUsers].filter(
                                    /** @param {cWorkspaceUserInfo} d */
                                    d => d.role != enmWorkspaceUserRole.Resource
                                );
                                const resources = $scope[_bndProjectUsers].filter(
                                    /** @param {cWorkspaceUserInfo} d */
                                    d => d.role == enmWorkspaceUserRole.Resource
                                );
                                const self = this;
                                const s = [];
                                const hash = $scope[_bndProjectTeams]
                                    .$item(ctx._data.id)
                                    .members.map(d => d.workspaceUserId);

                                s.push({
                                    label: core.getMsg(_msgAddUserMenuHeader).format({qty: users.length}),
                                    classes: [_clsPopupMenuHeader]
                                });

                                (users || []).forEach(
                                    /** @param {cUserInfo} d */
                                    u => {
                                        const obj = {
                                            data: {
                                                uid: u['id']
                                            },
                                            classes: ['user'],
                                            label:
                                                tpls.Avatar({id: u.id, namespace: _clsUserPic}) +
                                                soy.$$escapeHtml(`${u['firstName']} ${u['lastName']}`)
                                        };

                                        if (hash.indexOf(u['id']) > -1) {
                                            obj.classes.push(_clsNotSelectableItem, _clsShaded);
                                            obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                        }

                                        s.push(obj);
                                    }
                                );

                                if (resources.length) {
                                    s.push({
                                        label: core.getMsg(_msgAddResourceMenuHeader).format({qty: resources.length}),
                                        classes: [_clsPopupMenuHeader]
                                    });
                                    (resources || []).forEach(u => {
                                        const obj = {
                                            data: {
                                                uid: u['id']
                                            },
                                            classes: ['user'],
                                            label: `<div class="${imgs.userImage.makeId(
                                                u['id']
                                            )}"></div>${soy.$$escapeHtml(`${u['firstName']} ${u['lastName']}`)}`
                                        };

                                        if (hash.indexOf(u['id']) > -1) {
                                            obj.classes.push(_clsNotSelectableItem, _clsShaded);
                                            obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                        }
                                        s.push(obj);
                                    });
                                }
                                ctx.setItems(s);
                            },
                            onItemSelectCallback: _evAddMemberToTeamEvent
                        }
                    )
                );
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiStoriesEditorMenu, {
                        menuTitle: core.getMsg(_msgStoryEditorMenuHeader),
                        beforePopupCallback: ctx => {
                            if (!storyEditorScript) {
                                storyEditorScript = core_utils.loadScript(_gcStoryEditorScript).then(() => {
                                    const buttonHTML =
                                        '<button type="button" class="open-dialog" data-trix-action="img" data-trix-attribute="myDialog" title="Image">Image</button>' +
                                        '<button type="button" class="open-dialog" data-trix-action="embed" data-trix-attribute="embedDialog" title="Embed">Embed</button>' +
                                        '<button type="button" class="open-dialog" data-trix-action="html" data-trix-attribute="HTMLDialog" title="HTML">HTML</button>';

                                    const groupElement = Trix.config.toolbar.content.querySelector('.text_tools');
                                    groupElement.insertAdjacentHTML('beforeend', buttonHTML);

                                    /** @type {Object.<string,Object.<string, any>> */
                                    const attributes = {
                                        default: {
                                            tagName: 'story-block',
                                            parse: false
                                        },
                                        quote: {
                                            tagName: 'story-quote',
                                            nestable: true
                                        },
                                        bulletList: {
                                            tagName: 'ul',
                                            parse: false
                                        },
                                        bullet: {
                                            tagName: 'li',
                                            listAttribute: 'bulletList',
                                            /**
                                             * @param {HTMLElement} element
                                             * @return {boolean}
                                             */
                                            test(element) {
                                                return (
                                                    Trix.tagName(element.parentNode) ==
                                                    attributes[this.listAttribute].tagName
                                                );
                                            }
                                        },
                                        numberList: {
                                            tagName: 'ol',
                                            parse: false
                                        },
                                        number: {
                                            tagName: 'li',
                                            listAttribute: 'numberList',
                                            /**
                                             * @param {HTMLElement} element
                                             * @return {boolean}
                                             */
                                            test(element) {
                                                return (
                                                    Trix.tagName(element.parentNode) ==
                                                    attributes[this.listAttribute].tagName
                                                );
                                            }
                                        }
                                    };
                                    Trix.config.blockAttributes = attributes;

                                    const myDialogHtml = `<div class="dialog image-dialog" data-trix-attribute="image" data-trix-dialog="myDialog"><div class="dialog-content"><input type="url" required autofocus="true" name="src" placeholder="Image url"/></div><div class="button_group"><input type="button" value="Submit" data-trix-method="hideDialog" class="${_bndAddImageUrl}"></div></div><div class="dialog embed-dialog" data-trix-attribute="embed" data-trix-dialog="embedDialog"><div class="dialog-content"><textarea required autofocus="true" name="src" placeholder="Embed code" rows="5" style="width: 100%"></textarea></div><div class="button_group"><input type="button" value="Submit" data-trix-method="hideDialog" class="${_bndAddEmbedBlock}"></div></div><div class="dialog html-dialog" data-trix-attribute="html" data-trix-dialog="HTMLDialog"><div class="dialog-content"><textarea required autofocus="true" name="src" placeholder="HTML code" rows="5" style="width: 100%"></textarea></div><div class="button_group"><input type="button" value="Submit" data-trix-method="hideDialog" class="${_bndAddHTMLBlock}"></div></div>`;

                                    const dialogElement = Trix.config.toolbar.content.querySelector('.dialogs');
                                    dialogElement.insertAdjacentHTML('beforeend', myDialogHtml);
                                });
                                $('head').append(`<link rel='stylesheet' type='text/css' href='${_gcStoryEditorCSS}'>`);
                                // .append("<link rel='stylesheet' type='text/css' href='http://localhost:3000/assets/story.css'>");
                            }

                            let d;
                            switch (true) {
                                case this.storyData === null:
                                    d = _cnstStoryEmpty;
                                    break;
                                case !!this.storyData:
                                    d = _cnstStoryCreated;
                                    break;
                                // case !!this.storyData && !!this.storyEditor:
                                //     d = _cnstStoryOpen;
                                //     break;
                            }
                            ctx.setItems(tpls.project.storiesMenu({mode: d}));
                        },
                        /**
                         * @param {} ev
                         */
                        onItemSelectCallback: ev => {
                            /** @type {string} */
                            const m = $(ev.target).data('mode');
                            const self = this;
                            /** @type {string|null} */
                            let curId = null;
                            /** @type {*} */
                            let curObj;

                            switch (m) {
                                case _cnstStoryMenu_Create:
                                    this.storyData = new cStoryPageSettings({
                                        previewContent: $scope[_bndCurrentProject].description,
                                        pageInfo: {
                                            title: $scope[_bndCurrentProject].name
                                        }
                                    });
                                case _cnstStoryMenu_Settings:
                                    const ctidHandler = (id, force) => {
                                        if (curId !== id || force) {
                                            switch (storyBinds.get(_bndViewMode)) {
                                                // FIXME: local data have to be updated after save
                                                case _clsSettings: {
                                                    const d = {
                                                        projectId: $scope[_bndCurrentProject].id,
                                                        previewContent: storyBinds.get(_bndStoryContentInput), //editor.getDocument().toString(),
                                                        templateId: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.templateId`)
                                                            .val(),
                                                        coverImage: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.coverImage`)
                                                            .val(), //img.selectedData && img.selectedData.imageSrc || '',
                                                        title: storyBinds.$elem(`${_bndStoryPageInfo}.title`).val(),
                                                        authorName: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.authorName`)
                                                            .val(),
                                                        authorTitle: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.authorTitle`)
                                                            .val(),
                                                        source: storyBinds.$elem(`${_bndStoryPageInfo}.source`).val(),
                                                        sourceLink: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.sourceLink`)
                                                            .val(),
                                                        authorBio: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.authorBio`)
                                                            .val(),
                                                        authorImage: storyBinds
                                                            .$elem(`${_bndStoryPageInfo}.authorImage`)
                                                            .val(), //upic.selectedData && upic.selectedData.imageSrc
                                                        socialTweet: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.tweet`)
                                                            .val(),
                                                        socialTitle: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.ogTitle`)
                                                            .val(),
                                                        socialDescription: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.ogDescription`)
                                                            .val(),
                                                        socialImage: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.ogImage`)
                                                            .val(),
                                                        seoTitle: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.seoTitle`)
                                                            .val(),
                                                        seoDescription: storyBinds
                                                            .$elem(`${_bndStorySocialInfo}.seoDescription`)
                                                            .val()
                                                    };

                                                    if (!Object.isEqual(curObj, d)) {
                                                        core.moses.announce(_rFeatureQuestion, {
                                                            question: {
                                                                featureName: enmFeatureQuestion.StoryEditor,
                                                                question: enmFeatureQuestionEvent.UpdateStorySettings,
                                                                values: d
                                                            }
                                                        });
                                                    }
                                                    break;
                                                }
                                                case _clsChapter:
                                                    var d = {
                                                        projectId: $scope[_bndCurrentProject].id,
                                                        chapterId: curId,
                                                        content: storyBinds
                                                            .get(_bndStoryChapterInput)
                                                            ?.replace(/\<story\-block\>\<br\>\<\/story\-block\>/g, ''), //editor.getDocument().toString(),
                                                        title: storyBinds.$elem(_bndStoryEditor_ChapterTitle).val(),
                                                        partType: storyBinds.get(_bndStoryChapterPartType),
                                                        flow: storyBinds
                                                            .$elem(_bndViewMode)
                                                            .find('input[name="flow"]:checked')
                                                            .val()
                                                    };

                                                    if (!Object.isEqual(curObj, d)) {
                                                        core.moses.announce(_rFeatureQuestion, {
                                                            question: {
                                                                featureName: enmFeatureQuestion.StoryEditor,
                                                                question: enmFeatureQuestionEvent.UpdateStoryChapter,
                                                                values: d
                                                            }
                                                        });
                                                    }
                                                    break;
                                            }
                                            if (force && id === null) {
                                                return;
                                            }
                                        } else if (curId === id) {
                                            return;
                                        }

                                        curId = id;

                                        switch (true) {
                                            case !!id:
                                                var d = {};
                                                var rg;

                                                d[_bndViewMode] = _clsProcess;
                                                d[_bndStoryWindowTitle] = core.getMsg(
                                                    _msgStoryEditorWindowHeader_Chapter
                                                );

                                                core.moses
                                                    .announce(
                                                        _rFeatureQuestion,
                                                        (rg = {
                                                            question: {
                                                                featureName: enmFeatureQuestion.StoryEditor,
                                                                question: enmFeatureQuestionEvent.QueryStoryChapter,
                                                                values: {
                                                                    projectId: $scope[_bndCurrentProject].id,
                                                                    chapterId: id
                                                                }
                                                            }
                                                        })
                                                    )
                                                    .then(
                                                        /* cStoryPart */ obj => {
                                                            curObj = {
                                                                projectId: $scope[_bndCurrentProject].id,
                                                                chapterId: curId
                                                            };
                                                            d[_bndViewMode] = _clsChapter;
                                                            /**  @type {cTaskInfo} */
                                                            const task = core_DO.task(id);
                                                            /** @type {cStoryPart} */
                                                            const data = new $classes[_cStoryPart](
                                                                (obj.response.taskId && obj.response) || {
                                                                    taskId: id,
                                                                    title: task.name,
                                                                    content: task.description
                                                                }
                                                            );

                                                            storyBinds.elem(_bndViewMode).reset();

                                                            curObj.title = d[_bndStoryEditor_ChapterTitle] = data.title;
                                                            curObj.partType = d[_bndStoryChapterPartType] =
                                                                data.partType;

                                                            const editor = $(`#${_clsStoryEditor_chapter_editor}`).get(
                                                                0
                                                            ).editor;
                                                            editor.setSelectedRange([0, 0]);
                                                            editor.insertHTML((curObj.content = data.content || ''));

                                                            const $vm = storyBinds
                                                                .$elem(_bndViewMode)
                                                                .find('input[name="flow"]');

                                                            let el = $vm
                                                                .filter(':checked')
                                                                .attr('checked', false)
                                                                .get(0);
                                                            el && (el.checked = false);

                                                            el = $vm
                                                                .filter(`[value="${(curObj.flow = data.flow)}"]`)
                                                                .get(0);
                                                            el && (el.checked = true);

                                                            storyBinds.set(d);
                                                        }
                                                    )
                                                    .catch(obj => {
                                                        console.error(obj);
                                                    });
                                                break;
                                            default:
                                                var d = {};
                                                var rg;

                                                d[_bndViewMode] = _clsProcess;
                                                d[_bndStoryWindowTitle] = core.getMsg(
                                                    _msgStoryEditorWindowHeader_Settings
                                                );

                                                core.moses
                                                    .announce(
                                                        _rFeatureQuestion,
                                                        (rg = {
                                                            question: {
                                                                featureName: enmFeatureQuestion.StoryEditor,
                                                                question: enmFeatureQuestionEvent.QueryStorySettings,
                                                                values: {projectId: $scope[_bndCurrentProject].id}
                                                            }
                                                        })
                                                    )
                                                    .then(
                                                        /** @param {cStoryPageSettings} obj */

                                                        obj => {
                                                            const d = {};
                                                            obj = new cStoryPageSettings(obj.response);

                                                            curObj = {
                                                                projectId: $scope[_bndCurrentProject].id,
                                                                previewContent: obj.previewContent,
                                                                templateId: obj.pageInfo.templateId,
                                                                coverImage: obj.pageInfo.coverImage,
                                                                title: obj.pageInfo.title,
                                                                authorName: obj.pageInfo.authorName,
                                                                authorTitle: obj.pageInfo.authorTitle,
                                                                source: obj.pageInfo.source,
                                                                sourceLink: obj.pageInfo.sourceLink,
                                                                authorBio: obj.pageInfo.authorBio,
                                                                authorImage: obj.pageInfo.authorImage,
                                                                socialTweet: obj.pageSocial?.tweet,
                                                                socialTitle: obj.pageSocial?.ogTitle,
                                                                socialDescription: obj.pageSocial?.ogDescription,
                                                                socialImage: obj.pageSocial?.ogImage,
                                                                seoTitle: obj.pageSocial?.seoTitle,
                                                                seoDescription: obj.pageSocial?.seoDescription
                                                            };

                                                            if (m == _cnstStoryMenu_Create) {
                                                                obj.previewContent = self.storyData.previewContent;
                                                                obj.pageInfo.title = self.storyData.pageInfo.title;
                                                            }

                                                            self.storyData = obj;

                                                            d[_bndViewMode] = _clsSettings;
                                                            d[_bndStoryPageInfo] = obj.pageInfo;
                                                            d[_bndStorySocialInfo] = obj.pageSocial;

                                                            storyBinds.elem(_bndViewMode).reset();

                                                            const editor = $(`#${_clsStoryEditor_editor}`).get(0)
                                                                .editor;
                                                            editor.setSelectedRange([0, 0]);
                                                            editor.insertHTML(obj.previewContent || '');
                                                            storyBinds.set(d);
                                                            [
                                                                `${_bndStoryPageInfo}.coverImage`,
                                                                `${_bndStoryPageInfo}.authorImage`,
                                                                `${_bndStorySocialInfo}.ogImage`
                                                            ].forEach(n => {
                                                                const $el = storyBinds.$elem(n);
                                                                $el.imageSelector('destroy');
                                                                $el.imageSelector();
                                                            });
                                                        }
                                                    );
                                                break;
                                        }
                                        storyBinds.set(d);
                                    };
                                    const fupHandler = () => {
                                        const images = core_DO
                                            .files()
                                            .filter(f => f.location.match(/\.(png|jpg|jpeg)$/i));

                                        [
                                            `${_bndStoryPageInfo}.coverImage`,
                                            `${_bndStoryPageInfo}.authorImage`,
                                            `${_bndStorySocialInfo}.ogImage`
                                        ].forEach(n => {
                                            const $el = storyBinds.$elem(n);
                                            const val = $el.val();

                                            if ($el.css('display') == 'none') {
                                                $el.imageSelector('destroy');
                                            }

                                            // if (n == _bndStoryPageInfo + '.authorImage') {
                                            //     images.unshift({
                                            //         fileName: 'userAvatar',
                                            //         location: imgs.users.getSlice($scope[_bndCurrentWorkspaceUser].id, _imcOriginal, _avSVMedium, _imgDataType)
                                            //     })
                                            // }

                                            const fs = $el.data('text') || '';
                                            const s =
                                                fs &&
                                                `<option value="">${fs}</option>${images
                                                    .map(
                                                        n =>
                                                            `<option value="${n.location}"${
                                                                val && n.location == val ? 'selected="true"' : ''
                                                            }>${n.fileName}</option>`
                                                    )
                                                    .join('')}`;
                                            $el.html(s);
                                            $el.imageSelector();
                                        });
                                    };

                                    core_DO.onChangeCTID.subscribe(ctidHandler);
                                    core_DO.onFilesUpdate.subscribe(fupHandler);

                                    let storedHeight;
                                    this.storyEditor = $(tpls.project.storyEditorWindow())
                                        .appendTo(`#${_clsTree}`)
                                        .on(
                                            'click',
                                            `.${_clsStoryEditorWindow_Header} .${_idSymbolCircleXBlack}`,
                                            (storyEditorClose = ev => {
                                                // $('#' + _clsCoverImage + '-dd-placeholder').ddslick('destroy');
                                                core_DO.onChangeCTID.unsubscribe(ctidHandler);
                                                core_DO.onFilesUpdate.unsubscribe(fupHandler);
                                                storyBinds.free();
                                                this.storyEditor.remove();
                                                this.storyEditor = null;
                                                // document.removeEventListener('trix-attachment-add', picUploadHandler);
                                            })
                                        )
                                        .on('click', `.${_idSymbolDropdown}`, () => {
                                            if (this.storyEditor.hasClass(_clsExpanded)) {
                                                storedHeight = this.storyEditor.css('height');
                                                this.storyEditor.css({
                                                    height: 'auto',
                                                    minHeight: 0
                                                });
                                                this.storyEditor.removeClass(_clsExpanded);
                                            } else {
                                                this.storyEditor.css({
                                                    height: storedHeight,
                                                    minHeight: 300
                                                });
                                                this.storyEditor.addClass(_clsExpanded);
                                            }
                                        })
                                        .on('click', `.${_bndAddImageUrl}`, ev => {
                                            const $src = $(ev.target)
                                                .parents('.image-dialog')
                                                .find('input[type="url"]');
                                            this.storyEditor
                                                .find('trix-editor:visible')
                                                .get(0)
                                                .editor.insertHTML(`<img src="${$src.val()}" />`);
                                            $src.val('');
                                        })
                                        .on('click', `.${_bndAddEmbedBlock}`, ev => {
                                            const $src = $(ev.target)
                                                .parents('.embed-dialog')
                                                .find('textarea');
                                            const editor = this.storyEditor.find('trix-editor:visible').get(0).editor;
                                            const attach = new Trix.Attachment({content: $src.val()});
                                            $src.val('');
                                            editor.insertAttachment(attach);
                                        })
                                        .on('click', `.${_bndAddHTMLBlock}`, ev => {
                                            const $src = $(ev.target)
                                                .parents('.html-dialog')
                                                .find('textarea');
                                            const editor = this.storyEditor.find('trix-editor:visible').get(0).editor;
                                            const s = $src.val().replace(/<\/?([\w\-]+)>/g, a => {
                                                const c = a.indexOf('/') > -1;
                                                const m = a.replace(/<|\/|>/g, '').toUpperCase();
                                                const r = a;
                                                switch (m) {
                                                    case 'I':
                                                        return `<${c ? '/' : ''}em>`;
                                                    case 'B':
                                                        return `<${c ? '/' : ''}strong>`;
                                                    // case 'UL': return '<' + (c ? '/' : '') + 'story-list>';
                                                    // case 'OL': return '<' + (c ? '/' : '') + 'story-olist>';
                                                    default:
                                                        return a;
                                                }
                                            });
                                            editor.insertHTML(s);
                                            $src.val('');
                                        })
                                        .on('click', `.${_bndSaveButton}`, ev => {
                                            ctidHandler(null, true);
                                            ev.preventDefault();
                                        });

                                    var storyBinds = new $classes.Binds(this.storyEditor);

                                    core_dom.resizable(this.storyEditor, {
                                        dragHandle: `.${_clsStoryEditorWindow_Header}`,
                                        minTop: core_dom.elementOffset(`#${_clsTree} .${_clsTreeToolbar}`).height
                                    });

                                    core.moses.announce(_rListFiles, {
                                        workspaceId: $scope[_bndCurrentWorkspace].id,
                                        projectId: $scope[_bndCurrentProject].id
                                    });

                                    storyBinds.set(_bndViewMode, _clsProcess);
                                    Promise.resolve(storyEditorScript).then(() => {
                                        ctidHandler(undefined);
                                    });

                                    // var picUploadHandler = function(event) {
                                    //     var attachment;
                                    //     attachment = event.attachment;
                                    //     debugger;
                                    //     if (attachment.file) {
                                    //         return uploadAttachment(attachment);
                                    //     }
                                    // }
                                    // document.addEventListener('trix-attachment-add', picUploadHandler);
                                    break;
                                case _cnstStoryMenu_Preview:
                                    window.open(
                                        window['appConfig']['config']['sview'] +
                                        [
                                            `${_gcStoryPreviewUri}?key=${core.sessionId()}`,
                                            `wsid=${$scope[_bndCurrentWorkspace].id}`,
                                            `pid=${$scope[_bndCurrentProject].id}`
                                        ].join('&'),
                                        '_blank'
                                    );
                                    break;
                                case _cnstStoryMenu_Publish:
                                    window.open(
                                        window['appConfig']['config']['sview'] +
                                        [
                                            `${_gcStoryPublishUri}?key=${core.sessionId()}`,
                                            `wsid=${$scope[_bndCurrentWorkspace].id}`,
                                            `pid=${$scope[_bndCurrentProject].id}`
                                        ].join('&'),
                                        '_blank'
                                    );
                                    break;
                            }
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtModalDialog, _wiExport2File, {
                        modal: true,
                        buttons: [_drbOK],
                        header: 'Export Project to File'
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtModalDialog, _wiReassignUserTasks, {
                        modal: true,
                        buttons: [_drbOK, _drbCancel],
                        buttonsLabel: [{id: _drbOK, label: 'Reassign tasks'}],
                        header: "Reassign user's tasks to another team member",
                        onClose(role, $el) {
                            return new Promise(rslv => {
                                const data = $el
                                    .find('form')
                                    .serializeArray()
                                    .reduce((obj, v) => {
                                        obj[v['name']] = v['value'];
                                        return obj;
                                    }, {});
                                self.stateChange(enmStateChangeTypes.ReassignTasks, null, data, true).then(rslv);
                            });
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtModalDialog, _wiConsultingTools, {
                        modal: true,
                        buttons: [_drbOK, _drbCancel],
                        header: 'Clone project to other workspace',
                        onClose(role, $el) {
                            if (role == _drbOK) {
                                return new Promise(rslv => {
                                    const $form = $el.find('form');
                                    const data = core_utils.serializeForm($form);
                                    data['workspaceId'] = $scope[_bndCurrentProject].workspaceId;
                                    data['projectId'] = $scope[_bndCurrentProject].id;
                                    core.moses.announce(_rConsultantCloneProject, data).then(rslv);
                                });
                            }
                        }
                    })
                );

                // var picker;
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiUploadFileMenu, {
                        menuTitle: core.getMsg(_msgUploadMenuHeader),
                        beforePopupCallback(ctx, data, $el) {
                            this.elData = $el.data();
                            if (core_DO.hasFeature(_fndDropBox) && !$('#dropboxjs').length) {
                                $(
                                    `<div id="dropboxjs" data-app-key="${window['appConfig']['config']['dropboxkey']}"/>`
                                ).appendTo('body');
                                !__DropBoxLoaded && (__DropBoxLoaded = core_utils.loadScript(_gcDropBoxScript));
                            }

                            !__BoxLoaded &&
                            core_DO.hasFeature(_fndBox) &&
                            (__BoxLoaded = core_utils.loadScript(_gcBoxScript));
                            !__GoogleClientLoaded &&
                            core_DO.hasFeature(_fndGoogleDrive) &&
                            (__GoogleClientLoaded = core_utils.loadScript(_gcGoogleDriveClient));
                            !__OneDriveLoaded &&
                            core_DO.hasFeature(_fndOneDrive) &&
                            (__OneDriveLoaded = core_utils.loadScript(_gcOneDriveScript));

                            ctx.setItems(tpls.project.uploadFileMenu());
                        },
                        onItemSelectCallback: (ev, ctx, data) => {
                            switch (data['role']) {
                                case enmFileType.Upload:
                                    core_DO.hasFeature(_fndCloudStorage) &&
                                    fileUploader(
                                        ctx.options.elData['stream'],
                                        ctx.options.elData['stream'] == enmFileStreams.Task && core_DO.getCTID()
                                    );
                                    break;
                                case enmFileType.Dropbox:
                                    core_DO.hasFeature(_fndDropBox) &&
                                    __DropBoxLoaded.then(() => {
                                        Dropbox.choose({
                                            linkType: 'direct',
                                            success(files) {
                                                core_stat.track(_mpcFileDropbox);
                                                (files || []).forEach(f => {
                                                    let r;
                                                    core.moses
                                                        .announce(
                                                            _rNewFile,
                                                            (r = {
                                                                file: {
                                                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                                                    projectId: $scope[_bndCurrentProject].id,
                                                                    stream: ctx.options.elData['stream'],
                                                                    type: enmFileType.Dropbox,
                                                                    objectId:
                                                                        (ctx.options.elData['stream'] ==
                                                                            enmFileStreams.Task &&
                                                                            core_DO.getCTID()[0]) ||
                                                                        undefined,
                                                                    location: f['link'],
                                                                    fileName: f['name'],
                                                                    fileSize: f['bytes'],
                                                                    timeUploaded: new Date().getTime()
                                                                }
                                                            })
                                                        )
                                                        .catch(o => {
                                                            __USE_ROLLBAR &&
                                                            Rollbar.error(
                                                                'project::wiUploadFileMenu::rNewFile/Dropbox fail response',
                                                                {req: r, resp: o}
                                                            );
                                                        });
                                                });
                                            }
                                        });
                                    });
                                    break;
                                case enmFileType.Box:
                                    if (core_DO.hasFeature(_fndBox)) {
                                        __BoxLoaded.then(() => {
                                            const box = new BoxSelect({
                                                clientId: window['appConfig']['config']['boxkey'],
                                                linkType: 'direct',
                                                multiselect: true
                                            });
                                            box.success(files => {
                                                core_stat.track(_mpcFileBox);
                                                (files || []).forEach(f => {
                                                    let r;
                                                    core.moses
                                                        .announce(
                                                            _rNewFile,
                                                            (r = {
                                                                file: {
                                                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                                                    projectId: $scope[_bndCurrentProject].id,
                                                                    stream: ctx.options.elData['stream'],
                                                                    type: enmFileType.Box,
                                                                    objectId:
                                                                        (ctx.options.elData['stream'] ==
                                                                            enmFileStreams.Task &&
                                                                            core_DO.getCTID()[0]) ||
                                                                        undefined,
                                                                    location: f['url'],
                                                                    fileName: f['name'],
                                                                    fileSize: f['size'],
                                                                    timeUploaded: new Date().getTime()
                                                                }
                                                            })
                                                        )
                                                        .catch(o => {
                                                            __USE_ROLLBAR &&
                                                            Rollbar.error(
                                                                'project::wiUploadFileMenu::rNewFile/box fail response',
                                                                {req: r, resp: o}
                                                            );
                                                        });
                                                });
                                            });
                                            box.launchPopup();
                                        });
                                    }
                                    break;
                                case enmFileType.GoogleDrive:
                                    if (core_DO.hasFeature(_fndGoogleDrive)) {
                                        __GoogleClientLoaded
                                            .then(() => {
                                                const picker = new FilePicker({
                                                    apiKey: window['appConfig']['config']['gapiwebkey'],
                                                    clientId: window['appConfig']['config']['gapiid']
                                                });
                                                return picker;
                                            })
                                            .then(picker => {
                                                picker.onSelect = file => {
                                                    const obj = {
                                                        workspaceId: $scope[_bndCurrentWorkspace].id,
                                                        projectId: $scope[_bndCurrentProject].id,
                                                        stream: ctx.options.elData['stream'],
                                                        objectId:
                                                            (ctx.options.elData['stream'] == enmFileStreams.Task &&
                                                                core_DO.getCTID()[0]) ||
                                                            undefined,
                                                        location: file[google.picker.Document.URL],
                                                        timeUploaded: new Date().getTime()
                                                    };

                                                    file[google.picker.Document.EMBEDDABLE_URL] &&
                                                    (obj['preview'] = file[google.picker.Document.EMBEDDABLE_URL]);

                                                    switch (file[google.picker.Document.SERVICE_ID]) {
                                                        case 'maps':
                                                            obj['type'] = enmFileType.GoogleMaps;
                                                            if (file[google.picker.Document.THUMBNAILS]) {
                                                                const thmb = file[
                                                                    google.picker.Document.THUMBNAILS
                                                                    ].max(i => i.width);
                                                                obj['thumbnail'] = thmb['url'];
                                                            }

                                                            let req = null;
                                                            if (!file['latitude'] && !file['longitude']) {
                                                                //http://maps.google.com?ll=50.445236%2C30.525036&spn=0.000699%2C0.002135&ie=UTF8&z=19&t=roadmap
                                                                const m = obj['location'].match(
                                                                    /ll=([\d\.]+)%2C([\d\.]+)/
                                                                );
                                                                if (m) {
                                                                    req = core_utils.string.sprintf(
                                                                        _gcReverseGeocodeUrl,
                                                                        m[1],
                                                                        m[2],
                                                                        window['appConfig']['config']['gapiwebkey']
                                                                    );
                                                                }
                                                            } else {
                                                                req = core_utils.string.sprintf(
                                                                    _gcReverseGeocodeUrl,
                                                                    file['latitude'],
                                                                    file['longitude'],
                                                                    window['appConfig']['config']['gapiwebkey']
                                                                );
                                                            }

                                                            let promise;
                                                            if (req) {
                                                                promise = $.getJSON(req);
                                                            } else {
                                                                promise = true;
                                                            }

                                                            $.when(promise).then(data => {
                                                                if (promise === true) {
                                                                    obj['fileName'] = core.getMsg(_msgLocationUknown);
                                                                } else {
                                                                    if (data['status'] == 'OK') {
                                                                        if (data['results']) {
                                                                            obj['fileName'] = core.getMsg(
                                                                                _msgLocationLabel,
                                                                                {
                                                                                    address:
                                                                                        data['results'][0][
                                                                                            'formatted_address'
                                                                                            ]
                                                                                }
                                                                            );
                                                                        } else {
                                                                            obj['fileName'] = core.getMsg(
                                                                                _msgLocationUknown
                                                                            );
                                                                        }
                                                                    } else {
                                                                        obj['fileName'] = core.getMsg(
                                                                            _msgLocationUknown
                                                                        );
                                                                    }
                                                                }
                                                                let r;
                                                                core.moses
                                                                    .announce(_rNewFile, (r = {file: obj}))
                                                                    .catch(o => {
                                                                        __USE_ROLLBAR &&
                                                                        Rollbar.error(
                                                                            'project::wiUploadFileMenu::rNewFile/GoogleDrive fail response',
                                                                            {req: r, resp: o}
                                                                        );
                                                                    });
                                                            });
                                                            break;
                                                        default:
                                                            obj['fileName'] = file[google.picker.Document.NAME];
                                                            obj['type'] = enmFileType.GoogleDrive;
                                                            obj['thumbnail'] = file[google.picker.Document.ICON_URL];

                                                            file['sizeBytes'] && (obj['fileSize'] = file['sizeBytes']);
                                                            let r;
                                                            core.moses
                                                                .announce(_rNewFile, (r = {file: obj}))
                                                                .catch(o => {
                                                                    __USE_ROLLBAR &&
                                                                    Rollbar.error(
                                                                        'project::wiUploadFileMenu::rNewFile/default fail response',
                                                                        {req: r, resp: o}
                                                                    );
                                                                });
                                                            break;
                                                    }
                                                };
                                                picker.open();
                                            });
                                    }
                                    break;
                                case enmFileType.OneDrive:
                                    if (core_DO.hasFeature(_fndOneDrive)) {
                                        __OneDriveLoaded.then(() => {
                                            OneDrive.open({
                                                clientId: appConfig.config.onedrivekey,
                                                action: 'share',
                                                multiSelect: true,
                                                openInNewWindow: true,
                                                advanced: {
                                                    redirectUri: `${location.protocol}//${location.host}${_gcOneDriveRedirect}`
                                                },
                                                success(files) {
                                                    files['value'].forEach(f => {
                                                        let r;
                                                        core.moses
                                                            .announce(
                                                                _rNewFile,
                                                                (r = {
                                                                    file: {
                                                                        workspaceId: $scope[_bndCurrentWorkspace].id,
                                                                        projectId: $scope[_bndCurrentProject].id,
                                                                        stream: ctx.options.elData['stream'],
                                                                        type: enmFileType.OneDrive,
                                                                        objectId:
                                                                            (ctx.options.elData['stream'] ==
                                                                                enmFileStreams.Task &&
                                                                                core_DO.getCTID()[0]) ||
                                                                            undefined,
                                                                        location: f['webUrl'],
                                                                        fileName: f['name'],
                                                                        fileSize: f['size'],
                                                                        timeUploaded: new Date().getTime()
                                                                    }
                                                                })
                                                            )
                                                            .catch(o => {
                                                                __USE_ROLLBAR &&
                                                                Rollbar.error(
                                                                    'project::wiUploadFileMenu::rNewFile/box fail response',
                                                                    {req: r, resp: o}
                                                                );
                                                            });
                                                    });
                                                },
                                                error(e) {
                                                    console.error(e);
                                                }
                                            });
                                        });
                                    }
                            }
                        }
                    })
                );

                $(document).on('submit', `#${_clsProjectAddUserMenu} form`, function () {
                    const $form = $(this);

                    if (this.checkValidity?.() === false) {
                        alert(core.getMsg(_msgCheckInputField));
                        return false;
                    }

                    const data = core_utils.serializeForm($form);

                    data['workspaceId'] = $scope[_bndCurrentWorkspace].id;
                    data['projectId'] = $scope[_bndCurrentProject].id;
                    data['projectRole'] = enmWorkspaceUserRole.Leader;

                    let r;
                    core.moses
                        .announce(_rAddUserToWorkspace, (r = data))
                        .then(data => {
                            let msg;
                            switch (data['result']) {
                                case enmAddUserToWorkspaceResponseStatus.Added:
                                case enmAddUserToWorkspaceResponseStatus.InvitationSent:
                                    // msg = core.getMsg(_msgUserAdded);
                                    // data['workspaceUser'] && core_DO.addUser(data['workspaceUser']);
                                    // msg = core.getMsg(_msgInvitationSent);
                                    // data['workspaceUser'] && core_DO.addUser(data['workspaceUser']);
                                    core_stat.track(_mpcUserInvited);
                                    break;
                                case enmAddUserToWorkspaceResponseStatus.NoSuchUser:
                                    msg = core.getMsg(_msgNoSuchUser);
                                    break;
                                case enmAddUserToWorkspaceResponseStatus.NotAllowed:
                                    if (data.billingStatus == enmBillingResponseStatus.OutOfLimits) {
                                        msg = core.getMsg(_msgAddUserToWorkspaceOutOfLimits);
                                    } else {
                                        msg = core.getMsg(_msgNotAllowed2AddUser);
                                    }
                                    break;
                            }
                            msg && alert(msg);
                        })
                        .catch(o => {
                            __USE_ROLLBAR &&
                            Rollbar.error('project::clsProjectAddUserMenu::rAddUserToWorkspace fail response', {
                                req: r,
                                resp: o
                            });
                        });

                    core.pages
                        .page(_pidProject)
                        .widget(_wiAddUserToProjectMenu)
                        .hide();
                    return false;
                });

                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiAddUserToTaskMenu, {
                        menuId: _clsAddUserToTask,
                        menuTitle: core.getMsg(_msgAddUser),
                        beforePopupCallback: (ctx, data) => {
                            const users = core_DO.members();
                            const self = this;
                            const s = [];
                            const hash = data['execs'];

                            const isViewOnly = !(
                                core.mayI.removeExecutor() ||
                                (data['uid'] == $scope[_bndCurrentWorkspaceUser].id &&
                                    core.mayI.removeSelfAsExecutor(data)) ||
                                core.mayI.addExecutor(data) ||
                                (data['uid'] == $scope[_bndCurrentWorkspaceUser].id && core.mayI.becomeExecutor(data))
                            );

                            const $active = [];
                            var $teams = [];
                            const $spectators = [];
                            const $resources = [];

                            if (isViewOnly && hash.length == 0) {
                                return false;
                            }

                            ((isViewOnly && hash.map(id => core_DO.member(id))) || users).forEach(u => {
                                if (!u) {
                                    return;
                                }
                                const obj = {
                                    data: {
                                        uid: u['id'],
                                        sm: true
                                    },
                                    classes: ['user'],
                                    label:
                                        tpls.Avatar({id: u.id, namespace: _clsUserPic}) +
                                        soy.$$escapeHtml(`${u['firstName']} ${u['lastName']}`)
                                };

                                if (isViewOnly) {
                                    obj.classes.push(_clsNotSelectableItem);
                                } else if (hash.indexOf(u['id']) > -1) {
                                    obj.classes.push(_clsShadedItem);
                                    obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                }

                                switch (u.role) {
                                    case enmWorkspaceUserRole.Owner:
                                    case enmWorkspaceUserRole.Leader:
                                    case enmWorkspaceUserRole.Participant:
                                        $active.push(obj);
                                        break;
                                    case enmWorkspaceUserRole.Spectator:
                                        $spectators.push(obj);
                                        break;
                                    case enmWorkspaceUserRole.Resource:
                                        $resources.push(obj);
                                        break;
                                }
                                // s.push(obj);
                            });

                            if ($active.length) {
                                s.push({
                                    classes: [_clsPopupMenuHeader],
                                    label: core.getMsg(_msgAddUserMenuHeader).format({qty: $active.length})
                                });
                                [].push.apply(s, $active);
                            }

                            var $teams = core_DO.getTeams(true).sort(teamSort);
                            if (core_DO.hasFeature(_fndProjectTeams) && $teams.length) {
                                s.push({
                                    classes: [_clsPopupMenuHeader],
                                    label: core.getMsg(_msgAddTeamMenuHeader).format({qty: $teams.length})
                                });
                                $teams.forEach(d => {
                                    const obj = {
                                        data: {
                                            uid: d.id,
                                            sm: false
                                        },
                                        classes: ['user'],
                                        label:
                                            tpls.Avatar({id: d.id, namespace: _clsUserPic}) + soy.$$escapeHtml(d.name)
                                    };

                                    if (isViewOnly) {
                                        obj.classes.push(_clsNotSelectableItem);
                                    } else if (data.teamId == d.id) {
                                        obj.classes.push(_clsShadedItem);
                                        obj.label += tpls.Icon({id: _idSymbolMarkDone});
                                    }

                                    s.push(obj);
                                });
                            }

                            if ($spectators.length) {
                                s.push({
                                    classes: [_clsPopupMenuHeader],
                                    label: core.getMsg(_msgAddSpectatorMenuHeader).format({qty: $spectators.length})
                                });
                                [].push.apply(s, $spectators);
                            }

                            if ($resources.length) {
                                s.push({
                                    classes: [_clsPopupMenuHeader],
                                    label: core.getMsg(_msgAddResourceMenuHeader).format({qty: $resources.length})
                                });
                                [].push.apply(s, $resources);
                            }
                            ctx.setItems(s);
                        },
                        onItemSelectCallback: (ev, ctx, data) => {
                            DEBUG && console.assert(data.uid);
                            switch (true) {
                                case core_dom.hasClass(ev.target, _clsShadedItem):
                                    switch (data.sm) {
                                        case true:
                                            if (
                                                core.mayI.removeExecutor() ||
                                                (data.uid == $scope[_bndCurrentWorkspaceUser].id &&
                                                    core.mayI.removeSelfAsExecutor(data))
                                            ) {
                                                core_stat.track(_mpcTaskExecutorRemoved);
                                                this.stateChange(enmStateChangeTypes.RemoveTaskExecutor, data.id, {
                                                    workspaceUserId: data.uid
                                                });
                                                core_DO.removeExecutor(data.id, data.uid);
                                                this._tree.redraw();
                                            }
                                            break;
                                        case false:
                                            if (core.mayI.removeExecutor()) {
                                                core_stat.track(_mpcTaskUnassignedFromTeam);
                                                this.stateChange(enmStateChangeTypes.UpdateTask, data.id, {
                                                    teamId: null
                                                });
                                                core_DO.assignTeam(data.id, {teamId: null});
                                                this._tree.redraw();
                                            }
                                            break;
                                    }
                                    break;
                                default:
                                    switch (data.sm) {
                                        case true:
                                            if (
                                                core.mayI.addExecutor(data) ||
                                                (data.uid == $scope[_bndCurrentWorkspaceUser].id &&
                                                    core.mayI.becomeExecutor(data))
                                            ) {
                                                core_stat.track(_mpcTaskExecutorAdded);
                                                this.stateChange(enmStateChangeTypes.AddTaskExecutor, data.id, {
                                                    workspaceUserId: data.uid
                                                });
                                                core_DO.addExecutor(data.id, data.uid);
                                                this._tree.redraw();
                                            }
                                            break;
                                        case false:
                                            if (core.mayI.addExecutor(data)) {
                                                core_stat.track(_mpcTaskAssignedToTeam);
                                                this.stateChange(enmStateChangeTypes.UpdateTask, data.id, {
                                                    teamId: data.uid
                                                });
                                                core_DO.assignTeam(data.id, {teamId: data.uid});
                                                this._tree.redraw();
                                            }
                                            break;
                                    }
                                    break;
                            }
                            return true;
                        }
                    })
                );

                var self = this;
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiTaskTiming, {
                        menuTitle: 'Task Timing',
                        title: 'Task Timing',
                        custom: tpls.widgets.taskSchedule.popup(),
                        noClickHandler: true,
                        ignoreQuery: 'div.datepicker-container.datepicker-dropdown',
                        afterRender() {
                            const p = $classes.$factory(_wtTaskSchedule, _wiTaskSchedule, void 0, {
                                stateChangeCallback: self.stateChange.bind(self)
                            });
                            this._p = p.render(this.$rendered.find(`.${_clsTaskSchedule_PopupWrapper}`));
                        },
                        beforePopupCallback(ctx, data, $el) {
                            const p = ctx._p;
                            p._binds.set({task: data});
                            p.setData(data.id, data);
                        }
                    })
                );

                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiUserAction, {
                        menuTitle: core.getMsg(_msgUserAction),
                        beforePopupCallback(ctx, data) {
                            const user = core_DO.member(data['id']);
                            const iam = core_DO.member($scope[_bndCurrentWorkspaceUser].id);
                            let items = [enmWorkspaceUserRole.Spectator];

                            if (user.id == iam.id) {
                                switch (user.role) {
                                    case enmWorkspaceUserRole.Participant:
                                    case enmWorkspaceUserRole.Leader:
                                        items = [enmWorkspaceUserRole.Leader, enmWorkspaceUserRole.Participant].concat(
                                            items
                                        );
                                        break;
                                    case enmWorkspaceUserRole.Spectator:
                                        items = [enmWorkspaceUserRole.Resource];
                                        break;
                                    case enmWorkspaceUserRole.Resource:
                                        items = [];
                                        break;
                                }
                            } else {
                                switch (iam.role) {
                                    case enmWorkspaceUserRole.Leader:
                                    case enmWorkspaceUserRole.Owner:
                                        switch (user.wsrole) {
                                            case enmWorkspaceUserRole.Resource:
                                                items = [];
                                                break;
                                            default:
                                                items = [enmWorkspaceUserRole.Leader, enmWorkspaceUserRole.Participant]
                                                    .concat(items)
                                                    .remove(user.role);
                                                break;
                                        }
                                        break;
                                }
                            }

                            const roles = [enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader];
                            ctx.setItems(
                                tpls.manageProjectUsers_userActionMenu({
                                    list: items,
                                    curRole: user.role,
                                    isOwnerOrLeader: roles.indexOf(iam.role) > -1 || roles.indexOf(iam.wsrole) > -1,
                                    isOwner: user.role == enmWorkspaceUserRole.Owner
                                })
                            );
                        },
                        onItemSelectCallback: (ev, ctx, data) => {
                            if (data.role == _evReassignUserTask) {
                                this.widget(_wiReassignUserTasks).show(
                                    tpls.manageProjectUsers_reassignTasks({
                                        user: core_DO.members().$item(data.id),
                                        usersList: core_DO.members()
                                    })
                                );
                            } else {
                                let r;
                                const oldrole = core_DO.member(data.id).role;
                                core.moses
                                    .announce(
                                        _rProjectMembership,
                                        (r = {
                                            workspaceUserId: data['id'],
                                            projectId: $scope[_bndCurrentProject].id,
                                            become: data['value']
                                        })
                                    )
                                    .catch(
                                        /* cErrorMessage */ o => {
                                            switch (o.error) {
                                                case enmErrorMessage.AccessDenied:
                                                    Alertify.log.error(core.getMsg(_msgAccessDenied));
                                                    break;
                                                case enmErrorMessage.ReadOnly:
                                                    Alertify.log.error(core.getMsg(_msgWorkspaceIsReadOnly));
                                                    break;
                                                case enmErrorMessage.Error:
                                                case enmErrorMessage.IllegalMessage:
                                                    Alertify.log.error(core.getMsg(_msgUknownError, o));
                                                    break;
                                                case enmErrorMessage.NoSuchSession:
                                                    Alertify.log.warning(core.getMsg(_msgNoSuchSessionMessage));
                                                    break;
                                                default:
                                                    __USE_ROLLBAR &&
                                                    Rollbar.error(
                                                        'project::wiUserAction::rProjectMembership change user role fail response',
                                                        {req: r, resp: o}
                                                    );
                                                    break;
                                            }
                                            core_DO.changeMemberRole(data['id'], oldrole);
                                        }
                                    );
                                core_DO.changeMemberRole(data['id'], data['value']);
                            }
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtModalDialog, _wiGetEmbedCode, {
                        modal: true,
                        buttons: [_drbOK],
                        header: core.getMsg(_msgEmbedStoryHeader),
                        content: tpls.project.embedStoryGenerator(),
                        afterShow($el, ctx) {
                            const $form = $el.find('form');
                            const cb = () => {
                                const data = core_utils.serializeForm($form);
                                $el.find(`.${_bndGeneratedCode}`).val(
                                    tpls.project.embedStoryCode($.extend(false, data, ctx.options.url))
                                );
                            };
                            $el.find('textarea').on('focusin', cb);
                            cb();
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiExportAction, {
                        menuTitle: core.getMsg(_msgExportMenuTitle),
                        beforePopupCallback: ctx => {
                            ctx.setItems(
                                tpls.project.exportMenu({mode: this.is2ColumnsMode() ? ($groupHolder ? 2 : 1) : 0})
                            );
                        },
                        onItemSelectCallback: (ev, ctx, data) => {
                            const self = this;
                            let r;
                            switch (data['mode']) {
                                // case _expStoryEditorEmbed:
                                //     this.widget(_wiGetEmbedCode).show(undefined, {url: {sid: core.sessionId(), wsid: $scope[_bndCurrentWorkspace].id, pid: $scope[_bndCurrentProject].id, tid: 'actionplan'}});
                                //     break;
                                case _evShareProject:
                                    core_stat.track(_mpcShareSnapshot);
                                    core.moses.announce(_evShareProject, $scope[_bndCurrentProject]);
                                    break;
                                case _expPNG:
                                case _expPDF:
                                    core_stat.track(data['mode'] == _expPDF ? _mpcSharePdf : _mpcShareImage);
                                    this.treeHandlers.onEvent(
                                        _evTreeExport,
                                        (data['group'] && $groupHolder.data(_cnstGroupTree)) || this._tree,
                                        data['mode']
                                    );
                                    break;
                                case _expExcelExport:
                                    core.moses
                                        .announce(
                                            _rFeatureQuestion,
                                            (r = {
                                                question: {
                                                    featureName: 'ExcelExport',
                                                    question: 'ExportProjectToXLSX',
                                                    values: {
                                                        projectId: $scope[_bndCurrentProject].id
                                                    }
                                                }
                                            })
                                        )
                                        .then(obj => {
                                            if (obj.response.url) {
                                                if (core.isFirefox) {
                                                    window.open(obj.response.url, '_blank');
                                                } else {
                                                    window.location = obj.response.url;
                                                }
                                            }
                                        })
                                        .catch(res => {
                                            DEBUG && console.log('FAIL', res);
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'project::wiExportAction::rFeatureQuestion/ExportProjectToCSV fail response',
                                                {req: r, resp: res}
                                            );
                                            alert(core.getMsg(_msgServerError));
                                        });
                                    break;
                                case _expProjectFileIOExport:
                                    core.moses
                                        .announce(
                                            _rFeatureQuestion,
                                            (r = {
                                                question: {
                                                    featureName: 'ProjectFileIO',
                                                    question: 'ExportProjectToFile',
                                                    values: {
                                                        projectId: $scope[_bndCurrentProject].id
                                                    }
                                                }
                                            })
                                        )
                                        .then(obj => {
                                            if (obj.response.url) {
                                                self.widget(_wiExport2File).show(
                                                    tpls.project.E2FDialog({url: obj.response.url})
                                                );
                                            }
                                        })
                                        .catch(res => {
                                            DEBUG && console.log('FAIL', res);
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'project::wiExportAction::rFeatureQuestion/ProjectFileIO::ExportProjectToFile fail response',
                                                {req: r, resp: res}
                                            );
                                            alert(core.getMsg(_msgServerError));
                                        });
                                    break;
                                case _expProjectFileIOImport:
                                    const l = prompt('Import file location');
                                    if (!l) {
                                        return;
                                    }
                                    core.moses
                                        .announce(
                                            _rFeatureQuestion,
                                            (r = {
                                                question: {
                                                    featureName: 'ProjectFileIO',
                                                    question: 'ImportProjectFromFile',
                                                    values: {
                                                        projectId: $scope[_bndCurrentProject].id,
                                                        fileLocation: l
                                                    }
                                                }
                                            })
                                        )
                                        .then(obj => {
                                            window.location.reload();
                                        })
                                        .catch(res => {
                                            DEBUG && console.log('FAIL', res);
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'project::wiExportAction::rFeatureQuestion/ProjectFileIO::ImportProjectFromFile fail response',
                                                {req: r, resp: res}
                                            );
                                            alert(core.getMsg(_msgServerError));
                                        });
                                    break;
                                case _expGoogleCalendar:
                                    const openAndLoadGoogleCalendar = function openAndLoadGoogleCalendar() {
                                        const f = () => {
                                            core_utils.banner(core.getMsg(_msgGoogleCalendarLoadMessage));
                                            gapi.client.load('calendar', 'v3', () => {
                                                const req = gapi.client.calendar.calendarList.list();
                                                req.execute(resp => {
                                                    core_utils.banner();
                                                    const sel = core_DO.googleCalendars();
                                                    self.widget(_wiCalendarPicker).show(
                                                        tpls.project.calendarList({
                                                            selected: sel,
                                                            items: (resp.items || []).filter(d =>
                                                                /writer|owner/.test(d.accessRole)
                                                            )
                                                        }),
                                                        {current: sel === undefined ? null : sel}
                                                    );
                                                });
                                            });
                                        };

                                        if (!__GoogleClientLoaded) {
                                            window[_gcGoogleDriveCallbackFunction] = f;
                                            core_utils.loadScript(_gcGoogleDriveClient);
                                        } else {
                                            f.call(null);
                                        }
                                    };

                                    if (core_DO.projectAuthorizedAtGoogle()) {
                                        const token = core_DO.googleAuthToken();
                                        if (!token) {
                                            core_auth
                                                .googleAuth(
                                                    window['appConfig']['config']['gapiid'],
                                                    'https://www.googleapis.com/auth/calendar'
                                                )
                                                .then(obj => {
                                                    core_DO.googleAuthToken(undefined, obj['access_token']);
                                                    openAndLoadGoogleCalendar(obj['access_token']);
                                                })
                                                .catch(obj => {
                                                    console.error(obj);
                                                    __USE_ROLLBAR &&
                                                    Rollbar.error(
                                                        'project::wiExportAction::expGoogleCalendar//googleAuth fail response',
                                                        obj
                                                    );
                                                });
                                        } else {
                                            openAndLoadGoogleCalendar(token);
                                        }
                                    } else {
                                        window[_gcGoogleAuthCallback] = (prid => r => {
                                            core_DO.projectAuthorizedAtGoogle(prid, r);
                                            core_auth
                                                .googleAuth(
                                                    window['appConfig']['config']['gapiid'],
                                                    'https://www.googleapis.com/auth/calendar'
                                                )
                                                .then(obj => {
                                                    core_DO.googleAuthToken(undefined, obj['access_token']);
                                                    openAndLoadGoogleCalendar(obj['access_token']);
                                                })
                                                .catch(obj => {
                                                    console.log(obj);
                                                    __USE_ROLLBAR &&
                                                    Rollbar.error(
                                                        'project::wiExportAction::expGoogleCalendar//googleAuth/cb fail response',
                                                        obj
                                                    );
                                                });
                                        })($scope[_bndCurrentProject].id);

                                        window.open(
                                            `${_CONF_BASEURL}/g/auth?sessionKey=${core.sessionId()}&workspaceId=${
                                                $scope[_bndCurrentWorkspace].id
                                            }&featureName=GoogleCalendar`,
                                            '_blank'
                                        );
                                    }
                                    break;
                            }
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtContextMenu, _wiTaskContextMenu, {
                        parent: `#${_clsTree}`,
                        items: [
                            {
                                callback(d, item) {
                                    if (d['type'] == enmTaskInfoTypes.Group) {
                                        item.label = core.getMsg(_msgContextMenuOpenGroup);
                                        item.role = _evOpenGroup;
                                        item.default = true;
                                        return true;
                                    }
                                    switch (true) {
                                        case d.state == enmTaskInfoStates.Unblocked &&
                                        core_DO.hasFeature(_fndTaskDuration):
                                            item.label = 'Mark as In Progress';
                                            item.role = _evMarkStarted;
                                            return core.mayI.completeTask(d) || 0;
                                        case d.state == enmTaskInfoStates.Unblocked &&
                                        !core_DO.hasFeature(_fndTaskDuration):
                                            item.label = 'Mark as Done';
                                            item.role = _evMarkCompleted;
                                            return core.mayI.completeTask(d) || 0;
                                        case d.state == enmTaskInfoStates.Started &&
                                        core_DO.hasFeature(_fndTaskDuration):
                                        case d.state == enmTaskInfoStates.Started &&
                                        !core_DO.hasFeature(_fndTaskDuration):
                                        case d.state == enmTaskInfoStates.Blocked:
                                            item.label = core.getMsg(_msgContextMenuMarkComplete);
                                            item.role = _evMarkCompleted;
                                            return core.mayI.completeTask(d) || 0;
                                        case d.state == enmTaskInfoStates.Completed:
                                            item.label = core.getMsg(_msgContextMenuMarkIncomplete);
                                            item.role = _evMarkIncomplete;
                                            return core.mayI.updateTask(d) || 0;
                                    }
                                }
                            },
                            {
                                callback(d, item) {
                                    if (d.type == enmTaskInfoTypes.Task) {
                                        switch (true) {
                                            case d.state == enmTaskInfoStates.Unblocked &&
                                            core_DO.hasFeature(_fndTaskDuration):
                                                item.label = core.getMsg(_msgContextMenuMarkComplete);
                                                item.role = _evMarkCompleted;
                                                return core.mayI.completeTask(d) || 0;
                                            case d.state == enmTaskInfoStates.Started &&
                                            core_DO.hasFeature(_fndTaskDuration):
                                                item.label = 'Cancel In Progress';
                                                item.role = _evMarkIncomplete;
                                                return core.mayI.completeTask(d) || 0;
                                            default:
                                                return false;
                                        }
                                    } else return false;
                                }
                            },
                            {
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];
                                    switch (true) {
                                        case sel.length < 2 &&
                                        d['type'] == enmTaskInfoTypes.Group &&
                                        d['state'] != enmTaskInfoStates.Completed:
                                            item.label = core.getMsg(_msgMarkGroupAsCompleted);
                                            item.role = _evMarkGroupAsCompleted;
                                            return core.mayI.updateTask() || 0;
                                        case sel.length > 1 &&
                                        sel
                                            .map(id => core_DO.task(id))
                                            .filter(d => d.state != enmTaskInfoStates.Completed).length > 0:
                                            item.label = core.getMsg(_msgMarkSelectionAsCompleted);
                                            item.role = _evMarkSelectionAsCompleted;
                                            return core.mayI.updateTask() || 0;
                                        default:
                                            return false;
                                    }
                                }
                            },
                            {isLine: true},
                            {
                                role: _evTaskNameEdit,
                                callback(d, item) {
                                    item.label = core.getMsg(
                                        (d['type'] == enmTaskInfoTypes.Task && _msgContextMenuTaskNameEdit) ||
                                        _msgContextMenuGroupNameEdit
                                    );
                                    return core.mayI.updateTask(d) || 0;
                                }
                            },
                            {
                                callback(d, item) {
                                    (item.label = core_DO.hasFeature(_fndTaskDuration)
                                        ? 'Edit Timing'
                                        : core.getMsg(_msgContextMenuSetDeadline)),
                                        (item.role = _evSetDeadline);
                                    return d['state'] == enmTaskInfoStates.Completed
                                        ? 0
                                        : core.mayI.setDeadline(d) || 0;
                                }
                            },
                            {isLine: true},
                            {
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];
                                    if (sel.length > 1 || !core.mayI.updateTask()) {
                                        return false;
                                    }

                                    if (d['type'] == enmTaskInfoTypes.Task) {
                                        item.label = core.getMsg(_msgContextMenuConvertToGroup);
                                        item.role = _evConvertToGroup;
                                        return core.mayI.updateTask() || 0;
                                    } else {
                                        item.label = core.getMsg(_msgContextMenuConvertToTask);
                                        item.role = _evConvertToTask;
                                        return core_DO.groupStat(d.id).tasksQty > 0 ? 0 : core.mayI.updateTask() || 0;
                                    }
                                }
                            },
                            {
                                role: _evConvertSelectionToGroup,
                                label: core.getMsg(_msgContextMenuConvertSelectionToGroup),
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];

                                    if (sel.length < 2) {
                                        return false;
                                    }

                                    if (!__MLGROUPS) {
                                        const groupInSelection =
                                            (sel || []).filter(
                                                i => core_DO.task(i) && core_DO.task(i).type == enmTaskInfoTypes.Group
                                            ).length > 0;
                                        return __MLGROUPS
                                            ? !groupInSelection && sel.length
                                            : !groupInSelection &&
                                            sel.length &&
                                            !sel.map(id => core_DO.task(id) && core_DO.task(id).groupId).compact()
                                                .length;
                                    } else {
                                        return core.mayI.changeState() || 0;
                                    }
                                }
                            },
                            {isLine: true},
                            {
                                role: _evCopySelectionToClipboard,
                                label: core.getMsg(_msgCopyToClipboard),
                                callback(d, item) {
                                    return (core.mayI.changeState() && core.mayI.updateTask()) || 0;
                                }
                            },
                            {
                                role: _evPasteFromClipboard,
                                label: core.getMsg(_msgPasteFromClipboard),
                                callback(d, item) {
                                    return (
                                        (core.mayI.changeState() &&
                                            core.mayI.updateTask() &&
                                            core_DO.clipboard().length > 0) ||
                                        0
                                    );
                                }
                            },
                            {isLine: true},
                            {
                                role: _evRemoveTask,
                                callback(d, item) {
                                    item.label = core.getMsg(_msgContextMenuDeleteTask);
                                    return core.mayI.removeTask() || 0;
                                }
                            }
                        ],
                        onClick(data, d, xy, caller) {
                            switch (data['role']) {
                                case _evOpenGroup:
                                    self.treeHandlers.onEvent(_evOpenGroup, d['id']);
                                    break;
                                case _evTaskNameEdit:
                                    self.treeHandlers.onEvent(
                                        _evTaskNameEdit,
                                        d,
                                        d3
                                            .select(caller.getNode(d['id']))
                                            .select(`text.${_clsTaskName}`)
                                            .node()
                                    );
                                    break;
                                case _evSetDeadline:
                                    setTimeout(() => {
                                        self.treeHandlers.onEvent(
                                            _evSetDeadline,
                                            $(
                                                d3
                                                    .select(caller.getNode(d['id']))
                                                    .select(`text.${_clsDeadline}`)
                                                    .node()
                                            ),
                                            d,
                                            enmTaskDateMode.deadline
                                        );
                                    }, 25);
                                    break;
                                case _evMarkStarted:
                                    core_DO.updateTask(d['id'], {state: enmTaskInfoStates.Started});
                                    self.stateChange(enmStateChangeTypes.ChangeTaskState, d['id'], {
                                        state: enmTaskInfoStates.Started
                                    });
                                    break;
                                case _evMarkStoped:
                                    core_DO.updateTask(d['id'], {state: enmTaskInfoStates.Unblocked});
                                    self.stateChange(enmStateChangeTypes.ChangeTaskState, d['id'], {
                                        state: enmTaskInfoStates.Unblocked
                                    });
                                    break;
                                case _evMarkCompleted:
                                    core_DO.updateTask(d['id'], {state: enmTaskInfoStates.Completed});
                                    self.stateChange(enmStateChangeTypes.ChangeTaskState, d['id'], {
                                        state: enmTaskInfoStates.Completed
                                    });
                                    break;
                                case _evMarkIncomplete:
                                    core_DO.updateTask(d['id'], {state: enmTaskInfoStates.Unblocked});
                                    self.stateChange(enmStateChangeTypes.ChangeTaskState, d['id'], {
                                        state: enmTaskInfoStates.Unblocked
                                    });
                                    break;
                                case _evConvertToGroup:
                                    const ctid = core_DO.getCTID();
                                    core.moses.announce(_evConvert2Group, (ctid && ctid.length && ctid) || [d['id']]);
                                    break;
                                case _evConvertToTask:
                                    core.moses.announce(_evGroup2Task, d['id']);
                                    break;
                                case _evRemoveTask:
                                    var sel = core_DO.getCTID();
                                    self.treeHandlers.onEvent(
                                        _evRemoveSelectedTasks,
                                        (sel instanceof Array && sel.length && sel) || [d['id']]
                                    );
                                    break;
                                case _evConvertSelectionToGroup:
                                    var sel = core_DO.getCTID().map(id => core_DO.task(id));
                                    self.createGroupFromSelection(
                                        sel,
                                        core_utils.exportListed(d, ['rowId', 'colId']),
                                        caller
                                    );
                                    break;
                                case _evCopySelectionToClipboard:
                                    self.doCopy(d['id']);
                                    break;
                                case _evPasteFromClipboard:
                                    self.doPaste(caller.convertCoordinatesRelatedToPageToTree(xy), caller);
                                    break;
                                case _evMarkGroupAsCompleted:
                                    var ids = core_DO.getChildIds([d.id]);
                                    ids.forEach(id => {
                                        // core_DO.updateTask(id, {'state': enmTaskInfoStates.Completed});
                                        self.stateChange(enmStateChangeTypes.ChangeTaskState, id, {
                                            state: enmTaskInfoStates.Completed
                                        });
                                    });
                                    break;
                                case _evMarkSelectionAsCompleted:
                                    var sel = core_DO.getCTID();
                                    var ids = core_DO.getChildIds(sel);
                                    ids.length &&
                                    ids.forEach(id => {
                                        core_DO.updateTask(id, {state: enmTaskInfoStates.Completed});
                                        self.stateChange(enmStateChangeTypes.ChangeTaskState, id, {
                                            state: enmTaskInfoStates.Completed
                                        });
                                    });
                                    break;
                            }
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtContextMenu, _wiPaperContextMenu, {
                        parent: `#${_clsTree}`,
                        items: [
                            {
                                role: _evCreateTaskHere,
                                label: core.getMsg(_msgContextMenuCreateTaskHere),
                                callback(d, item) {
                                    return core.mayI.createTask();
                                }
                            },
                            {
                                role: _evConvertSelectionToGroup,
                                label: core.getMsg(_msgContextMenuConvertSelectionToGroup),
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];

                                    if (sel.length < 2) {
                                        return false;
                                    }

                                    if (!__MLGROUPS) {
                                        const groupInSelection =
                                            (sel || []).filter(
                                                i => core_DO.task(i) && core_DO.task(i).type == enmTaskInfoTypes.Group
                                            ).length > 0;
                                        return __MLGROUPS
                                            ? !groupInSelection && sel.length
                                            : !groupInSelection &&
                                            sel.length &&
                                            !sel.map(id => core_DO.task(id) && core_DO.task(id).groupId).compact()
                                                .length;
                                    } else {
                                        return true;
                                    }
                                }
                            },
                            {isLine: true},
                            {
                                role: _evCopySelectionToClipboard,
                                label: core.getMsg(_msgCopySelectionToClipboard),
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];
                                    return sel.length > 0 || false;
                                }
                            },
                            {
                                role: _evPasteFromClipboard,
                                label: core.getMsg(_msgPasteFromClipboard),
                                callback(d, item) {
                                    return core_DO.clipboard().length > 0 || 0;
                                }
                            },
                            {
                                role: _evPasteFromClipboardWithExecutors,
                                label: core.getMsg(_msgPasteFromClipboardWithExecutors),
                                callback(d, item) {
                                    // debugger;
                                    return core_DO.hasFeature(_fndAdvancedCopyPaste)
                                        ? core_DO.clipboard().length > 0 || 0
                                        : false;
                                }
                            },
                            {
                                isLine: true,
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];
                                    return sel.length > 1;
                                }
                            },
                            {
                                role: _evRemoveSelectedTasks,
                                label: core.getMsg(_msgContextMenuRemoveSelection),
                                callback(d, item) {
                                    const sel = core_DO.getCTID() || [];
                                    return sel.length > 0;
                                }
                            },
                            {
                                isLine: true,
                                callback(d, item, caller) {
                                    const sel = core_DO.getCTID() || [];
                                    return sel.length == 0 && !!caller.selectedLink();
                                }
                            },
                            {
                                role: _evRemoveSelectedLink,
                                label: core.getMsg(_msgRemoveSelectedLink),
                                callback(d, item, caller) {
                                    return !!caller.selectedLink();
                                }
                            }
                            // ,
                            // {isLine:true},
                            // {role: _evExportToImage, label: 'Export to image'}
                        ],
                        onClick(data, d, xy, caller) {
                            switch (data['role']) {
                                case _evCreateTaskHere:
                                    const cr = caller.convertCoordinatesRelatedToPageToTree(xy, true),
                                        gid = caller.groupId(),
                                        id = core_utils.uuid();
                                    const nt = {
                                        id,
                                        projectId: $scope[_bndCurrentProject].id,
                                        groupId: gid,
                                        colId: cr.col,
                                        rowId: cr.row,
                                        creatorId: $scope[_bndCurrentWorkspaceUser].id,
                                        type: enmTaskInfoTypes.Task,
                                        timeCreated: new Date().getTime()
                                    };
                                    core_DO.addTask(nt);
                                    self.stateChange(enmStateChangeTypes.CreateTask, nt.id, nt);
                                    core_stat.incAndTrack(_mpcTaskCreated);
                                    setTimeout(() => {
                                        const node = caller.getNode(id);
                                        self.treeHandlers.onEvent(
                                            _evTaskNameEdit,
                                            {id},
                                            d3
                                                .select(node)
                                                .select(`text.${_clsTaskName}`)
                                                .node()
                                        );
                                        core_DO.setCTID(id, _wtTree);
                                    }, 100);
                                    break;
                                case _evConvertSelectionToGroup:
                                    const sel = core_DO.getCTID().map(id => core_DO.task(id));
                                    self.createGroupFromSelection(sel, xy, caller);
                                    break;
                                case _evRemoveSelectedTasks:
                                    self.treeHandlers.onEvent(_evRemoveSelectedTasks, core_DO.getCTID());
                                    break;
                                case _evCopySelectionToClipboard:
                                    self.doCopy();
                                    break;
                                case _evPasteFromClipboard:
                                    self.doPaste(caller.convertCoordinatesRelatedToPageToTree(xy), caller);
                                    break;
                                case _evPasteFromClipboardWithExecutors:
                                    self.doPaste(caller.convertCoordinatesRelatedToPageToTree(xy), caller, true);
                                    break;
                                case _evRemoveSelectedLink:
                                    caller.removeLink(caller.selectedLink());
                                    break;
                                case _evExportToImage:
                                    caller.export();
                                    break;
                            }
                        }
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtPopupMenu, _wiTeamColorSelector, {
                        menuTitle: core.getMsg(_msgTeamColorPopup),
                        onItemSelectCallback: _evTeamColorSelectorAction
                    })
                );
                this.registerWidget(
                    $classes.$factory(_wtModalDialog, _wiCalendarPicker, {
                        modal: true,
                        buttons: [_drbOK, _drbClose],
                        // buttonsLabel: [{id: _drbClose, label: core.getMsg(_msgUnlinkCalendar)}],
                        header: core.getMsg(_msgCalendarPickerHeader),
                        afterShow($el, ctx) {
                            const cid = $($el).data('opts').current;
                            $el.on('change', 'input[type="radio"]', ev => {
                                const id = $(ev.target).val();
                                let mode;

                                switch (true) {
                                    case !!cid && cid != id && id != 'null':
                                        // switch
                                        mode = 1;
                                        break;
                                    case !!cid && cid != id && id == 'null':
                                        // remove
                                        mode = 2;
                                        break;
                                    case !!cid || cid == id:
                                        // calendar been synced before no changes yet
                                        mode = 3;
                                        break;
                                    default:
                                        // add
                                        mode = 0;
                                        break;
                                }
                                ctx._binds.set(_bndWrapperMode, mode);
                            });
                            cid && ctx._binds.set(_bndWrapperMode, 3);
                        },
                        onClose(role, $el) {
                            if (role != _drbOK) {
                                return;
                            }

                            const data = Object.create(null);
                            $el.find('input:checked:visible').each(function () {
                                data[$(this).attr('name')] = $(this).val();
                            });

                            const values = {
                                projectId: $scope[_bndCurrentProject].id,
                                calendarId: data[_bndSelectedCheckbox]
                            };
                            if (data[_bndAddCalendar]) {
                                values['syncCurrent'] = 'true';
                            }
                            if (data[_bndCleanupCalendar]) {
                                values['cleanupOld'] = 'true';
                            }
                            if (data[_bndMentionProjectName]) {
                                values['projectNameInTask'] = 'true';
                            }

                            let r;
                            core.moses
                                .announce(
                                    _rFeatureData,
                                    (r = {
                                        data: {
                                            featureName: 'GoogleCalendar',
                                            event: 'LinkCalendar',
                                            values
                                        }
                                    })
                                )
                                .then(obj => {
                                    Alertify.log.success(
                                        core.getMsg(
                                            (data[_bndSelectedCheckbox] == 'null' && _msgCalendarUnlinkedMessage) ||
                                            _msgCalendarAddedMessage
                                        )
                                    );
                                })
                                .catch(obj => {
                                    Alertify.log.error(core.getMsg(_msgCalendarAddedErrorMessage));
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'project::wiCalendarPicker::rFeatureData//GoogleCalendar fail response',
                                        {req: r, resp: obj}
                                    );
                                    console.error(obj);
                                });
                        }
                    })
                );

                core_DO.onChangeAU.subscribe(function (id, caller) {
                    //debugger;
                    this.uri();
                    this.update();
                    const highlights =
                        caller == _wtProjectUsers && this.is2ColumnsMode() && this.leftPane == 'people'
                            ? this.getHighlights(id)
                            : undefined;
                    this._tree.highlightTasks(highlights);
                    $groupHolder && $groupHolder.data(_cnstGroupTree).highlightTasks(highlights);
                }, this);
                core_DO.onChangeCTID.subscribe(function (id, caller) {
                    switch (caller) {
                        case `${_wtTasksList}dbl`:
                        case _wtTree:
                            if (!(id instanceof Array) && id) {
                                !this.widget(_wtTaskInfo).status && this.widget(_wtTaskInfo).activate();
                                if (this.is2ColumnsMode()) {
                                    $('#project-planner').addClass('tab-task');
                                    var w = this.widget(_wtTaskInfo);
                                    !w.isActive() && w.activate();
                                }
                            } else {
                                this.widget(_wtTaskInfo).deactivate();
                                if (this.is2ColumnsMode()) {
                                    $('#project-planner').removeClass('tab-task');
                                    var w = this.widget(_wtTaskInfo);
                                    w.isActive() && w.deactivate();
                                }
                            }
                        case _wtTasksList:
                        case _pcProject:
                            const td = core_DO.task(id);
                            this.uri(...((td && [td['groupId'], td['id']]) || []));
                            this.is2ColumnsMode() && this.update();
                            if (!id && this.is2ColumnsMode() && $('#project-planner').hasClass('tab-task')) {
                                $('#project-planner').removeClass('tab-task');
                                var w = this.widget(_wtTaskInfo);
                                w.isActive() && w.deactivate();
                            }
                            break;
                    }
                }, this);
                core_DO.onDraftsUpdate.subscribe(this.updateDrafts, this);

                const onTasksUpdateHandler = function (id, flags) {
                    if (flags & _dupRemoved) {
                        if (this.groupID == id) {
                            alert(core.getMsg(_msgOopsGroupRemoved));
                            this.treeHandlers.onEvent(_evCloseGroup);
                            core_DO.setCTID(undefined, _pcProject);
                            this.uri();
                        }
                        if (
                            core_DO.hasFeature(_fndStoryEditor) &&
                            this.storyEditor &&
                            core_DO.getCTID().indexOf(id) > -1
                        ) {
                            core_DO.setCTID(undefined, _pcProject);
                        }
                    }
                    if (flags & _dupNameChanged && this.groupID == id) {
                        $groupHolder
                            .data(_cnstGroupHolderBinds)
                            .set(_bndGroupCaption, core_DO.task(id).name || core.getMsg(_msgUnnamedGroup));
                    }
                    if ((flags & _dupStateChanged || flags & _dupDeadlineChanged) && this.groupID == id) {
                        const td = core_DO.task(id),
                            obj = {};
                        obj[_bndGroupState] = td.state;
                        obj[_bndIsAlerted] = td.isDeadlineAlert();
                        $groupHolder.data(_cnstGroupHolderBinds).set(obj);
                    }
                };

                core_DO.onTasksUpdate.subscribe(function (id, flags) {
                    if (arguments.length == 1 && Object.isArray(id)) {
                        id.forEach(args => {
                            onTasksUpdateHandler.apply(this, args);
                        });
                    } else {
                        onTasksUpdateHandler.call(this, id, flags);
                    }
                }, this);

                core_DO.onProjectUpdate.subscribe(function (id, flags) {
                    if (this._active && flags & _dupRemoved && id == this.pid) {
                        alert(core.getMsg(_msgYourAccessToProjectRemoved));
                        const ws = $scope[_bndCurrentWorkspace].id;
                        core.uriHandler.setUri((ws && {id: _umWorkspace, wsId: ws}) || _umWorkspaces);
                    }
                }, this);

                core_DO.onClipboardUpdate.subscribe(function () {
                    this.update();
                }, this);
                core_DO.onCPRChange.subscribe(function () {
                    this.storyEditor && storyEditorClose();
                    this.storyEditor = null;
                    this.storyData = null;
                }, this);

                $('body').on('keydown', this.keyHandler.bind(this));

                this.zoomHandler = (r => {
                    const vector = r - this.__ozf;
                    let rn;
                    if (vector < 0) {
                        switch (this.__ozf) {
                            case 0.75:
                                rn = 0.5;
                                break;
                            case 1:
                                rn = 0.75;
                                break;
                        }
                        if (rn > r && rn > 0.5) {
                            rn -= 0.25;
                        }
                    } else if (vector > 0) {
                        switch (this.__ozf) {
                            case 0.5:
                                rn = 0.75;
                                break;
                            case 0.75:
                                rn = 1;
                                break;
                        }
                        if (rn < r && rn < 1) {
                            rn += 0.25;
                        }
                    }
                    if (rn) {
                        this._tree.zoom(rn);

                        $(`#${_clsZoomCtrl}`)
                            .removeClass([_gcZoom50, _gcZoom75, _gcZoom100].join(' '))
                            .addClass(_ZoomMap[rn * 100]);
                        this.__ozf = rn;
                    }
                }).debounce(250);
                this.calendar = $('<input />')
                    .attr('type', 'hidden')
                    .appendTo('body');
            },
            getHighlights(id) {
                const ids = (id && id.split(':')) || id;
                let highlights =
                    (ids &&
                        core_DO.tasksOfTheUser(ids[0], ids[0] == $scope[_bndCurrentWorkspaceUser].id, ids[1]).$tasks) ||
                    undefined;
                if (highlights) {
                    const groups = [];
                    highlights.forEach(v => {
                        v.groupId && groups.push(core_DO.groupPath(v.groupId));
                    });
                    highlights = highlights.concat(
                        groups
                            .flatten()
                            .unique()
                            .map(id => $scope[_bndProjectTasks].$item(id))
                    );
                }
                return highlights;
            },
            is2ColumnsMode() {
                return this.plannerTab == _vm2Columns;
            },
            mkUri(gid, tid) {
                let r;

                const obj = {
                    id: _umProject
                };

                if (typeof gid == 'object' && typeof tid == 'object') {
                    const o = gid;
                    r = tid;
                    gid = o['gid'];
                    tid = o['tid'];
                }

                switch (this.activePane) {
                    case 'planner':
                        switch (this.plannerTab) {
                            case _vm2Columns:
                                obj['mode'] =
                                    ($(PANES_tabProcess).hasClass('tab-people') && _upmManagerUL) || _upmManagerTL;
                                obj['gid'] = gid || tid ? gid : this.groupID;
                                obj['tid'] = tid || core_DO.getCTID();
                                break;
                            case _vm3Columns:
                                obj['mode'] = _upmExecutor;
                                obj['tid'] = tid || core_DO.getCTID();
                                break;
                        }
                        core_DO.getAU() != $scope[_bndCurrentWorkspaceUser].id && (obj['filter'] = core_DO.getAU());
                        obj['filter'] === 0 && (obj['filter'] = 'all');
                        break;
                    case 'users':
                        obj['mode'] = _upmUsers;
                        break;
                    case 'files':
                        obj['mode'] = _upmFiles;
                        break;
                }

                obj['tid'] && obj['tid'] instanceof Array && obj['tid'].length == 0 && (obj['tid'] = undefined);

                const s = core.uriHandler.mkLink(obj);

                if (r && r.append) {
                    r.append(s);
                }
                return s;
            },
            uri(gid, tid) {
                !$.FullScreen.isFullScreen() && core.uriHandler.setUri(this.mkUri(gid, tid), true, undefined, true);
            },
            changeColumnsMode(mode) {
                const $ctrl = $('#project-planner');
                // var $xp = $ctrl.find('ul.expacolla');
                // var $el = $xp.find('[data-mode="' + mode + '"]');
                const classes = 'columns3 columns2';

                if (mode != _vmFullScreen) {
                    // $xp.find('.' + _clsActive).removeClass(_clsActive);
                    // $el.addClass(_clsActive);

                    this.plannerTab = mode;
                    switch (mode) {
                        case _vm3Columns:
                            core.moses.announce(_evHideTutorial);
                            if (!core_DO.getCTID()) {
                                const t = core_DO.upperTaskOfTheUser($scope[_bndCurrentWorkspaceUser].id, true);
                                t && core_DO.setCTID(t['id'], _pcProject);
                            }
                            [this.widget(_wtProjectUsers), this.widget(_wtTasksList), this.widget(_wtTaskInfo)].forEach(
                                m => {
                                    m.activate();
                                }
                            );
                            [this._tree].forEach(m => {
                                m.deactivate();
                            });
                            $ctrl
                                .removeClass(classes)
                                .addClass('columns3')
                                .removeClass(_clsColums3PanelHidden);
                            $('div.header .handle').removeClass(_gcMosesWatchClass);
                            break;
                        case _vm2Columns:
                            core.moses.announce(_evRestoreTutorial);
                            [this._tree, this.widget(_wtTasksList)].forEach(m => {
                                m.activate();
                            });
                            [this.widget(_wtProjectUsers), this.widget(_wtTaskInfo)].forEach(m => {
                                m.deactivate();
                            });
                            $ctrl.removeClass(classes).addClass('columns2');
                            $('div.header .handle').addClass(_gcMosesWatchClass);
                            break;
                    }
                } else {
                    // var $fse = $xp.find('[data-mode="' + _vmFullScreen + '"]');
                    if (!$.FullScreen.isFullScreen()) {
                        $('#project-planner').requestFullScreen(
                            /*core.isSafari ? undefined : */ Element.ALLOW_KEYBOARD_INPUT
                        );
                        // if (core.isSafari) {
                        //     $('.alertify-log.alertify-log-error').hide();
                        //     Alertify.log.error(core.getMsg(_msgSafariFullscreenWarn), 5000);
                        // }
                        $ctrl.addClass(_clsFullscreen);
                        core.setupDatepicker('#project-planner');
                    } else {
                        core.setupDatepicker(null);
                        $.FullScreen.cancelFullScreen();
                        $ctrl.removeClass(_clsFullscreen);
                    }
                }
            },
            generateListen(r) {
                // r[_evProjectDateStartEdit] = function (el) {
                //     if (!core.mayI.changeProjectState()) { return false }
                //     var $el = $(el);
                //     var dl = $scope[_bndCurrentProject] && core_utils.unpackDate($scope[_bndCurrentProject].startDate);
                //
                //     dl && $(el)
                //     .datepicker({'setDate': dl && core_utils.unpackDate(dl)});
                //     //     {
                //     //     date: dl && core_utils.unpackDate(dl),
                //     //     // autoPick: true,
                //     //     // autoShow: true,
                //     //     offset: el instanceof SVGElement ? 17 : 0
                //     // })
                //
                // }
                r[_evSetCompletedDate] = function (el) {
                    this.treeHandlers.onEvent(_evSetDeadline, $(el), null, enmTaskDateMode.complete);
                };
                r[_eProjectTabChange] = function (obj) {
                    if (obj['data']['el'] == PANES_tabProcess && this.plannerTab == null) {
                        this.changeColumnsMode(_vm2Columns);
                    }
                    // core.moses.announce(_evCancelTutorial);
                    this.switchPane(obj['data']['el']);
                    this.uri();
                };
                r[_evViewMode] = function (el) {
                    const mode = $(el).data('mode');
                    let sm;
                    this.changeColumnsMode(mode);

                    switch (mode) {
                        case _vm3Columns:
                            break;
                        case _vm2Columns:
                            core_DO.getAU() != $scope[_bndCurrentWorkspaceUser].id && core_DO.setAU(null, _pcProject);
                            break;
                        case _vmFullScreen:
                            break;
                    }
                    this.update();
                    this.uri();
                };
                r[_evTabChangeRequest] = function (el, d) {
                    this.switchTab($(el).data('target'));

                    setTimeout(
                        self => {
                            self.uri();
                        },
                        0,
                        this
                    );
                };
                r[_eShowProjectBanner] = () => {
                    core.tutorial(_tutRegularTutorial, () =>
                        core.q(rs => {
                            core_stat.track(_mpcOnboardingTutorialRevisit);
                            rs();
                        })
                    )
                        .then(() => {
                            core_stat.track(_mpcOnboardingTutorialFinish);
                        })
                        .catch(() => {
                            core_stat.track(_mpcOnboardingTutorialFinish);
                        });
                };
                r[_evAddNewTask] = function () {
                    let id = core_utils.uuid();
                    const $pool = $(`#${_clsTasksPool} div.${_clsDraftTask}`);

                    const obj = {
                        id,
                        projectId: $scope[_bndCurrentProject].id,
                        type: enmTaskInfoTypes.Draft,
                        timeCreated: new Date().getTime(),
                        creatorId: $scope[_bndCurrentWorkspaceUser].id,
                        rowId: $pool.length
                    };

                    //Fix wrong calculated ID and report
                    if (!id) {
                        obj.id = id = core_utils.uuid();
                        __USE_ROLLBAR &&
                        Rollbar.warning('project::evAddNewTask somehow ID was calculated in wrong way', {
                            newID: id
                        });
                    }

                    core_DO.addTask(new $classes[_cTaskInfo](obj));
                    this.updateDrafts();
                    this.stateChange(enmStateChangeTypes.CreateTask, id, obj);
                    core_stat.inc(_mpcDraftCreated);
                    setTimeout(() => {
                        $(`#${_clsTasksPool} div.${_clsDraftTask}`)
                            .first()
                            .find('input')
                            .focus();
                    });
                };
                r[_evRemoveDraftTask] = function (id) {
                    if (id instanceof HTMLElement) {
                        id = $(id)
                            .parents(`.${_clsDraftTask}`)
                            .attr('id');
                    }
                    if (!id || typeof id != 'string') {
                        __USE_ROLLBAR && Rollbar.debug('project::evRemoveDraftTask no ID');
                        return;
                    }
                    core_DO.removeTask(id);
                    this.updateDrafts();
                    this.stateChange(enmStateChangeTypes.DeleteTask, id);
                    core_stat.inc(_mpcDraftRemoved);
                };
                r[_eViewTaskInfo] = function (id) {
                    const t = core_DO.task(id);
                    // if (t['groupId'] != this._tree.groupId && this._tree.groupId != _dcNoGroup) {
                    //     this.switchGroup(_dcNoGroup);
                    //     this.update();
                    // }

                    this._tree.scrollToTask(id);
                    this.uri();
                };
                r[_evConvert2Group] = function (id) {
                    const sel = (!(id instanceof HTMLElement) && id) || core_DO.getCTID() || [];
                    let gid;

                    if (!sel.length) {
                        return;
                    }

                    if (sel.length == 1) {
                        this.stateChange(enmStateChangeTypes.ChangeTaskType, sel[0], {
                            type: enmTaskInfoTypes.Group
                        });
                        gid = sel[0];
                    } else if (sel.length > 1) {
                        gid = this.createGroupFromSelection(sel.map(id => core_DO.task(id)));
                    }

                    core_DO.changeTaskType(gid, {type: enmTaskInfoTypes.Group});

                    core_stat.track(_mpcGroupCreated);

                    core_DO.setCTID(gid, _pcProject);
                    this.update();
                };
                r[_evGroup2Task] = function (id) {
                    let sel = (typeof id == 'string' && [id]) || core_DO.getCTID() || [];

                    sel = sel.map(id => core_DO.task(id));

                    if (sel.length == 1 && sel[0]['type'] == enmTaskInfoTypes.Group) {
                        this.stateChange(enmStateChangeTypes.ChangeTaskType, sel[0]['id'], {
                            type: enmTaskInfoTypes.Task
                        });
                        core_DO.updateTask(sel[0]['id'], {type: enmTaskInfoTypes.Task});
                    }
                    this.update();
                };
                r[_evCopyToClipboard] = function () {
                    this.doCopy();
                };
                r[_evPasteClipboard] = function () {
                    this.doPaste();
                };
                r[_evCancelTaskNameEdit] = id => {
                    const $el = $(`#${_clsInlineEditShield}`);
                    $el.length && ($el.data() || {}).id == id && $el.remove();
                };
                r[_evChangeLeftPanelVisibility] = el => {
                    const $ctrl = $('#project-planner');
                    $ctrl.toggleClass(_clsColums3PanelHidden);
                    return false;
                };
                r[_evConsultantClone] = function () {
                    this.widget(_wiConsultingTools).show(
                        tpls.project.consultantCloneDialog({
                            workspaces: core_DO
                                .$workspaces()
                                .filter(v => v.id !== $scope[_bndCurrentProject].workspaceId),
                            name: $scope[_bndCurrentProject].name,
                            description: $scope[_bndCurrentProject].description
                        })
                    );
                };
            },
            createGroupFromSelection(sel, xy, caller) {
                const tree = caller || ($groupHolder && $groupHolder.data(_cnstGroupTree)) || this._tree,
                    rect = tree.rectOfSelection(),
                    rw = rect.x1 - rect.x,
                    cx = (rw - _trTaskWidth) / 2 + rect.x,
                    cr = tree.XY2CR(tree.adjustXY(cx, rect.y)),
                    gid = core_utils.uuid(),
                    gobj = {
                        id: gid,
                        type: enmTaskInfoTypes.Group,
                        colId: cr.col,
                        rowId: cr.row,
                        groupId: caller ? caller.groupId() : this.groupID,
                        name: '',
                        projectId: $scope[_bndCurrentProject].id,
                        timeCreated: new Date().getTime(),
                        creatorId: $scope[_bndCurrentWorkspaceUser].id
                    };

                if (xy) {
                    if (xy instanceof Array) {
                        const nxy = tree.convertCoordinatesRelatedToPageToTree(xy, true);
                        gobj['colId'] = nxy.col;
                        gobj['rowId'] = nxy.row;
                    } else {
                        gobj['colId'] = xy['colId'];
                        gobj['rowId'] = xy['rowId'];
                    }
                }

                [core_DO.onTasksUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                    p.suspend();
                });

                core_DO.addTask(gobj);
                this.stateChange(enmStateChangeTypes.CreateTask, gid, gobj);

                const list = core_utils.array2HashObj(sel, 'id');
                let transitions = [];
                const hash = Object.keys(list);
                const tr2remap = {};

                $.each(list, (i, v) => {
                    const trs = core_DO.taskTransitions(i);
                    transitions = core_utils.concatArrays(transitions, trs);
                    trs.forEach(t => {
                        const tr = core_DO.transition(t);
                        if (hash.indexOf(tr['toId']) > -1 && hash.indexOf(tr['fromId']) == -1) {
                            DEBUG &&
                            console.log(
                                'transition',
                                t,
                                `toId(${tr['toId']}) have to be remapped to group(${gid})`
                            );
                            tr2remap[t] = {toId: gid};
                        } else if (hash.indexOf(tr['fromId']) > -1 && hash.indexOf(tr['toId']) == -1) {
                            DEBUG &&
                            console.log(
                                'transition',
                                t,
                                `fromId(${tr['fromId']}) have to be remapped to group(${gid})`
                            );
                            tr2remap[t] = {fromId: gid};
                        }
                    });
                });

                var self = this;
                (hash || []).forEach(v => {
                    const d = {groupId: gid, id: v, projectId: $scope[_bndCurrentProject].id};
                    core_DO.updateTask(v, d);
                    self.stateChange(enmStateChangeTypes.UpdateTask, v, d);
                });

                const checkRemap = [];
                $.each(tr2remap, (i, v) => {
                    v = core_DO.updateTransition(i, v);
                    if (checkRemap.indexOf(`${[v.fromId, v.toId]}`) > -1) {
                        self.stateChange(enmStateChangeTypes.DeleteTransition, i);
                        core_DO.removeTransition(i);
                    } else {
                        checkRemap.push(`${[v.fromId, v.toId]}`);
                        self.stateChange(enmStateChangeTypes.UpdateTransition, i, v);
                    }
                });

                transitions.unique().forEach(id => {
                    core_DO.updateTransition(id, {});
                });

                [core_DO.onTasksUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                    p.resume();
                });

                var self = this;
                tree.redraw().then(() => {
                    core_DO.setCTID(gid, _pcProject);
                    self.treeHandlers.onEvent(
                        _evTaskNameEdit,
                        {id: gid},
                        d3
                            .select(tree.getNode(gid))
                            .select(`text.${_clsTaskName}`)
                            .node()
                    );
                });
            },
            /**
             * request changes on server
             * @param  {enmStateChangeTypes} type
             * @param  {string} id
             * @param  {Object.<string,*>} data
             * @param  {boolean} force
             * @return {Promise}
             */
            stateChange(type, id, data, force) {
                // DEBUG && console.assert(type, 'Project::stateChange state type is not set');
                // DEBUG && console.assert(id, 'Project::stateChange object id is not set');
                // DEBUG && console.assert($scope[_bndCurrentProject], 'Project::stateChange current project is not set');
                !this.pckt &&
                ((this.pckt = []),
                    (this.pckt.pid = ($scope[_bndCurrentProject] && $scope[_bndCurrentProject].id) || this.pid));

                const pckt = {
                    type,
                    id,
                    data
                };

                if (!$scope[_bndCurrentProject]) {
                    Alertify.log.error(
                        'We got some unsuspected issue, data may lost, please reload the page.<br />The issue was reported to developers',
                        30000
                    );
                    __USE_ROLLBAR &&
                    Rollbar.critical('project::stateChange no currentProject was set. again. damn', {
                        ws: new $classes[_cWorkspaceInfo]($scope[_bndCurrentWorkspace]),
                        data: pckt
                    });
                }

                this.pckt.push(pckt);

                if (force) {
                    const p = Object.clone(this.pckt);
                    const pid = this.pckt.pid || ($scope[_bndCurrentProject] && this.pid);
                    this.pckt = null;
                    let r = {
                        changes: p,
                        projectId: pid
                    };
                    return core.moses.announce(_rProjectStatePush, r).catch(o => {
                        __USE_ROLLBAR &&
                        Rollbar.error('project::stateChange::rProjectStatePush/force fail reaponse', {
                            req: r,
                            resp: o
                        });
                    });
                } else {
                    this._sendChanges();
                }
            },
            switchPane(id) {
                this.activePane = id.replace(/^\#project\-/, '');

                const $el = $(id),
                    $ch = $el.parent().children(),
                    self = this;

                $(`#page-${_pidProject} div.${_clsHorizontalWrapper}`)
                    .css({
                        left: `${-($ch.index($el) * 100)}%`,
                        overflow: ''
                    })
                    .data({id});

                core.setMainNav(_pidProject, id);

                [
                    _wtProjectUsers,
                    _wtTasksList,
                    _wtTaskInfo,
                    _wtManageProjectUsers,
                    _wtManageTeams,
                    _wtProjectFiles
                ].forEach(id => {
                    const w = self.widget(id);
                    w.isActive() && w.deactivate();
                });

                const wa = [];
                switch (id) {
                    case PANES_tabProcess:
                        wa.push(_wtProjectUsers, _wtTasksList, _wtTaskInfo);
                        core.moses.announce(_evRestoreTutorial);
                        break;
                    case PANES_tabUsers:
                        wa.push(_wtManageProjectUsers, _wtManageTeams);
                        core.moses.announce(_evHideTutorial);
                        break;
                    case PANES_tabFiles:
                        wa.push(_wtProjectFiles);
                        core.moses.announce(_evHideTutorial);
                        break;
                    // case PANES_tabDiscussion:
                    //     break;
                }

                wa.forEach(id => {
                    self.widget(id).activate();
                });
            },
            switchTab(trg) {
                $('#project-planner')
                    .removeClass('tab-people tab-tasks tab-task')
                    .addClass(`tab-${trg}`);

                switch (trg) {
                    case 'people':
                        [_wtProjectUsers].forEach(m => {
                            this.widget(m).activate();
                        });
                        [_wtTasksList, _wtTaskInfo].forEach(m => {
                            this.widget(m).deactivate();
                        });
                        core_DO.setAU(undefined, _wtProjectUsers);
                        break;
                    case 'tasks':
                        core_DO.setAU(undefined);
                        [_wtProjectUsers, _wtTaskInfo].forEach(m => {
                            this.widget(m).deactivate();
                        });
                        [_wtTasksList].forEach(m => {
                            this.widget(m).activate();
                        });
                        break;
                    case 'task':
                        [_wtProjectUsers, _wtTasksList].forEach(m => {
                            this.widget(m).deactivate();
                        });
                        [_wtTaskInfo].forEach(m => {
                            this.widget(m).activate();
                        });
                        break;
                }
                // var highlights = trg == 'people' ? this.getHighlights() : undefined;
                // this._tree.highlightTasks(highlights);
                // $groupHolder && $groupHolder.data(_cnstGroupTree).highlightTasks(highlights);

                this.leftPane = trg;
            },
            update() {
                if (!this._active || !$scope[_bndCurrentProject]) {
                    return;
                }
                let u = core_DO.getAU();
                const sel = core_DO.getCTID() || [];
                let tt;

                u && (u = u.split(':'));
                document.title = [
                    $scope[_bndCurrentProject].name,
                    $scope[_bndCurrentWorkspace].name,
                    _gcProjectTitle
                ].join(_gcTitleSeparator);

                const groupInSelection =
                    (sel || []).filter(i => core_DO.task(i) && core_DO.task(i).type == enmTaskInfoTypes.Group).length >
                    0;

                const obj = {};

                obj[_bndProjectName] = $scope[_bndCurrentProject].name;
                obj[_bndPrevTask] = this._ptn;
                obj[_bndShowPanelHideButton] = core_DO.hasFeature(_fndSidebarCollapse);

                let a;
                switch (true) {
                    case this.plannerTab == _vm2Columns:
                        a = core.getMsg(_msgMyTasksHeader);
                        break;
                    case !!u && u.length == 1 && u[0] == $scope[_bndCurrentWorkspaceUser].id:
                        a = core.getMsg(_msgMyTasksHeader);
                        break;
                    case u === 0:
                        a = core.getMsg(_msgAllTasksHeader);
                        break;
                    case !!u && u.length == 1:
                        var d = core_DO.user(u[0]);
                        a = core.getMsg(_msgUserTasksHeader).format(d);
                        break;
                    case !!u && u.length == 2 && u[0] == '':
                        var d = core_DO.$teams().$item(u[1]);
                        a = core.getMsg(_msgTeamTasksHeader).format(d);
                        break;
                    case !!u && u.length == 2:
                        a = core.getMsg(_msgTeamUserTasksHeader).format({
                            name: core_DO.$teams().$item(u[1]).name,
                            firstName: core_DO.user(u[0]).firstName
                        });
                        break;
                }
                obj[_bndListMode] = a;
                obj[_bndMakeGroup] = __MLGROUPS
                    ? !groupInSelection && sel.length
                    : !groupInSelection &&
                    sel.length &&
                    !sel.map(id => core_DO.task(id) && core_DO.task(id).groupId).compact().length;
                obj[_bndGroupingMode] = core.getMsg(
                    (sel.length > 1 && _msgConvertTasks2Group) || _msgConvertTask2Group
                );
                obj[_bndGroup2Task] = sel.length == 1 && groupInSelection && core_DO.groupTasks(sel[0]).length == 0;

                this._binds.set(obj);

                this._binds.set(_bndHasTeamFeature, core_DO.hasFeature(_fndProjectTeams));
                this._binds.set(
                    _bndAddNewMember,
                    core_DO.hasFeature(_fndProjectTeams) == true && core.mayI.addWorkspaceUserToProject() == true
                );
            },
            uriHandler(args) {
                if (args[1]) {
                    core_DO.setCPR(args[1]);
                }
            },
            tutorial() {
                const data = core.tutorial.getData();
                const {ctrl = undefined, args = []} = core.storage.$get(_skeyTutorialParams) || {};
                let pid;
                if (data.has(_skTutorialBeenSkeeped)) {
                    /** @type {number} */
                    let mode;
                    /** @type {cWorkspaceUserInfo} */
                    const wsu = $scope[_bndCurrentWorkspaceUser];
                    switch (true) {
                        case wsu && wsu.role == enmWorkspaceUserRole.Owner:
                            mode = _tutOwnerTutorial | _tutCloseTutorialButton;
                            break;
                        case wsu &&
                        [enmWorkspaceUserRole.Leader, enmWorkspaceUserRole.Participant].indexOf(wsu.role) > -1:
                            mode = _tutLeaderTutorial | _tutCloseTutorialButton;
                            if (ctrl == _pidProject) {
                                mode |= _tutConfirmVideoShow;
                                pid = args[1];
                            } else {
                                mode |= _tutIntroMessage;
                            }
                            break;
                        case wsu && wsu.role == enmWorkspaceUserRole.Spectator:
                            core.tutorial.removeFlag(_skTutorialEnums_New, true);
                            return;
                    }
                    core.tutorial(mode & ~_tutIntroMessage)
                        .then(rslv => {
                            core.tutorial.removeFlag(_skTutorialBeenSkeeped, true);
                            core_stat.track(_mpcOnboardingTutorialFinish);
                            rslv();
                        })
                        .catch(reason => {
                            //     if (typeof(reason) == 'object') {
                            //         if (reason.skipped !== true) {
                            //             core.tutorial.removeFlag(_skTutorialEnums_New);
                            //             !cameFromStories && core.tutorial.addFlag(_skTutorialBeenSkeeped);
                            //             core.storage.$set(_skTutorialBeenSkeeped, 1);
                            //             core.tutorial.saveTutorialData();
                            //         }
                            //     }
                        });
                }
            },
            activate(uri, navigate) {
                core.setupDatepicker();

                !this._tree &&
                (this._tree = new $classes.Tree(
                    this.$rendered.find(`svg.${_clsSVGCanvas}`)[0],
                    undefined,
                    core_utils.bindMap(this.treeHandlers, this),
                    core_DO
                ));

                DEBUG && console.info('pcProject::activate', uri, navigate);
                const obj = core_utils.mapArray2Obj([false, 'pid', 'mode', 'filter', 'gid', 'tid'], uri);
                var self = this;
                let $dfr;

                this.is2ColumnsMode() &&
                $('#project-planner').hasClass('tab-task') &&
                $('#project-planner').removeClass('tab-task');

                // if ($scope[_bndCurrentProject].id != obj['pid']) {

                if ($groupHolder) {
                    let groupTree = $groupHolder.data(_cnstGroupTree);
                    if (groupTree) {
                        groupTree.destroy();
                        groupTree = null;
                    }
                    $groupHolder.remove();
                    $groupHolder = null;
                }
                this.groupID = undefined;

                this._tree.cleanup();
                this.cleanup();
                this.pasteInfo && this.pasteInfo.node && this.pasteInfo.node.remove();
                this.pasteInfo = null;

                const cb = () => {
                    __USE_ROLLBAR && Rollbar.error('project::activate no project found', {uri, parsedValues: obj});
                    alert(core.getMsg(_msgNoProjectFound));
                    const ws = $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id;
                    core.uriHandler.setUri((ws && _umWorkspace) || _umWorkspaces);
                };
                Promise.resolve(core_DO.setCPR(obj['pid']))
                    .then(() => {
                        this.update();

                        this.pid = obj['pid'];

                        core_DO.updateProjectLTA(obj['pid']);

                        this.updateDrafts();

                        this.loadProjectData().then(lfg => {
                            // if user left the page, abort loading
                            if (!this._active) {
                                return;
                            }

                            this.updateDrafts();
                            this.__ozf = 1;
                            // this.$rendered.find('div.' + _clsZoomLayer + '.' + _clsZoomLayer1).removeClass([_gcZoom50, _gcZoom75].join(' ')).addClass(_gcZoom100);
                            this.$rendered
                                .find(`#${_clsZoomCtrl}`)
                                .removeClass([_gcZoom50, _gcZoom75].join(' '))
                                .addClass(_gcZoom100);

                            obj['filter'] == 'all' && (obj['filter'] = 0);
                            /** FIXME: determine user task owned/assigned */
                            obj['filter'] == 'auto' && (obj['filter'] = undefined);

                            /,/.test(obj['tid']) && (obj['tid'] = obj['tid'].split(/,/));

                            // check if task have correct path for group
                            if (obj['tid']) {
                                const d = core_DO.task((obj['tid'] instanceof Array && obj['tid'][0]) || obj['tid']);
                                d && (obj['gid'] = d['groupId']);
                            }

                            let sau;
                            switch (obj['mode']) {
                                case _upmExecutor:
                                    this.switchPane(PANES_tabProcess);
                                    this.changeColumnsMode(_vm3Columns);

                                    sau =
                                        (obj['filter'] == undefined && $scope[_bndCurrentWorkspaceUser].id) ||
                                        obj['filter'];
                                    break;
                                default:
                                case _upmManagerUL:
                                case _upmManagerTL:
                                    this.switchPane(PANES_tabProcess);
                                    this.changeColumnsMode(_vm2Columns);

                                    sau = obj['filter'];

                                    $(PANES_tabProcess)
                                        .removeClass('tab-people tab-tasks')
                                        .addClass((obj['mode'] == _upmManagerUL && 'tab-people') || 'tab-tasks');
                                    this.switchTab((obj['mode'] == _upmManagerUL && 'people') || 'tasks');
                                    break;
                                case _upmUsers:
                                    this.switchPane(PANES_tabUsers);
                                    sau = obj['filter'];
                                    break;
                                case _upmFiles:
                                    this.switchPane(PANES_tabFiles);
                                    sau = obj['filter'];
                                    break;
                            }
                            this.update();
                            $('#project-planner').addClass(_ppReady);

                            this._tree.setData(undefined, $scope[_bndCurrentProject].startDate).then(() => {
                                this._tree.zoom(1, false, false);
                                if (obj['gid']) {
                                    this._tree.openGroup(obj['gid']);
                                }
                                sau && core_DO.setAU(sau, _pcProject);
                                if (obj['tid'] || lfg) {
                                    core_DO.setCTID(lfg || obj['tid'], navigate ? _pcProject : _wtTree);
                                } else {
                                    this.alignTreeView(this._tree, true);
                                }
                                if (lfg) {
                                    alert(core.getMsg(_msgLostGroupFound));
                                }
                            });
                            this.tutorial();
                        });
                    })
                    .catch(o => {
                        if (o && o.cancel) {
                            return;
                        } else if (o && o.error) {
                            const ws = $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id;
                            core.uriHandler.setUri((ws && _umWorkspace) || _umWorkspaces);
                        } else if (loadedArchives[obj['pid']]) {
                            cb();
                        } else {
                            let r;
                            // try to load archived project
                            core.moses
                                .announce(_rProjectInfo, (r = {projectId: obj['pid']}))
                                .then(
                                    /* cProjectInfoBundle */ p => {
                                        core_DO.setProjects([p.project], true);
                                        core.moses
                                            .announce(_rListProject, {
                                                workspaceId: p.project.workspaceId,
                                                withStatus: enmProjectStatus.Archived,
                                                sendStats: true
                                            })
                                            .then(robj => {
                                                loadedArchives[p.project.workspaceId] = true;
                                                core_DO.setProjects(robj.projects, true);
                                                this.activate(uri, navigate);
                                            })
                                            .catch(o => {
                                                switch (o.error) {
                                                    case enmErrorMessage.AccessDenied:
                                                        alert(core.getMsg(_msgAccessDenied));
                                                        break;
                                                    default:
                                                        __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'project::loadProjectData::rProjectInfo fail response',
                                                            {req: r, resp: o}
                                                        );
                                                        alert(core.getMsg(_msgUknownError, o));
                                                        break;
                                                }
                                                const ws =
                                                    $scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id;
                                                core.uriHandler.setUri((ws && _umWorkspace) || _umWorkspaces);
                                            });
                                    }
                                )
                                .catch(o => {
                                    cb(o);
                                });
                        }
                    });

                this._super();
            },
            deactivate() {
                core.moses.announce(_evCancelTutorial);

                this.storyData = null;
                if (this.storyEditor) {
                    storyEditorClose();
                }

                DEBUG && console.log('pcProject::deactivate');
                core_DO.setCWS();
                this._tree.deactivate();
                this._super();
            },
            loadProjectData() {
                const self = this;
                core_utils.banner(core.getMsg(_msgProjectLoadingBanner));
                return core.q((rslv, rjct) => {
                    let r;
                    Promise.all([
                        core.moses.announce(
                            _rProjectMap,
                            (r = {
                                workspaceUserId: $scope[_bndCurrentWorkspaceUser].id,
                                projectId: $scope[_bndCurrentProject].id
                            })
                        ),
                        core.moses.announce(_rProjectExecutionBundle, {projectId: $scope[_bndCurrentProject].id})
                    ])
                        .then(v => {
                            core_utils.banner();
                            const pmap = v[0];
                            const peb = v[1];

                            if (!self._active) {
                                return;
                            }

                            if (!$scope[_bndCurrentProject]) {
                                rjct(false);
                                __USE_ROLLBAR &&
                                Rollbar.debug('project::loadProjectData no current project', {
                                    log:
                                        (console.getMessagesAsArray &&
                                            console.getMessagesAsArray().slice(-_gcLogLenghtForReporting)) ||
                                        'no LDA'
                                });
                                return;
                            }

                            let lfg;
                            const d = peb['teamsAndMembers'];

                            core_DO.members(d['members'], d['projectMemberships']);
                            core_DO.setTeams(d['teams'], d['teamMemberships']);

                            lfg = core_DO.setTasks(pmap['tasks'], pmap['transitions']);

                            let rg;
                            core_DO.hasFeature(_fndGoogleCalendar) &&
                            core.moses
                                .announce(
                                    _rFeatureQuestion,
                                    (rg = {
                                        question: {
                                            featureName: 'GoogleCalendar',
                                            question: 'GetCalendarLink',
                                            values: {
                                                projectId: $scope[_bndCurrentProject].id
                                            }
                                        }
                                    })
                                )
                                .then(obj => {
                                    const r = obj.response;
                                    switch (r['targetIds'][0]) {
                                        case enmGoogleCalendarStatus.WorkspaceUserUnlinked:
                                            core_DO.projectAuthorizedAtGoogle(undefined, false);
                                            core_DO.googleCalendars(undefined, undefined);
                                            break;
                                        case enmGoogleCalendarStatus.CalendarLinked:
                                            core_DO.googleCalendars(undefined, r['values']['calendarId']);
                                        case enmGoogleCalendarStatus.CalendarUnlinked:
                                            core_DO.projectAuthorizedAtGoogle(undefined, true);
                                            break;
                                    }
                                })
                                .catch(obj => {
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'project::loadProjectData::rFeatureQuestion/GoogleCalendar fail response',
                                        {req: rg, resp: obj}
                                    );
                                    Alertify.log.error(core.getMsg(_msgGoogleCalendarLoadErrorMessage));
                                });

                            core_DO.hasFeature(_fndStoryEditor) &&
                            core.moses
                                .announce(
                                    _rFeatureQuestion,
                                    (rg = {
                                        question: {
                                            featureName: enmFeatureQuestion.StoryEditor,
                                            question: enmFeatureQuestionEvent.QueryStorySettings,
                                            values: {projectId: $scope[_bndCurrentProject].id}
                                        }
                                    })
                                )
                                .then(
                                    /* cStoryPageSettings */ obj => {
                                        self.storyData =
                                            (obj.response &&
                                                obj.response.pageInfo &&
                                                new $classes[_cStoryPageSettings](obj.response)) ||
                                            null;
                                    }
                                )
                                .catch(obj => {
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'project::loadProjectData::rFeatureQuestion/QueryStorySettings fail response',
                                        {req: rg, resp: obj}
                                    );
                                    DEBUG &&
                                    console.error(
                                        'project::loadProjectData::rFeatureQuestion/QueryStorySettings fail response',
                                        {req: rg, resp: obj}
                                    );
                                });
                            rslv(lfg);
                        })
                        .catch(o => {
                            core_utils.banner();
                            rjct(o);
                            switch (o.error) {
                                case false: // is user canceled project loading?
                                    __USE_ROLLBAR &&
                                    Rollbar.debug(
                                        'project::loadProjectData sounds like user canceled project loading',
                                        {
                                            log:
                                                (console.getMessagesAsArray &&
                                                    console
                                                        .getMessagesAsArray()
                                                        .slice(-_gcLogLenghtForReporting)) ||
                                                'no LDA'
                                        }
                                    );
                                    break;
                                case enmErrorMessage.AccessDenied:
                                    alert(core.getMsg(_msgAccessDenied));
                                    break;
                                case enmErrorMessage.Error:
                                    alert(core.getMsg(_msgSomeErrorOnServerWhileRetrievingData, o));
                                    break;
                                default:
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'project::loadProjectData::rProjectMap+rProjectExecutionBundle fail response',
                                        {req: r, resp: o}
                                    );
                                    alert(core.getMsg(_msgUknownError, o));
                                    break;
                            }
                        });
                });
            },
            updateDrafts() {
                const dft = d3
                        .select(`#${_clsTasksPool}`)
                        .selectAll(`div.${_clsDraftTask}`)
                        .data(core_DO.drafts(), d => d['id']),
                    self = this,
                    ct = core.mayI.createTask();

                dft.enter()
                    .append('div')
                    .attr('id', d => d['id'])
                    .attr('draggable', ct)
                    .classed(_clsDraftTask, true)
                    .html(() => tpls.project.newTask())
                    .each(function (d) {
                        self._draftBinds.ns(d['id'], this);
                    });

                dft.exit()
                    .each(d => {
                        self._draftBinds.removeNS(d['id']);
                    })
                    .remove();

                dft.each(function (d) {
                    d['isAlerted'] = d.isDeadlineAlert();
                    self._draftBinds.ns(d['id']).set(d);
                    $(this).css('display') == 'none' && $(this).fadeIn();
                }).order();
            },
            cleanup() {
                this.updateDrafts();
            },
            doCopy(id) {
                let sel = core_DO.getCTID();
                if (!sel.length && this._popupAtTask) {
                    sel = [this._popupAtTask.id];
                }
                if (sel.length && sel[0] != undefined && $scope[_bndCurrentWorkspace].writable) {
                    const t = core_DO.task(sel[0]);
                    var id = core_utils.uuid();
                    let meta;

                    try {
                        meta = (
                            ([null, _dcNoGroup].indexOf(t.groupId) > -1 && this._tree) ||
                            ($groupHolder && $groupHolder.data(_cnstGroupTree))
                        ).createMetaData(sel);
                    } catch (e) {
                        __USE_ROLLBAR &&
                        Rollbar.debug('project::doCopy problem resolving copy source', {
                            groupId: t.groupId,
                            isGroupHolderEmpty: !$groupHolder,
                            isDataEmpty: ($groupHolder && $groupHolder.data(_cnstGroupTree) && false) || true
                        });
                        Alertify.log.error('Error creating data for copy');
                        return;
                    }

                    DEBUG && console.info('meta/data', id, meta);
                    core_DO.clipboardMeta(id, meta);
                    let r;
                    core.moses.announce(_rCopyToClipboard, (r = {entryId: id, tasks: sel})).catch(o => {
                        __USE_ROLLBAR &&
                        Rollbar.error('project::doCopy::rCopyToClipboard fail response', {req: r, resp: o});
                        switch (o.error) {
                            // #FIXME: complete errors response
                            case 'ReadOnly':
                                alert(core.getMsg(_msgWorkspaceIsReadOnly));
                                break;
                            default:
                                alert(core.getMsg(_msgServerError));
                                break;
                        }
                    });
                    core_stat.track(_mpcContentCopied);
                }
            },
            doPaste(xy, target, withExecs) {
                if (this.pasteInfo) {
                    // this.doActualPaste();
                    return false;
                }

                const data = core_DO.actualClipboard(),
                    tree = target || ($groupHolder && $groupHolder.data(_cnstGroupTree)) || this._tree;

                if (data) {
                    if (!data.meta) {
                        const sel = data.info.positions.map(v => v.id);
                        const tinfo = core_DO.task(sel[0]);
                        if (!tinfo) {
                            return;
                        }
                        const meta = (
                            ([undefined, null, _dcNoGroup].indexOf(tinfo.groupId) > -1 && this._tree) ||
                            ($groupHolder && $groupHolder.data(_cnstGroupTree))
                        ).createMetaData(sel);
                        core_DO.clipboardMeta(data.id, meta);
                        data.meta = meta;
                    }

                    this.pasteInfo = {
                        id: data.id,
                        tree,
                        execs: !!withExecs,
                        node: tree.meta2Node(data.meta, true, xy)
                    };
                }
            },
            doActualPaste() {
                if (!$scope[_bndCurrentWorkspace].writable) {
                    return;
                }
                const info = this.pasteInfo,
                    obj = info.tree.calculatePasteInfo(info.node);

                const r = {id: info.id, groupId: info.tree.groupId(), tasks: obj};

                if (info.execs) {
                    r['copyExecutors'] = true;
                }

                core.moses.announce(_rPasteFromClipboard, r).catch(o => {
                    __USE_ROLLBAR &&
                    Rollbar.error('project::doActualPaste::rPasteFromClipboard fail response', {req: r, resp: o});
                    switch (o.error) {
                        // #FIXME: complete errors response
                        case 'ReadOnly':
                            alert(core.getMsg(_msgWorkspaceIsReadOnly));
                            break;
                        default:
                            alert(core.getMsg(_msgServerError));
                            break;
                    }
                });
                info.node.remove();
                this.pasteInfo.node = this.pasteInfo.tree = null;
                this.pasteInfo = null;
                core_stat.track(_mpcContentPasted);
            },
            keyHandler(ev) {
                if (!this._active) {
                    return true;
                }

                if (
                    this.activePane == 'planner' &&
                    (this.plannerTab == _vm2Columns || this.plannerTab == _vmFullScreen)
                ) {
                    switch (ev.keyCode) {
                        case _keyBACKSPACE:
                            if (!/macintosh/i.test(navigator.userAgent)) {
                                return true;
                            }
                        case _keyDELETE:
                            if (/INPUT|TEXTAREA|SELECT|TRIX\-EDITOR/.test(ev.target.tagName)) {
                                return true;
                            }
                            $groupHolder && $groupHolder.data(_cnstGroupTree).removeSelected();
                            this._tree.removeSelected();
                            ev.preventDefault();
                            return false;
                        case _keyENTER:
                            // debugger;
                            if (/TEXTAREA|SELECT|TRIX\-EDITOR/.test(ev.target.tagName)) {
                                return true;
                            }
                            if (this.pasteInfo) {
                                this.doActualPaste();
                                return false;
                            }
                            core.mayI.createTask() && core.moses.announce(_evAddNewTask);
                            return false;
                        case _keyESC:
                            if (this.pasteInfo) {
                                // ($groupHolder && $groupHolder.data(_cnstGroupTree) || this._tree).removePasteHolder();
                                this.pasteInfo.node.remove();
                                this.pasteInfo.node = this.pasteInfo.tree = null;
                                this.pasteInfo = null;
                                return false;
                            }
                            if ($groupHolder) {
                                this.treeHandlers.onEvent(_evCloseGroup);
                                ev.preventDefault();
                                return false;
                            }
                            break;
                        case 67: // (Ctrl|CMD) + C
                            if (/INPUT|TEXTAREA|SELECT|TRIX\-EDITOR/.test(ev.target.tagName)) {
                                return true;
                            }
                            if (ev.metaKey || ev.ctrlKey) {
                                this.doCopy();
                            }
                            break;
                        case 86: // (Ctrl|CMD) + V
                            if (/INPUT|TEXTAREA|SELECT|TRIX\-EDITOR/.test(ev.target.tagName)) {
                                return true;
                            }
                            if (ev.metaKey || ev.ctrlKey) {
                                this.doPaste();
                            }
                    }
                }
            },
            treeHandlers: {
                onUpdate(type, id, data) {
                    DEBUG && console.log('onUpdate', type, id, data);
                    this.stateChange(type, id, data);
                },
                onEvent(...args) {
                    DEBUG && console.log('onEvent', JSON.stringify(Array.prototype.slice.call(args, 0, 1)));
                    DEBUG && console.assert(args[0]);
                    return (
                        (eventHandlers[args[0]] &&
                            eventHandlers[args[0]].apply(this, Array.prototype.slice.call(args, 1))) ||
                        undefined
                    );
                }
            },
            treeDragHandlers: {
                dragstart(ev) {
                    const $el = $(ev.target);
                    const name = $el.find('input').val();
                    const d = core_DO.task($el.attr('id'));
                    d['o'] = {
                        x: ev.originalEvent.layerX,
                        y: ev.originalEvent.layerY
                    };

                    ev.originalEvent.dataTransfer.setData((core.isIE && 'text') || _gcClipboardId, JSON.stringify(d));
                    ev.originalEvent.dataTransfer.effectAllowed = 'move';
                    $(ev.target).fadeOut();
                },
                dragend(ev) {
                    this.updateDrafts();
                }
            },
            alignTreeView(tree, skipAnimation) {
                let fn;
                __VIEWPORT_ALIGNMENT == 0 &&
                (fn = () => {
                    tree.centerView();
                });
                __VIEWPORT_ALIGNMENT == 1 &&
                (fn = gid => {
                    const d = core_DO.upperTaskOfTheUser(core_DO.getAU(), true, gid); //fisrtAvailableTask(gid);
                    if (d) {
                        DEBUG && console.log('scroll to', d.id);
                        tree.setSelection(d.id);
                        tree.scrollToTask(d.id, !skipAnimation);
                    } else {
                        tree.centerView();
                    }
                });
                __VIEWPORT_ALIGNMENT == 2 &&
                (fn = gid => {
                    const id = core_DO.fisrtAvailableTask(gid, core_DO.getAU());
                    if (id) {
                        DEBUG && console.log('scroll to', id);
                        tree.scrollToTask(id, !skipAnimation);
                    }
                });
                fn.call(this, this.groupID);
            },
            render() {
                this.$rendered = core_dom.renderAsElement(tpls.iface.page, {id: this.id});
                core_dom.renderAppend(this.$rendered, tpls.project.base, {}, this._binds);

                this.widget(_wtProjectUsers)
                    .render()
                    .$rendered.appendTo(this.$rendered.find('#project-planner .columns .people'));
                this.widget(_wtProjectUsers).clickHandler = id => {
                    this.treeHandlers.onEvent(_evUserSelect, id);
                    this.uri();
                };

                this.widget(_wtTasksList)
                    .render()
                    .$rendered.appendTo(this.$rendered.find('#project-planner .columns .tasks'));
                this.widget(_wtTaskInfo).render(this.$rendered.find('#project-planner .columns .task'));
                this.widget(_wtManageProjectUsers).render(); //.$rendered.appendTo(this.$rendered.find('#project-users'));
                this.widget(_wtManageTeams).render();
                this.widget(_wtProjectFiles)
                    .render()
                    .$rendered.appendTo(this.$rendered.find('#project-files'));

                this.widget(_wiUserAction).render();
                this.widget(_wiExportAction)
                    .attachTo(this.$rendered.find(`ul.expacolla li.${_clsExport}`))
                    .render();
                this.widget(_wiUploadFileMenu)
                    .attachTo(this.$rendered.find(`button[data-role="${_evFileUpload}"]`))
                    .render();
                this.widget(_wiTeamColorSelector).render();
                this.widget(_wiAddMemberToTeam).render();
                this.widget(_wiCalendarPicker).render();
                // this.widget(_wiProjectStartDate).render();
                this.widget(_wiAddUserToProjectMenu).render();
                this.widget(_wiStoriesEditorMenu)
                    .attachTo(this.$rendered.find(`ul.expacolla li.${_clsStoryEditorMenuButton}`))
                    .render();
                this.widget(_wiTaskTiming).render();
                this.widget(_wiReassignUserTasks).render();

                const self = this;
                this.$rendered
                    .on(core_utils.bindMap(this.treeDragHandlers, this), `div.${_clsDraftTask}`)
                    .on('click', `.${_clsDeadlineWrapper}.Completed`, ev => {
                        if (ev.isDefaultPrevented()) {
                            return true;
                        }
                        this.treeHandlers.onEvent(
                            _evChangeCompleteDate,
                            $(ev.currentTarget),
                            undefined,
                            enmTaskDateMode.complete
                        );
                        return false;
                    })
                    .on('click', `.${_clsDeadlineWrapper}`, ev => {
                        if (ev.isDefaultPrevented()) {
                            return true;
                        }
                        this.treeHandlers.onEvent(_evSetDeadline, $(ev.target), undefined, enmTaskDateMode.deadline);
                        return false;
                    })
                    .on('click', `.${_clsStartTaskDateSection}`, ev => {
                        if (ev.isDefaultPrevented()) {
                            return true;
                        }
                        this.treeHandlers.onEvent(_evSetDeadline, $(ev.target), undefined, enmTaskDateMode.startDate);
                        return false;
                    })
                    .on('focusin', `div.${_clsDraftTask} input`, function (ev) {
                        $(this)
                            .parents(`div.${_clsDraftTask}`)
                            .addClass(_clsActive);
                    })
                    .on('focusout', `div.${_clsDraftTask} input`, ev => {
                        // FIXME: double processing, why???
                        const $el = $(ev.target).parents(`div.${_clsDraftTask}`);

                        const d = d3.select($el[0]).datum();
                        let nn = $(ev.target).val();

                        if (DEBUG && nn == '#id#') {
                            nn = d['id'];
                        }
                        if (d.name == nn) {
                            return;
                        }

                        this.stateChange(enmStateChangeTypes.UpdateTask, d['id'], {
                            name: nn
                        });

                        d['name'] = nn;
                        d3.select($el[0]).datum(d);
                        this._draftBinds.ns(d['id']).set(d, true);

                        $el.removeClass(_clsActive);
                        return false;
                    })
                    .on(_evEscPressed, `div.${_clsDraftTask} input`, ev => {
                        const nv = $(ev.target).val();
                        const id = $(ev.target)
                            .parents(`.${_clsDraftTask}`)
                            .attr('id');

                        if (!nv) {
                            setTimeout(() => {
                                $(ev.target)
                                    .parents(`div.${_clsDraftTask}`)
                                    .remove();
                                if (!id || typeof id != 'string') {
                                    __USE_ROLLBAR && Rollbar.debug('project::evEscPressed::clsDraftTask no ID');
                                    return;
                                }
                                this.stateChange(enmStateChangeTypes.DeleteTask, id);
                            }, 100);
                        }
                        return false;
                    })
                    .on('keydown', `div.${_clsDraftTask} input`, function (ev) {
                        if (ev.keyCode == 9) {
                            const $els = $(`div.${_clsDraftTask}`);
                            if ($els.length == 1) {
                                return false;
                            } else if ($els.index($(this).parent()) == $els.length - 1) {
                                $els.first()
                                    .find('input')
                                    .get(0)
                                    .focus();
                                return false;
                            }
                        }
                    })
                    .on('click', `div.${_clsZoom}`, ev => {
                        const $el = $(ev.currentTarget);
                        const ofs = $el.offset();
                        const x = ev.pageX - ofs.left;
                        let r;

                        switch (true) {
                            case x <= 25:
                                r = 0.5;
                                break;
                            case x > 25 && x < 60:
                                r = 0.75;
                                break;
                            case x >= 60:
                                r = 1;
                                break;
                        }

                        const c = _ZoomMap[r * 100];
                        if (!$el.hasClass(c)) {
                            $el.removeClass([_gcZoom50, _gcZoom75, _gcZoom100].join(' ')).addClass(c);
                            this._tree.zoom((this.__ozf = r));
                            if ($groupHolder) {
                                $groupHolder.data(_cnstGroupTree).zoom(r);
                            }
                        }
                    })
                    .on(
                        'click',
                        `div.${_clsTreeToolbar} div.${_clsBackButton}, span.${_clsProjectHead}.${_clsProjectHeadGroup}`,
                        () => {
                            this.treeHandlers.onEvent(_evGroupBack);
                        }
                    )
                    .on('click', `.${_idSymbolCloseIcon}`, function () {
                        if (window.confirm(core.getMsg(_msgRemoveCommentConfirmation))) {
                            const d = d3
                                .select(
                                    $(this)
                                        .parents('div.comment')
                                        .get(0)
                                )
                                .datum();
                            let r;
                            core.moses
                                .announce(
                                    _rRemoveComment,
                                    (r = {
                                        historyEntryId: d.id
                                    })
                                )
                                .catch(o => {
                                    __USE_ROLLBAR &&
                                    Rollbar.error('project::idSymbolCloseIcon fail response', {req: r, resp: o});
                                });
                        }
                    })
                    .on('click', `input[type="checkbox"][data-event="${_evTaskDurationDebug}"]`, function () {
                        $scope[_bndTaskDebugMode] = this.checked;
                        self._tree.redraw();
                    })
                    .on('show.datepicker', `.${_clsProjectStartDateGWrapper}`, function (ev) {
                        const d = $(this).data();
                        const picker = ev.picker;
                        const date =
                            $scope[_bndCurrentProject] && core_utils.unpackDate($scope[_bndCurrentProject].startDate);
                        date && picker.setDate(new Date(date));
                        picker &&
                        picker.$picker
                            .find(`.${_clsDatePicker_ActionPanel}`)
                            .addClass(_clsProjectStartDate)
                            .on('click', ev => {
                                const p = $classes.$check(_cProjectInfo, $scope[_bndCurrentProject]).$export();
                                p.startDate = null;
                                p.projectId = p.id;

                                core_DO.updateProject(p.id, p);
                                core.moses.announce(_rUpdateProject, p).catch(o => {
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'project::clsProjectStartDate::clearDate clear start date fail response',
                                        {req: p, resp: o}
                                    );
                                });
                                picker.hide();
                                return true;
                            });
                    })
                    .on('pick.datepicker', `.${_clsProjectStartDateGWrapper}`, ev => {
                        const v = moment(ev.date)
                            .hours(0)
                            .minutes(0)
                            .valueOf();
                        const o = ev.old;
                        if (ev.view == 'day' && v != o) {
                            const p = $classes.$check(_cProjectInfo, $scope[_bndCurrentProject]).$export();
                            p.startDate = core_utils.packDate(v);
                            p.projectId = p.id;

                            core_DO.updateProject(p.id, p);
                            core.moses.announce(_rUpdateProject, p).catch(o => {
                                __USE_ROLLBAR &&
                                Rollbar.error(
                                    'project::clsProjectStartDate::setStartDate clear start date fail response',
                                    {req: p, resp: o}
                                );
                            });
                        }
                    })
                    .on('show.datepicker', `.${_clsDeadlineBlock},.${_clsDeadlineWrapper}`, ev => {
                        const data = ev.pdata;
                        const panel = ev.picker.$picker
                            .find(`.${_clsDatePicker_ActionPanel}`)
                            .removeClass([_evClearDeadline, _evClearTaskStartDate].join(' '));
                        data && data.clearDateClass && panel.addClass(data.clearDateClass);
                        panel.off('click').on('click', () => {
                            const o = {};
                            o[data.field] = null;
                            self.stateChange(enmStateChangeTypes.UpdateTaskTiming, data.id, o);
                            core_DO.updateTaskTiming(data.id, o);
                            ev.picker.$picker.hide();
                        });
                    })
                    .on('pick.datepicker', `.${_clsDeadlineBlock},.${_clsDeadlineWrapper}`, ev => {
                        const v = moment(ev.date)
                            .hours(0)
                            .minutes(0)
                            .valueOf();
                        const o = ev.old || ev.picker.options.date;
                        const d = ev.pdata;
                        if (ev.view == 'day' && v != o) {
                            // console.warn('setDeadline!!!', d, v);
                            const obj = {};
                            obj[d.field] = core_utils.packDate(v);
                            this.stateChange(enmStateChangeTypes.UpdateTaskTiming, d.id, obj);
                            core_DO.updateTaskTiming(d.id, obj);
                        }
                    });

                this.$rendered.find(`#${_clsAddNewTask}`).on('dragstart', ev => {
                    const o = {
                        id: core_utils.uuid(),
                        dragMode: _clsAddNewTask,
                        name: '',
                        o: {
                            x: ev.originalEvent.offsetX,
                            y: ev.originalEvent.offsetY
                        }
                    };
                    const dt = ev.originalEvent.dataTransfer;

                    dt.setData((core.isIE && 'text') || _gcClipboardId, JSON.stringify(o));
                    dt.effectAllowed = 'move';
                    dt.dropEffect = 'copy';

                    dt.setDragImage &&
                    dt.setDragImage(
                        dragImage,
                        ev.originalEvent.offsetX,
                        ev.originalEvent.offsetY + core.isSafari ? 60 : 0
                    );
                });

                this.widget(_wiAddUserToTaskMenu).render();

                $(window).fullScreenChange(() => {
                    if (!this._active) {
                        return;
                    }
                    const $ctrl = $('#project-planner');
                    const $fse = $ctrl.find(`ul.expacolla div[data-mode="${_vmFullScreen}"]`);

                    if ($.FullScreen.isFullScreen()) {
                        $fse.addClass('selected');
                        $ctrl.addClass('fullscreen');
                    } else {
                        $fse.removeClass('selected');
                        $ctrl.removeClass('fullscreen');
                        this.uri();
                    }
                });

                core.isWebKit &&
                this.$rendered.find(`div.${_clsHorizontalWrapper}`).on(_gcTransitionEnd, function () {
                    const id = $(this).data('id');
                    if (id == PANES_tabProcess) {
                        $(`#page-${_pidProject} div.${_clsHorizontalWrapper}`).css('overflow', 'hidden');
                    }
                });

                return this;
            }
        }
    );

    var eventHandlers = {};

    /** @this {pcProject} */
    eventHandlers[_evCreateLink] = function (data, d) {
        if (core.mayI.createTransition()) {
            const nl = {
                id: core_utils.uuid(),
                projectId: $scope[_bndCurrentProject].id,
                fromId: data.fromId,
                toId: data.toId
            };
            core_DO.addTransition(nl);
            this.stateChange(enmStateChangeTypes.CreateTransition, nl.id, nl);
            core_stat.inc(_mpcTransitionCreated);
        }
    };

    /** @this {pcProject} */
    eventHandlers[_evCreateTaskWithLink] = function (data, d) {
        if (core.mayI.createTask() && core.mayI.createTransition()) {
            const tid = core_utils.uuid();
            const nt = {
                id: tid,
                projectId: $scope[_bndCurrentProject].id,
                groupId: data.task.groupId,
                colId: data.task.cr.col,
                rowId: data.task.cr.row,
                creatorId: $scope[_bndCurrentWorkspaceUser].id,
                type: enmTaskInfoTypes.Task,
                timeCreated: new Date().getTime()
            };
            const nl = {
                id: core_utils.uuid(),
                projectId: $scope[_bndCurrentProject].id,
                fromId: data.link.fromId,
                toId: tid
            };
            core_DO.addTask(nt);
            core_DO.addTransition(nl);
            this.stateChange(enmStateChangeTypes.CreateTask, nt.id, nt);
            this.stateChange(enmStateChangeTypes.CreateTransition, nl.id, nl);
            core_stat.incAndTrack(_mpcTaskCreated);
            core_stat.inc(_mpcTransitionCreated);
            setTimeout(() => {
                let node = (!data.task.groupId && this._tree) || ($groupHolder && $groupHolder.data(_cnstGroupTree));
                if (node) {
                    node = node.getNode(tid);
                } else {
                    core_DO.setCTID(tid, _wtTree);
                    return;
                }
                this.treeHandlers.onEvent(
                    _evTaskNameEdit,
                    {id: tid},
                    d3
                        .select(node)
                        .select(`text.${_clsTaskName}`)
                        .node(),
                    true
                );
                core_DO.setCTID(tid, _wtTree);
            }, 100);
        }
    };

    /** @this {pcProject} */
    function removeTasks(ids) {
        let sel = ids || core_DO.getCTID() || [];
        !(sel instanceof Array) && (sel = [sel]);

        if (core.mayI.removeTask()) {
            DEBUG && console.time('removeTask::fetchData');
            const tt = core_DO.getChildIds(sel).compact();
            DEBUG && console.timeEnd('removeTask::fetchData');
            const isStoryOpen = !!(core_DO.hasFeature(_fndStoryEditor) && this.storyEditor);

            const ga = tt.subtract(sel).length;
            let m;

            switch (true) {
                case sel.length == 1 && isStoryOpen:
                    m = core.getMsg(_msgRemoveTaskAndStoryChapter);
                    break;
                case sel.length == 1 && core_DO.task(sel[0]).type == enmTaskInfoTypes.Group:
                    m = ga
                        ? core.getMsg(_msgConfirmGroupRemove, {gqty: ga})
                        : core.getMsg(_msgConfirmEmptyGroupRemove);
                    break;
                case sel.length > 1 && sel.filter(id => core_DO.task(id).type == enmTaskInfoTypes.Group).length > 0:
                    m = core.getMsg(_msgConfirmMultiRemove, {qty: sel.length, gqty: ga});
                    break;
                default:
                    m = core.getMsg(sel.length > 1 ? _msgConfirmTasksRemove : _msgConfirmTaskRemove, {
                        qty: sel.length
                    });
                    break;
            }

            // if (m) {
            const r = window.confirm(m);
            if (!r) {
                return;
            }
            // }

            // core_DO.setCTID(undefined, _wtTree);
            core_DO.setCTID(undefined, _pcProject);

            [core_DO.onTasksUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                p.suspend();
            });

            DEBUG && console.time('removeTask::actualRemove');

            tt.forEach(id => {
                if (id && typeof id == 'string') {
                    this.stateChange(enmStateChangeTypes.DeleteTask, id);
                    core_DO.removeTask(id);
                    core_DO.hasFeature(_fndStoryEditor) &&
                    core.moses.announce(_rFeatureQuestion, {
                        question: {
                            featureName: enmFeatureQuestion.StoryEditor,
                            question: enmFeatureQuestionEvent.DeleteStoryChapter,
                            values: {
                                projectId: $scope[_bndCurrentProject].id,
                                chapterId: id
                            }
                        }
                    });
                } else {
                    __USE_ROLLBAR && Rollbar.debug('project::removeTasks no ID', tt);
                }
            });

            DEBUG && console.timeEnd('removeTask::actualRemove');

            [core_DO.onTasksUpdate, core_DO.onTransitionsUpdate].forEach(p => {
                p.resume();
            });

            core_stat.incAndTrack(_mpcTaskRemoved, tt.length);
        }
    }

    /** @this {pcProject} */
    eventHandlers[_evRemoveTask] = function (id) {
        removeTasks.call(this, id);
    };

    /** @this {pcProject} */
    eventHandlers[_evRemoveSelectedTasks] = function (ids) {
        removeTasks.call(this, ids);
        core_DO.setCTID(undefined, _pcProject);
    };

    /** @this {pcProject} */
    eventHandlers[_evRemoveTransition] = function (id) {
        if (core.mayI.removeTransition()) {
            this.stateChange(enmStateChangeTypes.DeleteTransition, id);
            core_DO.removeTransition(id);
            core_stat.inc(_mpcTransitionRemoved);
            return true;
        } else {
            return false;
        }
    };

    /**
     * @this {pcProject}
     * @param {jQuery} data
     * @param {cTaskInfo} d
     * @param {enmTaskDateMode} mode
     */
    // mode:
    // 0 - deadline
    // 1 - complete
    // 2 - startDate
    eventHandlers[_evSetDeadline] = function (data, d, mode) {
        const tid =
            data.data('task-id') ||
            data.parents(`.${_clsDeadlineWrapper}`).data('taskId') ||
            data.parents(`.${_clsStartTaskDateWrapper}`).data('taskId') ||
            (d && d['id']);

        if (!tid) {
            return;
        }

        const task = core_DO.task(tid);
        if (
            (task.state != enmTaskInfoStates.Completed && !core.mayI.setDeadline(task)) ||
            (task.state == enmTaskInfoStates.Completed && !core.mayI.setCompletionTime(task))
        ) {
            return;
        }

        if (core_DO.hasFeature(_fndTaskDuration)) {
            switch (mode) {
                case enmTaskDateMode.complete:
                    data.datepicker({
                        date: new Date(task.timeCompleted),
                        autoPick: true,
                        autoShow: true,
                        offset: 17
                    })
                        .off('pick.datepicker')
                        .on('pick.datepicker', ev => {
                            const o = {
                                timeCompleted: core_utils.packDate(
                                    moment(ev.date)
                                        .hours(0)
                                        .minutes(0)
                                        .valueOf()
                                )
                            };
                            this.stateChange(enmStateChangeTypes.UpdateTaskTiming, d.id, o);
                            core_DO.updateTaskTiming(d.id, o);
                            $(ev.target).datepicker('destroy');
                        })
                        .on('hide.datepicker', function () {
                            $(this).datepicker('destroy');
                        });
                    break;
                default:
                    this.widget(_wiTaskTiming).popupAt(data, task, true);
                    break;
            }
        } else {
            let dl;
            let clearDate;
            let field;
            let clearDateClass;
            switch (mode) {
                case enmTaskDateMode.deadline:
                    dl = task.deadline;
                    field = 'deadline';
                    clearDate = _evClearDeadline;
                    clearDateClass = _clsDeadline;
                    break;
                case enmTaskDateMode.complete:
                    dl = task.timeCompleted;
                    field = 'timeCompleted';
                    clearDate = false;
                    clearDateClass = false;
                    break;
                case enmTaskDateMode.startDate:
                    dl = task.startDate;
                    field = 'startDate';
                    clearDate = _evClearTaskStartDate;
                    clearDateClass = _clsStartDate;
                    break;
            }

            if (
                (task.state != enmTaskInfoStates.Completed && !core.mayI.setDeadline(task)) ||
                (task.state == enmTaskInfoStates.Completed && !core.mayI.setCompletionTime(task))
            ) {
                return;
            }
            data.datepicker({
                date: core_utils.unpackDate(dl),
                autoPick: true,
                autoShow: true,
                offset: data.get(0) instanceof SVGElement ? 17 : 0,
                data: {
                    field,
                    clearDateEvent: clearDate,
                    clearDateClass,
                    id: task.id
                }
            }).on('hide.datepicker', function (ev) {
                $(this).datepicker('destroy');
            });
        }
    };

    eventHandlers[_evChangeCompleteDate] = function (data, d) {
        eventHandlers[_evSetDeadline].call(this, data, d, enmTaskDateMode.complete);
    };

    /** @this {pcProject} */
    eventHandlers[_evSetExecuters] = function (data, d) {
        const tid = data.data('task-id') || (d && d['id']),
            task = core_DO.task(tid);

        setTimeout(() => {
            this.widget(_wiAddUserToTaskMenu).popupAt(data, task, true);
        }, 5);
    };

    /** @this {pcProject} */
    eventHandlers[_evZoom] = function (data, d) {
        this.zoomHandler(data);
    };

    /** @this {pcProject} */
    eventHandlers[_evOpenGroup] = function (data, holdSelection) {
        const t = core_DO.task(data);
        let sp = core.storage.$get(`${_gcGroupInfoStorage}:${data}`);
        const trns = core.storage.$get(`${_gcGroupCanvasStorage}:${data}`);

        if (!t) {
            return;
        }

        if (!$groupHolder) {
            $groupHolder = $(tpls.project.groupPopupContainer()).insertBefore(`svg.${_clsSVGCanvas}`);
            $groupHolder.data(_cnstGroupHolderBinds, this._binds.ns(_cnstGroupTree, $groupHolder));

            core_dom.resizable($groupHolder, {
                dragHandle: `.${_clsGroupNameHeader}`,
                minTop: core_dom.elementOffset(`#${_clsTree} .${_clsTreeToolbar}`).height
            });

            $groupHolder
                .on('click', `.${_clsGroupNameHeader}`, () => {
                    core_DO.setCTID(this.groupID, _wtTree);
                })
                .on('click', `.${_idSymbolCircleXBlack}`, () => {
                    try {
                        core.storage.$set(`${_gcGroupCanvasStorage}:${data}`, groupTree.translate());
                    } catch (e) {
                    }
                    this.treeHandlers.onEvent(_evCloseGroup);
                })
                .on('click', `div.${_clsGroupNameHeader} .${_idSymbolBackArrow}`, () => {
                    const oid = this.groupID;
                    const pg = core_DO.task(oid).groupId;
                    try {
                        core.storage.$set(`${_gcGroupCanvasStorage}:${data}`, groupTree.translate());
                    } catch (e) {
                    }
                    this.treeHandlers.onEvent(_evOpenGroup, pg, true);
                    core_DO.setCTID(oid, _pcProject);
                    this.uri();
                })
                .on('dblclick', `div.${_clsGroupNameHeader}`, function () {
                    $(this)
                        .parent()
                        .toggleClass(_clsMaximized);
                    groupTree.onParentUpdate();
                })
                .on(_evPositionSizeChanged, function () {
                    try {
                        core.storage.$set(`${_gcGroupInfoStorage}:${data}`, [
                            this.offsetLeft,
                            this.offsetTop,
                            this.offsetWidth,
                            this.offsetHeight
                        ]);
                        groupTree.onParentUpdate();
                    } catch (e) {
                    }
                });
            // .find('svg.' + _clsGroupWrapper)
            //     .on(_gcOnSvgPanEvent, function () {
            //         try { core.storage.$set(_gcGroupCanvasStorage + ':' + data, groupTree.translate()); } catch (e) {}
            //     }.debounce(250));
        } else {
            const gt = $groupHolder.data(_cnstGroupTree);
            try {
                core.storage.$set(`${_gcGroupCanvasStorage}:${gt.groupId()}`, gt.translate());
            } catch (e) {
            }
            gt && gt.destroy(true);
            $groupHolder.data(_cnstGroupTree, null);
        }

        if (sp) {
            const [left, top, width, height] = typeof sp == 'string' ? sp.split(',') : sp;
            $groupHolder &&
            $groupHolder.css({
                left,
                top,
                width,
                height
            });
        }

        const obj = {
            [_bndGroupState]: t.state,
            [_bndIsAlerted]: t.isDeadlineAlert(),
            [_bndParentGroupId]: t.groupId || false,
            [_bndGroupCaption]: (t && t['name']) || core.getMsg(_msgUnnamedGroup)
        };

        $groupHolder.data(_cnstGroupHolderBinds).set(obj);

        let groupTree = new $classes.Tree(
            $groupHolder.find(`svg.${_clsGroupWrapper}`).get(0),
            data,
            /*core_utils.bindMap(*/ this.treeHandlers /*, this)*/,
            core_DO
        );

        $groupHolder.data(_cnstGroupTree, groupTree);

        !holdSelection && core_DO.setCTID(undefined, _pcProject);
        this.groupID = data;
        !holdSelection && this.uri();

        groupTree.setData().then(() => {
            groupTree.activate();
            groupTree.highlightTasks($tasksHighLighted);
            if (trns) {
                groupTree.transform(typeof trns == 'string' ? trns.split(',') : trns);
            } else {
                this.alignTreeView(groupTree, true);
            }
        });
    };

    /** @this {pcProject} */
    eventHandlers[_evShadowDragCreated] = function (data, d) {
        // debugger;
        const tree = (d.groupId && this._tree) || $groupHolder.data(_cnstGroupTree);
        const pof = ((d.groupId && this.$rendered.find(`#${_clsTree}`)) || $groupHolder).offset();
        const tof = ((d.groupId && $groupHolder) || this.$rendered.find(`#${_clsTree}`) || $groupHolder).offset();
        // tb = core_dom.elementOffset($groupHolder.find('.' + _clsGroupNameHeader)),
        const bw = parseInt(core_dom.getCStyle($groupHolder[0]).borderLeftWidth, 10);
        const [x, y] = tree.translate();
        const tx = d.xy[0] - pof.left - x - ((d.autoPopup && 277) || 0);
        const ty = d.xy[1] - pof.top - /*tb.height - */ bw * 2 - y - ((d.autoPopup && 42) || 0);

        tree.appendShadowDragNode(data, tx, ty);
    };

    /** @this {pcProject} */
    eventHandlers[_evShadowDragDrop] = function (data, targetGroup, rLinks) {
        const tree = (targetGroup && $groupHolder.data(_cnstGroupTree)) || this._tree,
            self = this;

        if (!tree) {
            return;
        }

        for (const i in data) {
            const cr = tree.XY2CR(tree.adjustXY(data[i][0], data[i][1]));
            const cd = {
                rowId: cr.row,
                colId: cr.col,
                groupId: targetGroup || null,
                projectId: $scope[_bndCurrentProject].id
            };
            core_DO.updateTask(i, cd);
            this.stateChange(enmStateChangeTypes.UpdateTask, i, cd);
        }

        (rLinks || []).forEach(id => {
            self.stateChange(enmStateChangeTypes.DeleteTransition, id);
            core_DO.removeTransition(id);
        });

        $groupHolder.data(_cnstGroupTree).redraw();
        this._tree.redraw();
    };

    /** @this {pcProject} */
    eventHandlers[_evCloseGroup] = function () {
        $groupHolder.hide().removeClass(_clsMaximized);
        this._tree.closeGroup(this.groupID);

        const groupTree = $groupHolder.data(_cnstGroupTree);
        groupTree.destroy();

        $groupHolder.data(_cnstGroupHolderBinds, null);
        this._binds.removeNS(_cnstGroupTree);

        $groupHolder.remove();
        $groupHolder.data(null);
        $groupHolder = null;

        this.groupID = undefined;
        core_DO.setCTID(undefined, _pcProject);
    };

    /** @this {pcProject} */
    eventHandlers[_evTaskNameEdit] = function (data, d, removeOnEsc) {
        const c = core_dom.elementOffset(d);
        const $el = $(tpls.project.inlineEdit(core_DO.task(data['id']))).appendTo(window.FullScreenElement || 'body');
        const self = this;
        const $pel = $(d).hide();

        $el.css({
            top: c.top, // - 6,//(core.isWebKit || core.isSafari ? 3 : 6),
            left: c.left - 2,
            width: $(d).data('width')
        }).data({id: data['id']});

        const $txt = $el
            .find('textarea')
            .on('keydown', function (ev) {
                switch (ev.keyCode) {
                    case _keyESC:
                        if (removeOnEsc) {
                            if (data.id && typeof data.id == 'string') {
                                self.stateChange(enmStateChangeTypes.DeleteTask, data['id']);
                                core_DO.removeTask(data['id']);
                            } else {
                                __USE_ROLLBAR && Rollbar.debug('project::evTaskNameEdit::removeTask no ID', data);
                            }
                        }
                        $(`#${_clsInlineEditShield}`).remove();
                        $pel.show();
                        return false;
                    case _keyENTER:
                        $(this).triggerHandler('focusout');
                        $(`#${_clsInlineEditShield}`).remove();
                        return false;
                }
            })
            .on('click', () => false)
            .on('focusout', function () {
                const $el = $(this);
                const d = $el.data();
                const o = core_DO.task(d['id']);
                let v = $el.val();

                if (o && v != o['name']) {
                    DEBUG && v == '#id#' && (v = d['id']);
                    core_DO.updateTaskName(d['id'], v);
                    self.stateChange(enmStateChangeTypes.UpdateTask, d['id'], {name: v});
                }
                $(`#${_clsInlineEditShield}`).remove();
                $pel.show();
            });
        setTimeout(() => {
            $txt.focus();
        }, 100);
    };

    /** @this {pcProject} */
    eventHandlers[_evGetGroupPopupLocation] = () => {
        $(window).triggerHandler('contextmenu');
        if ($groupHolder) {
            const {height, width, top, left} = core_dom.elementOffset($groupHolder);
            return {height, width, top, left};
            // ,
            //     bw = parseInt(core_dom.getCStyle($groupHolder[0])['borderLeftWidth'], 10),
            //     pof = this.$rendered.find('#' + _clsTree).offset(),
            //     tb = core_dom.elementOffset(this.$rendered.find('.' + _clsTreeToolbar));
            // return [ch.left - pof.left + bw, ch.top - pof.top - tb.height + bw, ch.width, ch.height];
        }
        return undefined;
    };

    /**
     * @this {pcProject}
     * @param {cTaskInfo} d
     */
    eventHandlers[_evShowContextMenu] = function (d, caller) {
        DEBUG && console.log('evShowContextMenu', d3.event, d, caller);
        if (DEBUG && d3.event.metaKey) {
            return;
        }

        d3.event.preventDefault();
        d3.event.stopImmediatePropagation();

        let n;
        switch (true) {
            case !!d && (d.type == enmTaskInfoTypes.Task || d.type == enmTaskInfoTypes.Group):
                n = _wiTaskContextMenu;

                // reset selection in case user clicked task/group outside of selection
                const sel = core_DO.getCTID() || [];
                if (sel.length > 0 && sel.indexOf(d.id) == -1) {
                    core_DO.setCTID(d.id, _wtTree);
                } else {
                    this._popupAtTask = d;
                }
                break;
            default:
                n = _wiPaperContextMenu;
                break;
        }

        setTimeout(
            (w, e, caller) => {
                // debugger;
                const groupHolderTarget = $groupHolder && $groupHolder.get(0).contains(e.target) && $groupHolder;
                const mainTarget =
                    $(`#${_clsTree}`)
                        .get(0)
                        .contains(e.target) && `#${_clsTree}`;
                w.popup(e.pageX, e.pageY, d, caller, groupHolderTarget || mainTarget, e);
            },
            50,
            this.widget(n),
            d3.event,
            caller
        );
    };

    /** @this {pcProject} */
    eventHandlers[_evApplyPaste] = function () {
        this.doActualPaste();
    };

    /** @this {pcProject} */
    eventHandlers[_evCancelPaste] = function () {
        this.pasteInfo?.node?.remove();
        this.pasteInfo = null;
    };

    /** @this {pcProject} */
    eventHandlers[_evTreeExport] = (tree, mode) => {
        // var defs = core_utils.concatArrays(
        //         d3.select(this.$rendered.find('svg.' + _clsSVGResources + ' defs')[0]).selectAll('marker, image')[0],
        //         d3.select(this.$rendered.find('svg.' + _clsSVGCanvas + ' defs')[0]).selectAll('marker, image')[0]
        //     );
        //
        let svgEl;
        if (mode == 'png') {
            Promise.all([
                tree.exportSVG(
                    $scope[_bndCurrentProject].name,
                    ($groupHolder && $groupHolder.data(_cnstGroupHolderBinds).get(_bndGroupCaption)) || undefined,
                    false
                )
            ])
                .then(([{svg, node, width, height}]) => {
                    const canvas = document.createElement('canvas');
                    canvas.width = width;
                    canvas.height = height;
                    svgEl = node;
                    const ctx = canvas.getContext('2d');
                    ctx.fillStyle = 'white';
                    ctx.fillRect(0, 0, width, height);

                    return new Promise(rslv => {
                        const img = new Image();
                        img.src = `data:image/svg+xml;base64,${btoa(unescape(encodeURIComponent(svg)))}`;

                        img.onload = () => {
                            ctx.drawImage(img, 0, 0);
                            rslv(canvas);
                        };
                    });
                })
                .then(canvas => {
                    canvas.toBlob(blob => {
                        saveAs(blob, `${$scope[_bndCurrentProject].name}.png`);
                        svgEl.parentNode.removeChild(svgEl);
                    }, 'image/png');
                });
        } else {
            core_utils.banner('Loading components...');
            const host = _CLOUD_HOST;
            const path = _CODE_PATH;
            Promise.all([
                tree.exportSVG(
                    $scope[_bndCurrentProject].name,
                    ($groupHolder && $groupHolder.data(_cnstGroupHolderBinds).get(_bndGroupCaption)) || undefined,
                    false
                ),
                fetch(`${host}${_gcFontData}`),
                core_utils.loadScript(`${host}${path}/${_SVG_LIB}`)
            ])
                .then(args => Promise.all([args[0], args[1].arrayBuffer()]))
                .then(([{svg, node, width, height}, font]) => {
                    core_utils.banner();
                    svgEl = node;

                    const doc = new PDFDocument({
                        compress: false,
                        size: [width, height]
                    });

                    doc.font(font);
                    doc.info = {
                        Title: $scope[_bndCurrentProject].name,
                        Author: `${$scope[_bndIAM].firstName} ${$scope[_bndIAM].lastName}`
                    };

                    SVGtoPDF(doc, svg, 0, 0, {
                        useCSS: false,
                        fontCallback(el, fo, ff, fb, fi) {
                            // debugger
                            return 'OpenSans-Regular';
                        }
                    });

                    const stream = doc.pipe(blobStream());
                    stream.on('finish', () => {
                        const blob = stream.toBlob('application/pdf');
                        saveAs(blob, `${$scope[_bndCurrentProject].name}.pdf`);
                        svgEl.parentNode.removeChild(svgEl);
                    });
                    doc.end();
                });
        }
    };

    /** @this {pcProject} */
    eventHandlers[_evShowTaskDetails] = function () {
        const $ctrl = $('#project-planner');
        if (this.is2ColumnsMode() && $ctrl.hasClass(_clsColums3PanelHidden)) {
            $ctrl.toggleClass(_clsColums3PanelHidden);
        }
    };

    // if (__DEV__) {
    //     const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

    //     eventHandlers[_evImportProjectData] = async function(projectMap) {
    //         var tasks = Armap('id', {
    //             // projectId: _gcEmbedProjectId,
    //             groupId: function(gid) {
    //                 return !!gid;
    //             },
    //             type: undefined
    //         });
    //         tasks.$concat(projectMap.tasks.sortBy('timeCreated'));
    //         var self = this;
    //         tasks
    //             .$map(function(v) {
    //                 return {
    //                     id: v.id,
    //                     projectId: $scope[_bndCurrentProject].id,
    //                     colId: v.colId,
    //                     rowId: v.rowId,
    //                     creatorId: $scope[_bndCurrentWorkspaceUser].id,
    //                     type: enmTaskInfoTypes.Task,
    //                     timeCreated: v.timeCreated,
    //                     name: v.name
    //                 };
    //             }, [])
    //             .sort(function(a, b) {
    //                 return a.timeCreated - b.timeCreated;
    //             })
    //             .forEach(function(v) {
    //                 self.stateChange(enmStateChangeTypes.CreateTask, v.id, v);
    //             });

    //         console.log('created tasks');

    //         await timeout(5000);
    //         console.log('change task type');
    //         tasks.$valuesByAggregateKeys({ type: enmTaskInfoTypes.Group }, []).forEach(function(v) {
    //             self.stateChange(enmStateChangeTypes.ChangeTaskType, v.id, { type: enmTaskInfoTypes.Group });
    //         });
    //         console.log('done');

    //         await timeout(5000);
    //         console.log('updating tasks');
    //         Array.prototype.slice.call(tasks).forEach(function(v) {
    //             self.stateChange(enmStateChangeTypes.UpdateTask, v.id, {
    //                 groupId: v.groupId || 'null'
    //             });
    //         });
    //         console.log('done');

    //         timeout(5000);
    //         console.log('update complete and deadline');
    //         Array.prototype.slice
    //             .call(tasks)
    //             .filter(function(d) {
    //                 return d.deadline;
    //             })
    //             .forEach(function(v) {
    //                 self.stateChange(enmStateChangeTypes.UpdateTask, v.id, {
    //                     timeCompleted: v.timeCompleted,
    //                     deadline: v.deadline
    //                 });
    //             });
    //         console.log('done');

    //         await timeout(5000);
    //         console.log('change task state');
    //         Array.prototype.slice.call(tasks).forEach(function(v) {
    //             self.stateChange(enmStateChangeTypes.ChangeTaskState, v.id, {
    //                 state: v.state,
    //                 timeCompleted: v.timeCompleted || 'null'
    //             });
    //         });
    //         console.log('done');

    //         await timeout(5000);
    //         console.log('create transitions');
    //         (projectMap.transitions || []).forEach(function(v) {
    //             v.projectId = $scope[_bndCurrentProject].id;
    //             self.stateChange(enmStateChangeTypes.CreateTransition, v.id, v);
    //         });
    //         console.log('all done');
    //         // type, id, data, force
    //         // this.stateChange(enmStateChangeTypes.CreateTransition, nl.id, nl);
    //     };
    // }

    /** @this {pcProject} */
    // eventHandlers[_] = function (data, d) {}

    core.pages.registerController(_pcProject);
})(core);
