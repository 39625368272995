Vue.component(_cmpProjectTeam, {
    template: tpls.manageTeamsListItem(),
    replace: true,
    inherit: true,
    methods: function () {
        var r = {};

        r[_evAbbrKeypress] = function (ev) {
            if (ev.which < 32) { return true }
            var s = this.symbol;
            if (s.length >= _gcSymbolLength + (/\./.test(s + String.fromCharCode(ev.keyCode)) && 1 || 0)) { ev.preventDefault() }
        }
        r[_evColorSelect] = function (ev) {
            core.moses.announce(_evOpenColorSelect, this);
            ev.preventDefault();
        }
        r[_evNameKeypress] = function (ev) {
            this.symbol = core_utils.string.getAbbr(this.name + (ev.keyCode && String.fromCharCode(ev.keyCode) || ''));
            return true;
        }
        r[_evNameChange] = function (ev) {
            this.symbol = core_utils.string.getAbbr(this.name);
            return true;
        }
        r[_evEdit] = function () {
            var o = {}, self = this;
            ['name','description','color','symbol'].forEach(function (v) {
                o[v] = self[v];
            });
            this.oldValue_ = o;
            this.isEdit_ = true;
        }
        r[_evAddTeamMember] = function (item) {
            if ($(item.$el).hasClass(_clsNewItem) == false) { return }
            core.moses.announce(_evOpenAddMemberPopup, [this, item.$event]);
            item.$event.preventDefault();
        }
        r[_evDragOver] = function (ev) {
            ev.preventDefault();
        }

        return r;
    }()
});

Vue.component(_cmpProjectTeamEditControls, {
    template: tpls.manageTeamsItemEditControls(),
    inherit: true,
    replace: true,
    methods: function () {
        var r = {};
        r[_evStore] = function () {
            var d = this.$parent,
                isNew = d.isNew_;

            d.isEdit_ = d.isNew_ = false;
            d.$set(_bndProcessing, true);

            d.teamId = d.id;
            core.moses.announce(isNew && _rCreateTeam || _rUpdateTeam, d)
                .catch(function (o) {
                    d.$set(_bndProcessing, false);
                    Alertify.log.error('Error updating team');
                    __USE_ROLLBAR && Rollbar.error('projectTeams::cmpProjectTeamEditControls::' + (isNew && 'rCreateTeam' || 'rUpdateTeam') + ' fail response', {'req': r, 'resp': o});
                    var o = d.oldValue_;
                    for (var k in o) {
                        d.$data[k] = o[k];
                    }
                })
        }
        r[_evCancel] = function () {
            var v = this.$parent;
            if (v.isNew_) {
                core_DO.removeTeam(v.id);
            } else {
                var o = v.oldValue_;
                for (var k in o) {
                    v.$data[k] = o[k];
                }
                v.isNew_ = v.isEdit_ = false;
            }
        }
        r[_evRemove] = function () {
            if (window.confirm(core.getMsg(_msgConfirmTeamRemove))) {
                var p = this.$parent;
                p.$set(_bndProcessing, true);
                var r;
                core.moses.announce(_rRemoveTeam, r = {'teamId': p.id})
                    .catch(function (o) {
                        Alertify.log.error('Error team removal');
                        __USE_ROLLBAR && Rollbar.error('projectTeams::cmpProjectTeamEditControls::rRemoveTeam fail response', {'req': r, 'resp': o});
                        p.$set(_bndProcessing, true);
                    });
            }
        }

        return r;
    }()
});

Vue.component(_cmpProjectTeamMember, {
    template: tpls.manageTeamsMemberItem(),
    inherit: true,
    replace: true,
    methods: function () {
        var r = {};
        r[_evRemoveMember] = function () {
            core_DO.removeMemberFromTeam(this.id, this.$parent.id);
            var r;
            core.moses.announce(_rTeamMembership, r = {'teamId': this.$parent.id, 'workspaceUserId': this.id, 'become': enmWorkspaceUserRole.Nobody})
                .catch(function (o) {
                    Alertify.error.log('Error while removing user from team');
                    __USE_ROLLBAR && Rollbar.error('projectTeams::cmpProjectTeamMember::evRemoveMember fail response', {'req': r, 'resp': o});
                })
        }
        return r;
    }()
});
