/**
 * Abstract Page Controller definition
 * @author Jacky Shikerya
 */
// @ts-ignore
new $classes.Class(
    _clAbstractPageController,
    // @lend clAbstractPageController
    {
        /**
         * Controller status
         * @private
         * @type {boolean}
         */
        _active: false,
        /**
         * List of registered widgets
         * @private
         * @type {Object.<string, Object>}
         */
        _widgets: {},
        /**
         * Page ID
         * @type {string}
         */
        id: null,
        /**
         * Page internal (uri handler) ID
         * @type {string}
         */
        um: null,
        /**
         * Determine is this controller is default
         * @type {boolean}
         */
        isDefault: false,
        /**
         * List of handlers for Moses object
         * @type {?Object.<string, function>}
         */
        listen: {},
        /**
         * Link to representation element
         * @type {jQuery}
         */
        $element: null,
        pageTitle: '',
        shouldActivateWidgets_: true,
        $rendered: null,
        /** @constructor */
        _create: core_utils.noop,
        init() {
            if (this.generateListen) {
                this.generateListen(this.listen);
            }
            if (!Object.isEmpty(this.listen)) {
                for (const i in this.listen) {
                    if (i == undefined) {
                        throw new Error('Cannot subscribe listener for undefined', this.listen[i]);
                    }
                    core.moses.subscribe(i, this.listen[i].bind(this));
                }
            }
        },
        /**
         * Widget registration process
         * @param {string|Object} widget Widget class name, widget id, or instance
         * @param {Object=} instance Widget instance
         * @return {Object} returns instance of widget
         */
        registerWidget(widget, instance) {
            if (typeof widget == 'string') {
                if (!instance) {
                    instance = $classes.$factory(`widgets:${widget}`);
                }
            } else {
                instance = widget;
                widget = widget.widgetName;
            }
            this._widgets[widget] = instance;
            instance.pageController = this;
            instance.afterRegister && instance.afterRegister(this);
            return instance;
        },
        /**
         * Widget removal
         * @param {string} id Id of the widget to be removed
         */
        removeWidget(id) {
            this._widgets[id].destroy();
            delete this._widgets[id];
        },
        /**
         * Returns status of controller
         * @return {boolean}
         */
        isActive() {
            return this._active;
        },
        /**
         * Activate widgets
         */
        _activateWidgets() {
            for (const i in this._widgets) {
                this._widgets[i].activate();
            }
            return true;
        },
        _deactivateWidgets() {
            for (const i in this._widgets) {
                this._widgets[i].isActive() && this._widgets[i].deactivate();
            }
            return true;
        },
        /**
         * Controller activation
         */
        activate() {
            DEBUG && console.log(`pageController::activate(${this.id})`);
            this._active = true;
            if (this.shouldActivateWidgets_) {
                this._activateWidgets();
            }
            this.$rendered.addClass(_clsActive);
        },
        /**
         * Controller deactivation
         */
        deactivate() {
            DEBUG && console.log(`pageController::deactivate(${this.id})`);
            this._active = false;
            this._deactivateWidgets();
            this.$rendered.removeClass(_clsActive);
        },
        /**
         * Return requested widget
         * @param {string} id ID of the widget
         * return {Object}
         */
        widget(id) {
            return this._widgets[id];
        },
        /**
         * Page prerender procedure. Called on controller registration
         */
        preRender: core_utils.noop,
        /**
         * Page rendering procedure
         */
        render: core_utils.noop,
        /**
         * Called after controller renders and result inserted to DOM tree
         */
        postRender: core_utils.noop
    }
);
