(function(core) {
    'use strict;';

    /**
     *
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtTasksList,
        _clAbstractWidget,
        // @lend wtTasksList
        {
            user: null,
            includeFree: true,
            afterRegister: function(pc) {
                core_DO.onTasksUpdate.subscribe(this.update.bind(this).debounce(200));
                core_DO.onDraftsUpdate.subscribe(this.update.bind(this));
                core_DO.onChangeAU.subscribe(
                    function(id, caller) {
                        DEBUG && console.info('wtTasksList::onChangeAU', id, caller);
                        if (this.status) {
                            if(this.pageController.plannerTab == _vm3Columns){
                                var ids = (id && id.split(':')) || [id];
                                var includeFree =
                                    (ids.length == 1 && ids[0] == $scope[_bndCurrentWorkspaceUser].id) || ids[0] == 0;
                                var uts = core_DO.upperTaskOfTheUser(ids[0], includeFree);
                                var sel = core_DO.getCTID() || [];
                                sel = ((sel instanceof Array && sel) || [sel])
                                var utasks = core_DO.tasksOfTheUser(ids[0], includeFree).$tasks.map(function (v) {
                                    return v.id;
                                });
                                if (sel.intersect(utasks).length == 0) {
                                    core_DO.setCTID((uts && uts.id) || void 0, _wtTasksList);
                                    this.update();
                                    return;
                                }
                            }
                            this.update();
                        }
                    }.bind(this)
                );
                core_DO.onChangeCTID.subscribe(
                    function(id, caller) {
                        this.status && this.setSelection((id instanceof Array && id) || [id]);
                    }.bind(this)
                );
                core_DO.onCPRChange.subscribe(
                    function() {
                        this.$rendered.find('div.section.done').addClass('hidden');
                        this.update();
                    }.bind(this)
                );
            },
            generateListen: function(r) {
                r[_eProjectTaskActionRequired] = function(el) {
                    // debugger;
                    var d =
                        d3.select(el.parentNode).datum() ||
                        core_DO.task($(el.parentNode).data()['id']) ||
                        core_DO.task($(el).data()['taskId']);
                    var ns;

                    if (!d) {
                        return;
                    }

                    if (!(ns = $(el).data('target'))) {
                        switch (d['state']) {
                            case enmTaskInfoStates.Started:
                            case enmTaskInfoStates.Unblocked:
                                ns = enmTaskInfoStates.Completed;
                                break;
                            case enmTaskInfoStates.Completed:
                                ns = enmTaskInfoStates.Unblocked;
                                break;
                            case enmTaskInfoStates.Blocked:
                                ns = enmTaskInfoStates.Completed;
                                break;
                        }
                    }

                    if (
                        ns &&
                        ((core.mayI.changeState() && ns == enmTaskInfoStates.Completed && core.mayI.completeTask(d)) ||
                            ns != enmTaskInfoStates.Completed)
                    ) {
                        $(el).addClass(_clsThrobber);
                        if (d.type == enmTaskInfoTypes.Group) {
                            var ids = core_DO.getChildIds([d.id]);
                            var self = this;
                            ids.forEach(function(id) {
                                self.pageController.stateChange(enmStateChangeTypes.ChangeTaskState, id, { state: ns });
                                ns == enmTaskInfoStates.Completed && core_stat.incAndTrack(_mpcTaskCompleted);
                            });
                        } else {
                            this.pageController.stateChange(enmStateChangeTypes.ChangeTaskState, d['id'], {
                                state: ns
                            });
                            ns == enmTaskInfoStates.Completed && core_stat.incAndTrack(_mpcTaskCompleted);
                            core_DO.changeTaskState({ id: d['id'], state: ns });
                        }
                    }
                };
            },
            activate: function() {
                this._super();
                this.update();
            },
            cleanup: function() {
                this.user = null;
                this.update();
            },
            setSelection: function(sel) {
                var list = d3
                        .select(this.$rendered[0])
                        .selectAll('ul.tlist li')
                        .classed(_clsActive, false)
                        .filter(function() {
                            return sel.indexOf($(this).data('id')) > -1;
                        })
                        .classed(_clsActive, true),
                    t = core_DO.task($(list.node()).data('id'));

                if (
                    list[0].length == 1 &&
                    (list.datum() || t || {})['state'] == enmTaskInfoStates.Completed &&
                    this._binds.$elem('done-sec').hasClass('hidden')
                ) {
                    this._binds.$elem('done-sec').removeClass('hidden');
                }
            },
            update: function(announced) {
                if (!$scope[_bndCurrentProject] || !this.status) {
                    return;
                }
                DEBUG && console.log('wtTasksList::update', announced ? 'through mediator' : 'direct update');
                DEBUG && console.time('wtTasksList::update');
                this._binds.set(_bndHasSearchFeature, core_DO.hasFeature(_fndSearch));
                var user = (core_DO.getAU() || '').split(':');
                var includeFree = (user.length == 1 && user[0] == $scope[_bndCurrentWorkspaceUser].id) || user[0] == 0;
                var filter =
                    (core_DO.hasFeature(_fndSearch) &&
                        this._binds.$elem(_bndTaskSearchValue) &&
                        this._binds.$elem(_bndTaskSearchValue).val()) ||
                    undefined;
                var filterCb = function(d) {
                    var r = new RegExp(filter, 'gi');
                    return filter ? r.test(d.name) /*|| r.test(d.description) */ : true;
                };
                var completed = core_DO.tasksListByStatus(
                    user,
                    includeFree,
                    enmTaskInfoStates.Completed,
                    enmTaskInfoTypes.Task,
                    (filter && filterCb) || undefined
                );
                var unblocked = core_DO.tasksListByStatus(
                    user,
                    includeFree,
                    [enmTaskInfoStates.Unblocked, enmTaskInfoStates.Started],
                    enmTaskInfoTypes.Task,
                    (filter && filterCb) || undefined
                );
                var blocked = core_DO.tasksListByStatus(
                    user,
                    includeFree,
                    enmTaskInfoStates.Blocked,
                    enmTaskInfoTypes.Task,
                    (filter && filterCb) || undefined
                );
                var total = completed.qty + unblocked.qty + blocked.qty;
                var sel = core_DO.getCTID() || [];
                var ttl = [].concat(
                    completed.$list.map(function(d) {
                        return d.id;
                    }),
                    unblocked.$list.map(function(d) {
                        return d.id;
                    }),
                    blocked.$list.map(function(d) {
                        return d.id;
                    })
                );

                if (
                    this.status &&
                    ttl.length > 0 &&
                    sel.length == 0 /*sel.intersect(ttl).length == 0*/ &&
                    this.pageController.plannerTab == _vm3Columns
                ) {
                    var upperTask = core_DO.upperTaskOfTheUser(this.user, this.includeFree);
                    upperTask && core_DO.setCTID(upperTask.id, _wtTasksList);
                    return;
                }

                [
                    d3
                        .select(this._binds.elem('done'))
                        .selectAll('li')
                        .data(completed.$list, function(d) {
                            return d && d['id'];
                        }),
                    d3
                        .select(this._binds.elem('current'))
                        .selectAll('li')
                        .data(unblocked.$list, function(d) {
                            return d && d['id'];
                        }),
                    d3
                        .select(this._binds.elem('future'))
                        .selectAll('li')
                        .data(blocked.$list, function(d) {
                            return d && d['id'];
                        })
                ].forEach(function(cc) {
                    cc.enter().append('li');
                    cc.exit().remove();
                    cc.html(function(d) {
                        d.alert = d.isDeadlineAlert();
                        return tpls.widgets.tasksList.taskItemTpl(d);
                    })
                        .attr('data-id', function(d) {
                            return d['id'];
                        })
                        .classed(_clsActive, function(d) {
                            return sel.indexOf(d['id']) > -1;
                        })
                        .order();
                });

                this._binds.set({
                    'done-qty': completed.qty,
                    'current-qty': unblocked.qty,
                    'future-qty': blocked.qty,
                    'ftc-status': core_DO.settings(_skeyFutureTasksCollapse) == 'true',
                    banner:
                        total == 0 && this.pageController.is2ColumnsMode() && core_DO.drafts().length == 0 && !filter
                });

                DEBUG && console.timeEnd('wtTasksList::update');
            },
            render: function() {
                this.$rendered = core_dom.renderAsElement(tpls.widgets.tasksList.base, {}, this._binds);
                this.$rendered
                    .on(
                        'click',
                        'li',
                        function(ev) {
                            // double click protection
                            if (new Date().getTime() - (this.lastClick || 0) < _gcDblClickDebounceRate) {
                                return false;
                            } else {
                                this.lastClick = new Date().getTime();
                            }

                            var d = d3.select(ev.currentTarget).datum() || $(ev.currentTarget).data();
                            var p = core_dom.parentsHasClass(ev.target, '.' + _clsCheckbox);
                            if (p) {
                                core.mayI.changeState(d) && core.moses.announce(_eProjectTaskActionRequired, p);
                            } else {
                                var sel = core_DO.getCTID() || [];

                                switch (true) {
                                    case (core.isApple && ev.metaKey) || (!core.isApple && ev.ctrlKey):
                                        if (sel.indexOf(d['id']) == -1) {
                                            sel.unshift(d['id']);
                                        } else {
                                            sel.splice(sel.indexOf(d['id']), 1);
                                        }
                                        core_DO.setCTID(sel, _wtTasksList);
                                        break;
                                    case ev.shiftKey:
                                    // FIXME: what should we do in case user clicks task with SHIFT holded?
                                    default:
                                        core_DO.setCTID(d['id'], _wtTasksList);
                                        break;
                                }
                            }
                            return false;
                        }.bind(this)
                    )
                    .on(
                        'dblclick',
                        'li',
                        function(ev) {
                            if (this.pageController.is2ColumnsMode()) {
                                var d = d3.select(ev.currentTarget).datum() || $(ev.currentTarget).data();
                                core_DO.setCTID(d['id'], _wtTasksList + 'dbl');
                                // this.pageController.treeHandlers.onEvent(_evTreeTaskSelected, d);
                            }
                            return false;
                        }.bind(this)
                    )
                    .on('click', `.section.${_clsCollapsibleHeader} .header`, function() {
                        const $c = $(this)
                            .parent()
                            .toggleClass('hidden')
                            .toggleClass(_clsExpanded);
                        core_DO.settings(_skeyFutureTasksCollapse, $c.hasClass('hidden'));
                    })
                    .on(
                        _evEscPressed + ' click',
                        'div.' + _clsTaskSearchPanel + ' input',
                        function(ev) {
                            $(ev.target).val('');
                            this.update();
                        }.bind(this)
                    )
                    .on(
                        'keyup',
                        'div.' + _clsTaskSearchPanel + ' input',
                        function() {
                            this.update();
                        }
                            .bind(this)
                            .debounce(250)
                    );
                // this.$rendered.sectionsList();
                return this._super();
            }
        }
    );
})(core);
