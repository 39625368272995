function l() {
    core.init().run();
}
if (!__DEV__) {
    var w = window;
    if (w.attachEvent) {
        w.attachEvent('onload', l);
    } else {
        w.addEventListener('load', l, false);
    }
} else {
    l();
}
