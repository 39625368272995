Vue.directive('user-pic', {
    bind: function () {
        switch (this.arg) {
            case _cssSVExtraSmall:
                this._method = imgs.usersImage20;
                break;
            case _cssSVMedium:
                this._method = imgs.usersImage80;
                break;
            case _cssSVSmall:
            default:
                this._method = imgs.usersImage40;
                break;
        }
    },
    update: function (id) {
        // this.el.className = this._method(id);
    }
});
Vue.directive('svg-user-pic', {
    update: function (id) {
        core.ImgCacheCollectionV4.attr(this.el, {'xlink:href': '#' + imgs.usersImage.makeId(id)});
    }
})
Vue.directive('team-small-pic', {
//     bind: function (id) {
//         this.el.className = imgs.usersImage20(this.key);
//     },
    update: function (id) {
        // this.el.className = imgs.usersImage20(id);
    }
})
Vue.directive('user-role', {
//     bind: function () {
//         $(this.el).addClass(this.key).html(tpls.translateRole({role: this.key}));
//         this.__oldValue =
//     },
    update: function (newValue, oldValue) {
        $(this.el)
            .removeClass(this.__oldValue)
            .addClass(newValue)
            .html(tpls.translateRole({role: newValue}));
         this.__oldValue = newValue;
    }
})
