/**
 * @extends AbstractPageController
 */
// @ts-ignore
new $classes.Class(
    _pcWorkspaces,
    _clAbstractPageController,
    // @lends pcWorkspaces
    {
        isDefault: true,
        id: _pidWorkspaces,
        um: _umWorkspaces,
        pageTitle: [core.getMsg(_msgWorkspacesTitle), _gcProjectTitle].join(_gcTitleSeparator),
        init: function() {
            this._binds = new $classes.Binds();
            core_DO.onWorkspaceUpdate.subscribe(this.update.bind(this));
            this._super();
        },
        update: function() {
            this._binds.set(
                _bndOwnerList,
                core_DO.$workspaces().filter(function(d) {
                    return d['workspaceUser']['role'] == enmWorkspaceUserRole.Owner;
                })
            );
            this._binds.set(
                _bndParticipantList,
                core_DO.$workspaces().filter(function(d) {
                    return d['workspaceUser']['role'] != enmWorkspaceUserRole.Owner;
                })
            );
        },
        activate: function() {
            var sws = core_DO.settings(_gcSelectedWorkspace);
            // core.storage.$get(_gcSelectedWorkspace);
            if (sws && core_DO.$workspaces(sws)) {
                core.uriHandler.setUri({ id: _umWorkspace, wsId: sws });
                return;
            }
            this._super();
            this.update();
        },
        uriHandler: function() {
            var ws = core_DO.$workspaces();
            if (ws.length == 1) {
                core.uriHandler.setUri({ id: _umWorkspace, wsId: ws[0]['id'] }, false, false);
                return true;
            } else {
                var sws = core_DO.settings(_gcSelectedWorkspace);
                // core.storage.$get(_gcSelectedWorkspace);
                if (sws && core_DO.$workspaces(sws)) {
                    core.uriHandler.setUri({ id: _umWorkspace, wsId: sws }, false, false);
                } else {
                    return false;
                }
                return true;
            }
        },
        /**
         * Inititial rendering
         */
        render: function() {
            this.$rendered = core_dom.renderAsElement(tpls.iface.page, { id: this.id });
            core_dom.renderIntoElement(this.$rendered, tpls.dashboards.decoration, null, this._binds);
            this.$rendered.on('click', 'div.' + _clsWSItem, function() {
                var d = d3.select(this).datum();
                core.uriHandler.setUri({ id: _umWorkspace, wsId: d['id'] });
            });
            // new iScroll(this.$rendered.find('div.scroll-wrapper')[0], _iScrollerOptions);
            return this;
        }
    }
);

core.pages.registerController(_pcWorkspaces);
