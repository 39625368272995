function calcCenter($el) {
    $el.find(`.${_clsTutorial_Frame}.${_clsTutorial_Frame__Centered}`).each(function() {
        const $box = core_dom.elementOffset($el);
        const $f = $(this);
        $f.css({
            left: ($box.width - $f.width()) / 2,
            top: Math.max(($box.height - $f.height()) / 2, 10)
        });
    });
}

function bindVimeoListener(callback) {
    const player = $(`#${_bndVimeoPlayer}`);
    let playerOrigin = '*';
    let playProgress;

    // Handle messages received from the player
    const onMessageReceived = event => {
        // Handle messages from the vimeo player only
        if (!/^https?:\/\/player.vimeo.com/.test(event.origin)) {
            return false;
        }

        if (playerOrigin === '*') {
            playerOrigin = event.origin;
        }

        const data = JSON.parse(event.data);

        switch (data.event) {
            case 'ready':
                post('addEventListener', 'finish');
                post('addEventListener', 'playProgress');
                break;
            case 'playProgress':
                playProgress = data.data;
                break;
            case 'finish':
                callback && callback.call(null);
                break;
        }
    };

    var post = (action, value) => {
        const data = {
            method: action
        };

        if (value) {
            data.value = value;
        }

        const message = JSON.stringify(data);
        player[0].contentWindow.postMessage(message, playerOrigin);
    };

    // Listen for messages from the player
    if (window.addEventListener) {
        window.addEventListener('message', onMessageReceived, false);
    } else {
        window.attachEvent('onmessage', onMessageReceived, false);
    }

    return {
        unbind() {
            if (window.removeEventListener) {
                window.removeEventListener('message', onMessageReceived, false);
            } else {
                window.detachEvent('onmessage', onMessageReceived, false);
            }
        },
        progress() {
            return playProgress;
        }
    };
}

// /** @const */_tutIntro              = 0x0001;
const /** @const */ _tutIntroMessage = 0x0002;
const /** @const */ _tutVideoShow = 0x0004;
const /** @const */ _tutCongratMessage = 0x0008;
const /** @const */ _tutConfirmVideoShow = 0x0010;
const /** @const */ _tutCloseTutorialButton = 0x0020;
const /** @const */ _tutFromStories = 0x0040;

const /** @const */ _tutOwnerTutorial = /*_tutIntro | */ _tutIntroMessage | _tutVideoShow | _tutCongratMessage;
const /** @const */ _tutLeaderTutorial = /*_tutIntro | */ _tutVideoShow | _tutCongratMessage;
const /** @const */ _tutRegularTutorial = _tutVideoShow | _tutCongratMessage | _tutCloseTutorialButton;

const /** @const */ _Opacity = 'opacity';
const /** @const */ _Click = 'click';

/**
 * @param {number} mode
 * @param {Function} createProjectCallback
 * @param {Function} finishTutorialCallback
 * @return {Function}
 */
core.tutorial = (mode, createProjectCallback, readyCallback) => {
    const trg = 'body';
    if ($(trg).find(`.${_clsTutorial}`).length) {
        return;
    }
    let $el = $(trg)
        .append(
            tpls.tutorial.base({
                haveCloseButton: (mode & _tutCloseTutorialButton) == _tutCloseTutorialButton,
                cameFromStories: (mode & _tutFromStories) == _tutFromStories,
                videos:
                    (mode & _tutFromStories) == _tutFromStories
                        ? [
                              { fn: 'tutorial3', txt: core.getMsg(_msgTutorialTextAssignUsers, { num: 1 }) },
                              { fn: 'tutorial5', txt: core.getMsg(_msgTutorialTextMarkAsDone, { num: 2 }) },
                              { fn: 'tutorial1', txt: core.getMsg(_msgTutorialTextCreateTask, { num: 3 }) },
                              { fn: 'tutorial2', txt: core.getMsg(_msgTutorialTextPlayWithArrows, { num: 4 }) },
                              { fn: 'tutorial4', txt: core.getMsg(_msgTutorialTextSelectTasks, { num: 5 }) },
                              { fn: 'tutorial6', txt: core.getMsg(_msgTutorialTextCopyNPaste, { num: 6 }) }
                          ]
                        : [
                              { fn: 'tutorial1', txt: core.getMsg(_msgTutorialTextCreateTask, { num: 1 }) },
                              { fn: 'tutorial2', txt: core.getMsg(_msgTutorialTextPlayWithArrows, { num: 2 }) },
                              { fn: 'tutorial3', txt: core.getMsg(_msgTutorialTextAssignUsers, { num: 3 }) },
                              { fn: 'tutorial4', txt: core.getMsg(_msgTutorialTextSelectTasks, { num: 4 }) },
                              { fn: 'tutorial5', txt: core.getMsg(_msgTutorialTextMarkAsDone, { num: 5 }) },
                              { fn: 'tutorial6', txt: core.getMsg(_msgTutorialTextCopyNPaste, { num: 6 }) }
                          ]
            })
        )
        .find(`.${_clsTutorial}`);
    const $frm = $el.find(`.${_clsTutorial_Frame}.${_clsTutorial_Step3}`);
    let queue = Promise.resolve();
    const setCur = (cur, oldcur) => {
        $frm.data('cur', cur)
            .find('[data-m]')
            .removeClass(_clsActive);
        const $cur = $frm.find(`[data-m="${cur}"]`).addClass(_clsActive);

        const player = $cur.find('video').get(0);

        if (oldcur) {
            const p = $frm.find(`[data-m="${oldcur}"] video`).get(0);
            if (p instanceof HTMLVideoElement) {
                p.pause();
                p.currentTime = 0;
            }
        }
        if (player instanceof HTMLVideoElement) {
            const p = player.play();
            p?.then(() => {
                $el.find(`.${_clsPlayButton}`).remove();
            }).catch(() => {
                $(tpls.tutorial.playButton())
                    .appendTo($el.find(`.${_clsTutorial_VideoFrame}`))
                    .on('click', function() {
                        player.play();
                        $(this).remove();
                    });
            });
        }

        const $prev = $(`.${_bndPrevNextStep}[data-inc="-1"]`);
        switch (cur) {
            case 1:
                $prev.addClass(_clsDisabled);
                break;
            default:
                $prev.removeClass(_clsDisabled);
                break;
        }
    };
    const resizeHandler = (() => {
        calcCenter($el);
    }).throttle(250);
    const closeTutorial = () => {
        $(window).off('resize', resizeHandler);
        $('body').removeClass('fade');
        core.moses.unsubscribe(_evCancelTutorial);
        if ($el) {
            $el.css(_Opacity, 0);
            $el.on(_gcTransitionEnd, function() {
                $(this).remove();
                $el = null;
            });
            setTimeout(() => {
                $el && ($el.remove(), ($el = null));
            }, 300);
        }
    };
    const hideTutorial = () => {
        if (!$el) {
            return;
        }
        const cb = () => {
            if (!$el) {
                return;
            }
            $el.css('display', 'none');
            $el.off(_gcTransitionEnd, cb);
        };
        $el && $el.css(_Opacity, 0).on(_gcTransitionEnd, cb);
        setTimeout(cb, 300);
    };
    const restoreTutorial = () => {
        if (!$el) {
            return;
        }
        $el.css('display', 'block');
        setTimeout(() => {
            $el && $el.css(_Opacity, 1);
        }, 50);
    };
    core.moses.subscribe([
        [_evCancelTutorial, closeTutorial],
        [_evHideTutorial, hideTutorial],
        [_evRestoreTutorial, restoreTutorial]
    ]);

    $(window).on('resize', resizeHandler);

    const fStep2 = () =>
        new Promise(rslv => {
            $('body').addClass('fade');
            $el.addClass(_clsTutorial_Step2).on(_Click, `.${_bndStep2Next}`, () => {
                $('body').removeClass('fade');
                $el.removeClass(_clsTutorial_Step2);
                rslv();
            });
        });
    const fStepConfirmTutorial = $pobj =>
        new Promise((rslv, rjct) => {
            $(`#${_bndProjectName}`).text($pobj.name);
            $('body').addClass('fade');
            $el.addClass(_clsTutorial_StepConfirm).on(_Click, `.${_bndStepConfirm}`, function() {
                const r = $(this).data('role') * 1;
                $('body').removeClass('fade');
                if (r) {
                    // want to see tutorial
                    $el.removeClass(_clsTutorial_StepConfirm);
                    rslv();
                } else {
                    // tutorial skipped
                    rjct({ skipped: true, canceled: false });
                }
            });
        });
    const fStep3 = () =>
        new Promise((rslv, rjct) => {
            core_stat.track(_mpcOnboardingTutorialStart);
            $el.addClass(_clsTutorial_Step3)
                .on(_Click, `.${_bndPrevNextStep}`, function() {
                    let cur;
                    const oldcur = (cur = $frm.data('cur') || 0);
                    const inc = $(this).data('inc') * 1;

                    cur += inc;

                    if (cur > 6) {
                        $frm.find('[data-m]').removeClass(_clsActive);
                        $el.removeClass(_clsTutorial_Step3);
                        rslv();
                        return;
                    } else if (cur < 1) {
                        cur = 1;
                    }

                    core_stat.track(inc > 0 ? _mpcOnboardingTutorialNext : _mpcOnboardingTutorialPrevious, {
                        videoTab: cur
                    });
                    setCur(cur, oldcur);
                })
                .on(_Click, `.${_clsTutorial_Indicator_Dot}`, function() {
                    const cur = $(this).data('m');
                    const oldcur = $frm.data('cur');
                    setCur(cur, oldcur);
                })
                .on(_Click, `.${_bndCancelButton}`, () => {
                    rjct({ skipped: true });
                });
            // reset initial video holder
            setCur(1, 1);
        });
    const fStep4 = () =>
        new Promise(rslv => {
            $el.addClass(_clsTutorial_Step4);
            $el.on(_Click, `.${_bndCloseTutorial}`, function() {
                const $this = $(this);

                const cb = () => {
                    if ($el == null) {
                        return;
                    }
                    $el.remove();
                    $el = null;
                    if ($this.hasClass(_bndStep4)) {
                        finishTutorialCallback && finishTutorialCallback();
                    } else {
                        core_stat.track(_mpcOnboardingTutorialClose);
                    }
                    rslv();
                };

                $(window).off('resize', resizeHandler);
                $el.css(_Opacity, 0);
                $el.on(_gcTransitionEnd, cb);
                setTimeout(cb, 300);
            });
        });

    // if ((mode & _tutIntro) == _tutIntro) {
    //     queue = queue.then(fStep1);
    // } else
    if (createProjectCallback) {
        queue = queue.then(() =>
            // .catch(rjct);
            Promise.resolve(createProjectCallback()).then($pobj => $pobj)
        );
    }
    if (readyCallback) {
        queue = queue.then(() => {
            readyCallback();
        });
    }
    if ((mode & _tutIntroMessage) == _tutIntroMessage) {
        queue = queue.then(fStep2);
    }
    if ((mode & _tutConfirmVideoShow) == _tutConfirmVideoShow) {
        queue = queue.then(fStepConfirmTutorial);
    }
    if ((mode & _tutVideoShow) == _tutVideoShow) {
        queue = queue.then(fStep3);
    }
    if ((mode & _tutCongratMessage) == _tutCongratMessage) {
        queue = queue.then(fStep4);
    }

    queue.catch(closeTutorial);

    setTimeout(() => {
        calcCenter($el);
    }, 0);

    return queue;
};

core.tutorial.getData = () => {
    const tutorialData = (core_DO.settings(_skTutorialKey) || '').split(' ');
    tutorialData.has = function(id) {
        return this.indexOf(id) > -1;
    };
    return (core.tutorial.tutorialData = tutorialData);
};

core.tutorial.removeFlag = (flag, save) => {
    core.tutorial.tutorialData.remove(flag);
    save && core.tutorial.saveTutorialData();
};

core.tutorial.addFlag = (flag, save) => {
    core.tutorial.tutorialData.push(flag);
    save && core.tutorial.saveTutorialData();
};

core.tutorial.saveTutorialData = (flag, data) => {
    if (data) {
        core.tutorial.tutorialData = data;
    }
    if (flag) {
        core.tutorial.tutorialData.push(flag);
    }
    core.tutorial.tutorialData = core.tutorial.tutorialData.unique();
    core_DO.settings(_skTutorialKey, core.tutorial.tutorialData.join(' '));
};
