/** @namespace */
const core_dom = {};

/**
 * Render template as element and bind data links
 * @param {function} tpl Template to render
 * @param {Object.<string, *>} data Data for template
 * @param {Object.<string, HTMLElement>=} binds object to store bindings
 * @return {jQuery} rendered element
 */
core_dom.renderAsElement = (tpl, data, binds) => {
    const $el = $(tpl(data));

    binds && binds.regBinds($el);

    return $el;
};
/**
 * Render template into element and process binds
 * @param {jQuery} el Element render into
 * @param {Function} tpl Template to render
 * @param {Object.<string, *>=} data Data for template
 * @param {Object.<string, HTMLElement>=} binds Holder for binds
 * @return {jQuery} rendered element
 */
core_dom.renderIntoElement = ($el, tpl, data, binds) => {
    const $e = $(tpl(data));
    binds && binds.regBinds($e);
    $el.append($e);
    return $e;
};
/**
 * Render element as fisrt element in childs list
 * @param {jQuery} parent element
 * @param {Function} tpl template to render
 * @param {Object.<string, *>=} data for template
 * @param {Object.<string, HTMLElement>=} binds holder
 * @return {jQuery} rendered element
 */
core_dom.renderAsFirstElement = ($parent, tpl, data, binds) => {
    let $el;
    $parent.prepend(($el = core_dom.renderAsElement(tpl, data, binds)));
    return $el;
};

/**
 * Render element and append to parent
 * @param {jQuery} parent
 * @param {Function} tpl template to render
 * @param {Object.<string, *>=} data template data
 * @param {Object.<string, HTMLElement>} binds storage
 * @return {jQuery} rendered element
 */
core_dom.renderAppend = ($parent, tpl, data, binds) =>
    core_dom.renderAsElement(tpl, data, binds, true).appendTo($parent);

/**
 * get computed style of the element
 * @param {string|HTMLElement} el id/rule/element to get info for
 * @return {object}
 */
core_dom.getCStyle = el => {
    if (typeof el == 'string') {
        el = core_dom.element(el);
    }

    return (el && window.getComputedStyle(el, null)) || null;
};

/**
 * Sort DOM elements
 *    (Note: found elements should belong to one parent)
 * @param {string|HTMLElement} CSS sort rule or parent childs shound be sorted
 * @param {function} callback function to provide comparing
 * @param {HTMLElement=} parent parent node for lookup
 */
core_dom.sortElements = (sortRule, callback, parent) => {
    const els = Object.values(core_dom.elements(sortRule, parent));
    let frg;

    if (els.length == 0) return false;

    frg = document.createDocumentFragment();

    els.sort(callback);

    for (let el, i = els.length; --i >= 0; ) {
        el = els[i];
        !parent && (parent = el.parentNode);
        parent.removeChild(el);
        frg.appendChild(el);
    }

    parent.appendChild(frg);
    frg = null;

    return true;
};
/**
 * Determine absolute offset (relative to document) offset of element
 * @param {jQuery|string} el Element to be determined
 * @return {Object} object contain left and top property of offset
 */
core_dom.elementOffset = $el => {
    if (typeof $el == 'string' || $el instanceof jQuery === false) {
        $el = $($el);
    }
    const _el = $el.get(0);

    if (!$el.length || !_el)
        return {
            top: 0,
            left: 0,
            width: 0,
            height: 0,
            right: 0,
            bottom: 0
        };

    const o = $el.offset() || {},
        w =
            ~~(_el instanceof HTMLElement ? $el.outerWidth() : _el instanceof SVGElement ? _el.getBBox().width : 0) ||
            0,
        h =
            ~~(_el instanceof HTMLElement ? $el.outerHeight() : _el instanceof SVGElement ? _el.getBBox().height : 0) ||
            0;
    return {
        top: ~~o.top || 0,
        left: ~~o.left || 0,
        width: w,
        height: h,
        right: (~~o.left || 0) + w,
        bottom: (~~o.top || 0) + h
    };
};

/**
 * Align box in center of parent
 * @param {jQuery} $parent
 * @param {jQuery} $box
 * @param {?boolean} apply position
 * @param {?number} valign
 * @return {Object} positioning info
 */
core_dom.centerBox = ($parent, $box, apply, valign) => {
    const pos = core_dom.elementOffset($box),
        src = core_dom.elementOffset($parent || $box.parent()),
        tp = {
            width: 0,
            height: 0,
            left: 0,
            top: 0,
            ttop: 0
        };

    if (pos.width > src.width) {
        tp.width = src.width - ~~(src.width / 8);
    } else {
        tp.width = pos.width;
    }

    if (pos.height > src.height) {
        tp.height = src.height - ~~(src.height / 8);
    } else {
        tp.height = pos.height;
    }

    tp.left = (src.width - tp.width) / 2;
    tp.top = -tp.height;
    switch (valign) {
        case _BOX_VALIGN_TOP:
            tp.ttop = 10;
            break;
        case _BOX_VALIGN_TOP14:
            tp.ttop = src.height / 4;
            break;
        case _BOX_VALIGN_TOP18:
            tp.ttop = src.height / 8;
            break;
        // case _BOX_VALIGN_BOTTOM34:
        // case _BOX_VALIGN_BOTTOM:
        case _BOX_VALIGN_MIDDLE:
        default:
            tp.ttop = (src.height - tp.height) / 2;
            break;
    }

    if (apply) {
        $box.css({
            top: tp.ttop,
            left: tp.left
        });
    }

    return tp;
};

/**
 * Detect interacting outside of modal element
 * @param {HTMLElement} el
 * @param {Function} callback
 * @param {string=} ignore
 * @return {Function}
 */
core_dom.modalEscape = (el, callback, ignore) => {
    const __release = () => {
        document.removeEventListener('click', __mouseHandler, true);
        document.removeEventListener('keyup', __keypressHandler, true);
    };
    /** @param {MouseEvent} ev */
    const __mouseHandler = ev => {
        const ignEl = ignore && document.querySelector(ignore);
        const target = ev.target;
        // @ts-ignore
        if (!el.contains(target) && ((ignEl && ignEl.contains(target) != true) || (!ignEl && true))) {
            __release();
            callback.call(null);
        }
    };
    /** @param {KeyboardEvent} ev */
    var __keypressHandler = ev => {
        if (ev.which == 27) {
            ev.stopPropagation();
            ev.preventDefault();
            callback.call(null);
        }
    };
    document.addEventListener('click', __mouseHandler, true);
    document.addEventListener('keyup', __keypressHandler, true);

    return __release;
};

/**
 * Get element className
 * @param {HTMLElement|SVGElement|JQuery} el
 * @param {string} cName
 * @return {boolean}
 */
core_dom.hasClass = (el, cName) => {
    switch (true) {
        //     return $(el).hasClass(cName);
        case el instanceof jQuery:
            return el.hasClass(cName);
        case el instanceof HTMLElement:
        case el instanceof SVGElement:
            return el.classList.contains(cName); //new RegExp(cName).test(el.className.baseVal);
        case window.SVGElementInstance && el instanceof SVGElementInstance:
            return el.classList.contains(cName); //new RegExp(cName).test(el.correspondingUseElement.className.baseVal);
        default:
            return false;
    }
};

/**
 * Determine is element has parent with requested class name
 * @param {HTMLElement|SVGElement} el
 * @param {string} query
 * @return {boolean|SVGElement}
 */
core_dom.parentsHasClass = (el, query) => {
    if (core.isIE || !el.matches) {
        const r = $(el).parents(query);
        return r.get(0) || false;
    } else {
        /** @type {Node | null} */
        let p = el;
        do {
            if (p?.matches(query)) {
                return p;
            } else {
                p = p.parentNode;
            }
        } while (p && p.matches);
        return false;
    }
};
/**
 * Actual event attach
 * @private
 * @param {*} element Element for attach
 * @param {string} ev Event to be watched
 * @param {function|string} arg Handler or subrule
 * @param {function?} arg1 handler to be attached if no subrule set
 */
function __mapEvent(element, ev, arg, arg1) {
    $(element).on(ev, arg, arg1);
}
/**
 * Actual event detach
 * @private
 * @param {*} element Element for detach
 * @param {string} ev Event to be watched
 * @param {function|string} arg Handler or subrule
 * @param {function=} arg1 handler to be attached if no subrule set
 */
function __deMapEvent(element, ev, arg, arg1) {
    $(element).off(ev, arg, arg1);
}

/**
 * Mapping events to elements
 * @param {Object.<string, function>|Object.<string, Object.<string, function>>} events Map of the events and handler
 * @example {
 *         'click': function (args) {},
 *         'click@#someElement': function () {},
 *         'div button': {
 *             'click': function () {}
 *         }
 *     }
 * @param {Object|string} parent element pointer
 * @param {boolean=} free true for detach events
 */
core_dom.eventsMap = (map, parent, free) => {
    for (const i in map) {
        if (map[i] instanceof Function) {
            const e = i.split('@');
            if (e.length > 1) {
                ((!free && __mapEvent) || __deMapEvent)(parent, e[0], e[1], map[i]);
            } else {
                ((!free && __mapEvent) || __deMapEvent)(parent, i, map[i]);
            }
        } else {
            for (const j in map[i]) {
                ((!free && __mapEvent) || __deMapEvent)(parent, j, i, map[i][j]);
            }
        }
    }
};

/**
 * Select content of element
 * @param {HTMLElement} el
 */
core_dom.selectElementContents = el => {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
};

core_dom.dblClick = (clickHandler, dblClickHandler, pd) => {
    const /** @const */ dblclickWatcher = 'dW',
        /** @const */ clickCount = 'cc',
        /** @const */ timer = 'ct';

    return function(ev) {
        const e = (ev instanceof Event && ev) || d3.event;
        const $trg = $(e.target);
        const args = Array.prototype.slice.call(arguments);
        let prevented;

        // if ('defaultPrevented' in e) {
        //     if (e.defaultPrevented === true) { return }
        // } else if ('isDefaultPrevented' in e) {
        //     if (e.isDefaultPrevented() === true) { return }
        // }
        if (pd) {
            e.preventDefault();
        }

        if (!(ev instanceof Event)) {
            args.unshift(e);
        }

        if ($trg.prop(dblclickWatcher)) {
            clearTimeout($trg.prop(timer));
            $trg.prop(dblclickWatcher, 0);
            (dblClickHandler || clickHandler).apply(e.target, args);
        } else {
            $trg.prop(dblclickWatcher, 1);
            $trg.prop(clickCount, 1);
            $trg.prop(
                timer,
                setTimeout(() => {
                    $trg.prop(dblclickWatcher, 0);
                    clickHandler.apply(e.target, args);
                }, 300)
            );
        }
    };
};

// function int (str) {
//     return parseInt(str, 10);
// }

core_dom.resizable = (el, copts) => {
    const opts = $.extend(
            {
                changeTop: true,
                changeBottom: true,
                changeLeft: true,
                changeRight: true,
                dragHandle: null,
                minSize: 5,
                minTop: 0
            },
            copts || {}
        ),
        resizers = [],
        classRegExp = new RegExp(
            [
                _clsTopResizer,
                _clsBottomResizer,
                _clsLeftResizer,
                _clsRightResizer,
                _clsTopRightResizer,
                _clsTopLeftResizer,
                _clsBottomRightResizer,
                _clsBottomLeftResizer,
                _clsDragHandle
            ].join('|')
        );

    opts.changeTop && resizers.push($(`<div class="${[_clsResizer, _clsTopResizer].join(' ')}" />`));
    opts.changeBottom && resizers.push($(`<div class="${[_clsResizer, _clsBottomResizer].join(' ')}" />`));
    opts.changeLeft && resizers.push($(`<div class="${[_clsResizer, _clsLeftResizer].join(' ')}" />`));
    opts.changeRight && resizers.push($(`<div class="${[_clsResizer, _clsRightResizer].join(' ')}" />`));
    opts.changeTop &&
        opts.changeRight &&
        resizers.push($(`<div class="${[_clsResizer, _clsTopRightResizer].join(' ')}" />`));
    opts.changeTop &&
        opts.changeLeft &&
        resizers.push($(`<div class="${[_clsResizer, _clsTopLeftResizer].join(' ')}" />`));
    opts.changeBottom &&
        opts.changeRight &&
        resizers.push($(`<div class="${[_clsResizer, _clsBottomRightResizer].join(' ')}" />`));
    opts.changeBottom &&
        opts.changeLeft &&
        resizers.push($(`<div class="${[_clsResizer, _clsBottomLeftResizer].join(' ')}" />`));

    const $el = (el instanceof jQuery && el) || $(el),
        $dragHandle =
            (opts.dragHandle instanceof jQuery && opts.dragHandle) ||
            (typeof opts.dragHandle == 'string' && $el.find(opts.dragHandle)) ||
            $(opts.dragHandle);

    resizers.forEach(r => {
        $el.append(r);
    });

    $dragHandle.addClass(_clsDragHandle);

    $el.on('mousedown', `.${_clsResizer}, .${_clsDragHandle}`, function(ev) {
        let origin = [ev.pageX, ev.pageY];
        const $trg = $(this);
        const trg = this;

        function mouseUp(ev) {
            $(document)
                .off('mousemove', mouseMove)
                .off('mouseup', mouseUp);
            ev.preventDefault();
            ev.stopPropagation();
            $el.trigger(_evPositionSizeChanged);
            return false;
        }
        function mouseMove(ev) {
            const dxy = [ev.pageX - origin[0], ev.pageY - origin[1]],
                cx = $el[0].offsetLeft,
                cy = $el[0].offsetTop,
                ch = $el[0].offsetHeight,
                cw = $el[0].offsetWidth,
                css = {},
                oorigin = origin,
                /** @const */ top = 'top',
                /** @const */ left = 'left',
                /** @const */ width = 'width',
                /** @const */ height = 'height',
                className = classRegExp.exec(trg.className) || [];

            switch (className[0]) {
                case _clsTopResizer:
                    if (ch - dxy[1] < opts.minSize || (opts.minTop && cy + dxy[1] <= opts.minTop)) {
                        return;
                    }
                    css[top] = cy + dxy[1];
                    css[height] = ch - dxy[1];
                    break;
                case _clsBottomResizer:
                    if (ch + dxy[1] < opts.minSize) {
                        return;
                    }
                    css[height] = ch + dxy[1];
                    break;
                case _clsLeftResizer:
                    if (cw - dxy[0] < opts.minSize) {
                        return;
                    }
                    css[left] = cx + dxy[0];
                    css[width] = cw - dxy[0];
                    break;
                case _clsRightResizer:
                    if (cw + dxy[0] < opts.minSize) {
                        return;
                    }
                    css[width] = cw + dxy[0];
                    break;
                case _clsTopRightResizer:
                    if (cw + dxy[0] < opts.minSize || ch - dxy[1] < opts.minSize) {
                        return;
                    }
                    if (opts.minTop && cy + dxy[1] > opts.minTop) {
                        css[top] = cy + dxy[1];
                        css[height] = ch - dxy[1];
                    }
                    css[width] = cw + dxy[0];
                    break;
                case _clsTopLeftResizer:
                    if (ch - dxy[1] < opts.minSize || cw - dxy[0] < opts.minSize) {
                        return;
                    }
                    if (opts.minTop && cy + dxy[1] > opts.minTop) {
                        css[top] = cy + dxy[1];
                        css[height] = ch - dxy[1];
                    }
                    css[left] = cx + dxy[0];
                    css[width] = cw - dxy[0];
                    break;
                case _clsBottomRightResizer:
                    if (ch + dxy[1] < opts.minSize || cw + dxy[0] < opts.minSize) {
                        return;
                    }
                    css[height] = ch + dxy[1];
                    css[width] = cw + dxy[0];
                    break;
                case _clsBottomLeftResizer:
                    if (ch + dxy[1] < opts.minSize || cw - dxy[0] < opts.minSize) {
                        return;
                    }
                    css[height] = ch + dxy[1];
                    css[left] = cx + dxy[0];
                    css[width] = cw - dxy[0];
                    break;
                case _clsDragHandle:
                    css[left] = cx + dxy[0];
                    opts.minTop && cy + dxy[1] > opts.minTop && (css[top] = cy + dxy[1]);
                    break;
            }

            // if (opts.minTop && css[top] <= opts.minTop) {
            //     delete css[top];
            //     ev.pageY = oorigin[1];
            // }

            $el.css(css);
            origin = [ev.pageX, ev.pageY];
        }

        $(document)
            .on('mousemove', mouseMove)
            .on('mouseup', mouseUp);
        return false;
    });
};

core_dom.dumpElements = (el, dumpParent, attrMap) => {
    const items = core_utils.cloneArray(el.childNodes),
        r = [];

    dumpParent && items.unshift(el);

    for (let l = items.length, i = 0; i < l; i++) {
        const item = items[i];

        const attribs = core_utils
            .cloneArray(item.attributes)
            .map(attrMap || (a => [a.name, '=', `"${a.value}"`].join('')))
            .compact();

        let inner = undefined;

        if (item.tagName) {
            if (item.tagName == 'tspan') {
                inner = item.textContent;
            } else {
                const sr = [];
                for (let sl = item.childNodes.length, si = 0; si < sl; si++) {
                    sr.push(core_dom.dumpElements(item.childNodes[si], true, attrMap));
                }
                inner = sr.join('');
            }

            r.push(`<${item.tagName} ${attribs.join(' ')}${(!inner && '/') || ''}>`);
            inner && (r.push(inner), r.push(`</${item.tagName}>`));
        }
    }

    return r.join('');
};

core_dom.selectElementContents = el => {
    const range = document.createRange();
    range.selectNodeContents(el);
    const sel = window.getSelection();
    sel.removeAllRanges();
    sel.addRange(range);
};
