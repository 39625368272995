(function(core) {
    'use strict;';

    var filesLoaded = {};

    function getCTID() {
        return (core_DO.getCTID() || [])[0];
    }

    /**
     *
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtTaskInfo,
        _clAbstractWidget,
        // @lend wtTaskInfo
        {
            historyLoaded: false,
            _taskData: null,
            _taskSchedule: null,
            afterRegister: function(pc) {
                core_DO.onTasksUpdate.subscribe(this.update.bind(this));
                core_DO.onTransitionsUpdate.subscribe(this.update.bind(this));
                core_DO.onHistoryUpdate.subscribe(this.historyUpdate.bind(this));
                core_DO.onFilesUpdate.subscribe(this.filesUpdate.bind(this));
                core_DO.onChangeCTID.subscribe(this.showTask.bind(this));
                this._taskSchedule = $classes.$factory(_wtTaskSchedule, _wiTaskSchedule, void 0, {
                    stateChangeCallback: this.pageController.stateChange.bind(this.pageController)
                });
            },
            activate: function() {
                this._super();
                this._taskSchedule.activate();
                this.update(getCTID());
            },
            deactivate: function() {
                this._taskSchedule.deactivate();
                this._super();
            },
            generateListen: function(r) {
                r[_eViewTaskInfo] = function(id) {
                    this.showTask(id);
                };
            },
            showTask: function(id, caller) {
                if (!this.status) {
                    return;
                }
                core.resetMutexByParent(this.$rendered);
                this.update(id);
            },
            cleanup: function() {
                this.update();
            },
            historyUpdate: function() {
                if (!this.status) {
                    return;
                }
                var id = getCTID(),
                    d = core_DO.getHistory(null, id);
                this._binds.set(_bndHistory, d);
                this._binds.set(_bndHistoryButtonHide, core_DO.isEndOfHistory(id) || d.length < _gcHistoryLimit);
            },
            filesUpdate: function() {
                if (!this.status) {
                    return;
                }
                this._binds.set({ files: core_DO.files(getCTID()) });
            },
            update: function(aid, flags) {
                if (!this.status) {
                    return;
                }
                this.$rendered
                    .find('div.' + _clsCheckbox + ',.' + _clsActionPanel_Start + ',.' + _clsActionPanel_Stop)
                    .removeClass(_clsThrobber)
                    .end();
                // .find('.' + _clsExpandable + '.' + _clsExpanded).removeClass(_clsExpanded);

                var data = { task: null };
                var id = getCTID();

                if (aid && aid != id && aid.length == undefined) {
                    return;
                }

                if (aid == id && flags & _dupRemoved) {
                    var sel = core_DO.getCTID() || [];
                    sel.remove(aid);
                    if (!sel.length) {
                        var uid = core_DO.getAU();
                        var includeFree = uid == $scope[_bndCurrentWorkspaceUser].id || uid == 0;
                        var upperTask = core_DO.upperTaskOfTheUser(uid, includeFree);
                        sel = upperTask && upperTask.id;
                    }
                    core_DO.setCTID(sel, _wtTaskInfo);
                }

                if (id) {
                    // debugger;
                    this._taskData = data['task'] = new $classes[_cTaskInfo](core_DO.task(id));
                    this.$rendered.data('id', id);

                    if (!data['task']) {
                        return;
                    }

                    var blks = core_DO.taskBlocksAndBlockedBy(id);
                    var blksQty = blks.blocked.length;

                    data[_bndBlockedBy] = blks.blocked.splice(0, _gcLimitBBTasks);
                    data[_bndBlocks] = blks.blocks.splice(0, _gcLimitBBTasks);

                    data['bbmore'] = blks.blocked;
                    data['bsmore'] = blks.blocks;

                    data['blocks-section'] = data[_bndBlockedBy].length + data[_bndBlocks].length;

                    switch (data['task']['state']) {
                        case enmTaskInfoStates.Blocked:
                            var c = core.getMsg(_msgTaskInfoHeaderTaskCapt);
                            if (data['task']['values'] && data['task']['values']['blockedByTiming']) {
                                var blockedDate=data['task']['startDate'] || data['task']['values']['canStart'];
                                blockedDate= blockedDate ? moment(blockedDate).utc().format(DATE_FORMAT) : "later";
                                data['headTitle'] = core.getMsg(_msgTaskInfoHeaderStartTime,{
                                    date: blockedDate
                                });
                            } else {
                                data['headTitle'] = core.getMsg(_msgTaskInfoHeaderFuture, {
                                    qty: blksQty,
                                    capt: (blksQty > 1 && c.pluralize()) || c
                                });
                            }
                            break;
                        case enmTaskInfoStates.Skipped:
                            data['headTitle'] = core.getMsg(_msgTaskInfoHeaderSkipped);
                            break;
                        case enmTaskInfoStates.Unblocked:
                        case enmTaskInfoStates.Started:
                            data['headTitle'] = core.getMsg(_msgTaskInfoHeaderCurrent);
                            break;
                        case enmTaskInfoStates.Completed:
                            data['headTitle'] = core.getMsg(_msgTaskInfoHeaderCompleted);
                            data[_bndShowDone] =
                                (data['task']['type'] == enmTaskInfoTypes.Group &&
                                    core_DO.groupStat(data['id']).tasksQty > 0) ||
                                data['task']['type'] == enmTaskInfoTypes.Task;
                            break;
                    }

                    data['task']['description'] == undefined && (data['task']['description'] = null);

                    data[_bndMayIAddExecutor] = core.mayI.addExecutor(data['task']);
                    data[_bndBecomeExecutor] = !data[_bndMayIAddExecutor] && core.mayI.becomeExecutor(data['task']);

                    data['execs'] = [];

                    if (data['task']['type'] == enmTaskInfoTypes.Task) {
                        var uid = $scope[_bndCurrentWorkspaceUser].id,
                            uhash = core_DO.membersHashed();

                        data['task']['execs'].forEach(function(id) {
                            var u = uhash.$item(id);
                            if (u) {
                                u.mayIRemove =
                                    core.mayI.removeExecutor(data['task']) ||
                                    (u.id == uid && core.mayI.removeSelfAsExecutor(data['task']));
                                data['execs'].push(u);
                            }
                        });

                        if (data['task'].teamId) {
                            var u = $scope[_bndProjectTeams].$item(data['task'].teamId);
                            if (u) {
                                u.mayIRemove = core.mayI.removeExecutor(data['task']);
                                u.isTeam = true;
                                data['execs'].push(u);
                            }
                        }

                        data['execs'].sort(function(a, b) {
                            var as =
                                    typeof a['firstName'] == 'string'
                                        ? a['lastName'].toLowerCase() + a['firstName']
                                        : a['name'],
                                bs =
                                    typeof b['firstName'] == 'string'
                                        ? b['lastName'].toLowerCase() + b['firstName']
                                        : b['name'];

                            return as.toLowerCase() > bs.toLowerCase();
                        });
                    }

                    data[_bndIsDeadlineAlert] = data['task'].isDeadlineAlert();
                    // FIXME: update to SVG lib
                    data[_bndCurrentWorkspaceUser] = $scope[_bndCurrentWorkspaceUser].id;
                    if (id != this.prevTask) {
                        data[_bndHistory] = [null];
                        data['files'] = [null];
                    }
                }

                this._binds.set(data);
                this._taskSchedule.setData(id, data);
                // this.$rendered.find('textarea').expandingTextarea('resize');
                this.$rendered.scrollTop(0);

                // FIXME: refactor history & files loading
                // id && id != this.prevTask
                //     && (
                //         core.moses.announce(_rHistoryStream, {
                //             'workspaceId':$scope[_bndCurrentWorkspace].id,
                //             'projectId': $scope[_bndCurrentProject].id,
                //             'objectId': id,
                //             'limit': _gcHistoryLimit
                //         }),
                //         core.moses.announce(_rListFiles, {
                //             'workspaceId':$scope[_bndCurrentWorkspace].id,
                //             'projectId': $scope[_bndCurrentProject].id,
                //             'stream': enmFileStreams.Task,
                //             'objectId': id
                //         }).catch(function () {
                //             console.error('error retrieving list of files');
                //         })
                //     );
                if (id && id != this.prevTask) {
                    var h = core_DO.getHistory(null, id),
                        fl = filesLoaded[id],
                        f = core_DO.files(id);

                    $('#comment textarea').val('');
                    this._binds.set(_bndNoteSaveNotification, 0);

                    if (h.length) {
                        this._binds.set(_bndHistory, h);
                        this._binds.set(
                            _bndHistoryButtonHide,
                            core_DO.isEndOfHistory(id) || h.length < _gcHistoryLimit
                        );
                    } else {
                        var r;
                        core.moses
                            .announce(
                                _rHistoryStream,
                                (r = {
                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                    projectId: $scope[_bndCurrentProject].id,
                                    objectId: id,
                                    limit: _gcHistoryLimit
                                })
                            )
                            .catch(function(o) {
                                __USE_ROLLBAR &&
                                    Rollbar.error('wtTaskInfo::update::rHistoryStream fail response', {
                                        req: r,
                                        resp: o
                                    });
                            });
                    }

                    if (fl) {
                        this._binds.set({ files: f });
                    } else {
                        var r;
                        core.moses
                            .announce(
                                _rListFiles,
                                (r = {
                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                    projectId: $scope[_bndCurrentProject].id,
                                    stream: enmFileStreams.Task,
                                    objectId: id
                                })
                            )
                            .catch(function(o) {
                                DEBUG && console.error('error retrieving list of files');
                                __USE_ROLLBAR &&
                                    Rollbar.error('wtTaskInfo::update::rListFiles fail response', { req: r, resp: o });
                            });
                        filesLoaded[id] = true;
                    }
                }

                // this._te.forEach(function (e) {
                //     e.update();
                //     // e.style.height
                // })
                this.prevTask = id;
            },
            render: function(parent) {
                // this.$rendered = core_dom.renderIntoElement($(parent), tpls.widgets.taskInfo.oldForm, {}, this._binds);
                this.$rendered = core_dom.renderIntoElement($(parent), tpls.widgets.taskInfo.newForm, {}, this._binds);
                var self = this;
                var kpfn = function(ev) {
                    if (ev.keyCode == _keyESC) {
                        var $e = $(this).css('display', 'none');
                        var $s = $e
                            .parents('div.name-wrapper')
                            .find('span.name')
                            .css('display', '');

                        $e.val($e.data('o'));
                        ev.preventDefault();
                        ev.stopPropagation();
                        return false;
                    } else if (ev.keyCode == _keyENTER) {
                        ev.preventDefault();
                        ev.stopPropagation();
                        $(this).triggerHandler('focusout');
                        return false;
                    }
                };
                var finfn = function() {
                    $(this).addClass(_clsActive);
                };
                var fofn = function(ev) {
                    var $e = $(this)
                        .removeClass(_clsActive)
                        .css('display', 'none');
                    var $s = $e
                        .parents('.name-wrapper')
                        .find('span.name')
                        .css('display', 'inline');
                    var id = getCTID();

                    if ($e.data('o') != $e.val()) {
                        var s = $e.val();

                        if (DEBUG && s == '#id#') {
                            s = id;
                        }

                        $s.text(s);
                        core_DO.updateTaskName(id, s);
                        self.pageController.stateChange(enmStateChangeTypes.UpdateTask, id, { name: s });
                        self.pageController._tree.redraw();
                    }
                };

                closeWindowSubscribers.subscribe(
                    function() {
                        var $el = this.$rendered.find('textarea[name="description"]');
                        if ($el.hasClass(_clsActive)) {
                            descrFocusOut($el, true);
                        }
                    }.bind(this)
                );

                var descrFocusOut = function(ev, force) {
                    var val = $(ev.target || ev)
                            .removeClass(_clsActive)
                            .val()
                            .trim(),
                        id = getCTID(),
                        old = core_DO.task(id);

                    if (old && old['description'] != val && !(old['description'] == undefined && val == '')) {
                        this._binds.set(_bndNoteSaveNotification, 1);
                        this.pageController
                            .stateChange(enmStateChangeTypes.UpdateTask, id, { description: val }, true)
                            .then(
                                function(r) {
                                    this._binds.set(_bndNoteSaveNotification, id == getCTID() ? 2 : 0);
                                }.bind(this)
                            );
                    }
                }.bind(this);

                var areas = (this._te = []);
                this.$rendered.find('textarea').on('input update', function() {
                    this.style.height = '';
                    this.style.height = this.scrollHeight + 'px';
                });

                this.$rendered
                    .on(
                        'click',
                        'div.userCardPlate .' + _idSymbolCircleXBlack,
                        function(ev) {
                            var id = getCTID(),
                                d = $(ev.currentTarget).data();

                            if (core.mayI.removeExecutor(id) || core.mayI.removeSelfAsExecutor(core_DO.task(id))) {
                                if (d.ut == 'undefined') {
                                    core_DO.removeExecutor(id, d.uid);
                                    this.pageController.stateChange(enmStateChangeTypes.RemoveTaskExecutor, id, {
                                        workspaceUserId: d.uid
                                    });
                                } else {
                                    core_DO.assignTeam(id, null);
                                    this.pageController.stateChange(enmStateChangeTypes.UpdateTask, id, {
                                        teamId: null
                                    });
                                }
                            }
                        }.bind(this)
                    )
                    .on(
                        'click',
                        'div.' + _clsBackButton,
                        function() {
                            $('#project-planner').removeClass('tab-task');
                            this.deactivate();
                            return false;
                        }.bind(this)
                    )
                    .on('click', '.capt', function() {
                        core.moses.announce(
                            _eProjectTaskActionRequired,
                            $(this)
                                .find('.' + _clsCheckbox)
                                .get(0)
                        );
                        return false;
                    })
                    .on(
                        'click',
                        '.' + _idSymbolProjectTrash,
                        function() {
                            var id = getCTID();
                            if (core.mayI.removeTask(id)) {
                                this.pageController._tree.removeTask(id);
                                core_stat.incAndTrack(_mpcTaskRemoved);
                            }
                            return false;
                        }.bind(this)
                    )
                    .on('click', 'div.name-wrapper', function() {
                        if (!core.mayI.updateTask(self._taskData)) {
                            return false;
                        }
                        var $el = $(this)
                            .find('span.name')
                            .css('display', 'none');
                        var $t = $(this)
                            .find('textarea[name="name"]')
                            .css('display', 'block');
                        $t.triggerHandler('update');

                        $t.unbind('keypress', kpfn)
                            .bind('keypress', kpfn)
                            .unbind('focusin', finfn)
                            .bind('focusin', finfn)
                            .unbind('focusout', fofn)
                            .bind('focusout', fofn)
                            .focus();
                    })
                    .on('focusin', 'textarea[name="description"]', function() {
                        // if (core.mayI.updateTask()) {
                        $(this).addClass(_clsActive);
                        // }
                    })
                    .on('focusout', 'textarea[name="description"]', descrFocusOut)
                    .on(
                        'input',
                        'textarea[name="description"]',
                        function() {
                            if (this._binds.get(_bndNoteSaveNotification) == _clsNoteSaved) {
                                this._binds.set(_bndNoteSaveNotification, 0);
                            }
                        }
                            .bind(this)
                            .debounce(50)
                    )
                    .on(
                        'click',
                        '.' + _idSymbolAssignUser + ',.' + _idSymbolAssignUser + ' ~ span.' + _clsLabel,
                        function(ev) {
                            var id = getCTID();
                            core.mayI.addExecutor(core_DO.task(id)) &&
                                this.pageController
                                    .widget(_wiAddUserToTaskMenu)
                                    .popupAt($(ev.target), core_DO.task(id), true);
                            // ev.stopPropagation();
                            return false;
                        }.bind(this)
                    )
                    .on(
                        'click',
                        '.' + _clsBecomeExecutor,
                        function() {
                            var id = getCTID(),
                                uid = $scope[_bndCurrentWorkspaceUser].id;
                            this.pageController.stateChange(enmStateChangeTypes.AddTaskExecutor, id, {
                                workspaceUserId: uid
                            });
                            core_DO.addExecutor(id, uid);
                        }.bind(this)
                    )
                    .on('submit', '#comment', function(ev) {
                        const $el = $(this);
                        const data = $el.serializeArray().reduce((acc, v) => {
                            acc[v['name']] = v['value'];
                            return acc;
                        }, {});

                        $el.find('textarea').val('');

                        data['comment'] = data['comment'].trim();

                        const r = {
                            workspaceId: $scope[_bndCurrentWorkspace].id,
                            projectId: $scope[_bndCurrentProject].id,
                            objectId: getCTID(),
                            message: data['comment'],
                            stream: enmStreamTypes.Task
                        };
                        data['comment'] &&
                            core.moses
                                .announce(_rPostComment, r)
                                .then(function(d) {
                                    DEBUG && console.log('message sent', d);
                                    core_stat.track(_mpcCommentAdded);
                                })
                                .catch(function(d) {
                                    DEBUG && console.warn('send failed', d);
                                    __USE_ROLLBAR &&
                                        Rollbar.error('wtTaskInfo::submit/comment::rPostComment fail response', {
                                            req: r,
                                            resp: d
                                        });
                                });
                        return false;
                    })
                    .on('click', '#comment button:not([type="submit"])', function() {
                        $(this)
                            .parent()
                            .find('textarea')
                            .val('');
                    })
                    .on('click', 'div.show-more', function(ev) {
                        // debugger;
                        var id = getCTID(),
                            blks = core_DO.taskBlocksAndBlockedBy(id),
                            s = tpls.widgets.taskInfo.showMore({
                                list: $(ev.target).data('src') == 'bb' ? blks.blocked : blks.blocks
                            }),
                            $el = $(s)
                                .appendTo('body')
                                .bind('click', function() {
                                    $(this).remove();
                                }),
                            $b = $(ev.target).prev(),
                            ofs = $b.offset(),
                            hl = $el.find('div.' + _clsPopup)[0].clientHeight,
                            hl = ofs.top + hl > screen.availHeight ? screen.availHeight - ofs.top - 20 : hl;

                        $el.find('div.' + _clsPopup).css({
                            top: ofs.top - 10,
                            left: ofs.left - 10,
                            'max-width': $b[0].offsetWidth + 20,
                            height: hl,
                            opacity: 1
                        });
                    })
                    .on('click', 'div.files .' + _idSymbolCircleXBlack, function(ev) {
                        if (window.confirm(core.getMsg(_msgProjectFileRemoveConfirm))) {
                            var data = d3
                                .select(
                                    $(this)
                                        .parents('div.file')
                                        .get(0)
                                )
                                .datum();
                            var r;
                            core.moses
                                .announce(
                                    _rRemoveFile,
                                    (r = {
                                        fileId: data['id'],
                                        workspaceId: data['workspaceId'],
                                        projectId: data['projectId'],
                                        stream: data['stream'],
                                        objectId: data['objectId']
                                    })
                                )
                                .catch(function(o) {
                                    __USE_ROLLBAR &&
                                        Rollbar.error('wtTaskInfo::rRemoveFile fail response', { req: r, resp: o });
                                });
                            core_DO.removeFile(data['id']);
                        }
                    })
                    .on('click', '#' + _clsLoadMoreButton, function() {
                        var d = self._binds
                                .get(_bndHistory)
                                .data()
                                .last(),
                            $spinner = $(tpls.components.spinner({ type: 'black ' + _clsPartialLoad })),
                            $but = $(this);

                        $(this)
                            .hide()
                            .after($spinner);
                        var r;
                        core.moses
                            .announce(
                                _rHistoryStream,
                                (r = {
                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                    projectId: $scope[_bndCurrentProject].id,
                                    objectId: getCTID(),
                                    limit: _gcHistoryLimit,
                                    before: d['eventTime'],
                                    serialTag: getCTID()
                                })
                            )
                            .then(function() {
                                $spinner.remove();
                            })
                            .catch(function(o) {
                                $spinner.remove();
                                $but.show();
                                __USE_ROLLBAR &&
                                    Rollbar.error('wtTaskInfo::clsLoadMoreButton::rHistoryStream fail response', {
                                        req: r,
                                        resp: o
                                    });
                            });
                    })
                    .on('click', 'div.section.' + _clsBlocks + ' .' + _clsLine, function(ev) {
                        var d = d3.select(this).datum();
                        core_DO.setCTID(d['id'], _wtTasksList);
                    })
                    .on('keyup', 'textarea[name="comment"]', function(ev) {
                        if (ev.keyCode == _keyENTER && ev.altKey) {
                            $(this.form).submit();
                            return false;
                        }
                    })
                    .on('click', '.' + _clsValueWrapper, function() {
                        $(this).toggleClass(_clsExpanded);
                        return false;
                    })
                    .on('click', '.' + _clsActionPanel_Start + ',.' + _clsActionPanel_Stop, function(ev) {
                        core.moses.announce(_eProjectTaskActionRequired, this);
                        return false;
                    })
                    .on(
                        'click',
                        '.section.' + _clsExpandable + ' .' + _clsExpectations_Short + ',.' + _clsSection_SubHeader,
                        function() {
                            $(this)
                                .parents('.' + _clsExpandable)
                                .toggleClass(_clsExpanded);
                        }
                    );

                this._taskSchedule.render(this.$rendered.find('.' + _clsTaskSchedule));

                return this._super();
            }
        }
    );

    core_utils.intExport(_ienShouldShowFileSection, function(list) {
        var r = false;
        switch (true) {
            case list instanceof Array && list.length && list[0] == null:
            case core.mayI.addFile() == true:
                r = true;
                break;
            default:
                r = false;
                break;
        }
        return (r && 'block') || 'none';
    });
    core_utils.intExport(_ienShouldUserSeeHelperBar, function(task) {
        return task && task['type'] == enmTaskInfoTypes.Task && core.mayI.changeState() ? '' : 'none';
    });
    core_utils.intExport(_ienShouldUserSeeExecutorPane, function(task) {
        return task && task['type'] == enmTaskInfoTypes.Task && (task['execs'].length || core.mayI.changeState())
            ? ''
            : 'none';
    });
    core_utils.intExport(_ienShouldUserSeeDeadline, function(task) {
        return task && (task['state'] == enmTaskInfoStates.Completed || task.deadline || core.mayI.setDeadline(task))
            ? ''
            : 'none';
    });
    core_utils.intExport(
        _ienTaskValues,
        /**
         * @param {Node} el
         * @param {Object<string, string>} data
         */
        function(el, data) {
            el.innerHTML = Object.keys(data || {})
                .map(function(k) {
                    return '<div><div>' + k + '</div><div>' + data[k] + '</div></div>';
                })
                .join('');
        }
    );

    /**
     * determine classes to show required controls for deadline/start date/etc
     * @param  {cTaskInfo} data
     * @return {string}
     */
    core_utils.intExport(_ienTaskScheduleWrapperMode, function(data) {
        var r = [];
        if (!data || (!data.durationDef && !data.startDate && !data.deadline)) {
            return '';
        }
        !!data.durationDef && r.push(_clsTaskSchedule_HaveDuration);
        !!data.startDate && r.push(_clsTaskSchedule_HaveStartDate);
        !!data.deadline && r.push(_clsTaskSchedule_HaveDeadline);
        return r.join(' ');
    });

    /** @const */ var DATE_FORMAT = 'MMM D';
    /** @const */ var TIME_FORMAT = 'h:mm a';

    function valueWrapper(v, av) {
        return v === av
            ? v
            : '<span class="' +
                  _clsValueWrapper +
                  '">' +
                  '<span class="' +
                  _clsValueWrapper_Main +
                  '">' +
                  v +
                  '</span>' +
                  '<span class="' +
                  _clsValueWrapper_Alt +
                  '">' +
                  av +
                  ' of work time</span>' +
                  '</span>';
    }

    function getSmartDate(v) {
        var d = moment(v);
        var t =
            d
                .clone()
                .utc()
                .hour() > 0
                ? ' at ' + d.format(TIME_FORMAT)
                : '';
        return d.format(DATE_FORMAT) + t;
    }

    function fmt(data) {
        return data.s.replace(/%([dtas])/g, function(_, q, v) {
            switch (q) {
                case 'd':
                    return moment(data.v)
                        .utc()
                        .format(DATE_FORMAT);
                case 't':
                    return moment(data.v)
                        .utc()
                        .format(TIME_FORMAT);
                case 'a':
                    return getSmartDate(data.v);
                case 's':
                    return data.av ? valueWrapper(data.v, data.av) : data.v;
            }
        });
    }

    core_utils.intExport(
        _ienTaskTimingLegend,
        /**
         * @param {Node} el
         * @param {cTaskInfo} data
         */
        function(el, data) {
            /** @type {Array<{s: string, v: (number | string), av?: (number | string)}>} */
            var short = [];
            /** @type {Array<{s: string, v: (number | string)}, av?: (number | string), p: string>} */
            var full = [];

            if (data) {
                var vs = data.values || {};
                var h = '';
                switch (data.state) {
                    case enmTaskInfoStates.Blocked:
                        short.push(
                            vs.canStart && {
                                s: 'Estimated start: %a',
                                v: vs.canStart
                            },
                            vs.lateDays && {
                                s: 'Start this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours
                            }
                        );
                        full.push(
                            vs.canStart && {
                                s: 'Estimated start: %a',
                                v: vs.canStart,
                                p: 'e'
                            },
                            vs.canFinish && {
                                s: 'Estimated finish: %a',
                                v: vs.canFinish,
                                p: 'e'
                            },
                            vs.lateDays && {
                                s: 'Start this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours,
                                p: 'l'
                            },
                            vs.mustStart && {
                                s: 'Start before: %a',
                                v: vs.mustStart,
                                p: 'l'
                            },
                            vs.mustFinish && {
                                s: 'Finish before: %a',
                                v: vs.mustFinish,
                                p: 'l'
                            }
                        );
                        break;
                    case enmTaskInfoStates.Unblocked:
                        short.push(
                            vs.canStart && {
                                s: 'Estimated start: %a',
                                v: vs.canStart
                            },
                            vs.lateDays && {
                                s: 'Start this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours
                            }
                        );
                        full.push(
                            data.timeUnblocked && {
                                s: 'Unblocked: %a',
                                v: data.timeUnblocked,
                                p: 'f'
                            },
                            vs.canStart && {
                                s: 'Estimated start: %a',
                                v: vs.canStart,
                                p: 'e'
                            },
                            vs.canFinish && {
                                s: 'Estimated finish: %a',
                                v: vs.canFinish,
                                p: 'e'
                            },
                            vs.lateDays && {
                                s: 'Start this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours,
                                p: 'l'
                            },
                            vs.mustStart && {
                                s: 'Start before: %a',
                                v: vs.mustStart,
                                p: 'l'
                            },
                            vs.mustFinish && {
                                s: 'Finish before: %a',
                                v: vs.mustFinish,
                                p: 'l'
                            }
                        );
                        break;
                    case enmTaskInfoStates.Started:
                        short.push(
                            vs.canFinish && {
                                s: 'Estimated finish: %a',
                                v: vs.canFinish
                            },
                            vs.lateDays && {
                                s: 'Finish this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours
                            }
                        );
                        full.push(
                            data.timeUnblocked && {
                                s: 'Unblocked: %a',
                                v: data.timeUnblocked,
                                p: 'f'
                            },
                            data.timeStarted && {
                                s: 'Started: %a',
                                v: data.timeStarted,
                                p: 'f'
                            },
                            vs.canFinish && {
                                s: 'Estimated finish: %a',
                                v: vs.canFinish,
                                p: 'e'
                            },
                            vs.lateDays && {
                                s: 'Finish this task %s earlier to meet deadlines.',
                                v: vs.lateDays,
                                av: vs.lateHours,
                                p: 'l'
                            },
                            vs.mustStart &&
                                moment(vs.mustStart).isBefore(new Date()) && {
                                    s: 'Start before: %a',
                                    v: vs.mustStart,
                                    p: 'l'
                                },
                            vs.mustFinish && {
                                s: 'Finish before: %a',
                                v: vs.mustFinish,
                                p: 'l'
                            }
                        );
                        break;
                    case enmTaskInfoStates.Completed:
                        short.push(
                            data.timeCompleted && {
                                s: 'Finished: %a',
                                v: data.timeCompleted
                            }
                        );
                        full.push(
                            data.timeUnblocked && {
                                s: 'Unblocked: %a',
                                v: data.timeUnblocked,
                                p: 'f'
                            },
                            data.timeStarted && {
                                s: 'Started: %a',
                                v: data.timeStarted,
                                p: 'f'
                            },
                            data.timeCompleted && {
                                s: 'Finished: %a',
                                v: data.timeCompleted,
                                p: 'f'
                            }
                        );
                        break;
                }
            }
            // var _facts = full.filter(function (v) { return v.p == 'f' });
            // var _estimate = full.filter(function (v) { return v.p == 'e' });
            // var _lates = full.filter(function (v) { return v.p == 'l' });
            // var _recomendations = full.filter(function (v) { return v.p == 'r' });
            var _map = function(v) {
                return '<div><span>' + fmt(v) + '</span></div>';
            };
            var _wrap = function(src, type) {
                var r = src.filter(function(v) {
                    return v && v.p == type;
                });
                return r.length ? '<div class="' + type + '">' + r.map(_map).join('') + '</div>' : undefined;
            };

            el.innerHTML = [
                '<div class="' + _clsExpectations_Short + '">',
                short
                    .filter(function(v) {
                        return !!v;
                    })
                    .map(_map)
                    .join(''),
                '</div>',
                '<div class="' + _clsExpectations_Full + '">',
                _wrap(full, 'f'),
                _wrap(full, 'e'),
                _wrap(full, 'l'),
                // _wrap(full, 'r'),
                '</div>'
            ]
                .filter(function(v) {
                    return !!v;
                })
                .join('');
        }
    );

    core_utils.intExport(
        _ienTaskTimingLegendHeader,
        /** @param {enmTaskInfoStates} */
        function(state) {
            switch (state) {
                default:
                    return 'Estimates';
                case enmTaskInfoStates.Started:
                    return 'Estimates & Facts';
                case enmTaskInfoStates.Completed:
                    return 'Facts';
            }
        }
    );
})(core);
