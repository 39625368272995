'use strict';
/** @namespace */
var colorLib = {};

var rnd = Math.round;

/**
 * Convert RGB color to HSV
 * source: http://stackoverflow.com/questions/1664140/js-function-to-calculate-complementary-colour
 * @param {Object.<{{
 *      r: number,
 *      g: number,
 *      b: number,
 * }}>} rgb
 * @return {Object.<{
 *      hue: number,
 *      saturation: number,
 *      value: number
 * >}
 */
colorLib.RGB2HSV = RGB2HSV;
function RGB2HSV(rgb) {
    var hsv = {};
    var max = max3(rgb.r, rgb.g, rgb.b);
    var dif = max - min3(rgb.r, rgb.g ,rgb.b);

    hsv.saturation = (max == 0.0) ? 0 : (100 * dif / max);

    if (hsv.saturation == 0) {
        hsv.hue = 0;
    } else if (rgb.r == max) {
        hsv.hue = 60.0 * (rgb.g - rgb.b) / dif;
    } else if (rgb.g == max) {
        hsv.hue = 120.0 + 60.0 * (rgb.b - rgb.r) / dif;
    } else if (rgb.b == max) {
        hsv.hue = 240.0 + 60.0 * (rgb.r - rgb.g) / dif;
    }

    if (hsv.hue < 0.0) {
        hsv.hue += 360.0;
    }

    hsv.value = rnd(max * 100 / 255);
    hsv.hue = rnd(hsv.hue);
    hsv.saturation = rnd(hsv.saturation);

    return hsv;
}

colorLib.HSV2RGB = HSV2RGB;
/**
 * RGB2HSV and HSV2RGB are based on Color Match Remix [http://color.twysted.net/]
 * which is based on or copied from ColorMatch 5K [http://colormatch.dk/]
 * @param {Object.<{
 *      hue: number,
 *      saturation: number,
 *      value: number
 * >}
 * @return {Object.<{{
 *      r: number,
 *      g: number,
 *      b: number,
 * }}>}
 */
function HSV2RGB(hsv) {
    var rgb = {};

    if (hsv.saturation == 0) {
        rgb.r = rgb.g = rgb.b = rnd(hsv.value * 2.55);
    } else {
        hsv.hue /= 60;
        hsv.saturation /= 100;
        hsv.value /= 100;

        var i = Math.floor(hsv.hue);
        var f = hsv.hue - i;
        var p = hsv.value * (1 - hsv.saturation);
        var q = hsv.value * (1 - hsv.saturation * f);
        var t = hsv.value * (1 - hsv.saturation * (1 - f));

        switch(i) {
            case 0:
                rgb.r = hsv.value;
                rgb.g = t;
                rgb.b = p;
                break;
            case 1:
                rgb.r = q;
                rgb.g = hsv.value;
                rgb.b = p;
                break;
            case 2:
                rgb.r = p;
                rgb.g = hsv.value;
                rgb.b = t;
                break;
            case 3:
                rgb.r = p;
                rgb.g = q;
                rgb.b = hsv.value;
                break;
            case 4:
                rgb.r = t;
                rgb.g = p;
                rgb.b = hsv.value;
                break;
            default:
                rgb.r = hsv.value;
                rgb.g = p;
                rgb.b = q;
                break;
        }

        rgb.r = rnd(rgb.r * 255);
        rgb.g = rnd(rgb.g * 255);
        rgb.b = rnd(rgb.b * 255);
    }

    return rgb;
}

colorLib.HUEShift = HUEShift;
/**
 * @param {number} h
 * @param {number} s
 * @return {number}
 */
function HUEShift(h, s) {
    h += s;
    while (h >= 360.0) {
        h -= 360.0;
    }
    while (h < 0.0) {
        h += 360.0;
    }
    return h;
}

function min3(a, b, c) {
    return (a < b)
        ? (
            (a < c)
                ? a
                : c
        )
        : (
            (b < c)
                ? b
                : c
        );
}
function max3(a, b, c) {
    return (a > b)
        ? (
            (a > c)
                ? a
                : c
        )
        : (
            (b > c)
                ? b
                : c
        );
}

/**
 * Convert RGB to hex representation
 * @param {string|Number} rgb
 * @param {Number} g
 * @param {Number} b
 * @return {string}
 */
colorLib.RGB2HEX = function RGB2HEX(rgb, g, b) {
    var r;
    var re = /rgb\((\d+),\s?(\d+),\s?(\d+)\)/i;

    if (arguments.length == 1) {
        switch (true) {
            case re.test(rgb):
                var r = re.exec(rgb);
                b = r[3];
                g = r[2];
                r = r[1];
                break;
            case /^#[A-F0-9]{6}/i.test():
                return rgb;
        }
    } else {
        r = rbg;
    }

    return "#" + ((1 << 24) + (r * 1 << 16) + (g * 1 << 8) + b * 1).toString(16).slice(1);
}

/**
 * Convert HEX color to RGB
 * @param {string} hex
 * @return {Object.<{{
 *      r: number,
 *      g: number,
 *      b: number,
 * }}>}
 */
 colorLib.HEX2RGB = function HEX2RGB(hex) {
     var h = hex.replace(/^#/, '');
     var re;
     var r = {};

     if (h.length == 6) {
         re = h.match(/(\w{2})(\w{2})(\w{2})/);
     } else {
         re = h.match(/(\w)(\w)(\w)/);
         for (var i = 1; i < 4; i++) {
             re[i] += re[i];
         }
     }

     r.r = ('0x' + re[1]) * 1;
     r.g = ('0x' + re[2]) * 1;
     r.b = ('0x' + re[3]) * 1;

     return r;
 }
