(function(core) {
    'use strict;';
    /**
     *
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtManageProjectUsers,
        _clAbstractWidget,
        // @lends wtManageProjectUsers
        {
            render: function() {
                this.$rendered = this.pageController.$rendered.find('.' + _clsManageUsers);
                this.$rendered.on(
                    'click',
                    '.' + _clsAddUser,
                    function(ev) {
                        ev.preventDefault();
                        this.pageController.widget(_wiAddUserToProjectMenu).popupAt($(ev.target), undefined, true);
                    }.bind(this)
                );
                return this._super();
            }
        }
    );
})(core);
