/**
 * @extends AbstractPageController
 */
// @ts-ignore
new $classes.Class(
    _pcManageBillingWorkspaces,
    _clAbstractPageController,
    // @lend pcManageBillingWorkspaces
    {
        isDefault: false,
        id: _pidManageBillingWorkspaces,
        um: _umManageBillingWorkspaces,
        pageTitle: ['My Workspaces', _gcProjectTitle].join(_gcTitleSeparator),
        cws: null,
        subscriptions: null,
        workspaces: null,
        _create() {
            this._binds = new $classes.Binds();
            core_DO.onWorkspaceUpdate.subscribe(this.update.bind(this));
            core_DO.onWorkspaceStatusChange.subscribe(id => {
                if (this._active) {
                    this._requestData();
                }
            });
            core_DO.onOwnershipChanged.subscribe(() => {
                if (this._active) {
                    this._requestData();
                }
            });
            this.subscriptions = Armap();
            this.$workspaces = Armap('id', {
                subscriptionId: null,
                writable: false,
                isMine(arg, d) {
                    return d.workspaceOwner && d.workspaceUser && d.workspaceOwner.id == d.workspaceUser.id;
                }
            });
        },
        generateListen(r) {
            r[_evCreateSubscription] = function(wsid, shouldRedirect) {
                let sid,
                    manage = true;
                if (arguments.length == 1) {
                    if (typeof wsid == 'boolean') {
                        shouldRedirect = wsid;
                        wsid = undefined;
                    } else if (typeof wsid == 'object') {
                        wsid.sid && (sid = wsid.sid);
                        wsid.shouldRedirect && (shouldRedirect = wsid.shouldRedirect);
                        wsid.manage != undefined && (manage = wsid.manage);
                        (wsid.wsid && (wsid = wsid.wsid)) || (wsid = undefined);
                    }
                } else {
                    sid = core_DO.workspace(wsid || this._sid).subscriptionId;
                }

                this.widget(_wiManageSubscription).show(
                    tpls.manageBillingWorkspaces.manageSubscription({
                        url: `${window['appConfig']['config']['billing'] +
                            ((!manage && 'revive') || 'subscription')}?key=${core.sessionId()}&wsid=${wsid ||
                            this._sid}${(sid && `&sid=${sid}`) || ''}`,
                        width: 1000,
                        height: window.innerHeight / 1.2
                    }),
                    {
                        header: core.getMsg(_msgManageSubscription),
                        onClose: () => {
                            core_stat.track(_mpcSubscriptionMgmt, { action: 'close' });
                            if (shouldRedirect) {
                                core.uriHandler.setUri(
                                    {
                                        id: _umManageBillingWorkspaces,
                                        wsId: this._sid
                                    },
                                    true,
                                    true
                                );
                            }
                        }
                    }
                );
            }.bind(this);
            r[_evAddNewWorkspace] = function() {
                let nwsid = null;
                const handler = (id, flag) => {
                    if (flag == _dupCreated) {
                        nwsid = id;
                        core_DO.onWorkspaceUpdate.unsubscribe(handler);
                    }
                };
                core_DO.onWorkspaceUpdate.subscribe(handler);
                this.widget(_wiManageSubscription).show(
                    tpls.manageBillingWorkspaces.manageSubscription({
                        url: `${window['appConfig']['config']['billing']}createworkspace?key=${core.sessionId()}`,
                        width: 1000,
                        height: window.innerHeight / 1.2
                    }),
                    {
                        header: core.getMsg(_msgCreateNewWorkspace),
                        onClose() {
                            nwsid && core.uriHandler.setUri({ id: _umWorkspace, wsId: nwsid });
                        }
                    }
                );
            };
            r[_evSubscribeNowMenuItem] = function() {
                this._requestData().then(data => {
                    core.moses.announce(_evCreateSubscription, $scope[_bndCurrentWorkspace].id);
                });
            };
            r[_evEditWorkspaceLogo] = el => {
                const d = $(el)
                    .parents(`div.${_clsLogoImage}`)
                    .data();
                core.avatarUploader(_avSNWSPics, d['uid']);
            };
            r[_evWorkspaceSettingsView] = function() {
                this._binds.set(_bndManageWorkspaceSettingsBlock, true);
            };
            r[_evWorkspaceSettingsView_Save] = function(el) {
                const $form = $(el).parents('form');
                const data = core_utils.serializeForm($form);

                $(`.${_clsManageWorkspaceSettingsBlock_EditView} button.white`).addClass('process');

                core.moses
                    .announce(_rUpdateWorkspaceSettings, {
                        workspaceId: this._sid,
                        settings: data
                    })
                    .then(() => {
                        this._binds.set(_bndManageWorkspaceSettingsBlock, false);
                        $(`.${_clsManageWorkspaceSettingsBlock_EditView} button.white`).removeClass('process');
                    })
                    .catch(e => {
                        $(`.${_clsManageWorkspaceSettingsBlock_EditView} button.white`).removeClass('process');
                    });
            };
            r[_evWorkspaceSettingsView_Cancel] = function(el) {
                this._binds.set(_bndManageWorkspaceSettingsBlock, false);
            };
            r[_evWorkspaceSettingsTimeslotAdd] = function(el) {
                const d = [];
                [_bndWorkspaceSettings_days, _bndWorkspaceSettings_timeStart, _bndWorkspaceSettings_timeEnd].forEach(
                    k => {
                        d.push(this._binds.$elem(k).val());
                    }
                );
                let data = $(el)
                    .parents('form')
                    .find('input[name="schedule"]')
                    .val();
                data = data ? data.split(';').filter(f => !!f) : [];
                data.push(d.join('-'));
                data = data.unique();
                const s = {};
                s[_bndWorkspaceSettings] = {};
                s[_bndWorkspaceSettings].schedule = data.join(';');
                this._binds.set(s);
            };
            r[_evWorkspaceSettingsTimeslotRemove] = function(el) {
                let data = $(el)
                    .parents('form')
                    .find('input[name="schedule"]')
                    .val();
                const id = $(el).data('id');
                data = data ? data.split(';').filter(f => !!f) : [];
                data.remove(id);
                const s = {};
                s[_bndWorkspaceSettings] = {};
                s[_bndWorkspaceSettings].schedule = data.join(';');
                this._binds.set(s);
            };
        },
        init() {
            this.registerWidget($classes.$factory(_wtModalDialog, _wiCreateWorkspace));
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiSubscriptionPlan, {
                    modal: true,
                    buttons: null,
                    header: core.getMsg(_msgSelectSubscriptionPlan),
                    valign: _BOX_VALIGN_TOP34
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiCreateNewWorkspace, {
                    modal: true,
                    buttons: [_drbOK, _drbCancel],
                    header: core.getMsg(_msgCreateNewWorkspace),
                    attachTo: '#page-manage-billing',
                    valign: _BOX_VALIGN_TOP34
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiTransferOwnership, {
                    modal: true,
                    buttons: [_drbOK, _drbCancel],
                    header: core.getMsg(_msgTransferOwnershipHeader),
                    attachTo: '#page-manage-billing',
                    valign: _BOX_VALIGN_TOP34
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiManageSubscription, {
                    modal: true,
                    buttons: null,
                    valign: _BOX_VALIGN_TOP34,
                    noBodyPadding: true
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiAskPassword, {
                    modal: true,
                    buttons: [_drbOK, _drbCancel],
                    valign: _BOX_VALIGN_TOP34
                })
            );
            this._super();
        },
        storeWorkspace($form) {
            if ($form.get(0)?.checkValidity?.() === false) {
                return false;
            }

            const data = core_utils.serializeForm($form);

            core.moses.announce(_rUpdateWorkspace, data).catch(o => {
                __USE_ROLLBAR &&
                    Rollbar.error('manageBillingWorkspaces::storeWorkspace::rUpdateWorkspace fail response', {
                        req: data,
                        resp: o
                    });
            });
            return true;
        },
        update(data, flags) {
            if (!this._active) {
                return;
            }
            const self = this,
                subs = this.subscriptions,
                wss = this.$workspaces;

            if (data && data instanceof Array) {
                subs.$empty();
                wss.$empty();

                data.forEach(s => {
                    subs.$push(s);

                    const c = s.contents
                        .filter(t => t.objectType == 'Workspace')
                        .forEach(t => {
                            const ws = core_DO.workspace(t.objectId);
                            if (ws) {
                                ws.subscriptionId = s.id;
                                wss.$push(ws);
                            } else {
                                DEBUG && console.warn('workspace was not found', t.objectId);
                                __USE_ROLLBAR &&
                                    Rollbar.warning(
                                        'manageBillingWorkspaces::update no declared workspace in subscription was found',
                                        { subid: s.id, wsid: t.objectId }
                                    );
                            }
                        });
                });
            } else if (typeof data == 'string' && data == this._sid) {
                if ((flags & _dupRemoved) == _dupRemoved) {
                    if (subs.length == 0) {
                        $('#ws-details > .body').hide();
                    } else {
                        const $item = self.$rendered.find(`.${_clsWorkspaceItem}`).first();
                        $item.trigger('click');
                    }
                } else {
                    this.showDetails(data);
                }
            }

            let c = '',
                cnt = 1;

            subs.forEach(s => {
                const l = wss.$valuesByAggregateKeys({ subscriptionId: s.id, writable: true, isMine: true }, []);
                if (l.length) {
                    c += tpls.manageBillingWorkspaces.subscriptionItem({
                        num: cnt++,
                        list: l
                    });
                } else {
                    DEBUG && console.warn(s.id);
                    __USE_ROLLBAR &&
                        Rollbar.warning(
                            `manageBillingWorkspaces::update(subscriptionId: ${s.id}, writable: true, isMine: true) is empty`,
                            { subscriptions: subs, workspaces: core_DO.$workspaces() }
                        );
                }
            });

            this._binds.set(_bndShowCreateWorkspaceButton, true);
            this._binds.set(_bndSubscriptions, c ? c : false);

            const l = wss.map(d => d.id);

            const inactive = [],
                participate = [];

            core_DO.$workspaces().forEach(d => {
                if (l.indexOf(d.id) == -1) {
                    switch (true) {
                        case d.workspaceOwner && d.workspaceOwner.id == d.workspaceUser.id:
                            inactive.push(d);
                            break;
                        default:
                            participate.push(d);
                            break;
                    }
                }
            });

            this._binds.set(_bndInactiveWorkspaces, inactive.length ? inactive : false);
            this._binds.get(_bndInactiveWorkspaces).classed(_clsGrayed, true);

            this._binds.set(_bndOtherWorkspaces, participate.length ? participate : false);
            this._binds.get(_bndOtherWorkspaces).classed(_clsGrayed, d => d.writable == false);

            const $el = this._binds.$elem(_bndSubscriptions);

            if ($el.find(`.${_clsWide}`).length) {
                $el.addClass(_clsShowSubscriptionHeaders);
            } else {
                $el.removeClass(_clsShowSubscriptionHeaders);
            }

            this.msnry && this.msnry.destroy();
            this.msnry = new Masonry(this._binds.elem(_bndSubscriptions), {
                itemSelector: `.${_clsSubscriptionFrame}, .${_clsNewWorkspace}`,
                isFitWidth: true
            });

            if (this._sid) {
                $(`.${_clsWorkspaceItem}[data-id="${this._sid}"]`).addClass(_clsActive);
            }
        },
        showDetails(id) {
            if (!this._active) {
                return;
            }
            $(`#ws-details .${_clsEditMode}`).removeClass(_clsEditMode);
            core.resetMutex('#ws-details div.body');
            this._sid = id;

            const w = core_DO.workspace(id),
                sub = (w && w.subscriptionId && this.subscriptions.$item(w.subscriptionId)) || false,
                obj = {},
                wsl = w && w.workspaceLimits;

            DEBUG && console.assert(w, `manageBillingWorkspaces::showDetails(${id})`);
            __USE_ROLLBAR && !w && Rollbar.warning(`manageBillingWorkspaces::showDetails(${id}) is empty`);

            obj[_bndWorkspaceUsage] = [];
            obj[_bndWorkspaceLimits] = wsl;
            obj[_bndWorkspaceInfo] = w;
            obj[_bndWorkspaceInfo].imageId = obj[_bndWorkspaceInfo].imageId || null;
            obj[_bndIsOnwer] = w && w.workspaceUser.role == enmWorkspaceUserRole.Owner;
            obj[_bndIsSubscriptionActive] = sub && sub.status == enmSubscriptionStatus.Active;
            obj[_bndIsCardPresent] = (wsl && wsl.cardPresent == true) || false;
            obj[_bndSubscriptionCanceled] = sub === false;
            obj[_bndProductCode] =
                sub.productTitle ||
                (wsl && wsl.productCode) ||
                (sub && sub.productCode) ||
                core.getMsg(_msgProductCodeAsNone);
            obj[_bndTrialInfo] = (wsl && !wsl.cardPresent && wsl.inTrial && !!wsl.trialEnds) || false;
            obj[_bndReactivateSubscription] =
                (wsl && wsl.subscriptionId && w.writable == false && !this.subscriptions.$item(wsl.subscriptionId)) ||
                false;
            obj[_bndSubscriptionButton] =
                !obj[_bndReactivateSubscription] && !obj[_bndIsSubscriptionActive] && !obj[_bndTrialInfo];
            obj[_bndWorkspaceSettings] = w.settings || {};
            obj[_bndManageWorkspaceSettingsBlock] = false;

            if (w && w.resourceUsage) {
                for (const k in w.resourceUsage.count) {
                    obj[_bndWorkspaceUsage].push({
                        id: k,
                        limit: w.workspaceLimits.limits[k] || 0,
                        use: w.resourceUsage.count[k] || 0
                    });
                }
            }
            this._binds.set(obj);
        },
        _requestData() {
            const self = this;
            $(`#${_clsWsListBody}`)
                .find(`.${_clsScroller}`)
                .hide()
                .end()
                .append(tpls.components.spinner({ id: _clsSpinner }));

            return core.moses
                .announce(_rListActiveSubscriptions)
                .then(
                    /* cListOfActiveSubscriptionsResponse */ data => {
                        $(`#${_clsSpinner}`).remove();
                        $(`#${_clsWsListBody} .${_clsScroller}`).show();
                        self.update(data.subscriptions);
                        setTimeout(() => {
                            if (data.subscriptions.length == 0) {
                                $('#ws-details > .body').hide();
                            } else {
                                const $item = self.$rendered
                                    .find(`.${_clsWorkspaceItem}${self._sid ? `[data-id="${self._sid}"]` : ''}`)
                                    .first();
                                $item.trigger('click');
                                $('#ws-details > .body').show();
                            }
                        }, 100);
                        return data;
                    }
                )
                .catch(err => {
                    $(`#${_clsSpinner}`).remove();
                    $(`#${_clsWsListBody} .${_clsScroller}`).show();
                    DEBUG && console.error(err);
                    __USE_ROLLBAR &&
                        Rollbar.error('manageBillingWorkspaces::_requestData::rListActiveSubscriptions fail response', {
                            resp: err
                        });
                });
        },
        activate(uri) {
            !window['closeSubscriptionPopup'] &&
                (window['closeSubscriptionPopup'] = () => {
                    this.widget(_wiManageSubscription).close();
                    func = null;
                });

            this._super();
            core_DO.setCWS(null);

            core_stat.track(_mpcMyWorkspacesVisited);
            switch (uri[1]) {
                case 'manage':
                case 'reactivate':
                    this._requestData().then(() => {
                        const s = this.subscriptions.$item(uri[2]);
                        let wsid;
                        if (s) {
                            wsid = s.contents.find(t => t.objectType == 'Workspace');
                            wsid && (wsid = wsid.objectId);
                        }
                        core.moses.announce(_evCreateSubscription, {
                            sid: uri[2],
                            wsid,
                            shouldRedirect: true,
                            manage: uri[1] == 'manage'
                        });
                    });
                    break;
                default:
                    this._sid = uri[2];
                    this._requestData();
                    break;
            }
            this._binds.$elem(`${_bndWorkspaceSettings}.timeZone`).timezones();
            this._binds.$elem(`${_bndWorkspaceSettings}.digestHour`).html(
                Array(14)
                    .fill(true)
                    .map((v, i) => {
                        const h = i + 7;
                        return `<option value="${h}">${moment()
                            .hour(h)
                            .minutes(0)
                            .format('hh:mm a')}</option>`;
                    })
                    .join('')
            );
            this._binds.$elem(_bndWorkspaceSettings_days).html(
                Array(7)
                    .fill(true)
                    .map(
                        (_, v) =>
                            `<option value="${v + 1}">${moment()
                                .day(v + 1)
                                .format('dddd')}</option>`
                    )
                    .join('')
            );
            const times = Array(24)
                .fill(true)
                .map((_, d) => {
                    const v = moment()
                        .hour(d)
                        .minutes(0);
                    const vr = v.format('hh:mm a');
                    const vv = v.format('HH:mm');
                    return `<option value="${vv}">${vr}</option>`;
                });
            [_bndWorkspaceSettings_timeStart, _bndWorkspaceSettings_timeEnd].forEach(k => {
                this._binds.$elem(k).html(times.join(''));
                times.shift(1);
                const v = moment()
                    .hour(23)
                    .minutes(59);
                const vr = v.format('hh:mm a');
                const vv = v.format('HH:mm');
                times.push(`<option value="${vv}">${vr}</option>`);
            });
        },
        deactivate() {
            this._super();
            this._sid = undefined;
        },
        render() {
            this.$rendered = core_dom.renderAsElement(tpls.iface.page, { id: this.id });
            core_dom
                .renderAsElement(tpls.manageBillingWorkspaces.decoration, null, this._binds)
                .appendTo(this.$rendered);
            this.$rendered
                .on('click', `div.${_clsWorkspaceItem}`, ev => {
                    const $el = $(ev.currentTarget),
                        id = $el.data('id') || d3.select(ev.currentTarget).datum()['id'];

                    this.$rendered.find(`div.${_clsWorkspaceItem}.${_clsActive}`).removeClass(_clsActive);
                    $el.addClass(_clsActive);
                    this.showDetails(id);
                    $('#ws-details > .body').show();

                    core.uriHandler.setUri(
                        {
                            id: _umManageBillingWorkspaces,
                            wsId: id
                        },
                        true,
                        true
                    );
                })
                .on('click', `.${_clsNewWorkspace}`, () => {
                    this.widget(_wiManageSubscription).show(
                        tpls.manageBillingWorkspaces.manageSubscription({
                            url: `${window['appConfig']['config']['billing']}createworkspace?key=${core.sessionId()}`,
                            width: 1000,
                            height: window.innerHeight / 1.2
                        }),
                        {
                            header: core.getMsg(_msgCreateNewWorkspace)
                        }
                    );
                })
                .on('click', `div.${_clsEditButton}`, function() {
                    $(this)
                        .parent()
                        .toggleClass(_clsEditMode);
                })
                .on('click', `.${_clsManageButton}`, () => {
                    const sid = core_DO.workspace(this._sid).subscriptionId;
                    core.uriHandler.setUri(
                        {
                            id: _umManageBillingWorkspaces,
                            mode: 'manage',
                            wsId: sid
                        },
                        true,
                        true
                    );
                    core.moses.announce(_evCreateSubscription, { sid, wsid: this._sid, shouldRedirect: true });
                })
                .on('click', `.${_clsReactivateButton}`, () => {
                    const ws = core_DO.workspace(this._sid),
                        w = ws && ws.workspaceLimits;
                    if (!w) {
                        return;
                    }
                    core.uriHandler.setUri(
                        {
                            id: _umManageBillingWorkspaces,
                            mode: 'reactivate',
                            wsId: w.subscriptionId
                        },
                        true,
                        true
                    );
                    core.moses.announce(_evCreateSubscription, {
                        sid: w.subscriptionId,
                        wsid: this._sid,
                        shouldRedirect: true,
                        manage: false
                    });
                })
                .on('click', `.${_clsTransferOnwership}`, () => {
                    const ws = core_DO.$workspaces(this._sid),
                        self = this;

                    core_utils.banner(core.getMsg(_msgLoadUsers));
                    let r;
                    core.moses
                        .announce(_rListWorkspaceUsers, (r = { workspaceId: this._sid }))
                        .then(obj => {
                            core_utils.banner();
                            if (
                                obj['workspaceUsers'].length == 1 ||
                                obj['workspaceUsers'].filter(
                                    d =>
                                        d.status == enmWorkspaceUserStatus.Confirmed &&
                                        d.id != ws.workspaceOwner.id &&
                                        enmActiveWorkspaceUserRole.has(d.role)
                                ).length == 0
                            ) {
                                alert(core.getMsg(_msgNoUsersToTransferOwnership));
                            } else {
                                const users = obj['workspaceUsers'].filter(
                                    u =>
                                        u.id != ws.workspaceOwner.id &&
                                        enmActiveWorkspaceUserRole.has(u.role) &&
                                        u.status == enmWorkspaceUserStatus.Confirmed
                                );

                                let curPane = 2;

                                self.widget(_wiTransferOwnership).show(
                                    tpls.manageBillingWorkspaces.transferOwnershipForm({ users }),
                                    {
                                        beforeShow($el) {
                                            $el.find('li[data-pane="2"]').show();
                                        },
                                        buttonsLabel: [{ id: _drbOK, label: core.getMsg(_msgNextPane) }],
                                        onClose(role, $body) {
                                            if (role == _drbOK) {
                                                let label = core.getMsg(_msgNextPane);
                                                const ret = false;
                                                const self = this;

                                                switch (curPane) {
                                                    case 2:
                                                        label = core.getMsg(_msgWizardDoneCaption);
                                                        break;
                                                    case 3:
                                                        const $form = $body.find('form');

                                                        if ($form[0].checkValidity?.() === false) {
                                                            return false;
                                                        }

                                                        const data = core_utils.serializeForm($form);

                                                        $body
                                                            .parent()
                                                            .find(`.${_clsDialogButtons}`)
                                                            .hide();
                                                        transfwerOwnership.call(
                                                            self,
                                                            ws.id,
                                                            data['newOwnerId'],
                                                            data['password'],
                                                            data['removeCard'],
                                                            $body
                                                        );
                                                        break;
                                                }

                                                curPane++;

                                                $body
                                                    .parent()
                                                    .find(`button[data-role="${_drbOK}"]`)
                                                    .html(label)
                                                    .end()
                                                    .find('li')
                                                    .css('display', 'none')
                                                    .end()
                                                    .find(`li[data-pane="${curPane}"]`)
                                                    .css('display', 'block');

                                                return ret;
                                            }
                                        }
                                    }
                                );
                            }
                        })
                        .catch(o => {
                            __USE_ROLLBAR &&
                                Rollbar.error(
                                    'manageBillingWorkspaces::clsTransferOnwership::rListWorkspaceUsers fail response',
                                    { req: r, resp: o }
                                );
                        });
                })
                .on('click', `button.${_clsRemoveWorkspace}`, () => {
                    const self = this;
                    const ws = core_DO.workspace(this._sid);
                    this.widget(_wiAskPassword).show(
                        tpls.manageBillingWorkspaces.askPassword({
                            text: core.getMsg(_msgConfirmWorkspaceRemove, { name: ws?.name })
                        }),
                        {
                            onClose(role, $body) {
                                if (role == _drbOK) {
                                    const pwd = $body.find('input').val(),
                                        id = self._sid,
                                        sid = ws?.subscriptionId,
                                        dlg = this;

                                    if (!ws) {
                                        __USE_ROLLBAR &&
                                            Rollbar.debug(
                                                'manageBillingWorkspaces::clsRemoveWorkspace no WS selected',
                                                {
                                                    sid: self._sid
                                                }
                                            );
                                    }

                                    if (window.confirm(core.getMsg(_msgConfirmWorkspaceRemoval, { name: ws.name }))) {
                                        let r;
                                        core.moses
                                            .announce(_rRemoveWorkspace, (r = { workspaceId: ws.id, password: pwd }))
                                            .then(() => {
                                                dlg.close(_drbCancel);
                                                // cleanup removed workspace info
                                                self.$workspaces.$remove(id);
                                                if (sid) {
                                                    /** @type {cSubscriptionInfo} */
                                                    const s = self.subscriptions.$item(sid);
                                                    if (s) {
                                                        s.contents.remove(d => d.objectId == id);
                                                    }
                                                }
                                            })
                                            .catch(obj => {
                                                alert(core.getMsg(_msgWorkspaceRemoveError, obj));
                                                if (__USE_ROLLBAR && obj.error != enmErrorMessage.AccessDenied) {
                                                    r['password'] = r['password'].replace(/./g, '*');
                                                    __USE_ROLLBAR &&
                                                        Rollbar.error(
                                                            'manageBillingWorkspaces::clsRemoveWorkspace::rRemoveWorkspace fail response',
                                                            { req: r, resp: obj }
                                                        );
                                                }
                                            });
                                    } else {
                                        dlg.close(_drbCancel);
                                    }

                                    return false;
                                }
                            }
                        }
                    );
                })
                .on('click', `button.${_clsLeaveWorkspace}`, () => {
                    const p = window.confirm(core.getMsg(_msgLeaveWorkspaceConfirmation)),
                        ws = core_DO.workspace(this._sid);

                    if (p && ws) {
                        this._sid = null;
                        let r;
                        core.moses
                            .announce(
                                _rRemoveUserFromWorkspace,
                                (r = {
                                    workspaceId: ws.id,
                                    workspaceUserId: ws.workspaceUser.id
                                })
                            )
                            .then(r => {
                                switch (r.result) {
                                    case enmRemoveUserFromWorkspaceResult.Removed:
                                        this.update();
                                        break;
                                    case enmRemoveUserFromWorkspaceResult.NotAllowed:
                                        alert(core.getMsg(_msgWorkspaceUserRemovalNotAllowed));
                                        break;
                                }
                            })
                            .catch(o => {
                                __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'manageBillingWorkspaces::clsLeaveWorkspace::rRemoveUserFromWorkspace fail reponse',
                                        { req: r, resp: o }
                                    );
                                alert(core.getMsg(_msgLeaveWorkspaceFail));
                            });
                    }
                })
                .on('click', `button.${_bndUpgradeLink}, .${_clsSubscribeButton}`, ev => {
                    ev.preventDefault();
                    const ws = core_DO.workspace(this._sid),
                        sid = ws && ws.subscriptionId;
                    core.moses.announce(_evCreateSubscription, { sid, wsid: this._sid, shouldRedirect: true });
                })
                .find('form')
                .on('submit', ev => {
                    if (this.storeWorkspace($(ev.target))) {
                        $(ev.target)
                            .parents(`div.${_clsEditMode}`)
                            .removeClass(_clsEditMode);
                    }
                    return false;
                })
                .on('click', 'button.cancel', function() {
                    $(this)
                        .parents(`div.${_clsEditMode}`)
                        .removeClass(_clsEditMode);
                });

            return this;
        }
    }
);

function transfwerOwnership(wsid, newOwnerId, pwd, removeCard, $body) {
    const self = this;
    $body.find(`.${_clsTransferOwnershipThrobber}`).html(`<div class="${_clsThrobber}" />`);
    let r;
    core.moses
        .announce(
            _rTransferOwnership,
            (r = {
                workspaceId: wsid,
                newOwnerId,
                password: pwd,
                removeCard
            })
        )
        .then(obj => {
            let r;
            core.moses
                .announce(_rWorkspaceInfo, (r = { workspaceId: wsid, details: enmWorkspaceInfoRequestDetails.values() }))
                .then(obj => {
                    core_DO.updateWorkspace(wsid, obj.workspace);
                })
                .catch(o => {
                    __USE_ROLLBAR &&
                        Rollbar.error('manageBillingWorkspaces::transfwerOwnership::rWorkspaceInfo fail response', {
                            req: r,
                            resp: o
                        });
                });
            setTimeout(() => {
                self.close(_drbCancel);
            }, 250);
        })
        .catch(o => {
            switch (o.error) {
                case enmErrorMessage.Error:
                    Alertify.log.error(core.getMsg(_msgSomeErrorOnServerWhileSavingChanges, o));
                    break;
                default:
                    alert('Failed to transfer ownership');
                    r.password = r.password.replace(/./g, '*');
                    __USE_ROLLBAR &&
                        Rollbar.error('manageBillingWorkspaces//transfwerOwnership::rTransferOwnership fail response', {
                            req: r,
                            resp: o
                        });
                    break;
            }
        });
}

function removeWSFromSubscription(sbs, wsid) {
    sbs.contents.remove(obj => obj == wsid);
}

core.pages.registerController(_pcManageBillingWorkspaces);

core_utils.intExport(_ienPostProcessWorkspaceItems, function(d) {
    d3.select(this).attr('data-id', d['id']);
});

core_utils.intExport(_ienSubstUsageItem, id => tpls.substWSUsageItem({ id }));
