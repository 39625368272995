(function(core) {
    // 'use strict;';

    var colors = [
        '#ed1c24',
        '#f26522',
        '#fbca04',
        '#39b54a',

        '#790000',
        '#7d4900',
        '#827b00',
        '#005e20',

        '#00a99d',
        '#00aeef',
        '#0054a6',
        '#662d91',

        '#005952',
        '#005b7f',
        '#002157',
        '#32004b'
    ];

    $classes.Class(_wtManageTeams, _clAbstractWidget, {
        // vue: null,
        // activate: function () {
        //     this._super();
        //     /*this.vue*/core.pages.ctrl[_bndProjectTeams] = core_DO.getTeams();
        //     /*this.vue*/core.pages.ctrl[_bndMayIAddTeam] = core.mayI.createProjectTeam();
        //     /*this.vue*/core.pages.ctrl[_bndHasTeamsFeature] = core_DO.hasFeature(_fndProjectTeams);
        // },
        // deactivate: function () {
        //     this._super();
        //     /*this.vue*/core.pages.ctrl[_bndProjectTeams].$release && /*this.vue*/core.pages.ctrl[_bndProjectTeams].$release();
        // },
        /**
         * @param {{ [x: string]: (item: any) => void; }} g
         */
        generateListen: function(g) {
            g[_evOpenAddMemberPopup]
            /**
             * @param {any[]} item
             */ = function(item) {
                var p, ev;
                if (item instanceof Array) {
                    p = item[0];
                    ev = item[1];
                } else {
                    p = item;
                    ev = item.$event;
                }
                this.pageController.widget(_wiAddMemberToTeam).popupAt($(ev.currentTarget), { id: p.id }, true);
            };
            g[_evAddMemberToTeamEvent]
            /**
             * @param {{ id: any; uid: any; }} data
             */ = function(data) {
                core.moses
                    .announce(_rTeamMembership, {
                        teamId: data.id,
                        workspaceUserId: data.uid,
                        become: enmWorkspaceUserRole.Leader
                    })
                    .catch(
                        /**
                         * @param {any} err
                         */
                        function(err) {
                            console.error(err);
                        }
                    );
            };
            // g[_evEscPressed] = function () {
            //
            // }
            g[_evOpenColorSelect]
            /**
             * @param {{ $parent: any; color: any; symbol: any; $event: { target: any; }; }} item
             */ = function(item) {
                var wi = this.pageController.widget(_wiTeamColorSelector),
                    v = item.$parent;

                wi.setItems(
                    tpls.manageTeams_colorSelector({
                        selected: item.color,
                        colors: colors,
                        symbol: item.symbol
                    })
                ).popupAt($(item.$event.target), item, true);

                wi.options.onItemSelectCallback
                /**
                 * @param {{ target: any; }} ev
                 * @param {any} ctx
                 * @param {{ color: any; }} data
                 */ = function(ev, ctx, data) {
                    var tc = $(ev.target)
                        .parent()
                        .data('color');
                    data.color = tc;
                };
            };
        },
        addNewTeam: function() {
            var id = core_utils.uuid();
            var teams = $scope[_bndProjectTeams];
            var rname = new RegExp('^' + core.getMsg(_msgDefaultTeamName));
            var ucolor = teams.map(
                /**
                 * @param {{ color: any; }} d
                 */
                function(d) {
                    return d.color;
                }
            );
            var fcolors = colors.filter(function(d) {
                return ucolor.indexOf(d) == -1;
            });

            var c = teams.filter(
                    /**
                     * @param {{ name: string; }} d
                     */
                    function(d) {
                        return rname.test(d.name);
                    }
                ),
                n = core.getMsg(_msgDefaultTeamName) + ((c.length && ' ' + (c.length + 1)) || ''),
                s = core_utils.string.getAbbr(n, _gcSymbolLength);

            var obj = core_DO.addTeam(id, n, s, undefined, (fcolors.length && fcolors[0]) || color[0], undefined, true);
            // obj.oldValue_ = new $classes[_cTeamInfo](obj);
            obj.isEdit_ = true;

            // setTimeout(function () { this.editTeam(id); }.bind(this), 50);
        },
        // editTeam: function (id) {
        //     var item = /*this.vue*/core.pages.ctrl.$[id];
        //     item.edit.call(item, item);
        // },
        render: function() {
            this.$rendered = this.pageController.$rendered.find('.' + _clsManageTeams);
            this.pageController.$rendered.find('.' + _clsAddNewTeam).on('click', this.addNewTeam.bind(this));

            // var data = Object.create(null);
            // data[_bndProjectTeams] = $scope.$teams;
            // data[_bndProjectTeamMembers] = $scope.$teamMembers;
            // data[_bndMayIAddTeam] = false;
            // data[_bndHasTeamsFeature] = false;
            // this.vue = new Vue({
            //     el: this.$rendered.get(0),
            //     data: data,
            //     filters: {
            //         teams: function (teams) {
            //             return (teams || this[_bndProjectTeams]).sort(teamSort);
            //         },
            //         members: function (members) {
            //             return members.map(function (o) {
            //                 var u = core_DO.user(o.workspaceUserId);
            //                 return u;
            //             }).sort(membersSort);
            //         }
            //     }
            // });
            return this._super();
        }
    });

    // $filters['teams'] = function (teams) {
    //     return (teams || this[_bndProjectTeams]).sort(teamSort);
    // }
    // $filters['members'] = function (members) {
    //     return members.map(function (o) {
    //         var u = core_DO.user(o.workspaceUserId);
    //         return u;
    //     }).sort(membersSort);
    // }
})(core);
