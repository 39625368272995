(function(core) {
    function filterMySelf(d) {
        return d && d['id'] != $scope[_bndCurrentWorkspaceUser].id;
    }
    /**
     *
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtProjectUsers,
        _clAbstractWidget,
        // @lends wtProjectUsers
        {
            afterRegister: function(pc) {
                core_DO.onMembersUpdate.subscribe(this.update.bind(this));
                core_DO.onTasksUpdate.subscribe(this.update.bind(this));
                core_DO.onChangeAU.subscribe(this.update.bind(this));
                core_DO.onTeamsUpdate.subscribe(this.update.bind(this));
                core_DO.onTeamMembersUpdate.subscribe(this.update.bind(this));
            },
            activate: function() {
                this._super();
                this.update();
            },
            deactivate: function() {
                this._super();
            },
            selectUser: function(id) {
                this.$rendered.find('.' + _clsActive).removeClass(_clsActive);
                if (id == null || id == $scope[_bndCurrentWorkspaceUser].id) {
                    this.$rendered.find('li.' + _clsMyTask).addClass(_clsActive);
                } else {
                    this.$rendered.find('[data-id="' + id + '"]').addClass(_clsActive);
                }
            },
            update: function() {
                if (!this.isActive()) {
                    return;
                }
                var AltTaskNumbers = core_DO.hasFeature(_fndAltTaskNumbers);
                var uid = core_DO.getAU();
                var self = this,
                    members = [].concat(core_DO.members().filter(filterMySelf), [
                        {
                            firstName: core.getMsg(_msgAllTasks),
                            id: 0,
                            skipLogo: true
                        }
                    ]),
                    ul = d3
                        .select(this._binds.elem('everyone'))
                        .selectAll('li.' + _clsMemberItem)
                        .data(members, function(d) {
                            return d['id'];
                        }),
                    tl = d3
                        .select(this._binds.elem(_bndTeams))
                        .selectAll('li.' + _clsProjectUsers_TeamItem)
                        .data($scope[_bndProjectTeams], function(d) {
                            return d['id'];
                        });

                this._binds.$elem(_bndTeams).css('display', $scope[_bndProjectTeams].length ? 'block' : 'none');

                ul.enter()
                    .append('li')
                    .classed(_clsMemberItem, true);

                ul.exit().remove();

                ul.html(function(d) {
                    var t = core_DO.tasksOfTheUser(d['id']);
                    d.tasksQty = AltTaskNumbers ? t.completed + '/' + t.$tasks.length : t.$tasks.length;
                    d.alerted = t.$alerted;
                    return tpls.widgets.projectUsers.memberItemTpl(d);
                })
                    .attr('data-id', function(d) {
                        return d['id'];
                    })
                    .order()
                    .filter(function(d) {
                        return d.id == 0;
                    })
                    .classed(_clsAllSelector, true);

                var ud = core_DO.tasksOfTheUser($scope[_bndCurrentWorkspaceUser].id, true);

                this._binds.set(
                    $.extend($scope[_bndCurrentWorkspaceUser], {
                        tasksQty: AltTaskNumbers ? ud.completed + '/' + ud.$tasks.length : ud.$tasks.length,
                        alerted: !!ud.$alerted,
                        everyoneOpen: members.length > 1
                    })
                );

                tl.enter()
                    .append('li')
                    .classed(_clsProjectUsers_TeamItem + ' ' + _clsExpandable, true);
                tl.exit().remove();
                tl.html(function(d) {
                    return tpls.widgets.projectUsers.teamsListItem({
                        id: d.id,
                        name: d.name,
                        childs: d
                            .members_map(function(d) {
                                return $scope[_bndWorkspaceUsers].$item(d.workspaceUserId);
                            })
                            .filter(filterMySelf)
                    });
                }).order();
                this.selectUser(uid);
            },
            render: function() {
                this.$rendered = core_dom.renderAsElement(tpls.widgets.projectUsers.decoration, {}, this._binds);
                var self = this;
                this.$rendered
                    .on('click', 'li.' + _clsMyTask + ',li.' + _clsMemberItem, function(ev) {
                        var d = $(this).data();
                        core_DO.setAU(
                            d['id'] != undefined ? d['id'] : $scope[_bndCurrentWorkspaceUser].id,
                            _wtProjectUsers
                        );
                        return false;
                    })
                    .on('click', '.' + _clsExpandable, function(ev) {
                        $(this).toggleClass(_clsExpanded);
                        return false;
                    });
                return this._super();
            }
        }
    );
})(core);
