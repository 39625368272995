(function(core) {
    /**
     * User toolBar
     * @constructor
     */
    $classes.Class(_wtUserToolBar, _clAbstractWidget, {
        // widgetName: _wtUserToolBar,
        applied: false,
        init: function() {
            core_DO.onNotificationsUpdate.subscribe(this.notificationsUpdate.bind(this));
            this._super();
        },
        generateListen: function(r) {
            r[[_cUserInfoResponse, _nUserInfoUpdated].join(',')] = function(obj) {
                this.update();
            };
        },
        update: function() {
            // var u = core.iam;
            if (this.$rendered && core.iam) {
                this._binds.set({ u: core.iam });
                this.notificationsUpdate();
            }
            return this;
        },
        notificationsUpdate: function() {
            var n = core_DO.notifications().filter(function(d) {
                return !d.read_;
            });
            this._binds.set(_bndNotificationsQty, n.length);
        },
        render: function() {
            this.$rendered = core_dom.renderAsElement(tpls.components.userToolBar, null, this._binds);
            return this._super();
        }
    });
})(core);
