var core_auth = {};
var __GoogleAuthLoaded = false;
var oauthToken = {};

core_auth.googleAuth = function googleAuth(clientId, scope) {
    return core.q(function(rslv, rjct) {
        //Call for authorization.
        //Immediate=true will try to verify access without opening pop-up.
        //But it may fail and then there is necessary to call with immediate=false
        var auth = function auth(immediate) {
            DEBUG && console.log('Google::Auth begin');
            window.gapi.auth.authorize(
                {
                    client_id: clientId,
                    scope: scope,
                    immediate: immediate
                },
                handleAuthResult
            );
        };

        var handleAuthResult = function handleAuthResult(authResult) {
            if (authResult && !authResult.error) {
                oauthToken[clientId + scope.toString()] = authResult;
                rslv(authResult);
            } else {
                //If immediate failed, then try open pop-up.
                if (authResult.error == 'immediate_failed') {
                    auth(false);
                } else {
                    rjct(authResult);
                }
            }
        };

        if (!__GoogleAuthLoaded) {
            if (__GoogleAuthLoaded === false) {
                __GoogleAuthLoaded = 0;
                window[_gcGoogleDriveCallbackFunction] = function() {
                    gapi.load('auth', {
                        callback: function() {
                            __GoogleAuthLoaded = true;
                            auth(true);
                        }
                    });
                };
                core_utils.loadScript(_gcGoogleDriveClient);
            }
        } else if (!!oauthToken[clientId + scope.toString()]) {
            rslv(oauthToken[clientId + scope.toString()]);
        } else {
            auth(true);
        }
    });
};
