core.mayI = {};
/** @const */ const workspaceUser = 'workspaceUser';
/** @const */ const role = 'role';
/** @const */ const callerRole = 'callerRole';

const cdo = core_DO;

core_utils.userIsSame = isSame;
function isSame(user1, user2) {
    return user1['id'] == user2['id'];
}

core_utils.userHaveRole = isInRole;
function isInRole(...args) {
    const a = Array.prototype.slice.call(args),
        r = a.splice(0, 1)[0];

    return a.indexOf(r) > -1;
}

function isInProjectRole(...args) {
    const a = Array.prototype.slice.call(args),
        p = a.splice(0, 1)[0];

    return p && p[callerRole] && a.indexOf(p[callerRole]) > -1;
}

function isInWorkspaceRole(...args) {
    const a = Array.prototype.slice.call(args),
        p = a.splice(0, 1)[0];

    return a.indexOf((p[workspaceUser] && p[workspaceUser][role]) || p[role]) > -1;
}

function hasExecutor(task) {
    return (task && task['execs'] && task['execs'].indexOf($scope[_bndCurrentWorkspaceUser].id) > -1) || false;
}

((r, c) => {
    c[_urChangeUserImage] = r.changeUserImage = user =>
        isSame(core.iam, (user && user['id'] && user) || $scope[_bndCurrentWorkspaceUser].id);

    c[_urChangeUserInfo] = r.changeUserInfo = user => isSame(core.iam, user);

    c[_urChangeWorkspaceImage] = r.changeWorkspaceImage = c[_urChangeWorkspaceInfo] = r.changeWorkspaceInfo = ws =>
        ws &&
        ws.writable == true &&
        ((ws && ws[workspaceUser]) || $scope[_bndCurrentWorkspaceUser]) &&
        isInRole(
            (ws && ws[workspaceUser] && ws[workspaceUser][role]) || $scope[_bndCurrentWorkspaceUser].role,
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader
        );

    c[_urDetachWorkspaceUser] = r.detachWorkspaceUser = user =>
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) &&
        !isInRole(user[role], enmWorkspaceUserRole.Owner);

    c[_urChangeWorkspaceUserImage] = r.changeWorkspaceUserImage = user =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (isSame($scope[_bndCurrentWorkspaceUser], user) ||
            isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));

    c[_urAddWorkspaceUser] = r.addWorkspaceUser = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urListWorkspaceUsers] = r.listWorkspaceUsers = () =>
        isInRole(
            $scope[_bndCurrentWorkspaceUser].role,
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        );

    c[_urAttachWorkspaceUser] = r.attachWorkspaceUser = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urChangeWorkspaceUserInfo] = r.changeWorkspaceUserInfo = user =>
        isSame($scope[_bndCurrentWorkspaceUser], user) ||
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urChangeWorkspaceUserRole] = r.changeWorkspaceUserRole = user =>
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) &&
        !isInRole(user[role], enmWorkspaceUserRole.Owner);

    c[_urChangeWorkspaceUserRoleToOwner] = r.changeWorkspaceUserRoleToOwner = user =>
        !isSame($scope[_bndCurrentWorkspaceUser], user) &&
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner);

    c[_urChangeWorkspaceUserRoleToLeader] = r.changeWorkspaceUserRoleToLeader = () =>
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urChangeWorkspaceUserRoleToParticipant] = r.changeWorkspaceUserRoleToParticipant = () =>
        isInRole(
            $scope[_bndCurrentWorkspaceUser].role,
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        );

    c[_urChangeWorkspaceUserRoleToSpectator] = r.changeWorkspaceUserRoleToSpectator = () =>
        isInRole(
            $scope[_bndCurrentWorkspaceUser].role,
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant,
            enmWorkspaceUserRole.Spectator
        );

    c[_urLeaveWorkspace] = r.leaveWorkspace = user =>
        !isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner) &&
        isSame($scope[_bndCurrentWorkspaceUser].role, user[role]);

    c[_urRemoveWorkspaceUser] = r.removeWorkspaceUser = user =>
        (isSame($scope[_bndCurrentWorkspaceUser], user) ||
            isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader)) &&
        !isInRole(user[role], enmWorkspaceUserRole.Owner);
    c[_urViewWorkspaceOverviewForOtherUsers] = () =>
        $scope[_bndCurrentWorkspaceUser] &&
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urArchiveProject] = r.archiveProject = c[_urReactivateProject] = r.reactivateProject = pr =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
            isInProjectRole(pr || $scope[_bndCurrentProject], enmWorkspaceUserRole.Owner));
    c[_urDeleteProject] = r.deleteProject = pr =>
        isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
        isInProjectRole(pr || $scope[_bndCurrentProject], enmWorkspaceUserRole.Owner);

    c[_urCreateProject] = r.createProject = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInRole(
            $scope[_bndCurrentWorkspaceUser].role,
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        );
    c[_urChangeProjectImage] = r.changeProjectImage = c[_urChangeProjectInfo] = r.changeProjectInfo = c[
        _urChangeMemberRole
    ] = r.changeMemberRole = pr =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (isInProjectRole(pr || $scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
            isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urChangeProjectState] = r.changeProjectState = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (!isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator, enmWorkspaceUserRole.Nobody) ||
            isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urListProjectMembers] = r.listProjectMembers = () =>
        !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator) ||
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urCreateTask] = r.createTask = () =>
        // !who.isInProjectRole(Spectator, Resource, Nobody)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        $scope[_bndCurrentProject] &&
        !isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Participant,
            enmWorkspaceUserRole.Resourse,
            enmWorkspaceUserRole.Spectator,
            enmWorkspaceUserRole.Nobody
        );
    c[_urAddWorkspaceUserToProject] = r.addWorkspaceUserToProject = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (isInRole($scope[_bndCurrentWorkspaceUser].role, enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urAddExecutor] = r.addExecutor = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urBecomeExecutor] = r.becomeExecutor = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        ) &&
            task &&
            task['execs'] &&
            task['execs'].length == 0;
    c[_urChangeTaskType] = r.changeTaskType = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urCompleteTask] = r.completeTask = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urSetCompletionTime] = r.setCompletionTime = (
        task // with.hasExecutor(who) || who.isInProjectRole(Owner, Leader)
    ) =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urRemoveTask] = r.removeTask = task =>
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveDeadline] = r.removeDeadline = task =>
        hasExecutor(task) ||
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveExecutor] = r.removeExecutor = task =>
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveSelfAsExecutor] = r.removeSelfAsExecutor = task =>
        hasExecutor(task) &&
        !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator) &&
        task &&
        task['execs'] &&
        task['execs'].length > 1;
    c[_urSetDeadline] = r.setDeadline = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urSetTaskStartDate] = r.setTaskStartDate = (
        task // (with.hasExecutor(who) && !who.isInProjectRole(Spectator)) || who.isInProjectRole(Owner, Leader)
    ) =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urRemoveTaskStartDate] = r.removeTaskStartDate = task =>
        hasExecutor(task) ||
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urStartTask] = r.startTask = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        );
    c[_urUndoTask] = r.undoTask = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        ((hasExecutor(task) && !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Spectator)) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urUpdateTask] = r.updateTask = task =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urCreateTransition] = r.createTransition = c[_urRemoveTransition] = r.removeTransition = c[
        _urUpdateTransition
    ] = r.updateTransition = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urPostComment] = r.postComment = () =>
        // !who.isInProjectRole(Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (!isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Resource, enmWorkspaceUserRole.Nobody) ||
            isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urAddFile] = r.addFile = () =>
        // who.isInProjectRole(Owner, Leader, Participant)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Owner,
            enmWorkspaceUserRole.Leader,
            enmWorkspaceUserRole.Participant
        );
    c[_urChangeState] = r.changeState = () =>
        // !who.isInProjectRole(Spectator, Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (!isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Spectator,
            enmWorkspaceUserRole.Resource,
            enmWorkspaceUserRole.Nobody
        ) ||
            isInWorkspaceRole(enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urListMembers] = r.listMembers = () =>
        // !who.isInProjectRole(Spectator, Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        !isInProjectRole(
            $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Spectator,
            enmWorkspaceUserRole.Resource,
            enmWorkspaceUserRole.Nobody
        ) || isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urReadFiles] = r.readFiles = () =>
        // !who.isInProjectRole(Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Resource, enmWorkspaceUserRole.Nobody) ||
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urReadHistory] = r.readHistory = () =>
        // !who.isInProjectRole(Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Resource, enmWorkspaceUserRole.Nobody) ||
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urReadState] = r.readState = () =>
        // !who.isInProjectRole(Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
        !isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Resource, enmWorkspaceUserRole.Nobody) ||
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urPublishShare] = r.publishShare = (
        pr // who.isInProjectRole(Owner, Leader) || who.isInWorkspaceRole(Owner, Leader)
    ) =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        (isInProjectRole(pr || $scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
            isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader));
    c[_urReadShares] = r.readShares = (
        pr // !who.isInProjectRole(Resource, Nobody) || who.isInWorkspaceRole(Owner, Leader)
    ) =>
        !isInProjectRole(
            pr || $scope[_bndCurrentProject],
            enmWorkspaceUserRole.Resource,
            enmWorkspaceUserRole.Nobody
        ) || isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveShare] = r.removeShare = (
        pr // who.isInProjectRole(Owner, Leader) || who.isInWorkspaceRole(Owner, Leader)
    ) =>
        isInProjectRole(pr || $scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader) ||
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urDeleteSomeonesComment] = r.deleteSomeonesComment = uid => {
        // who.id.equals(with.workspaceUserId) || who.isInProjectRole(Owner, Leader)
        if (!$scope[_bndCurrentWorkspace]) {
            return false;
        }
        const cws = $scope[_bndCurrentWorkspace];
        return (
            (cws && cws.workspaceUserId == uid) ||
            isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader)
        );
    };

    c[_urCreateProjectTeam] = r.createProjectTeam = () =>
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urUpdateProjectTeam] = r.updateProjectTeam = () =>
        // who.isInTeamRole(Owner, Leader) || who.isInProjectRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveProjectTeam] = r.removeProjectTeam = () =>
        // who.isInTeamRole(Owner, Leader) || who.isInProjectRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urAddMemberToTeam] = r.addMemberToTeam = () =>
        // who.isInProjectRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urRemoveMemberFromTeam] = r.removeMemberFromTeam = () =>
        // who.isInProjectRole(Owner,Leader)
        $scope[_bndCurrentWorkspace] &&
        $scope[_bndCurrentWorkspace].writable == true &&
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);

    c[_urTagProject] = r.tagProject = () =>
        // who.isInRole(Owner, Leader)  || who.isInProjectRole(with.getId(), Owner)
        ($scope[_bndCurrentWorkspace] &&
            isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader)) ||
        isInProjectRole($scope[_bndCurrentProject], enmWorkspaceUserRole.Owner);
    c[_urUpdateProjectTag] = r.updateProjectTag = () =>
        // who.isInRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] && isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner);
    c[_urRemoveProjectTag] = r.removeProjectTag = () =>
        // who.isInRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urCreateProjectTag] = r.createProjectTag = () =>
        // who.isInRole(Owner, Leader)
        $scope[_bndCurrentWorkspace] &&
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urManageProjectTags] = r.manageProjectTags = () =>
        $scope[_bndCurrentWorkspace] &&
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
    c[_urProjectReporting] = r.projectReporting = () =>
        $scope[_bndCurrentWorkspace] &&
        isInWorkspaceRole($scope[_bndCurrentWorkspace], enmWorkspaceUserRole.Owner, enmWorkspaceUserRole.Leader);
})(core.mayI, $ACL);

for (const k in $ACL) {
    core_exp[k] = $ACL[k];
}
