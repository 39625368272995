Vue.component(_cmpProjectUser, {
    template: tpls.manageProjectUsers_userCard(),
    inherit: true,
    replace: true,
    methods: function () {
        var r = {}
        r[_mtdUserRole] = function (ev) {
            ev.preventDefault();
            core.pages.page(_pidProject)
                .widget(_wiUserAction)
                    .popupAt($(ev.target), {'id': this.id}, true);
        }
        // r[_evDrag] = function (ev) {
        //     console.log(this, ev);
        // }
        return r;
    }()
});

Vue.component(_cmpProjectUserNew, {
    template: tpls.manageProjectUsers_addUser(),
    replace: true
})
