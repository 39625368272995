const loadedArchives = {};

/** @this {pcWorkspace} */
function sync2Evernote(ev) {
    const wi = this.widget(_wiEvernoteNotebooks);
    core.moses
        .announce(_rFeatureQuestion, {
            question: {
                featureName: _fndEvernote,
                question: enmEvernoteClientQuestions.GetEvernoteUser
            }
        })
        .then(obj => {
            if (obj.response.error) {
                switch (obj.response.error) {
                    case enmEvernoteErrorType.Unauthorized:
                        core.featureAuth(_fndEvernote, 'evernote').then(() => {
                            core.moses.announce(ev.$event, ev);
                        });
                        break;
                    case enmEvernoteErrorType.GeneralError:
                        break;
                }
            } else {
                core_utils.banner(core.getMsg(_msgLoadEvernoteNotebooks));
                Promise.all([
                    core.moses.announce(_rFeatureQuestion, {
                        question: {
                            featureName: _fndEvernote,
                            providerId: 'evenote',
                            question: enmEvernoteClientQuestions.ListNotebooks
                        }
                    }),
                    obj.response.businessUserInfo
                        ? core.moses.announce(_rFeatureQuestion, {
                            question: {
                                featureName: _fndEvernote,
                                providerId: 'evenote',
                                question: enmEvernoteClientQuestions.ListBusinessNotebooks
                            }
                        })
                        : {}
                ])
                    .then(v => {
                        const r = v[0];
                        const rb = v[1];

                        core_utils.banner();
                        wi.show(tpls.dashboard.evernoteNotebooksList(), {
                            beforeShow($el, ctx) {
                                const data = {};
                                data[_bndEvernoteUserInfo] = obj.response.name;
                                data[_bndEvernoteNotebooks] = r.response.notebooks;
                                data[_bndEvernoteBusinessNotebooks] = rb.response && rb.response.linkedNotebooks;
                                data[_bndEvernoteBusinessUser] =
                                    (obj.response.businessUserInfo && obj.response.businessUserInfo.businessName) ||
                                    undefined;
                                data[_bndNotebookAction] = ev.$mode;

                                ctx.vue = new Vue({
                                    el: $el.get(0),
                                    data
                                });

                                $el.on('click', `div.${_clsEvernote_List_Item}` /* + '[data-action]'*/, function () {
                                    const bussiness = $(this)
                                            .parent()
                                            .hasClass(_clsEvernote_List_Bussiness),
                                        $event = $(this)
                                            .parents(`.${_clsEvernoteWrapper}`)
                                            .data(),
                                        v = {};

                                    ctx.close();

                                    v[(bussiness && 'linkedNotebookGuid') || 'notebookGuid'] = $(this).data('id');

                                    core.moses
                                        .announce(_rFeatureData, {
                                            data: {
                                                featureName: 'Evernote',
                                                providerId: 'evernote',
                                                event: $event['action'],
                                                values: v
                                            }
                                        })
                                        .then(() => {
                                            Alertify.log.success(
                                                core.getMsg(_msgEvernoteSubscribed2Sync, {mode: ''})
                                            );
                                        })
                                        .catch(() => {
                                            Alertify.log.success(core.getMsg(_msgEvernoteSubscribeFailed));
                                        });
                                });
                            },
                            afterShow() {
                                this.align();
                            },
                            onClose() {
                                this.vue.$destroy();
                            }
                        });
                        // console.info(r);
                        // var rs = [];
                        // alert('loaded list of notebooks:\n' + r.response.notebooks.map(function (l) { return l.name }).join('\n'));
                    })
                    .catch(r => {
                        __USE_ROLLBAR && Rollbar.error('dashboard::sync2Evernote load evernote notebooks', r);
                    });
            }
        })
        .catch(r => {
            __USE_ROLLBAR && Rollbar.error('dashboard::sync2Evernote rFeatureQuestion', r);
        });
}

function updateCatsPane($el) {
    const stags = getTags($el);
    const tags = $scope[_bndProjectTags];
    $el.css('display', tags.length ? 'block' : 'none');
    let label;
    switch (stags.length) {
        case 0:
            label = core.getMsg(_msgSetCategory);
            break;
        case 1:
            label = core.getMsg(_msgSetSecondCategory);
            break;
    }
    return (
        stags.map(id => tpls.dashboard.categoryLabel({tagId: id, removeMark: true})).join('') +
        (label ? `<span>${label}</span>` : '')
    );
}

function createTagsPopup(tagsPane, tags) {
    tags && setTags(tagsPane, tags);
    tagsPane
        .html(updateCatsPane(tagsPane))
        .on('click', `.${_clsCategoryLabel}`, function (ev) {
            const $tagEl = $(this);
            const id = $tagEl.data('id');
            setTags(tagsPane, getTags(tagsPane).remove(id));
            tagsPane.html(updateCatsPane(tagsPane));
            return false;
        })
        .on('click', () => {
            const stags = getTags(tagsPane);
            stags.length < 2 && tagsPane.popover('show');
            return false;
        })
        .popover({
            html: true,
            placement: 'bottom',
            className: _clsCategoriesSelector,
            trigger: '',
            selector: '',
            container: 'body',
            content() {
                const stags = getTags(tagsPane);
                return $scope[_bndProjectTags]
                    .filter(d => stags.indexOf(d.id) == -1)
                    .map(d => tpls.dashboard.categoryLabel({tagId: d.id}))
                    .join('');
            }
        });
}

/**
 * @param {jQuery|string} $el
 * @return {Array<string>}
 */
function getTags($el) {
    const v = $el instanceof jQuery ? $el.next().val() || '' : (typeof $el == 'string' && $el) || '';

    return v.split(',').filter(d => !!d && d != 'undefined');
}

/**
 * @param {jQuery} $el
 * @param {Array<string>} value
 * @return {void}
 */
function setTags($el, value) {
    $el.next().val(value.join(','));
}

/**
 * @extends AbstractPageController
 */
// @ts-ignore
new $classes.Class(_pcWorkspace, _clAbstractPageController,
    // @lend pcWorkspace
    {
        id: _pidWorkspace,
        um: _umWorkspace,
        lastView: null,
        shouldActivateWidgets_: false,
        activePane: _clsProjectsPane,
        MOD: null,
        sortable: null,
        cwsq: null,
        _create() {
            $scope[_bndProjectTags_ManageMode] = false;
            $scope[_bndProjectTags_ActiveFilter] = false;
            $scope[_bndIsNewProject] = false;
            $scope[_bndProjectSearchMode] = false;
            $scope[_bndProjectSearchValue] = '';
            $scope[_bndProjectListViewMode] = enmProjectStatus.Active;
            $scope[_bndColorMakeVisible] = core_utils.makeFColorVisible;
            this._binds = new $classes.Binds();
            core_DO.onProjectUpdate.subscribe(this.update.bind(this));
            core_DO.onProjectStatsUpdate.subscribe(() => {
                this.update(undefined, _dupStatsUpdate);
            });
            core_DO.onCWSChange.subscribe(() => {
                const $list = this._binds.$elem(_bndProjectsList);
                if ($list.data('reserveFirst')) {
                    $list
                        .data('reserveFirst', null)
                        .find(`div.${_clsNewProject}`)
                        .remove();
                    $scope[_bndIsNewProject] = false;
                }
                // $scope[_bndProjectTags_ActiveFilter] = null;
            });
            this._super();
        },
        init() {
            this.registerWidget($classes.$factory(_wtModalDialog, _wiEditProject));
            this.registerWidget($classes.$factory(_wtWSPeopleList));
            this.registerWidget($classes.$factory(_wtWorkspaceOverview));
            this.registerWidget($classes.$factory(_wtModalDialog, _wiCloneProject));
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiInviteUser, {
                    modal: true,
                    buttons: [_drbOK, _drbCancel],
                    buttonsLabel: [{id: _drbOK, label: core.getMsg(_msgInviteUserButtonLabel)}],
                    valign: _BOX_VALIGN_TOP34
                })
            );
            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiDashboardProp, {
                    beforePopupCallback: ctx => {
                        ctx.setItems(
                            tpls.dashboard.popup({
                                sel: core_DO.settings(_skeyProjectSortBy),
                                groupByCategories: !!parseInt(core_DO.settings(_skeyProjectGroupByCategories) || 0, 10),
                                activeFilter: $scope[_bndProjectListViewMode]
                            })
                        );
                    },
                    onItemSelectCallback: (ev, ctx, data) => {
                        switch (true) {
                            case [_prsbLTA, _prsbAlphabet, _prsbCreationTime].indexOf(data['role']) > -1:
                                core_DO.settings(_skeyProjectSortBy, data['role']);
                                break;
                            case data['role'] == _prsbTag:
                                core_DO.settings(
                                    _skeyProjectGroupByCategories,
                                    parseInt(core_DO.settings(_skeyProjectGroupByCategories) || 0, 10) ? 0 : 1
                                );
                                break;
                            case data['role'] == _prsbEnableListPaging:
                                core_DO.pushFeature(_fndProjectsListPaging);
                                break;
                        }
                        this.update();
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtPopupMenu, _wiWorkspaceOverviewOpts, {
                    beforePopupCallback(ctx) {
                        ctx.setItems(tpls.dashboard.workspaceOverviewPopup());
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiProjectRecycleBin, {
                    modal: true,
                    buttons: [_drbOK, _drbClose],
                    buttonsLabel: [{id: _drbOK, label: core.getMsg(_msgProjectsPurgeAllCaption)}],
                    header: core.getMsg(_msgProjectsRestoreHeader),
                    onClose(role) {
                        if (role == _drbOK) {
                            if (!window.confirm(core.getMsg(_msgPurgeProjectConfirm))) {
                                return false;
                            }

                            const self = this;
                            const dd = [];
                            const ids = this._binds.get(_bndProjectsRecycleBin).map(d => {
                                dd.push(d);
                                return d.id;
                            });
                            const r = {workspaceId: $scope[_bndCurrentWorkspace].id, projectIds: ids};

                            this._binds.set(_bndProjectsRecycleBin, [null]);

                            core.moses
                                .announce(_rPurgeRemovedProjects, r)
                                .then(() => {
                                    self.close();
                                })
                                .catch(o => {
                                    self._binds.set(_bndProjectsRecycleBin, dd);
                                    __USE_ROLLBAR &&
                                    Rollbar.error(
                                        'dashboard::wiProjectRecycleBin::rPurgeRemovedProjects fail response',
                                        {req: r, resp: o}
                                    );
                                });
                            return false;
                        }
                    }
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiEvernoteNotebooks, {
                    modal: true,
                    header: core.getMsg(_msgEvernoteNotebooksList),
                    buttons: [_drbClose]
                })
            );
            this.registerWidget(
                $classes.$factory(_wtModalDialog, _wiProjectReport, {
                    modal: true,
                    header: 'Project Reports',
                    buttons: [_drbBack, _drbOK, _drbCancel],
                    buttonsLabel: [{id: _drbOK, label: core.getMsg(_msgProjectReports_NextButton)}],
                    afterShow($el, ctx) {
                        const obj = {};

                        $el.addClass((obj[_bndProjectReports] = _clsProjectReports_stage1));
                        ctx._binds.set(obj);
                        // $el.find('.' + _clsDialogButtons).hide();
                        ctx.align();

                        $el.on('click', `.${_clsProjectReports_Pane1_items}`, ev => {
                            const obj = {};
                            $el.removeClass(_clsProjectReports_stage1).addClass(
                                (obj[_bndProjectReports] = _clsProjectReports_stage2)
                            );

                            switch ((obj[_bndProjectReports_Pane2] = $(ev.target).data('role'))) {
                                case _clsProjectStatusReport:
                                    break;
                                case _clsWorkspaceWideOverview:
                                    setTimeout(() => {
                                        ctx.$rendered.find(`[data-role="${_drbOK}"]`).trigger('click');
                                    }, 0);
                                    break;
                            }

                            ctx._binds.set(obj);
                            // $el.find('.' + _clsDialogButtons).show();
                            ctx.align();
                        });
                    },
                    onClose(role, $el) {
                        if (role == _drbCancel) {
                            return;
                        }

                        const d = this._binds.get();
                        const self = this;

                        switch (role) {
                            case _drbBack:
                                this.$rendered
                                    .removeClass(_clsProjectReports_stage2)
                                    .addClass((obj[_bndProjectReports] = _clsProjectReports_stage1));
                                this._binds.set(obj);
                                // this.$rendered.find('.' + _clsDialogButtons).hide();
                                this.align();
                                return false;
                            case _drbOK:
                                const win = window.open('#', '_blank');
                                const doc = win.document;
                                const head = doc.querySelector('head');
                                doc.open();

                                switch (d[_bndProjectReports_Pane2]) {
                                    case _clsProjectStatusReport: {
                                        const a = $el
                                            .find('form:visible')
                                            .serializeArray()
                                            .reduce((acc, v) => {
                                                acc[v['name']] = v['value'];
                                                return acc;
                                            }, {});
                                        a.workspaceId = $scope[_bndCurrentWorkspace].id;

                                        const max = 99999;
                                        core.moses
                                            .announce(_rReportProjectsAndStats, a)
                                            .then(data => {
                                                const e = data.entries
                                                    .map(p => {
                                                        const a = p.project;
                                                        a.stats_ = p.stats.content.stats;
                                                        return a;
                                                    })
                                                    .sort(
                                                        core_DO.hasFeature(_fndProjectCategories)
                                                            ? sortProjectsByTagsAndNames
                                                            : sortProjectsByName
                                                    );

                                                core_utils.getProjectPicURL = (fid, uid) =>
                                                    core.getAvatarUrl({
                                                        stream: _avSNPrPics,
                                                        size: _avSVLarge,
                                                        fid,
                                                        uid
                                                    });

                                                doc.write(
                                                    tpls.dashboard.reportWaitScreen({
                                                        title: core.getMsg(_msgProjectReport_Title),
                                                        head: Array.prototype.slice
                                                            .call(document.querySelectorAll('link[rel="stylesheet"]'))
                                                            .map(e => e.outerHTML)
                                                            .join(''),
                                                        body: tpls.dashboard.reportProjectsStatus({data: e})
                                                    })
                                                );
                                                doc.close();

                                                setTimeout(() => {
                                                    Array.prototype.slice
                                                        .call(doc.querySelectorAll(`svg.${_clsSVGStatusLine}`))
                                                        .forEach(e => {
                                                            window[_ienSVGStatusLine](e);
                                                        });
                                                }, 50);
                                                self.close();
                                            })
                                            .catch(o => {
                                                DEBUG &&
                                                console.error(
                                                    'dashboard::wiProjectReport::rReportProjectsAndStats fail response',
                                                    {req: a, resp: o}
                                                );
                                                __USE_ROLLBAR &&
                                                Rollbar.error(
                                                    'dashboard::wiProjectReport::rReportProjectsAndStats fail response',
                                                    {req: a, resp: o}
                                                );
                                            });
                                        break;
                                    }
                                    case _clsWorkspaceWideOverview: {
                                        const a = {
                                            workspaceId: $scope[_bndCurrentWorkspace].id
                                        }

                                        core.moses
                                            .announce(_rWorkspaceOverview, a)
                                            .then(
                                                /* cWorkspaceOverviewResponse */ obj => {
                                                    const p = obj.overview.projects;
                                                    const pd = obj.overview.projectDigests;
                                                    const tasks = Armap('id', ['projectId', 'deadline']);
                                                    Object.keys(pd).forEach(id => {
                                                        Object.keys(pd[id].tasks)
                                                            .map(tid => $classes.$check(_cTaskInfo, pd[id].tasks[tid]))
                                                            .filter(t => {
                                                                if (t.type == enmTaskInfoTypes.Group) {
                                                                    return false;
                                                                }
                                                                if (t.deadline) {
                                                                    t.alerted = t.isDeadlineAlert();
                                                                    t.upcoming = moment(d.deadline)
                                                                        .subtract(2, 'd')
                                                                        .isBefore();
                                                                }
                                                                return true;
                                                            })
                                                            .forEach(t => {
                                                                t.assignedTo = t.execs
                                                                    .map(e => {
                                                                        const u = core_DO.user(e);
                                                                        return (
                                                                            (u && core.getMsg(_msgUserFLName, u)) ||
                                                                            'Uknown user'
                                                                        );
                                                                    })
                                                                    .join(', ');
                                                                tasks.$push(t);
                                                            });
                                                        tasks.forEach(t => {
                                                            if (!t.deadline) {
                                                                return;
                                                            }

                                                            const pr = {};
                                                            tasks
                                                                .$valuesByAggregateKeys({deadline: t.deadline})
                                                                .$filter(p => p.projectId != t.projectId, [])
                                                                .forEach(p => {
                                                                    !pr[p.projectId] && (pr[p.projectId] = 0);
                                                                    pr[p.projectId]++;
                                                                });
                                                            t.overlap = Object.keys(pr).map(k => ({
                                                                name: p[k].name,
                                                                qty: pr[k]
                                                            }));
                                                        });
                                                    });
                                                    const projects = Object.keys(p)
                                                        .map(i => {
                                                            const p = obj.overview.projects[i];
                                                            p._tasks = tasks
                                                                .$valuesByAggregateKeys({projectId: i}, [])
                                                                .sort((a, b) => {
                                                                    const c =
                                                                        (a.deadline || _gcMaxInteger) -
                                                                        (b.deadline || _gcMaxInteger);
                                                                    switch (true) {
                                                                        case c == 0:
                                                                            return tasksSortFn(a, b);
                                                                        default:
                                                                            return c;
                                                                    }
                                                                });
                                                            return p;
                                                        })
                                                        .filter(p => !!p._tasks.length)
                                                        .sort(
                                                            core_DO.hasFeature(_fndProjectCategories)
                                                                ? sortProjectsByTagsAndNames
                                                                : sortProjectsByName
                                                        );

                                                    doc.write(
                                                        tpls.dashboard.reportWaitScreen({
                                                            title: core.getMsg(_msgOverviewWideReport_Title),
                                                            head: Array.prototype.slice
                                                                .call(document.querySelectorAll('link[rel="stylesheet"]'))
                                                                .map(e => e.outerHTML)
                                                                .join(''),
                                                            body: tpls.dashboard.reportWorkspacesWideOverview({
                                                                data: projects,
                                                                workspaceTitle: $scope[_bndCurrentWorkspace].name
                                                            })
                                                        })
                                                    );
                                                    doc.close();

                                                    self.close();
                                                }
                                            )
                                            .catch(o => {
                                                DEBUG &&
                                                console.error(
                                                    'dashboard::wiProjectReport::rWorkspaceOverview fail response',
                                                    {req: a, resp: o}
                                                );
                                                __USE_ROLLBAR &&
                                                Rollbar.error(
                                                    'dashboard::wiProjectReport::rWorkspaceOverview fail response',
                                                    {req: a, resp: o}
                                                );
                                            });
                                        break;
                                    }

                                        this.$rendered.removeClass(_clsProjectReports_stage2).addClass(_clsProjectReports_stage3);
                                        this._binds.set(_bndProjectReports, _clsProjectReports_stage3);
                                        this.align();
                                        return false;
                                }
                        }
                    }
                })
            );
            this._super();
        },
        generateListen(r) {
            // const waitFor = ms => new Promise(r => setTimeout(r, ms));
            // const asyncForEach = async (array, callback) => {
            //     for (let index = 0; index < array.length; index++) {
            //         await callback(array[index], index, array);
            //     }
            // };
            // const randomInteger = (min, max) => {
            //     var rand = min - 0.5 + Math.random() * (max - min + 1);
            //     rand = Math.round(rand);
            //     return rand;
            // };

            r[_eDashboardTabChange] = obj => {
                let l;
                switch (obj['data']['el']) {
                    case _clsWSOverviewPane:
                        core_stat.track(_mpcCentralButton, {label: (l = 'overview')});
                        break;
                    case _clsProjectsPane:
                        core_stat.track(_mpcCentralButton, {label: (l = 'projects')});
                        break;
                    case _clsWSPeoplePane:
                        core_stat.track(_mpcCentralButton, {label: (l = 'people')});
                        break;
                }
                core.uriHandler.setUri({
                    id: _umWorkspace,
                    link: l
                });
            };
            r[_evVideoGuide] = () => {
                core_stat.track(_mpcGettingStarted, {element: 'video guide'});
                let $shield = $(tpls.iface.helpWindow({})).appendTo('body'),
                    $frame = $(
                        tpls.iface.helpVideo({url: _helpItems[_helpMainFeatures].url /*_gcVideoGuideURL*/})
                    ).appendTo('body');

                $frame.find(`div.${_clsIconCRemove}`).on('click', () => {
                    $shield.removeClass(_clsActive).bind(_gcTransitionEnd, function () {
                        $(this).remove();
                    });
                    $frame.removeClass(_clsActive).bind(_gcTransitionEnd, function () {
                        $(this).remove();
                    });
                    setTimeout(() => {
                        $shield.remove();
                        $frame.remove();
                        $shield = null;
                        $frame = null;
                    }, 500);
                });

                setTimeout(() => {
                    $frame.addClass(_clsActive);
                    $shield.addClass(_clsActive);
                }, 50);
            };
            r[_evProjectRecycleBin] = function () {
                const wi = this.widget(_wiProjectRecycleBin);
                wi.show(tpls.dashboard.projectsRecycleBin());
                wi._binds.set(_bndProjectsRecycleBin, [null]);
                let r;
                core.moses
                    .announce(
                        _rListProject,
                        (r = {
                            workspaceId: $scope[_bndCurrentWorkspace].id,
                            withStatus: enmProjectStatus.Deleted,
                            sendStats: false
                        })
                    )
                    .then(robj => {
                        robj.projects.forEach(p => {
                            // imgs.projects.add(p['id'], p['imageId'])
                            imgs.projectImage.add(p['id'], p['imageId']);
                        });

                        robj.projects.sort((a, b) => {
                            const na = a['name'].toLowerCase(),
                                nb = b['name'].toLowerCase();
                            switch (true) {
                                case na > nb:
                                    return 1;
                                case na < nb:
                                    return -1;
                                default:
                                    return 0;
                            }
                        });

                        wi._binds.set(_bndProjectsRecycleBin, robj.projects);
                        wi.$rendered
                            .find(`.${_clsDialogButtons} button[data-role="${_drbOK}"]`)
                            .css('display', (robj.projects.length == 0 && 'none') || '');
                        wi.align();
                    })
                    .catch(o => {
                        wi._binds.set(_bndProjectsRecycleBin, []);
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::evProjectRecycleBin::rListProject fail response', {
                            req: r,
                            resp: o
                        });
                    });
            };
            r[_evProjectRecycleBinPurge] = function (srcel) {
                if (!window.confirm(core.getMsg(_msgPurgeProjectConfirm))) {
                    return;
                }

                const d = d3.select($(srcel).parents(`div.${_clsPrjectRecycleBinItem}`)[0]).datum();
                const wi = this.widget(_wiProjectRecycleBin);
                const ds = wi._binds.get(_bndProjectsRecycleBin);

                const el = ds.filter(p => d.id == p.id).node();

                let r;

                $(el)
                    .addClass(_clsProcessing)
                    .find('button')
                    .attr('disabled', true);

                core.moses
                    .announce(_rPurgeRemovedProjects, (r = {workspaceId: d['workspaceId'], projectIds: [d['id']]}))
                    .then(() => {
                        $(el).fadeOut(function () {
                            $(this).remove();
                            ds[0].length == 1 && wi.close();
                        });
                    })
                    .catch(o => {
                        $(el).removeClass(_clsProcessing);
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::evProjectRecycleBinPurge::rPurgeRemovedProjects fail response', {
                            req: r,
                            resp: o
                        });
                    });
            };
            r[_evProjectRecycleBinRestore] = function (srcel) {
                const d = d3.select($(srcel).parents(`div.${_clsPrjectRecycleBinItem}`)[0]).datum();
                const wi = this.widget(_wiProjectRecycleBin);
                const ds = wi._binds.get(_bndProjectsRecycleBin);

                const el = ds.filter(p => d.id == p.id).node();

                let r;

                $(el)
                    .addClass(_clsProcessing)
                    .find('button')
                    .attr('disabled', true);

                core.moses
                    .announce(_rReactivateProject, (r = {projectId: d['id']}))
                    .then(() => {
                        $(el).fadeOut(function () {
                            $(this).remove();
                            ds[0].length == 1 && wi.close();
                        });
                    })
                    .catch(o => {
                        $(el).removeClass(_clsProcessing);
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::evProjectRecycleBinRestore::rReactivateProject fail response', {
                            req: r,
                            resp: o
                        });
                    });
            };
            r[_evOpenActiveProjects] = () => {
                core.uriHandler.setUri({
                    id: _umWorkspace,
                    link: 'projects'
                });
                // this._binds.set(_bndProjectsList, [null]);
            };
            r[_evOpenArchivedProjects] = () => {
                core.uriHandler.setUri({
                    id: _umWorkspace,
                    link: 'projects',
                    archived: true
                });
                // this._binds.set(_bndProjectsList, [null]);
            };
            r[_evAddCreditCard] = () => {
                core.moses.announce(_evCreateSubscription, {wsid: $scope[_bndCurrentWorkspace].id});
            };
            r[_evPublishOverview2Evernote] = function () {
                sync2Evernote.call(this, {
                    $event: _evPublishOverview2Evernote,
                    $mode: enmEvernoteEventRequest.PushWSOverview
                });
            };
            r[_evProjectStatus2Evernote] = function () {
                sync2Evernote.call(this, {
                    $event: _evProjectStatus2Evernote,
                    $mode: enmEvernoteEventRequest.PushWSProjectStats
                });
            };
            r[_evTaskList2Evernote] = function () {
                sync2Evernote.call(this, {
                    $event: _evTaskList2Evernote,
                    $mode: enmEvernoteEventRequest.PushProjectTaskList
                });
            };
            r[_evProjectOverview2Evernote] = function () {
                sync2Evernote.call(this, {
                    $event: _evProjectOverview2Evernote,
                    $mode: enmEvernoteEventRequest.PushProjectTeamOverview
                });
            };
            r[_evManageTags] = function () {
                $scope[_bndProjectTags_ManageMode] = true;
                if (this.sortable) {
                    this.sortable.destroy();
                }
                // Vue.nextTick(function () {
                this.sortable = new Sortable(this.$rendered.find(`.${_clsProjectTagsPane_TagsWrapper}`).get(0), {
                    disabled: false,
                    handle: `.${_clsDragHandle}`,
                    draggable: `.${_clsProjectTagsPane_TagItem}`,
                    dataIdAttr: 'data-id',
                    animation: 250,
                    chosenClass: _clsSortableChoosen,
                    ghostClass: _clsSortableGhost,
                    onSort(ev) {
                        const $list = $(ev.from).find(`.${_clsProjectTagsPane_TagItem}`);
                        const nIndex = ev.newIndex;
                        let reindexRequired = false;
                        let tIndex;
                        switch (true) {
                            case nIndex == 0:
                                var next = $($list.get(nIndex + 1)).data('index') * 1;
                                if (next == 0) {
                                    reindexRequired = true;
                                } else {
                                    tIndex = next - 1000;
                                }
                                break;
                            case nIndex == $list.length - 1:
                                var next = $($list.get(nIndex - 1)).data('index') * 1;
                                if (next == 0) {
                                    reindexRequired = true;
                                } else {
                                    tIndex = next + 1000;
                                }
                                break;
                            default:
                                const prev = $($list.get(nIndex - 1)).data('index') * 1;
                                var next = $($list.get(nIndex + 1)).data('index') * 1;
                                tIndex = Math.round((next - prev) / 2) + prev;
                                if (tIndex == 0 || tIndex == prev || tIndex == next) {
                                    reindexRequired = true;
                                }
                                break;
                        }

                        if (reindexRequired) {
                            let idx = 10;
                            const step = 1000;
                            $list.each(function () {
                                $(this).data('index', idx++ * step);
                            });
                        } else {
                            $(ev.item).data('index', tIndex);
                        }
                    }
                });
                // }.bind(this));
            };
            r[_evStoreProjectTags] = function () {
                this.$rendered.find(`.${_clsProjectTagsPane_TagItem}`).each(function (el) {
                    const $el = $(this);
                    const id = $el.data('id');
                    const n = $el.find('input[name="name"]').val();
                    const c = $el.find(`.${_clsProjectTagsPane_ColorSelector}`).css('backgroundColor');
                    const idx = $el.data('index');
                    /** @type {cProjectTagInfo} */
                    let o = new $classes[_cProjectTagInfo]({id, name: n, color: c, order: idx});

                    /** @type {cProjectTagInfo} */
                    const oo = $scope[_bndProjectTags].$item(id);

                    let r;

                    switch (true) {
                        case oo && oo[_flgMarkedForRemoval]:
                            if (oo.isNew) {
                                $scope[_bndProjectTags].$remove(id);
                            } else {
                                $scope[_bndProjectTags].$remove(id);
                                core.moses.announce(_rRemoveProjectTag, (r = {tagId: id})).catch(o => {
                                    switch (o['error']) {
                                        case 'AccessDenied':
                                            alert(core.getMsg(_msgTagRemoveErrorAccessDenied));
                                            break;
                                        default:
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'dashboard::evStoreProjectTags::rRemoveProjectTag fail response',
                                                {req: r, resp: o}
                                            );
                                            break;
                                    }
                                });
                            }
                            break;
                        case oo && !oo.isNew:
                            if (oo.name != o.name || oo.color != o.color || (oo.order != o.order && n)) {
                                o = o.$export();
                                o.tagId = o.id;
                                core.moses.announce(_rUpdateProjectTag, o).catch(f => {
                                    switch (o['error']) {
                                        case 'AccessDenied':
                                            alert(core.getMsg(_msgTagUpdateErrorAccessDenied));
                                            break;
                                        default:
                                            __USE_ROLLBAR &&
                                            Rollbar.error(
                                                'dashboard::evStoreProjectTags::rUpdateProjectTag fail response',
                                                {req: o, resp: f}
                                            );
                                            break;
                                    }
                                });
                                oo.name = o.name;
                                oo.color = o.color;
                            }
                            break;
                        case !!n:
                            core.moses.announce(_rCreateProjectTag, (r = o.$export())).catch(o => {
                                switch (o['error']) {
                                    case 'AccessDenied':
                                        alert(core.getMsg(_msgTagCreateErrorAccessDenied));
                                        break;
                                    default:
                                        __USE_ROLLBAR &&
                                        Rollbar.error(
                                            'dashboard::evStoreProjectTags::rCreateProjectTag fail response',
                                            {req: r, resp: o}
                                        );
                                        break;
                                }
                            });
                            $scope[_bndProjectTags].$remove(o.id);
                            break;
                        default:
                            $scope[_bndProjectTags].$remove(o.id);
                            break;
                    }
                });
                if (this.sortable) {
                    this.sortable.destroy();
                    this.sortable = null;
                }
                $scope[_bndProjectTags_ManageMode] = false;

                //Reset
                core.moses.announce(_evProjectCategorySelected);
            };
            r[_evCreateProjectTag] = $src => {
                const fclrs = _gcProjectTagsColors.subtract($scope[_bndProjectTags].map(p => p.color).unique());
                let nc;

                if (fclrs.length > 0) {
                    nc = fclrs[0];
                } else {
                    nc = _gcProjectTagsColors[0];
                }

                const idx = Array.prototype.reduce.call(
                    $scope[_bndProjectTags],
                    (p, c) => (p.order > c.order ? p.order : c.order),
                    0
                );

                /** @type cProjectTagInfo */
                const o = new $classes[_cProjectTagInfo]({id: core_utils.uuid(), color: nc, order: idx + 1000});
                o.isNew = true;
                $scope[_bndProjectTags].$push(o);
                setTimeout(
                    id => {
                        $(`.${_clsProjectTagsPane_TagItem}`)
                            .filter((i, el) => $(el).data('id') == id)
                            .find('input')
                            .focus();
                    },
                    100,
                    o.id
                );
            };
            r[_evResetProjectTags] = function () {
                const ids = $scope[_bndProjectTags].$filter(d => {
                    d[_flgMarkedForRemoval] = false;
                    return d.isNew;
                }, []);
                ids.forEach(d => {
                    $scope[_bndProjectTags].$remove(d.id);
                });
                if (this.sortable) {
                    this.sortable.destroy();
                    this.sortable = null;
                }
                $scope[_bndProjectTags_ManageMode] = false;
                $scope[_bndProjectTags].sort(tagsSort);
            };
            r[_evProjectCategorySelected] = function (el) {
                core.storage.$set(
                    _idDashboardActiveFilter + $scope[_bndCurrentWorkspace].id,
                    ($scope[_bndProjectTags_ActiveFilter] = $(el).data('id')) || null
                );
                this.update();
            };
            r[_evCreateProject] = function () {
                const $c = this._binds.$elem(_bndProjectsList).data('reserveFirst', 1);

                const $d = $(
                    tpls.dashboard.newProjectTpl({
                        cats: $scope[_bndProjectTags]
                    })
                );

                const $t = $c.find(`.${_clsProjectItem}`).first();
                if ($t.length) {
                    $d.insertBefore($t);
                } else {
                    $c.append($d);
                }

                $scope[_bndIsNewProject] = true;

                if (core_DO.hasFeature(_fndProjectCategories)) {
                    const tagsPane = $d.find(`.${_clsCategoriesPane}`);
                    createTagsPopup(
                        tagsPane,
                        $scope[_bndProjectTags_ActiveFilter] && [$scope[_bndProjectTags_ActiveFilter]]
                    );
                }

                this.update();
            };
            r[_evProjectReports] = function () {
                const wi = this.widget(_wiProjectReport);
                wi.show(tpls.dashboard.printProjects());
            };
            r[_evDisablePagingFeature] = function () {
                core_DO.settings(_skeyPagingFeature, false);
                this.update(this.pid);
            };
            r[_evEnablePagingFeature] = function () {
                core_DO.settings(_skeyPagingFeature, true);
                this.update(this.pid);
            };
            r[_evPagerPageEvent] = data => {
                core.uriHandler.setUri(
                    {
                        id: _umWorkspace,
                        link: 'projects',
                        archived: $scope[_bndProjectListViewMode] == enmProjectStatus.Archived,
                        page: data
                    },
                    false,
                    true,
                    true
                );
            };
            // const req = _rCreateProject;
            // r[_evCreateSpamProject] = async () => {
            //     await asyncForEach(new Array(1000), async (_, i) => {
            //         await waitFor(100);
            //         const o = {
            //             name: `aProject ${i}`,
            //             description:
            //                 "Aoccdrnig to a rscheearch at an Elingsh uinervtisy, it deosn't mttaer in waht oredr the ltteers in a wrod are, the olny iprmoetnt tihng is taht the frist and lsat ltteer is at the rghit pclae. The rset can be a toatl mses and you can sitll raed it wouthit porbelm. Tihs is bcuseae we do not raed ervey lteter by it slef but the wrod as a wlohe"
            //         };
            //         await core.moses.announce(req, o);
            //     });
            // };
            // r[_evDeleteAllProjects] = async () => {
            //     const list = core_DO.projects();
            //     await asyncForEach(list, async p => {
            //         await core.moses.announce(_rRemoveProject, { projectId: p.id });
            //         await waitFor(100);
            //     });
            // };
            // r[_evCloneInsane] = async data => {
            //     const req = _rCloneProject;
            //     await asyncForEach(new Array(100), async (_, i) => {
            //         await waitFor(5000);
            //         data.newName += i;
            //         await core.moses.announce(req, data);
            //     });
            // };
        },
        storeProject(role, $el, isnew, cleanupCallback) {
            if (role == _drbCancel) {
                cleanupCallback instanceof Function && cleanupCallback.call(null);
                return true;
            }

            const $form = $el.find('form');

            if ($form.get(0)?.checkValidity?.() === false) {
                alert(core.getMsg(_msgCheckInputField));
                return false;
            }

            /** @type cProjectInfo */
            const data = core_utils.serializeForm($form);
            isnew &&
            ((data['workspaceId'] = $scope[_bndCurrentWorkspace].id),
                $(`<div class="${_clsProcessing}">`)
                    .append(tpls.components.spinner())
                    .appendTo($form.addClass(_clsProcessing)));

            if (core_DO.hasFeature(_fndProjectCategories)) {
                const tags = getTags(data['_tags'] || '');
                delete data['_tags'];
                data.tagId = tags[0] || null;
                data.tag2Id = tags[1] || null;
            }

            const m = (isnew && _rCreateProject) || _rUpdateProject;

            if (!isnew) {
                /** @type {cProjectInfo} */
                const pr = core_DO.projectInfo(data.projectId);
                data.startDate = pr.startDate;
            }

            core.moses
                .announce(m, data)
                .then(
                    /* cCreateProjectResponse */ obj => {
                        cleanupCallback instanceof Function && cleanupCallback.call(null);
                        $form
                            .removeClass(_clsProcessing)
                            .find(`div.${_clsProcessing}`)
                            .remove();
                        if (m == _rCreateProject && !obj.billingStatus) {
                            core_stat.track(_mpcProjectCreated);
                            core_stat.inc(_mpcProjectCreatedInc);
                        }
                        switch (obj.billingStatus) {
                            case enmBillingResponseStatus.Denied:
                                Alertify.log.error(core.getMsg(_msgProjectBillingStatusDenied));
                                break;
                            case enmBillingResponseStatus.OutOfLimits:
                                Alertify.log.error(core.getMsg(_msgProjectBillingStatusOutOfLimits));
                                break;
                            case enmBillingResponseStatus.NoCard:
                                Alertify.log.error(core.getMsg(_msgProjectBillingStatusNoCard));
                                break;
                            case enmBillingResponseStatus.Unknown:
                                Alertify.log.error(core.getMsg(_msgUknownError));
                                break;
                            // case undefined:
                            //     !isnew && core_DO.updateProject(data.id || data.projectId, data);
                            //     break;
                        }
                    }
                )
                .catch(o => {
                    cleanupCallback instanceof Function && cleanupCallback.call(null);
                    switch (o['error']) {
                        case 'ReadOnly':
                            alert(core.getMsg(_msgWorkspaceIsReadOnly));
                            break;
                        default:
                            __USE_ROLLBAR &&
                            Rollbar.error(
                                `dashboard::storeProject::${
                                    m == _rCreateProject ? 'rCreateProject' : 'rUpdateProject'
                                } fail response`,
                                {req: data, resp: o}
                            );
                            DEBUG &&
                            console.error(
                                `dashboard::storeProject::${
                                    m == _rCreateProject ? 'rCreateProject' : 'rUpdateProject'
                                } fail response`,
                                {req: data, resp: o}
                            );
                            alert(core.getMsg(_msgServerError));
                            break;
                    }
                });
        },
        switchPane(id) {
            this.activePane = id;

            const $el = $(`#${id}`),
                $ch = $el.parent().children();

            $(`#page-${_pidWorkspace} div.${_clsHorizontalWrapper}`).css(
                'transform',
                `translateX(${-($ch.index($el) * 100)}%)`
            );

            this._deactivateWidgets();

            switch (this.activePane) {
                case _clsWSPeoplePane:
                    this.widget(_wtWSPeopleList).activate();
                    core_stat.track(_mpcWorkspacePeopleVisited);
                    break;
                case _clsWSOverviewPane:
                    this.widget(_wtWorkspaceOverview).activate();
                    break;
                default:
                    this._deactivateWidgets();
                    break;
            }

            core.setMainNav(_pidWorkspace, id);
        },
        uriHandler(args) {
            if (args[2] == '') {
                return false;
            }

            if (!(this.cwsq = core_DO.setCWS(args[1]))) {
                alert(core.getMsg(_msgNoWorkspaceFound));
                // core.storage.$kill(_gcSelectedWorkspace);
                core_DO.settings(_gcSelectedWorkspace, null);
                core.uriHandler.setUri(_umWorkspaces);
                return true;
            }

            const pc = core_DO.projects().length;
            if (pc < 3 && args[2] != 'overview') {
                core.uriHandler.setUri({id: _umWorkspace, wsId: args[1], link: 'projects'});
                return true;
            }
        },
        activate(args, navigate) {
            if (!($scope[_bndCurrentWorkspace] && $scope[_bndCurrentWorkspace].id == args[1])) {
                var p;
                if (!(p = core_DO.setCWS(args[1]))) {
                    alert(core.getMsg(_msgNoWorkspaceFound));
                    // core.storage.$kill(_gcSelectedWorkspace);
                    core_DO.settings(_gcSelectedWorkspace, null);
                    core.uriHandler.setUri(_umWorkspaces);
                    return false;
                }
            }

            Promise.resolve(p || this.cwsq).then(() => {
                if (navigate && core_DO.projects().length < 3 && !args[2]) {
                    args[2] = 'projects';
                }

                $scope[_bndProjectTags_ManageMode] = false;
                $scope[_bndProjectTags_ActiveFilter] = core.storage.$get(
                    _idDashboardActiveFilter + $scope[_bndCurrentWorkspace].id
                );

                dashboardLastViewMode = args[2];

                // try { core.storage.$set(_gcSelectedWorkspace, args[1]); } catch (e) {}
                core_DO.settings(_gcSelectedWorkspace, args[1]);

                if (this.pid !== args[1]) {
                    this.paging = {};
                }

                this.pid = args[1];

                // if (core_DO.hasFeature(_fndHideWhatsNew)) {
                const v = core_DO.settings(_skeyMOTDStatus);
                core.moses.announce(_rGetMessageOfTheDay).then(
                    /** @param {cMessageOfTheDayResponse|boolean} obj */obj => {
                        if (v === false) {
                            if (typeof obj == 'object' && obj.version != core_DO.settings(_skeyMOTDVersion)) {
                                core_DO.settings(_skeyMOTDStatus, true);
                                core_DO.settings(_skeyMOTDVersion, obj.version);
                            } else {
                                obj = false;
                            }
                        }
                        $scope[_bndMOD] === undefined ? $scope.$add(_bndMOD, obj) : ($scope[_bndMOD] = obj);
                    }
                );
                // } else {
                //     !$scope[_bndMOD] &&
                //         core.moses.announce(_rGetMessageOfTheDay).then(function(obj) {
                //             $scope[_bndMOD] === undefined ? $scope.$add(_bndMOD, obj) : ($scope[_bndMOD] = obj);
                //         });
                // }

                /** @type {cWorkspaceInfo} */
                const ws = $scope[_bndCurrentWorkspace];

                document.title = [ws['name'], _gcProjectTitle].join(_gcTitleSeparator);

                this._super();

                let ap;
                switch (args[2]) {
                    case 'people':
                        ap = _clsWSPeoplePane;
                        this.widget(_wtWSPeopleList).cuser = args[3];
                        break;
                    case 'projects':
                        ap = _clsProjectsPane;
                        $scope[_bndProjectListViewMode] =
                            args[3] == 'archived' ? enmProjectStatus.Archived : enmProjectStatus.Active;

                        const c = parseInt(args[5], 10) - 1;
                        const o = this.getPaging();
                        this.getPaging(isNaN(c) ? o : c);
                        this._binds.set(_bndProjectsList, [null]);
                        this._binds.set(_bndProjectsListPages, false);
                        break;
                    default:
                        ap = _clsWSOverviewPane;
                        const woau = args[3] && $scope[_bndWorkspaceUsers].$item(args[3]);
                        woau && ($scope[_bndWorkspaceOverviewActiveUser] = woau);
                        break;
                }
                this.update();
                if (core_DO.hasFeature(_fndProjectListScrollMemory)) {
                    //Do nothing
                }else{
                    this._binds.elem(_bndProjectsList).scrollTop = 0;
                }
                this._binds.set(_bndHasEvernoteFeature, core_DO.hasFeature(_fndEvernote));
                this.switchPane(ap);
            });
        },
        getPaging(page) {
            const id = [
                $scope[_bndProjectListViewMode],
                $scope[_bndProjectSearchMode],
                $scope[_bndProjectTags_ActiveFilter]
            ].join(':');
            // console.log('getPaging', id, this.paging[id], page);
            return page !== undefined ? (this.paging[id] = page) : this.paging[id] || 0;
        },
        deactivate() {
            this.sortable && this.sortable.destroy(), (this.sortable = null);
            this._super();
        },
        update(id, flags) {
            if (!this._active) {
                return;
            }
            if (id == this.pid && flags & _dupRemoved) {
                alert(core.getMsg(_msgYourAccessToWorkspaceRemoved));
                core.uriHandler.setUri(_umWorkspaces);
            }

            const $e = this._binds.$elem(_bndProjectsList);
            $e.length &&
            $e
                .removeClass(_clsCenterBox)
                .find(`div.${_clsEmpty}`)
                .remove();

            let pl;

            const searchRegExp =
                ($scope[_bndProjectSearchMode] &&
                    $scope[_bndProjectSearchValue] &&
                    new RegExp($scope[_bndProjectSearchValue], 'gi')) ||
                false;

            const filterFunc = p =>
                p.status == $scope[_bndProjectListViewMode] &&
                ($scope[_bndProjectTags_ActiveFilter]
                    ? $scope[_bndProjectTags_ActiveFilter] == p.tagId || $scope[_bndProjectTags_ActiveFilter] == p.tag2Id
                    : true) &&
                (searchRegExp ? searchRegExp.test(p.name) || searchRegExp.test(p.description) : true);

            this._binds.set(
                _bndOverviewHorizontSelect,
                core_DO.hasFeature(_fndOverviewHorizont)
                    ? core_DO.settings(_skeyOverviewHorizontKey + $scope[_bndCurrentWorkspaceUser].id) ||
                    _gcOverviewHorizontDefaultValue
                    : 0
            );

            if ($scope[_bndProjectListViewMode] == enmProjectStatus.Active) {
                pl = core_DO.projects().$filter(filterFunc);
            } else {
                if (flags == _dupStatsUpdate) {
                    pl = core_DO.projects().$filter(filterFunc);
                } else {
                    let r;
                    pl = loadedArchives[$scope[_bndCurrentWorkspace].id]
                        ? core_DO.projects().$filter(filterFunc)
                        : this._binds.set(_bndProjectsList, [null]).set(_bndProjectsListPages, false) &&
                        core.moses
                            .announce(
                                _rListProject,
                                (r = {
                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                    withStatus: enmProjectStatus.Archived,
                                    sendStats: true
                                })
                            )
                            .then(robj => {
                                loadedArchives[$scope[_bndCurrentWorkspace].id] = true;
                                core_DO.setProjects(robj.projects, true);
                                return core_DO.projects().$filter(filterFunc);
                            })
                            .catch(o => {
                                __USE_ROLLBAR &&
                                Rollbar.error('dashboard::update::rListProject fail response', {req: r, resp: o});
                            });
                }
            }

            Promise.resolve(pl).then(d => {
                d.projects && (d = d.projects);
                const $list = this._binds.$elem(_bndProjectsList);
                const isNew = $list.data('reserveFirst');

                const totalPages = Math.ceil(d.length / _gcProjectsPerPage);
                const p = this.getPaging();
                const i = p * _gcProjectsPerPage;
                const pageFeature = core_DO.settings(_skeyPagingFeature);
                this._binds.set({
                    [_bndProjectsList]: pageFeature ? d.slice(i, i + _gcProjectsPerPage) : d,
                    [_bndProjectsListPages]:
                        totalPages > 1 && pageFeature
                            ? tpls.dashboard.pager({
                                curPage: p,
                                total: totalPages
                            })
                            : false
                });

                if ($scope[_bndProjectListViewMode] == enmProjectStatus.Active) {
                    if (!$scope[_bndProjectTags_ActiveFilter] && !$scope[_bndProjectSearchMode]) {
                        if (pl.length == 0 && isNew) {
                            $list.css({
                                display: '',
                                height: 230
                            });
                        }
                    }
                } else {
                    if (d.length == 0) {
                        $list.addClass(_clsCenterBox).append(tpls.dashboard.emptyArchive());
                    }
                }
            });
        },
        editProject($el) {
            const data = d3.select($el[0]).datum();
            data.cats = $scope[_bndProjectTags];
            data.tags = [data.tagId, data.tag2Id].filter(d => !!d && core_utils.validateProjectTag(d)).join(',');
            this.widget(_wiEditProject).show(tpls.dashboard.editProject(data), {
                modal: true,
                afterShow($el, dialog) {
                    dialog._binds.set(data);
                    createTagsPopup($el.find(`.${_clsCategoriesPane}`));
                },
                onClose: this.storeProject.bind(this)
            });
        },
        cloneProject($el) {
            /** @type {cProjectInfo} */
            const dd = d3.select($el[0]).datum();
            dd.cats = $scope[_bndProjectTags];
            dd.tags = [dd.tagId, dd.tag2Id].filter(d => !!d && core_utils.validateProjectTag(d)).join(',');
            const self = this;

            this.widget(_wiCloneProject).show(tpls.dashboard.cloneProject(dd), {
                header: core.getMsg(_msgCloneProjectDialogHeader),
                modal: true,
                beforeShow($el, ctx) {
                    if (core_DO.hasFeature(_fndImprovedProjectDeadlines)) {
                        core.setupDatepicker();
                        ctx._binds.set(_bndStartDate, core_utils.packDate(core_utils.zeroTime().valueOf()));
                        $(ctx._binds.$elem(_bndStartDate))
                            .datepicker({
                                autoPick: true
                            })
                            .on('click', ev => {
                                ev.preventDefault();
                            })
                            .on('pick.datepicker', ev => {
                                ctx._binds.set(_bndStartDate, core_utils.packDate(ev.date));
                            });
                    }
                },
                afterShow($el, dialog) {
                    createTagsPopup($el.find(`.${_clsCategoriesPane}`));
                },
                onClose(role, $el) {
                    if (role == _drbCancel) {
                        return true;
                    }

                    const $form = $el.find('form');

                    if ($form.get(0)?.checkValidity?.() === false) {
                        alert(core.getMsg(_msgCheckInputField));
                        return false;
                    }

                    const data = core_utils.serializeForm($form);

                    if (core_DO.hasFeature(_fndProjectCategories)) {
                        const tags = getTags(data['_tags'] || '');
                        delete data['_tags'];
                        data.newTag = tags[0] || null;
                        data.newTag2 = tags[1] || null;
                    }

                    if (
                        core_DO.hasFeature(_fndImprovedProjectDeadlines) &&
                        $el.find(
                            `input[name="options"][value="${enmCloneProjectRequestsOption.RelativeDeadlines}"]:checked`
                        ).length
                    ) {
                        data['newStartDate'] = core_utils.packDate(this._binds.$elem(_bndStartDate).data('timestamp'));
                    }

                    core.moses.announce(_rCloneProject, data).catch(o => {
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::cloneProject::rCloneProject fail response', {req: data, resp: o});
                    });
                    // core.moses.announce(_evCloneInsane, data);
                    core.moses.announce(_evOpenActiveProjects);
                }
            });
        },
        removeProject($el) {
            const data = d3.select($el[0]).datum();
            let r;
            if (window.confirm(core.getMsg(_msgRemoveProjectConfirm))) {
                core.moses
                    .announce(_rRemoveProject, (r = {projectId: data['id']}))
                    .then(d => {
                        core_DO.removeProject(data['id']);
                    })
                    .catch(o => {
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::removeProject::rRemoveProject fail response', {req: r, resp: o});
                    });
            }
        },
        render() {
            this.$rendered = core_dom.renderAsElement(tpls.iface.page, {id: this.id});
            core_dom.renderAsElement(tpls.dashboard.decoration, null, this._binds).appendTo(this.$rendered);

            const self = this;
            this.$rendered
                .on('change', `select.${_clsOverviewHorizontSelect}`, ev => {
                    core_DO.settings(_skeyOverviewHorizontKey + $scope[_bndCurrentWorkspaceUser].id, ev.target.value);
                    const widget = self.widget(_wtWorkspaceOverview);
                    widget.$tasks.$reindex();
                    widget.update();
                })
                .on('click', `div.${_clsProjectItem}`, ev => {
                    const id = d3.select(ev.currentTarget).datum()['id'];
                    DEBUG && console.assert(id);
                    core.uriHandler.setUri({id: _umProject, prId: id});
                    return true;
                })
                .on('click', `.${_clsItemEdit}`, ev => {
                    self.$rendered.find(`.${_clsPanelOpen}`).removeClass(_clsPanelOpen);
                    $(ev.target)
                        .parents(`.${_clsProjectItem}`)
                        .addClass(_clsPanelOpen);
                    return false;
                })
                .on('click', `div.${_clsProjectItem} .${_clsItemPanelClose}`, function (ev) {
                    $(this)
                        .parents(`div.${_clsProjectItem}`)
                        .removeClass(_clsPanelOpen);
                    return false;
                })
                .on('click', `div.${_clsProjectItem} .panel .${_clsTableCell}`, ev => {
                    const role = $(ev.currentTarget).data('role'),
                        $pi = $(ev.currentTarget).parents(`div.${_clsProjectItem}`),
                        id = d3.select($pi[0]).datum()['id'];

                    DEBUG && console.assert(role);
                    DEBUG && console.assert(id);

                    switch (role) {
                        case _evProjectEdit:
                            self.editProject($pi);
                            break;
                        case _evProjectClone:
                            core_stat.track(_mpcProjectCloned);
                            self.cloneProject($pi);
                            break;
                        case _evProjectRemove:
                            self.removeProject($pi);
                            break;
                        case _evShareProject:
                            // self.shareProject($pi);
                            core_stat.track(_mpcShareSnapshot);
                            core.moses.announce(role, d3.select($pi[0]).datum());
                            break;
                        case _evProjectArchive:
                            core.moses.announce(_rArchiveProject, {projectId: id});
                            break;
                        case _evProjectReactivate:
                            core.moses.announce(_rReactivateProject, {projectId: id});
                            break;
                    }
                    $pi.find('button').trigger('click');
                    return false;
                })
                .on('click', `div.${_clsNewProject} button`, ev => {
                    const $p = $(ev.target).parents(`div.${_clsNewProject}`),
                        $f = $p.find('form'),
                        self = this,
                        callback = () => {
                            $p.remove();
                            self._binds.$elem(_bndProjectsList).data('reserveFirst', 0);
                            $scope[_bndIsNewProject] = false;
                        };

                    if ($(ev.target).data('role') == _drbOK) {
                        this.storeProject(_drbOK, $p, true, callback);
                    } else {
                        callback.call(null);
                        this.update();
                    }
                    return false;
                })
                .on('click', `.${_clsWorkspaceIsReadOnlyBanner} button.${_bndSubscribeButton}`, ev => {
                    ev.preventDefault();
                    const w = $scope[_bndCurrentWorkspace],
                        obj = {wsid: w.id};
                    if (w.workspaceLimits && w.workspaceLimits.subscriptionId) {
                        obj.sid = w.workspaceLimits.subscriptionId;
                        obj.manage = false;
                    }
                    core.moses.announce(_evCreateSubscription, obj);
                    return false;
                })
                .on('click', `.${_clsWorkspaceIsReadOnlyBanner} button.${_bndExtendTrialButton}`, ev => {
                    ev.preventDefault();
                    core_utils.banner('Asking permition to extend the trial...');
                    core.moses
                        .announce(_rRedeemTrialOffer, {workspaceId: $scope[_bndCurrentWorkspace].id})
                        .then(
                            /* cUserBillingManagementResponse */ r => {
                                if (r.status == enmBillingResponseStatus.Allowed) {
                                    $scope[_bndExtendTrialProcessed] = true;
                                }
                                core_utils.banner();
                            }
                        )
                        .catch(e => {
                            core_utils.banner();
                            console.error('bndExtendTrialButton', e);
                        });
                })
                .on('click', `.${_clsWorkspaceIsReadOnlyBanner} a`, ev => {
                    try {
                        Intercom('showNewMessage');
                    } catch (e) {
                        __USE_ROLLBAR && Rollbar.debug('Intercom::showNewMessage', {e});
                    }
                })
                .on('click', `.${_clsProjectTagsPane_ColorSelector}`, function (ev) {
                    if (
                        $(this)
                            .parent()
                            .hasClass(_clsMarkedForRemoval)
                    ) {
                        return false;
                    }
                    $(`.${_clsColorSelector_Base}`)
                        .each(function () {
                            $(this).data('active', null);
                        })
                        .fadeOut(function () {
                            $(this).remove();
                        });
                    const $trg = $(ev.target);
                    if ($trg.data('active')) {
                        $trg.data('active', null);
                        return false;
                    } else {
                        $trg.data('active', true);
                    }
                    const pos = core_dom.elementOffset($trg);
                    const $el = $(tpls.dashboard.colorSelector()).appendTo('body');
                    $el.css({
                        left: pos.left,
                        top: pos.bottom
                    }).on('click', 'div', function (ev) {
                        const $this = $(this);
                        const color = _gcProjectTagsColors[$this.data('idx')];
                        $trg.css('backgroundColor', color);
                        $trg.data('active', null);
                        $el.fadeOut(function () {
                            $(this).remove();
                        });
                    });

                    core_dom.modalEscape($el.get(0), () => {
                        $trg.data('active', null);
                        $el.fadeOut(function () {
                            $(this).remove();
                        });
                    });
                })
                .on('dragstart', `.${_clsProjectTagsPane_TagItem}`, function (ev) {
                    const $el = $(this);
                    const d = $(this).data();
                    if ($scope[_bndProjectTags_ManageMode] && $(this).hasClass(_clsSortableChoosen) == false) {
                        return false;
                    }
                    const dt = ev.originalEvent.dataTransfer;
                    dt.effectAllowed = 'link';
                    dt.setData((core.isIE && 'text') || 'tag', d.id);
                })
                .on('keydown', `.${_clsProjectTagsPane_TagItem} input`, ev => {
                    if (ev.which == 13) {
                        core.moses.announce(_evStoreProjectTags);
                        return false;
                    }
                })
                .on(_evEscPressed, `.${_clsProjectTagsPane_TagItem} input`, () => {
                    core.moses.announce(_evResetProjectTags);
                    return false;
                })
                .on('click', `.${_clsIconSearch}`, function () {
                    if ($scope[_bndProjectSearchMode]) {
                        const $el = $(this)
                            .parent()
                            .find(`.${_clsProjectSearchPanel} input`);
                        setTimeout(() => {
                            $el.focus();
                        });
                        return true;
                    }
                    $scope[_bndProjectSearchMode] = false;
                    $scope[_bndProjectSearchValue] = '';
                    self.update();
                })
                .on(_evEscPressed, `.${_clsProjectSearchPanel} input`, () => {
                    $scope[_bndProjectSearchMode] = false;
                    $scope[_bndProjectSearchValue] = '';
                    self.update();
                })
                .on(
                    'keyup',
                    `.${_clsProjectSearchPanel} input`,
                    (() => {
                        self.update();
                        return true;
                    }).debounce(250)
                );
            // .end()

            $('body')
                .on('dragenter', `.${_clsProjectItem}`, ev => {
                    const dt = ev.originalEvent.dataTransfer;
                    const t = dt.types && dt.types.length && dt.types[0];
                    if ((t == core.isIE && 'text') || 'tag') {
                        ev.preventDefault();
                    }
                })
                .on('dragover', `.${_clsProjectItem}`, ev => {
                    const dt = ev.originalEvent.dataTransfer;
                    const t = dt.types && dt.types.length && dt.types[0];
                    if ((t == core.isIE && 'text') || 'tag') {
                        ev.preventDefault();
                        dt.dropEffect = 'link';
                        ev.preventDefault();
                    }
                })
                .on('drop', `.${_clsProjectItem}`, function (ev) {
                    const id = ev.originalEvent.dataTransfer.getData((core.isIE && 'text') || 'tag');
                    ev.originalEvent.preventDefault();

                    /** @type cProjectInfo */
                    const data = d3
                        .select(this)
                        .datum()
                        .$export();

                    if (data.tagId == id || data.tag2Id == id) {
                        return false;
                    }

                    let r;
                    core.moses.announce(_rTagProject, (r = {projectId: data.id, tagId: id})).catch(o => {
                        __USE_ROLLBAR &&
                        Rollbar.error('dashboard::drop.clsProjectItem::rTagProject fail response', {req: r, resp: o});
                    });
                    return false;
                })
                .on('drop', `.${_clsNewProject}`, function (ev) {
                    const id = ev.originalEvent.dataTransfer.getData((core.isIE && 'text') || 'tag');
                    const $pane = $(this).find(`.${_clsCategoriesPane}`);
                    const tags = getTags($pane);
                    tags.push(id);
                    setTags($pane, tags.slice(-2));
                    $pane.html(updateCatsPane($pane));
                    ev.originalEvent.preventDefault();
                    return false;
                })
                .on('click', `.${_clsCategoriesSelector} .${_clsCategoryLabel}`, ev => {
                    const $pane = $(`.${_clsCategoriesPane}`);
                    const $label = $(ev.target);
                    const ids = getTags($pane);
                    ids.push($label.data('id'));
                    setTags($pane, ids);
                    $pane.html(updateCatsPane($pane));
                    $pane.popover('hide');
                    return false;
                });

            this.widget(_wiEditProject).render();
            this.widget(_wtWSPeopleList).render(this.$rendered);
            this.widget(_wtWorkspaceOverview).render(this.$rendered);
            this.widget(_wiDashboardProp)
                .attachTo(this.$rendered.find(`#${_clsProjectsPane} .${_clsWrapperHeader} .${_idSymbolCogIcon}`), 'click')
                .render();
            this.widget(_wiWorkspaceOverviewOpts)
                .attachTo(this.$rendered.find(`#${_clsWSOverviewPane} .${_clsWrapperHeader} .${_idSymbolCogIcon}`), 'click')
                .render();
            this.widget(_wiProjectReport).render();

            return this;
        }
    });

core.pages.registerController(_pcWorkspace);

core_utils.intExport(_ienProjectsPager, tpls.dashboard.pager);
core_utils.intExport(_ienProjectsGroupBy, item => {
});
core_utils.intExport(_ienCheckPagingFeatureStatus, () => core_DO.settings(_skeyPagingFeature));
