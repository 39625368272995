(function(core) {
    /**
     * Workspace/Project navigation toolbar
     * @constructor
     * @extends AbstractComponent
     */
    // @ts-ignore
    new $classes.Class(
        _wtWsToolBar,
        _clAbstractWidget,
        // @lend wtWsToolBar
        {
            selectedWorkspace: null,
            selectedProject: null,
            curPage: null,
            init: function() {
                this._super();
                core_DO.onCWSChange.subscribe(
                    function() {
                        this.selectedWorkspace = $scope[_bndCurrentWorkspace];
                        this.selectedProject = null;
                        this.update();
                    }.bind(this)
                );

                core_DO.onCPRChange.subscribe(
                    function() {
                        this.selectedProject = $scope[_bndCurrentProject];
                        this.update();
                    }.bind(this)
                );

                core_DO.onWorkspaceUpdate.subscribe(this.update.bind(this));
                core_DO.onProjectUpdate.subscribe(this.update.bind(this));
            },
            generateListen: function(r) {
                r[_MOSES_PAGE_CHANGED] = function(id) {
                    this.curPage = id;
                    var r = new RegExp(
                        '^(' + [_pidWorkspace, _pidProject, _pidManageBillingWorkspaces].join('|') + ')$'
                    );

                    if (!r.test(id) || id == _pidManageBillingWorkspaces) {
                        this.selectedProject = null;
                        this.selectedWorkspace = null;
                    } else if (this.selectedWorkspace == null) {
                        this.selectedWorkspace = $scope[_bndCurrentWorkspace];
                    } else {
                        if (id == _pidWorkspace && $scope[_bndCurrentProject]) {
                            this.selectedProject = null;
                        }
                    }
                    this.update();
                };
                r[_evChangeWSAvatar] = function() {
                    core.avatarUploader(_avSNWSPics, this.selectedWorkspace.id).catch(function(data) {
                        if (data && data.canceled) {
                            return;
                        }
                        console.error(data);
                    });
                };
            },
            update: function() {
                var ws = this.selectedWorkspace || {
                    id: null,
                    imageId: null
                };

                // this._binds.set($.extend(ws, {
                //         'prname': (this.selectedProject || {})['name'] || null,
                //         'title': this.curPage || null,
                //         'fid': ws['imageId'],
                //         'uid': ws['id']
                //     }
                // ));
                // if (ws.id) { debugger; }
                this._binds.set({
                    ws: ws,
                    prname: (this.selectedProject || {})['name'] || null
                });

                this._binds.set(_bndShowMenu, !!this.selectedWorkspace);
                this._binds.set(_bndTitle, this.curPage);
                var s = true;
                switch (this.curPage) {
                    case _pidProject:
                        s = false; //core_DO.projects().length > 1;
                        break;
                    case _pidWorkspace:
                    case _pidManageBillingWorkspaces:
                        s = core_DO.$workspaces().length > 1;
                        break;
                }
                this._binds.set(_bndShowDropDown, s);
                return this;
            },
            render: function() {
                this.$rendered = core_dom.renderAsElement(tpls.components.wsToolBar, null, this._binds);
                return this._super();
            }
        }
    );
})(core);
