(function(core) {
    var people = 'people';

    /**
     * Workspace users list
     * @constructor
     * @extends AbstractWidget
     */
    // @ts-ignore
    new $classes.Class(
        _wtWSPeopleList,
        _clAbstractWidget,
        // @lend wtWSPeopleList
        {
            cuser: null,
            init: function() {
                core_DO.onUsersUpdate.subscribe(this.update.bind(this));
                this._super();
            },
            update: function(id, remove) {
                DEBUG && console.info('wtWSPeopleList::update');
                var d = core_DO.users().map(function(d) {
                        if (enmActiveWorkspaceUserRole.has(d.role)) {
                            d.active = true;
                        }
                        return d;
                    }),
                    self = this,
                    uinfo = this.cuser && Object.assign({}, core_DO.user(this.cuser));

                d.sort(
                    /**
                     * @param {cUserInfo} a
                     * @param {cUserInfo} b
                     */
                    function(a, b) {
                        var ar = enmActiveWorkspaceUserRole.has(a.role),
                            br = enmActiveWorkspaceUserRole.has(b.role);

                        switch (true) {
                            case ar == br:
                                var al = a.lastName.toLowerCase() || 'z',
                                    bl = b.lastName.toLowerCase() || 'z';

                                switch (true) {
                                    case al == bl:
                                        var af = a.firstName.toLowerCase(),
                                            bf = b.firstName.toLowerCase();

                                        switch (true) {
                                            case af == bf:
                                                return 0;
                                            case af > bf:
                                                return 1;
                                            case af < bf:
                                                return -1;
                                        }
                                        break;
                                    case al > bl:
                                        return 1;
                                    case al < bl:
                                        return -1;
                                }
                                break;
                            default:
                                return br - ar;
                        }
                    }
                );

                this.pageController._binds.set(_bndWSUsers, d);

                uinfo &&
                    this.pageController._binds
                        .get(_bndWSUsers)
                        .attr({
                            'data-status': function(d) {
                                return d['status'];
                            },
                            'data-role': function(d) {
                                return d['role'];
                            }
                        })
                        .classed(_clsActive, function(d) {
                            return d['id'] == self.cuser;
                        });

                if (this.cuser && uinfo) {
                    this.updateUserCard(uinfo);
                }
            },
            updateUserCard: function(data) {
                data.emails =
                    data.id == $scope[_bndCurrentWorkspaceUser].id ? core_exp[_ienUserEmails](true, false) : null;
                $('#' + _clsUserDetails + ' .body')
                    .empty()
                    .removeClass(_clsEditMode)
                    .html(tpls.widgets.WSPeopleList.userCard(data));
            },
            activate: function() {
                DEBUG && console.log('wtWSPeopleList::activate');
                this.update();
                this._super();
            },
            clearList: function(throbber) {
                this.pageController._binds.set(_bndWSUsers, [null]);
            },
            render: function($el) {
                const self = this;

                $el.on('click', 'div.' + _clsRoleWrapper, function() {
                    const resRole = $(this).data('role') == _evAddResource;
                    const pc = self.pageController;
                    const tpl = tpls.widgets.WSPeopleList;
                    const wd = pc.widget(_wiInviteUser);
                    const users = core_DO.users()?.filter(function(d) {
                        return enmActiveWorkspaceUserRole.has(d.role);
                    });
                    const uqty = $scope[_bndCurrentWorkspace].workspaceLimits.limits['userActive'] - users.length;

                    wd.show(
                        (resRole && tpls.widgets.WSPeopleList.addTeamMember()) ||
                            tpls.widgets.WSPeopleList.inviteUsers(),
                        {
                            header: core.getMsg((resRole && _msgAddTeamMemberHeader) || _msgInviteUserHeader),
                            buttonsLabel: [
                                {
                                    id: _drbOK,
                                    label: core.getMsg(
                                        (resRole && _msgAddTeamMemberButtonLabel) || _msgInviteUserButtonLabel
                                    )
                                }
                            ],
                            beforeShow: function($el, ctx) {
                                if (!resRole) {
                                    ctx.__isOwner =
                                        $scope[_bndCurrentWorkspace].workspaceOwner.id ==
                                        $scope[_bndCurrentWorkspaceUser].id;
                                    ctx._binds.set(_bndInviteMoreQty, (ctx.__qty = uqty));
                                    ctx._binds.set(_bndIsOwner, /*uqty < 3 && */ ctx.__isOwner);
                                }
                            },
                            afterShow: !resRole
                                ? function($el) {
                                      $el.on('click', '.' + _bndAddMore, function() {
                                          if ($(this).hasClass(_clsDisabled)) {
                                              return false;
                                          }

                                          var p = $(this).parents('div.' + _clsDialogBody);

                                          p.find('.' + _clsInviteUsersWrapper).append(
                                              tpls.widgets.WSPeopleList.inviteUsersItem()
                                          );
                                          p.find('input')
                                              .last()
                                              .focus();
                                          if (p.css('overflow-y') != 'scroll') {
                                              wd.align();
                                          } else {
                                              p.scrollTop(9e5);
                                          }
                                          updateQty(p, wd);
                                          return false;
                                      })
                                          .on('keydown', 'select', function(ev) {
                                              if (
                                                  ev.keyCode == _keyTab &&
                                                  ev.metaKey == false &&
                                                  ev.shiftKey == false &&
                                                  $(this)
                                                      .parents('.' + _clsInviteUsersWrapper)
                                                      .find('select')
                                                      .last()[0] == this
                                              ) {
                                                  var $e = $el.find('.' + _bndAddMore);
                                                  ev.preventDefault();
                                                  if (!$e.hasClass(_clsDisabled)) {
                                                      $e.trigger('click');
                                                      return false;
                                                  }
                                                  updateQty($(this).parents('div.' + _clsDialogBody), wd);
                                              }
                                              return true;
                                          })
                                          .on('change', 'select[name="role"]', function() {
                                              updateQty($(this).parents('div.' + _clsDialogBody), wd);
                                          })
                                          .on('click', '.' + _bndUpgradeLink, function() {
                                              var w = $scope[_bndCurrentWorkspace],
                                                  obj = { wsid: w.id };
                                              if (w.workspaceLimits && w.workspaceLimits.subscriptionId) {
                                                  obj.sid = w.workspaceLimits.subscriptionId;
                                                  obj.manage = true;
                                              }
                                              core.moses.announce(_evCreateSubscription, obj);
                                              return false;
                                          });
                                  }
                                : undefined,
                            onClose: function(role, $body) {
                                if (role == _drbOK) {
                                    var $form = $body.find('form'),
                                        self = this;

                                    if (resRole) {
                                        var data = core_utils.serializeForm($form);
                                        // src.push({ name: 'role', value: enmWorkspaceUserRole.Resource });
                                        if (!data.role) {
                                            data.role = enmWorkspaceUserRole.Resource;
                                        }

                                        core.moses.announce(_rAddUserToWorkspace, data);
                                        core_stat.track(_mpcDetachedUserCreated);
                                        return true;
                                    } else {
                                        $form.find('input, select, button').attr('disabled', true);

                                        var pms = $.map($form.find(' > div.' + _clsInviteUsersItem), function(el) {
                                            return core.q(function(rslv, rjct) {
                                                var $el = $(el),
                                                    e = $el.find('input').val(),
                                                    r = $el.find('select').val(),
                                                    rq;

                                                if (e) {
                                                    $el.addClass(_clsInviteProcess);

                                                    core.moses
                                                        .announce(
                                                            _rAddUserToWorkspace,
                                                            (rq = {
                                                                email: e,
                                                                role: r
                                                            })
                                                        )
                                                        .then(
                                                            /** @param {cAddUserToWorkspaceResponse} d */ function(d) {
                                                                $el.removeClass(_clsInviteProcess);
                                                                switch (d.result) {
                                                                    case enmAddUserToWorkspaceResponseStatus.NotAllowed:
                                                                        rjct();
                                                                        $el.addClass(_clsInviteFail);
                                                                        if (
                                                                            d.billingStatus ==
                                                                            enmBillingResponseStatus.OutOfLimits
                                                                        ) {
                                                                            $el.find('.' + _clsThrobber).attr(
                                                                                'title',
                                                                                core.getMsg(
                                                                                    _msgAddUserToWorkspaceOutOfLimits
                                                                                )
                                                                            );
                                                                        }
                                                                        break;
                                                                    default:
                                                                        $el.addClass(_clsInviteDone); //_clsIconGreenMark
                                                                        rslv();
                                                                        break;
                                                                }
                                                            }
                                                        )
                                                        .catch(function(o) {
                                                            $el.removeClass(_clsInviteProcess);
                                                            DEBUG && console.error('fail', o);
                                                            __USE_ROLLBAR &&
                                                                Rollbar.error(
                                                                    'wtWSPeopleList::rAddUserToWorkspace fail response',
                                                                    { req: rq, resp: o }
                                                                );
                                                            $el.addClass(_clsInviteFail);
                                                            rjct();
                                                        });
                                                } else {
                                                    rslv();
                                                }
                                            });
                                        });

                                        Promise.all(pms)
                                            .then(function() {
                                                setTimeout(function() {
                                                    self.close();
                                                }, 500);
                                            })
                                            .catch(function() {
                                                setTimeout(function() {
                                                    $form
                                                        .find('.' + _clsInviteDone)
                                                        .fadeOut(function() {
                                                            $(this).remove();
                                                        })
                                                        .end()
                                                        .find('input, select, button')
                                                        .attr('disabled', null)
                                                        .end()
                                                        .find('.' + _clsInviteFail + ' input')
                                                        .first()
                                                        .focus();
                                                }, 250);
                                            });

                                        return false;
                                    }
                                }
                            }
                        }
                    );
                })
                    .on('click', 'div.' + _clsUserItem, function(ev) {
                        var uinfo = d3.select(this).datum();
                        var $trg = $(ev.target);

                        if ($trg.hasClass(_clsLink) && $trg.data('event') == _evResendInviteRequested) {
                            if (confirm(core.getMsg(_msgResendUserInviteConfirmation))) {
                                core.moses
                                    .announce(_rResendWorkspaceInvitation, {
                                        workspaceId: uinfo.workspaceId,
                                        workspaceUserId: uinfo.id
                                    })
                                    .then(function(obj) {
                                        switch (obj.result) {
                                            case enmResendWorkspaceInvitationResult.Sent:
                                                Alertify.log.success(core.getMsg(_msgInvitationSent));
                                                break;
                                            case enmResendWorkspaceInvitationResult.NotPending:
                                                break;
                                        }
                                    })
                                    .catch(function(o) {
                                        switch (o['error']) {
                                            case enmErrorMessage.ReadOnly:
                                                Alertify.log.error(core.getMsg(_msgWorkspaceIsReadOnly));
                                                break;
                                            case enmErrorMessage.AccessDenied:
                                                Alertify.log.error(core.getMsg(_msgAccessDenied));
                                                break;
                                            case enmErrorMessage.Error:
                                                Alertify.log.error(
                                                    core.getMsg(_msgSomeErrorOnServerWhileSavingChanges, o)
                                                );
                                                break;
                                        }
                                    });
                            }
                        } else {
                            self.cuser = uinfo['id'];

                            core.uriHandler.setUri(
                                {
                                    id: _umWorkspace,
                                    link: 'people',
                                    user: self.cuser
                                },
                                true
                            );

                            $(this)
                                .parents('.' + _clsScroller)
                                .find('div.' + _clsUserItem)
                                .removeClass(_clsActive);
                            $(this).addClass(_clsActive);
                            // $('#' + _clsUserDetails + ' .body')
                            //     .empty()
                            //     .removeClass(_clsEditMode)
                            //     .html(tpls.widgets.WSPeopleList.userCard(uinfo));
                            self.updateUserCard(uinfo);
                        }
                        return false;
                    })
                    .on('click', 'div.' + _clsUserItem + ' .release', function(ev) {
                        return false;
                    })
                    .on('click', '#' + _clsUserDetails + ' .edit', function() {
                        $(this)
                            .parents('div.body')
                            .addClass(_clsEditMode);
                    })
                    .on('click', '#' + _clsUserDetails + ' .row.action', function(ev) {
                        switch ($(this).data('role')) {
                            case _actRoleRemove:
                                if (window.confirm(core.getMsg(_msgConfirmUserRemoval))) {
                                    self.update(self.cuser);
                                    var u = core_DO.user(self.cuser);
                                    u &&
                                        u['role'] == enmWorkspaceUserRole.Resource &&
                                        core_stat.track(_mpcDetachedUserRemoved);
                                    var rq;
                                    core.moses
                                        .announce(
                                            _rRemoveUserFromWorkspace,
                                            (rq = {
                                                workspaceId: $scope[_bndCurrentWorkspace].id,
                                                workspaceUserId: self.cuser
                                            })
                                        )
                                        .catch(function(o) {
                                            __USE_ROLLBAR &&
                                                Rollbar.error(
                                                    'wtWSPeopleList::rRemoveUserFromWorkspace fail response',
                                                    {
                                                        req: rq,
                                                        resp: o
                                                    }
                                                );
                                        });
                                    self.cuser = $scope[_bndCurrentWorkspaceUser].id;
                                }
                                break;
                            case _actRoleResource2User:
                                var email = window.prompt(core.getMsg(_msgCovertResource2UserEmailRequest)),
                                    uin = core_DO.user(self.cuser);

                                if (email) {
                                    var rq;
                                    core.moses
                                        .announce(
                                            _rAttachUserToWorkspaceUser,
                                            (rq = {
                                                workspaceId: $scope[_bndCurrentWorkspace].id,
                                                workspaceUserId: self.cuser,
                                                role: enmWorkspaceUserRole.Participant,
                                                email: email,
                                                firstName: uin['firstName'],
                                                lastName: uin['lastName']
                                            })
                                        )
                                        .then(
                                            /** @param {cAttachUserToWorkspaceUserResponse} obj */ function(obj) {
                                                switch (obj.billingStatus) {
                                                    case enmBillingResponseStatus.Allowed:
                                                        switch (obj.result) {
                                                            case enmAttachUserToWorkspaceUserStatus.Attached:
                                                                Alertify.log.success(
                                                                    core.getMsg(_msgUserAttached),
                                                                    5000
                                                                );
                                                                break;
                                                            case enmAttachUserToWorkspaceUserStatus.InvitationSent:
                                                                Alertify.log.success(
                                                                    core.getMsg(_msgUserBeenInvitedToTakeOver),
                                                                    5000
                                                                );
                                                                break;
                                                            case enmAttachUserToWorkspaceUserStatus.UserExists:
                                                                Alertify.log.error(
                                                                    core.getMsg(_msgUserIsAlreadyExists),
                                                                    5000
                                                                );
                                                                break;
                                                            case enmAttachUserToWorkspaceUserStatus.NotAllowed:
                                                                Alertify.log.error(
                                                                    core.getMsg(_msgUserAttachNotAllowed)
                                                                );
                                                                break;
                                                        }
                                                        break;
                                                    case enmBillingResponseStatus.Denied:
                                                        alert(core.getMsg(_msgUserAttachDenied));
                                                        break;
                                                    case enmBillingResponseStatus.OutOfLimits:
                                                        alert(core.getMsg(_msgCannotChangeResourceToUserOutOfLimits));
                                                        break;
                                                    case enmBillingResponseStatus.NoCard:
                                                        alert(core.getMsg(_msgUserAttachNoCard));
                                                        break;
                                                    case enmBillingResponseStatus.Unknown:
                                                        alert(core.getMsg(_msgServerError));
                                                        break;
                                                }
                                            }
                                        )
                                        .catch(function(o) {
                                            __USE_ROLLBAR &&
                                                Rollbar.error(
                                                    'wtWSPeopleList::rAttachUserToWorkspaceUser fail response',
                                                    {
                                                        req: rq,
                                                        resp: o
                                                    }
                                                );
                                            alert(core.getMsg(_msgServerError));
                                        });
                                }
                                break;
                            case _actRoleUser2Resource:
                                if (window.confirm(core.getMsg(_msgConfirmUser2ResourceConvert))) {
                                    var uin = core_DO.user(self.cuser);

                                    if (
                                        uin['status'] == enmWorkspaceUserStatus.Confirmed ||
                                        uin['status'] == enmWorkspaceUserStatus.Pending
                                    ) {
                                        var rq;
                                        core.moses
                                            .announce(
                                                _rDetachUserFromWorksaceUser,
                                                (rq = {
                                                    workspaceId: $scope[_bndCurrentWorkspace].id,
                                                    workspaceUserId: self.cuser
                                                })
                                            )
                                            .catch(function(o) {
                                                DEBUG && console.error(aruments);
                                                __USE_ROLLBAR &&
                                                    Rollbar.error(
                                                        'wtWSPeopleList::rDetachUserFromWorksaceUser fail response',
                                                        { req: rq, resp: o }
                                                    );
                                            });
                                    }
                                }
                                break;
                        }
                    })
                    .on('submit', 'form.' + _clsEditWSUserForm, function() {
                        if (this.checkValidity?.() === false) {
                            alert(core.getMsg(_msgCheckInputField));
                            return false;
                        }

                        var data = {
                            ...core_DO.user(self.cuser),
                            ...$(this)
                                .serializeArray()
                                .reduce((acc, v) => {
                                    acc[v['name']] = v['value'];
                                    return acc;
                                }, {})
                        };
                        var notifyTo =
                            self.cuser == $scope[_bndCurrentWorkspaceUser].id && data[_bndSendNotificationsTo];
                        var rq;
                        core.moses
                            .announce(
                                _rUpdateWorkspaceUserInfo,
                                (rq = { workspaceId: $scope[_bndCurrentWorkspace].id, workspaceUser: data })
                            )
                            .catch(function(o) {
                                __USE_ROLLBAR &&
                                    Rollbar.error('wtWSPeopleList::rUpdateWorkspaceUserInfo fail response', {
                                        req: rq,
                                        resp: o
                                    });
                            });
                        if (notifyTo) {
                            core.moses.announce(
                                _rAssignContactInfoToWorkspaceUser,
                                (rq = {
                                    contactInfoId: notifyTo,
                                    workspaceUserId: data.id
                                })
                            );
                        }

                        // core.avatar.freeHandlers($('form.' + _clsEditWSUserForm + ' .' + _clsLogoImage));
                        $(this)
                            .parents('div.body')
                            .removeClass(_clsEditMode);
                        return false;
                    })
                    .on('click', 'form.' + _clsEditWSUserForm + ' button.cancel', function() {
                        $(this)
                            .parents('div.body')
                            .removeClass(_clsEditMode);
                        return false;
                    });

                this._super();
            }
        }
    );

    /**
     * @this {jQuery}
     */
    function updateQty($el, wd) {
        var qty = 0;

        $el.find('select[name="role"]').each(function($s) {
            if (
                enmActiveWorkspaceUserRole.has($(this).val()) &&
                $(this)
                    .prev('input')
                    .val() != ''
            ) {
                qty++;
            }
        });

        wd._binds.set(_bndInviteMoreQty, wd.__qty - qty);
        wd._binds.set(_bndIsOwner, wd.__qty - qty < 3 && wd.__isOwner);

        // var $am = $el.find('.' + _bndAddMore);
        // if (qty >= wd.__qty - 1) {
        //     $am.addClass(_clsDisabled);
        // } else {
        //     $am.removeClass(_clsDisabled);
        // }
    }
})(core);
