/**
 * Widget Abstract definition
 * @author Jacky Shikerya
 */

/**
 * Abstract Widget
 * @class AbstractWidget
 */
// @ts-ignore
new $classes.Class(
    _clAbstractWidget,
    /** @lends AbstractWidget */
    {
        /**
         * Name of the widget
         * @memberof AbstractWidget
         * @type {string}
         */
        widgetName: null,
        /**
         * Options list for the widget
         * @memberof AbstractWidget
         * @type {object}
         */
        options: null,
        /**
         * uplink to widget representation(holder) in DOM
         * @memberof AbstractWidget
         * @type {JQuery}
         */
        $element: null,
        /**
         * element widget attached to
         * @memberof AbstractWidget
         * @type {JQuery}
         */
        $rendered: null,
        /**
         * List of the Moses events to listen with handlers
         * @memberof AbstractWidget
         * @type {Object.<string, function>}
         */
        listen: {},
        /**
         * List of watches
         * @memberof AbstractWidget
         * @type {?Object.<string, function>}
         */
        watches: null,
        /**
         * DOM Event handlers definition
         * @memberof AbstractWidget
         * @type {?Object.<string, Function>}
         */
        events: null,
        /**
         * Status of the widget Active/Not active
         * @memberof AbstractWidget
         * @type {Boolean}
         */
        status: false,
        /**
         * List of data-bind controls
         * @private
         * @memberof AbstractWidget
         * @type {?Binds}
         */
        _binds: null,
        /**
         * Data storage to watch for
         * @private
         * @memberof AbstractWidget
         * @type {Object.<string, *>}
         */
        _data: {},
        /**
         * pre-initiation of the widget
         * @constructor
         * @param {string} name Widget name
         * @memberof AbstractWidget
         * @this AbstractWidget
         * @param {?JQuery} $el widget DOM representative element
         * @param {?Object.<string, *>} options list for widget
         */
        _create(name, $el, options) {
            this.widgetName = name || this.widgetName || this.Class.className;
            this.$element = $el;
            this.options = $.extend(false, this.options || {}, options || {});
            if (this.generateEvents) {
                this.events = this.events || {};
                this.generateEvents(this.events);
            }
            this._binds = new $classes.Binds();
        },
        /**
         * Initiation of the widget
         * @memberof AbstractWidget
         */
        init() {
            this.listen = this.listen || {};
            if (this.generateListen) {
                this.generateListen(this.listen);
            }
            if (!Object.isEmpty(this.listen)) {
                for (var i in this.listen) {
                    if (i == undefined) {
                        throw new Error('Cannot subscribe listener for undefined', this.listen[i]);
                    }
                    core.moses.subscribe(i, this.listen[i].bind(this));
                }
            }

            this.watches = this.watches || {};
            if (this.generateWatches) {
                this.generateWatches(w);
            }
            if (!Object.isEmpty(this.watches)) {
                let id;
                for (var i in this.watches) {
                    if (i == undefined) {
                        throw new Error('Cannot subscribe watcher for undefined', this.watcher[i]);
                    }
                    id = core.moses.watch(i, this.watches[i].bind(this));
                    this.watches[i] = id;
                }
            }

            this.events = this.events || {};
            if (this.options.events) {
                this.events = $.extend(true, this.options.events, this.events);
            }
            if (this.generateEvents) {
                this.generateEvents(this.events);
            }
        },
        setElement(el) {
            this.$element = el;
            return this;
        },
        initEvents() {
            if (!Object.isEmpty(this.events)) {
                core_dom.eventsMap(this.events, this.$rendered);
            }
            return this;
        },
        freeEvents() {
            if (!Object.isEmpty(this.events)) {
                core_dom.eventsMap(this.options.events, this.$rendered, true);
            }
            return this;
        },
        /**
         * Update options set
         * @param {Objects.<string, *>} opts New options set
         */
        setOptions(opts) {
            this.options = $.merge(true, this.options, opts);
            return this;
        },
        /**
         * Render HTML representation of the widget
         * @type {function}
         */
        render() {
            this.initEvents();
            return this;
        },
        /**
         * Syncronize data and representation
         * @type {Function}
         */
        update: () => {},
        /**
         * Widget activation
         */
        activate() {
            // if (typeof(this.$element) == 'string' && this.$element) this.$element = $(this.$element);
            this.status = true;
            return this;
        },
        isActive() {
            return this.status;
        },
        /**
         * Widget deactivation
         */
        deactivate() {
            this.status = false;
            return this;
        },
        override(method, scope) {
            this[method] = method.bind(scope || this);
            return this;
        },
        /**
         * Widget workarea cleanup
         */
        cleanup: () => {},
        /**
         * Destroy the widget and cleanup registered handler
         */
        destroy() {
            if (!Object.isEmpty(this.listen)) {
                for (var i in this.listen) {
                    core.moses.unsubscribe(i, this.listen[i].bind(this));
                }
            }
            if (!Object.isEmpty(this.watches)) {
                for (var i in this.watches) {
                    core.moses.cancelWatch(this.watch[i]);
                }
            }
            this.freeEvents();
            this.$rendered = null;
        }
    }
);
