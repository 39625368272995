$(document).on('click', 'ul.' + _clsHeadMenu_Container + ' li, ul.' + _clsHeaderMenu + ' li', function (ev) {
    var $el = $(this);

    if ($el.hasClass(_clsDisabled)) { return true; }

    switch (true) {
        case $el.hasClass(_clsToggle):
            if (!$el.hasClass(_clsActive)) {
                $el.parents('ul').find('li.' + _clsToggle).removeClass(_clsActive);
                $el.addClass(_clsActive);
            }
            break;
    }

    if (!$el.hasClass(_gcMosesWatchClass)) {
        var e = $el.data('event');
        e && core.moses.announce(e, {el: $el[0], data: $el.data()});
        return true;
    }
});

$(document).on('change', '.' + _clsSelectBox + ' select', function (ev) {
    var $el = $(this);
    $el.siblings().html($el.find('option:selected')[0].label);
    $el.trigger({
        'type': 'selectBoxChange',
        value: $el.val()
    });
});

$('body').popover({
    'html': true,
    'container': 'body',
    'placement': 'auto',
    'animation': 'true',
    'selector': '.' + _bndPopover
});
